import React, { Fragment, useContext, useEffect, useState } from "react";
import AnnouncementContext from "../../../../context/announcements/announcementContext";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

const AnnouncementAlert = () => {
  const announcementContext = useContext(AnnouncementContext);
  const { unreadAnnouncements, getUnreadAnnouncements, updateAnnouncmentRead } = announcementContext;

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter(counter + 1);
      getUnreadAnnouncements();
    }, 3600000);

    return () => clearInterval(intervalId);
  }, [counter]);

  useEffect(() => {
    getUnreadAnnouncements();
  }, []);

  const handleUpdateAlertToRead = (announcement) => {
    updateAnnouncmentRead(announcement);
  };

  return (
    <Fragment>
      {unreadAnnouncements &&
        unreadAnnouncements.length > 0 &&
        unreadAnnouncements.map((announcement) => (
          <div key={announcement.id} className={`alert alert-success`}>
            <div className="row">
              <div className="col-md-1">
                <InfoIcon fontSize="medium" />
              </div>
              <div className="col-md-8 myFont">{announcement.msg}</div>

              <div className="col-md-3">
                <CloseIcon onClick={() => handleUpdateAlertToRead(announcement)} className="float-end pointer" />
              </div>
            </div>
          </div>
        ))}
    </Fragment>
  );
};

export default AnnouncementAlert;
