import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import OrderContext from "../../../../context/order/orderContext";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import { useForm, Controller } from "react-hook-form";
import { Modal, DatePicker, Tag, List, Table } from "antd";
import moment from "moment";

const defaultValues = {};

const CompanyViewInvoiceForm = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues,
  });
  const [messages, setMessages] = useState();
  const orderContext = useContext(OrderContext);
  const { setCurrentOrder } = orderContext;
  const invoiceContext = useContext(InvoiceContext);
  const { currentInvoice, updateInvoiceStatus, updateInvoice, deleteInvoice } = invoiceContext;
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    currentInvoice && setValue("_id", currentInvoice._id);
    currentInvoice && setValue("invoicedDate", moment(currentInvoice.invoicedDate));
    currentInvoice && setValue("invoiceNumber", currentInvoice.invoiceNumber);
    currentInvoice && setValue(`order`, currentInvoice.order);
    currentInvoice && setMessages(currentInvoice.messages);
    currentInvoice && setCurrentOrder(currentInvoice.messages);
    currentInvoice && setCurrentOrder(currentInvoice.order);
  }, []);

  const onSubmit = (data) => {
    updateInvoice(data);
  };

  const setExit = () => {
    navigate(-1);
  };

  const handleViewOrder = () => {
    navigate("/company/order/form");
  };

  const dateFormat = "MM/DD/YYYY";

  const handleDeleteInvoice = () => {
    Modal.confirm({
      title: "Are you sure you want to Delete this Invoice.",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteInvoice(currentInvoice);
      },
    });
  };

  const columns = [
    {
      title: "ITEM",
      key: "item",
      render: (text, record) => <span>{record.order.serialNumber}</span>,
    },
    {
      title: "QTY",
      key: "qty",
      render: (text, record) => <span>1</span>,
    },
    {
      title: "RATE",
      key: "rate",
      render: (text, record) => (
        <span>
          {record.order.grandTotal.shop.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
    {
      title: "AMOUNT",
      key: "amount",
      render: (text, record) => (
        <span>
          {record.order.grandTotal.shop.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
    {
      title: "View",
      width: "10%",
      key: "view",
      render: (text, record) => (
        <button className="btn btn-outline-danger btn-sm " type="button" onClick={handleViewOrder}>
          View Order
        </button>
      ),
    },
  ];

  return (
    <div className="mt-2">
      <div className="row mb-4">
        <div className="col-md-12">
          <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-11">
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "8px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 mt-4">
                        <div className="form-group"></div>
                      </div>

                      <div className="col-md-7"></div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4">
                        <Tag className="mytag float-start " color="green">
                          {currentInvoice && currentInvoice.status}
                        </Tag>
                      </div>
                      <div className="col-md-4 text-center">
                        <h4>Invoice</h4>
                      </div>
                      <div className="col-md-4">
                        <button
                          className={user.viewOnly ? "viewOnly" : "btn btn-outline-danger float-end "}
                          disabled={user.viewOnly}
                          // className="btn btn-outline-danger float-end "
                          type="button"
                          onClick={(e) => handleDeleteInvoice(e)}
                        >
                          Delete Invoice
                        </button>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-5">
                        <div className="row mb-2">
                          <label className="col-sm-3 col-form-label">INVOICE #</label>
                          <div className="col-sm-9">
                            <input
                              className="form-control inputfield"
                              readOnly
                              name="invoiceNumber"
                              {...register("invoiceNumber", { required: true })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7"></div>
                    </div>
                    <div className="row ">
                      <div className="col-md-5">
                        <div className="row mb-2">
                          <label className="col-sm-3 col-form-label">DATE</label>
                          <div className="col-sm-9">
                            <Controller
                              render={({ field, name }) => (
                                <DatePicker
                                  format={dateFormat}
                                  className="form-control inputfield"
                                  name={name}
                                  {...field}
                                  disabled
                                />
                              )}
                              control={control}
                              name={`invoicedDate`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7"></div>
                    </div>
                    <div className="row ">
                      <div className="col-md-5">
                        <div className="row mb-2">
                          <label className="col-sm-3 col-form-label">BILL TO:</label>
                          <div className="col-sm-9">
                            <input
                              readOnly
                              className="form-control inputfield"
                              name="billTo"
                              defaultValue={"StorMor"}
                              {...register("billTo", { required: true })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7"></div>
                    </div>
                    {currentInvoice && <Table columns={columns} dataSource={[currentInvoice]} pagination={false} />}

                    <div className="row mb-4 mt-4">
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8"></div>
        <div className="col-md-4">
          <List
            itemLayout="horizontal"
            dataSource={messages}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta title={item.madeBy} description={new Date(item.date).toLocaleString()} />
                <List.Item.Meta title={item.msg} />
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyViewInvoiceForm;
