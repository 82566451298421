import React, { Fragment, useContext } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import InvoiceContext from "../../../../../context/invoice/invoiceContext";
import NumberFormat from "react-number-format";
import { Input, Table } from "antd";

export default function Fields({ control, register, setValue, getValues }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "invoices",
  });

  const invoiceContext = useContext(InvoiceContext);
  const { getInvoiceById } = invoiceContext;

  const handleViewInvoice = (e, record) => {
    e.preventDefault();
    getInvoiceById(record.invoiceId);
  };

  const columns = [
    {
      title: "Invoice #",
      dataIndex: "invoice",
      key: "invoice",
      render: (_, record, index) => (
        <Controller
          render={({ field, name }) => (
            <input readOnly className="form-control custom-input readonlyinput" name={name} {...field} />
          )}
          control={control}
          name={`invoices[${index}].invoice.invoiceNumber`}
        />
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record, index) => (
        <Controller
          render={({ field, name }) => (
            <NumberFormat
              readOnly
              customInput={Input}
              className={`form-control custom-input readonlyinput`}
              style={{ fontSize: 16, padding: 6 }}
              {...field}
              name={name}
              thousandSeparator={true}
              // suffix={' %'}
              prefix={"$ "}
              displayType="input"
              decimalScale={2}
              fixedDecimalScale="true"
            />
          )}
          name={`invoices[${index}].invoice.order.grandTotal.shop`}
          control={control}
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record, index, invoiceId) => (
        <button className="btn btn-primary" onClick={(e) => handleViewInvoice(e, record)}>
          View Invoice
        </button>
      ),
    },
  ];

  const data = fields.map((field, index) => {
    return {
      key: index,
      invoiceId: field.invoice._id,
      // ... other fields
    };
  });

  return <Table columns={columns} dataSource={data} pagination={false} />;
}
