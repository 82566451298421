import React, { useReducer } from "react";
import ComponentCategoryContext from "./componentCategoryContext";
import componentCategoryReducer from "./componentCategoryReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_COMPONENT_CATEGORY,
  DELETE_COMPONENT_CATEGORY,
  CLEAR_COMPONENT_CATEGORYS,
  SET_CURRENT_COMPONENT_CATEGORY,
  CLEAR_CURRENT_COMPONENT_CATEGORY,
  UPDATE_COMPONENT_CATEGORY,
  COMPONENT_CATEGORY_ERROR,
  GET_COMPONENT_CATEGORYS,
  SET_LOADING_COMPONENT_CATEGORY,
} from "../types";

const ComponentCategoryState = (props) => {
  const initialState = {
    componentCategorys: null,
    currentComponentCategory: null,
    loadingComponentCategory: false,
    error: null,
  };

  const [state, dispatch] = useReducer(componentCategoryReducer, initialState);

  // get all componentCategorys
  const getComponentCategorys = async () => {
    try {
      const res = await axios.get(`/api/component/category/`);
      dispatch({ type: GET_COMPONENT_CATEGORYS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMPONENT_CATEGORY_ERROR });
    }
  };

  const addComponentCategory = async (componentCategory) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/component/category/", componentCategory, config);
      dispatch({ type: ADD_COMPONENT_CATEGORY, payload: res.data });
    } catch (err) {
      dispatch({ type: COMPONENT_CATEGORY_ERROR });
    }
  };

  const deleteComponentCategory = async (id) => {
    try {
      const res = await axios.delete(`/api/component/category/delete/${id}`);
      dispatch({ type: DELETE_COMPONENT_CATEGORY, payload: id });
    } catch (err) {
      dispatch({ type: COMPONENT_CATEGORY_ERROR });
    }
  };

  // update ComponentCategory
  const updateComponentCategory = async (componentCategory) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/component/category/update/${componentCategory._id}`, componentCategory, config);
      dispatch({ type: UPDATE_COMPONENT_CATEGORY, payload: componentCategory });
      toast.success(`componentCategory is updated`);
    } catch (err) {
      dispatch({ type: COMPONENT_CATEGORY_ERROR });
    }
  };

  const clearComponentCategorys = () => {
    dispatch({ type: CLEAR_COMPONENT_CATEGORYS });
  };

  const setCurrentComponentCategory = (componentCategory) => {
    dispatch({ type: SET_CURRENT_COMPONENT_CATEGORY, payload: componentCategory });
  };

  const clearCurrentComponentCategory = () => {
    dispatch({ type: CLEAR_CURRENT_COMPONENT_CATEGORY });
  };

  const setLoadingComponentCategory = (bool) => {
    dispatch({ type: SET_LOADING_COMPONENT_CATEGORY, payload: bool });
  };

  return (
    <ComponentCategoryContext.Provider
      value={{
        componentCategorys: state.componentCategorys,
        currentComponentCategory: state.currentComponentCategory,
        filteredComponentCategorys: state.filteredComponentCategorys,
        loadingComponentCategory: state.loadingComponentCategory,
        error: state.error,
        getComponentCategorys,
        addComponentCategory,
        deleteComponentCategory,
        setCurrentComponentCategory,
        clearCurrentComponentCategory,
        updateComponentCategory,
        clearComponentCategorys,
        setLoadingComponentCategory,
      }}
    >
      {props.children}
    </ComponentCategoryContext.Provider>
  );
};

export default ComponentCategoryState;
