import {
  ADD_ADDON_CATEGORY,
  DELETE_ADDON_CATEGORY,
  CLEAR_ADDON_CATEGORYS,
  SET_CURRENT_ADDON_CATEGORY,
  CLEAR_CURRENT_ADDON_CATEGORY,
  UPDATE_ADDON_CATEGORY,
  FILTER_ADDON_CATEGORYS,
  CLEAR_FILTER_ADDON_CATEGORY,
  ADDON_CATEGORY_ERROR,
  GET_ADDON_CATEGORYS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ADDON_CATEGORYS:
      return {
        ...state,
        addonCategorys: action.payload,
        loadingAddonCategory: false,
      };

    case ADD_ADDON_CATEGORY:
      return {
        ...state,
        addonCategory: action.payload,
        loading: false,
      };
    case UPDATE_ADDON_CATEGORY:
      const updatedAddonCategorys = state.addonCategorys.map((addonCategory) =>
        addonCategory._id === action.payload._id ? action.payload : addonCategory
      );
      return {
        ...state,
        addonCategorys: updatedAddonCategorys,
        loading: false,
      };
    case DELETE_ADDON_CATEGORY:
      return {
        ...state,
        addonCategorys: state.addonCategorys.filter((addon) => addon._id !== action.payload),
        loading: false,
      };
    case CLEAR_ADDON_CATEGORYS:
      return {
        ...state,
        addonCategorys: null,
        filteredAddonCategory: null,
        error: null,
        currentAddonCategory: null,
      };
    case SET_CURRENT_ADDON_CATEGORY:
      return {
        ...state,
        currentAddonCategory: action.payload,
      };
    case CLEAR_CURRENT_ADDON_CATEGORY:
      return {
        ...state,
        currentAddonCategory: null,
      };
    case FILTER_ADDON_CATEGORYS:
      return {
        ...state,
        filteredAddonCategory: state.AddonCategorys.filter((addonCategory) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return addonCategory.customer.value.name.match(regex);
        }),
      };
    case CLEAR_FILTER_ADDON_CATEGORY:
      return {
        ...state,
        filteredAddonCategory: null,
      };
    case ADDON_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
