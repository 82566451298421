import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import ShopContext from "./shopContext";
import shopReducer from "./shopReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_SHOP,
  DELETE_SHOP,
  CLEAR_SHOPS,
  SET_CURRENT_SHOP,
  CLEAR_CURRENT_SHOP,
  UPDATE_SHOP,
  SET_SHOPS,
  SHOP_ERROR,
  GET_SHOPS,
  FILTER_SHOPS,
  CLEAR_FILTER_SHOP,
} from "../types";

const ShopState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    shops: null,
    currentShop: null,
    error: null,
    filteredShop: null,
  };

  const [state, dispatch] = useReducer(shopReducer, initialState);

  const getShops = async () => {
    try {
      const res = await axios.get(`/api/shop`);
      dispatch({ type: GET_SHOPS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHOP_ERROR });
      console.log("this is the err", err);
      toast.error(`Shop ${err}`);
    }
  };

  const getShop = async () => {
    try {
      const res = await axios.get(`/api/shop/current`);
      setCurrentShop(res.data);
    } catch (err) {
      dispatch({ type: SHOP_ERROR });
      console.log("this is the err", err);
      toast.error(`Shop ${err}`);
    }
  };

  // add
  const addShop = async (shop) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/shop", { shop }, config);
      dispatch({ type: ADD_SHOP, payload: res.data });
    } catch (err) {
      dispatch({ type: SHOP_ERROR });
    }
  };

  // delete
  const deleteShop = async (id) => {
    try {
      const res = await axios.delete(`/api/shop/delete/${id}`);
      dispatch({ type: DELETE_SHOP, payload: id });
      toast.success(`Shop is deleted`);
    } catch (err) {
      dispatch({ type: SHOP_ERROR });
    }
  };

  // update
  const updateShop = async (id, shop) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/shop/update/${id}`, shop, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_SHOP, payload: res.data });
      toast.success(`Shop is updated`);
    } catch (err) {
      dispatch({ type: SHOP_ERROR });
      toast.error(`Shop ${err}`);
    }
  };

  const updateShopMetal = async (id, shop) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/shop/metal/update/shop/${id}`, shop, config);
      dispatch({ type: UPDATE_SHOP, payload: res.data });
      toast.success(`Shops metal items are updated`);
    } catch (err) {
      dispatch({ type: SHOP_ERROR });
      toast.error(`Shop ${err}`);
    }
  };

  const updateStandardMetal = async (id, shop) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/shop/metal/update/standard/${id}`, shop, config);
      dispatch({ type: UPDATE_SHOP, payload: res.data });
      toast.success(`Shops metal items are updated`);
    } catch (err) {
      dispatch({ type: SHOP_ERROR });
      toast.error(`Shop ${err}`);
    }
  };

  // clear
  const clearShops = () => {
    dispatch({ type: CLEAR_SHOPS });
  };

  // set current
  const setCurrentShop = (shop) => {
    dispatch({ type: SET_CURRENT_SHOP, payload: shop });
  };

  const setShops = (labors) => {
    dispatch({ type: SET_SHOPS, payload: labors });
  };

  // clear current
  const clearCurrentShop = () => {
    dispatch({ type: CLEAR_CURRENT_SHOP });
  };

  const filterShops = (text) => {
    dispatch({ type: FILTER_SHOPS, payload: text });
  };

  const clearFilterShop = () => {
    dispatch({ type: CLEAR_FILTER_SHOP });
  };

  return (
    <ShopContext.Provider
      value={{
        shops: state.shops,
        currentShop: state.currentShop,
        error: state.error,
        filteredShop: state.filteredShop,
        getShops,
        setShops,
        addShop,
        deleteShop,
        setCurrentShop,
        clearCurrentShop,
        updateShop,
        clearShops,
        filterShops,
        clearFilterShop,
        getShop,
        updateShopMetal,
        updateStandardMetal,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopState;
