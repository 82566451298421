import React, { Fragment, useEffect, useContext } from "react";
import DealerContext from "../../../../context/dealer/dealerContext";
import DealerColumns from "./DealerColumns";

const AllDealers = () => {
  const dealerContext = useContext(DealerContext);
  const { getDealers, dealers, filteredDealer } = dealerContext;

  useEffect(() => {
    getDealers();
  }, []);

  useEffect(() => {}, [filteredDealer]);

  const theTitle = "Dealers";

  return (
    <Fragment>
      <div>
        <DealerColumns dealers={dealers} theTitle={theTitle} filteredDealer={filteredDealer} />
      </div>
    </Fragment>
  );
};

export default AllDealers;
