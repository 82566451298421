import React, { Fragment, useEffect, useContext } from "react";
import CompanyContext from "../../../../../context/company/companyContext";
import UserColumns from "./UserColumns";

const AllUsers = () => {
  const companyContext = useContext(CompanyContext);
  const { company } = companyContext;
  const theTitle = "Users";

  return <UserColumns shopUsers={company && company.companyUsers} theTitle={theTitle} />;
};

export default AllUsers;
