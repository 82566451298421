import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OrderChartOne = ({ orders }) => {
  const labels = [];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false, // Remove the title as it's now in the Card
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: `Count`,
        data: [
          { x: "In Queue", y: orders?.filter((order) => order.status === "InQueue").length },
          { x: "Building", y: orders?.filter((order) => order.status === "Building").length },
          { x: "Finished", y: orders?.filter((order) => order.status === "Finished").length },
          { x: "On Hold", y: orders?.filter((order) => order.status === "On Hold").length },
        ],
        backgroundColor: ["rgba(170, 235, 255)", "rgba(129, 200, 255)", "rgba(156, 212, 152)", "rgba(100, 200, 100)"],
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "300px" }}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default OrderChartOne;
