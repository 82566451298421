import React, { Fragment, useEffect, useContext, useState } from "react";
import PaymentContext from "../../../../../context/payment/paymentContext";
import AuthContext from "../../../../../context/auth/authContext";
import { Table, Modal, Button, Spin } from "antd";
import * as XLSX from "xlsx";
// import UploadPayment from "./UploadPayment";

const CompanyPayments = () => {
  const paymentContext = useContext(PaymentContext);
  const { getPayments, payments, getPaymentById, deletePayment, recalculatePayment } = paymentContext;
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPayments();
  }, []);

  const handleViewPayment = (payment) => {
    getPaymentById(payment._id);
  };

  // const handleUpdatePaymentHaul = (payment) => {
  //   updateHaulPayment(payment);
  // };

  const handleDeletePayment = (payment) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Payment? This will revert all Invoices in this Payment to Approved.",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deletePayment(payment);
      },
    });
  };

  const handleExportToExcel = () => {
    const dataToExport = payments.map((payment) => ({
      Shop: payment.shop.name,
      "Payment Number": payment.paymentNumber,
      "Payment Date": payment.paymentDate ? new Date(payment.paymentDate).toLocaleDateString() : "",
      "Shop Amount": payment.paymentTotal.shop,
      "Company Amount": payment.paymentTotal.company,
      "Order Amount": payment.paymentTotal.order,
      "Standard Profit %": payment.averageProfitPercent ? (payment.averageProfitPercent.standard * 100).toFixed(2) : "",
      "Shop Profit %": payment.averageProfitPercent ? (payment.averageProfitPercent.shop * 100).toFixed(2) : "",
      "StorMor Profit %": payment.averageProfitPercent ? (payment.averageProfitPercent.company * 100).toFixed(2) : "",
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Payments");
    XLSX.writeFile(wb, "company_payments.xlsx");
  };

  const columns = [
    {
      title: `Shop`,
      sorter: (a, b) => b.madeBy.name > a.madeBy.name,
      defaultSortOrder: ["descend"],
      render: (text, record) => <span>{record.shop.name}</span>,
    },
    {
      title: `Payment Number`,
      render: (text, record) => <span>{record.paymentNumber}</span>,
    },
    {
      title: `Payment Date`,
      sorter: (a, b) => a.paymentDate < b.paymentDate,
      defaultSortOrder: ["descend"],
      render: (text, record) => <span>{record.paymentDate && new Date(record.paymentDate).toLocaleDateString()}</span>,
    },

    {
      title: `Shop Amount`,
      render: (text, record) => (
        <span>
          {record &&
            record.paymentTotal.shop.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },
    {
      title: `Company Amount`,
      render: (text, record) => (
        <span>
          {record &&
            record.paymentTotal.company.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },
    {
      title: `Order Amount`,
      render: (text, record) => (
        <span>
          {record &&
            record.paymentTotal.order.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },
    {
      title: `Standard Profit %`,
      render: (text, record) => (
        <span>{record.averageProfitPercent && (record.averageProfitPercent.standard * 100).toFixed(2)} %</span>
      ),
    },
    // {
    //   title: `Shop Profit %`,
    //   render: (text, record) => (
    //     <span>{record.averageProfitPercent && (record.averageProfitPercent.shop * 100).toFixed(2)} %</span>
    //   ),
    // },
    {
      title: `StorMor Profit %`,
      render: (text, record) => (
        <span>{record.averageProfitPercent && (record.averageProfitPercent.company * 100).toFixed(2)} %</span>
      ),
    },

    {
      title: "Actions",
      width: "15%",
      align: "center",
      render: (text, record) => (
        <Fragment>
          {/* <PopConfirm
              record={record}
              msg={'Approve Invoice?'}
              btnText={'Approve'}
              theStatus={'Approved'}
              myClasses={'btn btn-xs btn-outline-success'}
            />
            <PopConfirm record={record} msg={'Deny Invoice?'} btnText={'Deny'} theStatus={'Denied'} myClasses={'btn btn-xs btn-outline-danger'} /> */}

          <button className="btn btn-xs btn-outline-primary mb-2" type="button" onClick={(e) => handleViewPayment(record, e)}>
            View
          </button>
          {/* {user.superRole === "admin" && ( */}
          {/* <Fragment> */}
          <br />
          <button
            className={user.viewOnly ? "viewOnly" : "btn btn-xs btn-outline-danger mb-2"}
            disabled={user.viewOnly}
            // className="btn btn-xs btn-outline-danger"
            type="button"
            onClick={(e) => handleDeletePayment(record)}
          >
            Delete
          </button>
          {/* {user && user.name === "Matt Schmidt" && (
            <Fragment> */}
          <br />
          <button className="btn btn-xs btn-outline-warning" type="button" onClick={(e) => showConfirmModal(record, e)}>
            Recalculate
          </button>
          {/* </Fragment>
          )} */}

          {/* </Fragment> */}
          {/* )} */}
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const showConfirmModal = (payment) => {
    setSelectedPayment(payment);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsLoading(true);
    await recalculatePayment(selectedPayment._id);
    setIsLoading(false);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    if (!isLoading) {
      setIsModalVisible(false);
    }
  };

  return (
    <div>
      <div className="row">
        {/* <UploadPayment /> */}
        <div className="col-md-4">{/* <ShopFilter /> */}</div>

        <div className="col-md-4 text-center mb-2">
          <h3>Payments</h3>
        </div>

        <div className="col-md-4 text-right">
          <Button onClick={handleExportToExcel} type="primary">
            Export to Excel
          </Button>
        </div>
      </div>

      <div className="row">
        <Table
          rowClassName={() => "hover-row"}
          pagination={{ pageSize: 200 }}
          loading={payments ? false : true}
          columns={columns}
          dataSource={payments && payments}
          rowKey="_id"
          onChange={onChange}
        />
      </div>
      <Modal
        title="Recalculate Payment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isLoading}
        okText={isLoading ? "Recalculating..." : "Recalculate"}
        cancelButtonProps={{ disabled: isLoading }}
      >
        {isLoading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <Spin size="large" />
            <p style={{ marginTop: "10px" }}>Recalculating payment...</p>
          </div>
        ) : (
          <p>
            Are you sure you want to recalculate this payment? This will recalculate the Cost and Profit for each order within the
            payment based off of current Items cost. Then we will also recalculate the Payment Totals based off of the Orders new
            Cost and Profit numbers.
          </p>
        )}
      </Modal>
    </div>
  );
};

export default CompanyPayments;
