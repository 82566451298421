import React, { useState, useContext, useEffect } from "react";
import { Comment, Form, Button, List, Input } from "antd";
import CommentContext from "../../../../context/comment/commentContext";
import AuthContext from "../../../../context/auth/authContext";
import ShopContext from "../../../../context/shop/shopContext";

import axios from "axios";

// import io from "socket.io-client";
// const ENDPOINT = "http://localhost:5010";

const { TextArea } = Input;

const CommentList = ({ orderComments }) => (
  <List
    className="myCommentBackground p-2"
    dataSource={orderComments}
    header={`${orderComments.length} ${orderComments.length > 1 ? "replies" : "reply"}`}
    itemLayout="horizontal"
    renderItem={(item) => (
      <li>
        <Comment
          // actions={item.actions}
          author={item.author}
          content={item.content}
          datetime={item.datetime}
        />
      </li>
    )}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Add Comment
      </Button>
    </Form.Item>
  </>
);

const AddComment = ({ currentOrderComments }) => {
  const commentContext = useContext(CommentContext);
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { addOrderComment, orderComments, getCommentsForOrder } = commentContext;
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");

  const fetchMessages = async () => {
    if (!currentOrderComments) return;
    try {
      getCommentsForOrder(currentOrderComments);
    } catch (error) {
      console.log("there is a error", error);
    }
  };

  const sendMessage = async () => {
    try {
      let today = new Date();
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        "/api/comment",
        {
          order: currentOrderComments._id,
          author: user.name,
          content: value,
          commentFor: user.role === "Shop Admin" || user.role === "Shop User" ? user.companyId : currentOrderComments.shop._id,
          commentForType: user.role === "Shop Admin" || user.role === "Shop User" ? "Company" : "Shop",
          commentFrom: user.role === "Shop Admin" || user.role === "Shop User" ? currentOrderComments.shop._id : user.companyId,
          commentFromType: user.role === "Shop Admin" || user.role === "Shop User" ? "Shop" : "Company",
          datetime: today.toLocaleString(),
        },
        config
      );
      addOrderComment(data);
      setValue("");
    } catch (error) {
      console.log("there is a error", error);
    }
    // }
  };

  useEffect(() => {
    fetchMessages();
    // selectedOrder = currentOrderComments && currentOrderComments._id;
    // eslint-disable-next-line
  }, [currentOrderComments]);

  useEffect(() => {
    setValue("");
  }, [orderComments]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!value) {
      return;
    }
    sendMessage();
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  orderComments &&
    orderComments.forEach((comment) => {
      comment.datetime = new Date(comment.datetime).toLocaleString();
    });

  return (
    <>
      {orderComments && orderComments.length > 0 && <CommentList orderComments={orderComments} />}
      <Comment
        className="myCommentBackground p-2"
        author={user.userName}
        content={<Editor onChange={handleChange} onSubmit={(e) => handleSubmit(e)} submitting={submitting} value={value} />}
      />
    </>
  );
};

export default AddComment;
