// import React, { useState, Fragment, useEffect } from "react";
// import { useFieldArray, Controller } from "react-hook-form";
// import ReactSelect from "react-select";
// import { Table } from "antd";

// export default function Fields({
//   control,
//   register,
//   setValue,
//   getValues,
//   newComponents,
//   company,
//   shedCategorys,
//   currentShop,
//   getMetalComponents,
//   watch,
// }) {
//   const { fields, append, remove } = useFieldArray({
//     control,
//     name: "metalOrder",
//   });

//   const appendToItems = (recordKey) => {
//     const newItem = { item: { name: "" }, quantity: 0 };

//     setValue(`metalOrder[${recordKey}].metalPackage.items`, [...formValues.metalOrder[recordKey].metalPackage.items, newItem]);
//   };

//   // const handleAddMetalPack = () => {
//   //   clearMetals();
//   // };
//   const metalPakOptions =
//     newComponents &&
//     newComponents.map((option) => {
//       return { label: `${option.name}`, value: option };
//     });

//   const shedCategoryOptions =
//     shedCategorys &&
//     shedCategorys.map((option) => {
//       return { label: `${option.name}`, value: option };
//     });

//   const roofColorOptions =
//     company &&
//     company.roofColors.map((option) => {
//       return { label: `${option.color}`, value: option };
//     });

//   const sidingColorOptions =
//     company &&
//     company.sidingColors.map((option) => {
//       return { label: `${option.color}`, value: option };
//     });

//   const trimColorOptions =
//     company &&
//     company.trimColors.map((option) => {
//       return { label: `${option.color}`, value: option };
//     });

//   const setMetalOrderChange = (e, metalOrderIndex) => {
//     let name = { label: e.label, value: e.value._id };
//     setValue(`metalOrder[${metalOrderIndex}].metalPackage.name`, name);
//     setValue(`metalOrder[${metalOrderIndex}].metalPackage.items`, e.value.shopItems);
//     setValue(`metalOrder[${metalOrderIndex}].metalPackage._id`, e.value._id);
//     let theMetalPakage = getValues(`metalOrder[${metalOrderIndex}].metalPackage`);
//   };

//   const setSerialNumber = (serialNumber, metalOrderIndex) => {
//     setValue(`metalOrder[${metalOrderIndex}].serialNumber`, serialNumber);
//   };

//   const setZone = (category, index) => {
//     // Set the shedCategory field in the form
//     setValue(`metalOrder[${index}].shedCategory`, category.value._id);
//     getMetalComponents(category.value._id, currentShop._id);
//     setZoneSelected(false);
//   };

//   const setRoofColorChange = (e, metalOrderIndex) => {
//     setValue(`metalOrder[${metalOrderIndex}].roofColor`, e);
//   };
//   const setSidingColorChange = (e, metalOrderIndex) => {
//     setValue(`metalOrder[${metalOrderIndex}].sidingColor`, e);
//   };
//   const setTrimColorChange = (e, metalOrderIndex) => {
//     setValue(`metalOrder[${metalOrderIndex}].trimColor`, e);
//     console.log("This is e", e);
//   };

//   const [zoneSelected, setZoneSelected] = useState(true);

//   // metalOrder.value && setValue(`metalOrder[${metalOrderIndex}].value.label`, metalOrder.value.title);
//   const [selectedOptions, setSelectedOptions] = useState([]);

//   useEffect(() => {
//     const newSelectedOptions = fields.map((field) => {
//       const initialShedCategoryId = field.shedCategory;
//       return shedCategoryOptions?.find((option) => option.value._id === initialShedCategoryId);
//     });

//     // Check if newSelectedOptions is different from the current selectedOptions
//     if (JSON.stringify(newSelectedOptions) !== JSON.stringify(selectedOptions)) {
//       setSelectedOptions(newSelectedOptions);
//     }
//   }, [fields, shedCategoryOptions, selectedOptions]);
//   const columns = [
//     {
//       title: "Zone",
//       dataIndex: "shedCategory",
//       key: "shedCategory",
//       render: (text, record, index) => {
//         return (
//           <Controller
//             render={({ field }) => (
//               <ReactSelect
//                 {...field}
//                 options={shedCategoryOptions}
//                 value={selectedOptions[index] || null}
//                 menuPortalTarget={document.body}
//                 menuPosition={"fixed"}
//                 onChange={(e) => {
//                   field.onChange(e.value._id);
//                   setZone(e, index);
//                   const newSelectedOptions = [...selectedOptions];
//                   newSelectedOptions[index] = e;
//                   setSelectedOptions(newSelectedOptions);
//                   // Update the corresponding field in the fields array
//                   const newFields = [...fields];
//                   newFields[index].shedCategory = e.value._id;
//                   setValue("metalOrder", newFields);
//                 }}
//               />
//             )}
//             name={`metalOrder[${index}].shedCategory`}
//             control={control}
//           />
//         );
//       },
//     },

//     {
//       title: "Metal Package",
//       dataIndex: "metalPackage",
//       key: "metalPackage",
//       render: (text, record, index) => (
//         <Controller
//           render={({ field }) => (
//             <ReactSelect
//               {...field}
//               isDisabled={!metalPakOptions}
//               options={metalPakOptions}
//               menuPortalTarget={document.body}
//               menuPosition={"fixed"}
//               onChange={(e) => setMetalOrderChange(e, index)}
//             />
//           )}
//           name={`metalOrder[${index}].metalPackage.name`}
//           control={control}
//         />
//       ),
//     },
//     {
//       title: "Serial Number",
//       dataIndex: "metalPackage",
//       key: "metalPackage",
//       render: (text, record, index) => (
//         <Controller
//           render={({ value, field, onChange, name, ...restProps }) => (
//             <input
//               className="form-control inputbg"
//               name={name}
//               onChange={(e) => {
//                 const serialNum = e.target.value;
//                 setSerialNumber(serialNum);
//               }}
//               required
//               {...restProps}
//               {...field}
//             />
//           )}
//           control={control}
//           name={`metalOrder[${index}].serialNumber`}
//         />
//       ),
//     },
//     {
//       title: "Roof Color",
//       dataIndex: "roofColor",
//       key: "roofColor",
//       render: (text, record, index) => (
//         <Controller
//           render={({ field }) => (
//             <ReactSelect
//               {...field}
//               options={roofColorOptions}
//               isClearable={true}
//               menuPortalTarget={document.body}
//               menuPosition={"fixed"}
//               onChange={(e) => setRoofColorChange(e, index)}
//             />
//           )}
//           name={`metalOrder[${index}].roofColor`}
//           control={control}
//         />
//       ),
//     },
//     {
//       title: "Siding Color",
//       dataIndex: "sidingColor",
//       key: "sidingColor",
//       render: (text, record, index) => (
//         <Controller
//           render={({ field }) => (
//             <ReactSelect
//               {...field}
//               options={sidingColorOptions}
//               isClearable={true}
//               menuPortalTarget={document.body}
//               menuPosition={"fixed"}
//               onChange={(e) => setSidingColorChange(e, index)}
//             />
//           )}
//           name={`metalOrder[${index}].sidingColor`}
//           control={control}
//         />
//       ),
//     },
//     {
//       title: "Trim Color",
//       dataIndex: "trimColor",
//       key: "trimColor",
//       render: (text, record, index) => (
//         <Controller
//           render={({ field }) => (
//             <ReactSelect
//               {...field}
//               options={trimColorOptions}
//               isClearable={true}
//               menuPortalTarget={document.body}
//               menuPosition={"fixed"}
//               onChange={(e) => setTrimColorChange(e, index)}
//             />
//           )}
//           name={`metalOrder[${index}].trimColor`}
//           control={control}
//         />
//       ),
//     },
//     {
//       title: "Action",
//       dataIndex: "action",
//       key: "action",
//       render: (_, record, index) => (
//         <button className="btn btn-danger float-end" onClick={(e) => handleDeleteLine(e, index)}>
//           Delete
//         </button>
//       ),
//     },
//   ];

//   const handleDeleteLine = (e, index) => {
//     e.preventDefault();
//     remove(index);
//   };

//   const formValues = watch(); // Get the current form values

//   const data = fields.map((field, index) => {
//     return {
//       key: index,
//       id: field.id,
//       metalPackage: field.metalPackage,
//       // ... other fields
//     };
//   });
//   // Augment each metalOrder record with its index
//   const dataSourceWithIndex = data.map((record, index) => ({ ...record, nestIndex: index }));

//   return (
//     <div className="container mt-4 ">
//       <Table
//         dataSource={dataSourceWithIndex}
//         loading={shedCategoryOptions === null}
//         columns={columns}
//         pagination={false}
//         expandable={{
//           expandedRowRender: (record) => (
//             <NestedTableRow
//               record={record}
//               control={control}
//               formValues={formValues}
//               appendToItems={appendToItems}
//               setValue={setValue}
//               remove={remove}
//               fields={fields}
//             />
//           ),
//           rowExpandable: (record) => true,
//         }}
//       />

//       <button
//         className="btn btn-info mb-2 mt-2 float-end"
//         onClick={(e) => {
//           e.preventDefault();
//           append({ value: "0" });
//           // handleAddMetalPack();
//         }}
//       >
//         Add Metal Package
//       </button>
//     </div>
//   );
// }

// function NestedTableRow({ record, control, formValues, appendToItems, setValue, remove, fields }) {
//   const nestedColumns = [
//     {
//       title: "Item Name",
//       render: (value, record, index) => {
//         let key = record.key.split("-");
//         let recordKey = key[0];
//         return (
//           <Controller
//             control={control}
//             name={`metalOrder[${recordKey}].metalPackage.items[${index}].item.name`}
//             render={({ field }) => <input {...field} className="form-control custom-input readonlyinput" />}
//           />
//         );
//       },
//     },

//     {
//       title: "Value",
//       render: (value, record, index) => {
//         let key = record.key.split("-");
//         let recordKey = key[0];
//         return (
//           <Controller
//             control={control}
//             name={`metalOrder[${recordKey}].metalPackage.items[${index}].quantity`}
//             render={({ field }) => <input {...field} className="form-control custom-input readonlyinput" />}
//           />
//         );
//       },
//     },
//     {
//       title: "Action",
//       render: (text, record, index) => {
//         let key = record.key.split("-");
//         let recordKey = key[0];
//         return (
//           <button className="btn btn-danger float-end" onClick={(e) => deleteItem(e, recordKey, index)}>
//             Delete
//           </button>
//         );
//       },
//     },
//   ];

//   const deleteItem = (e, recordKey, itemIndex) => {
//     e.preventDefault();
//     // Get a copy of the current items array
//     let items = [...formValues.metalOrder[recordKey].metalPackage.items];
//     // Remove the item at the specified index
//     let newItems = items.filter((item, index) => index !== itemIndex);
//     // Use setValue to update the items array in the formValues state
//     setValue(`metalOrder[${recordKey}].metalPackage.items`, newItems);
//   };

//   let items = formValues.metalOrder[record.key].metalPackage.items || [];
//   const nestedData = items.map((item, index) => {
//     return {
//       key: `${record.key}-${index}`,
//       name: item.item.name, // Assuming item has a name field
//       value: item.quantity, // Assuming item has a quantity field
//       // value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.item.quantity,
//     };
//   });

//   // Rest of the component

//   return (
//     <div>
//       <Table columns={nestedColumns} pagination={false} dataSource={nestedData} />
//       <button
//         className="btn btn-info mt-2 float-end"
//         onClick={(e) => {
//           e.preventDefault();
//           appendToItems(record.key);
//         }}
//       >
//         Add Item
//       </button>
//     </div>
//   );
// }

import React, { useState, Fragment, useEffect, useContext } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Table } from "antd";

import ComponentCategoryContext from "../../../../../context/componentCategory/componentCategoryContext";
import ComponentContext from "../../../../../context/component/componentContext";
import ItemContext from "../../../../../context/item/itemContext";
import SettingsContext from "../../../../../context/settings/settingsContext";

export default function Fields({
  control,
  register,
  setValue,
  getValues,
  watch,
  errors,
  newComponents,
  company,
  shedCategorys,
  currentShop,
  getMetalComponents,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "metalOrder",
  });
  const componentContext = useContext(ComponentContext);
  const { components, getComponents } = componentContext;

  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { componentCategorys, getComponentCategorys } = componentCategoryContext;

  const settingsContext = useContext(SettingsContext);
  const { settings, getSettings } = settingsContext;

  const itemContext = useContext(ItemContext);
  const { allItems, getItems, clearItems } = itemContext;

  // useEffect(() => {
  //   const newSelectedOptions = fields.map((field) => {
  //     const initialShedCategoryId = field.shedCategory;
  //     return shedCategoryOptions?.find((option) => option.value._id === initialShedCategoryId);
  //   });

  //   // Check if newSelectedOptions is different from the current selectedOptions
  //   if (JSON.stringify(newSelectedOptions) !== JSON.stringify(selectedOptions)) {
  //     setSelectedOptions(newSelectedOptions);
  //   }
  // }, [fields, shedCategoryOptions, selectedOptions]);

  useEffect(() => {
    getComponentCategorys();
    getSettings();
    getItems();
  }, []);

  const metalPakOptions =
    newComponents &&
    newComponents.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const shedCategoryOptions =
    shedCategorys &&
    shedCategorys.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const roofColorOptions =
    company &&
    company.roofColors.map((option) => {
      return { label: `${option.color}`, value: option };
    });

  const sidingColorOptions =
    company &&
    company.sidingColors.map((option) => {
      return { label: `${option.color}`, value: option };
    });

  const trimColorOptions =
    company &&
    company.trimColors.map((option) => {
      return { label: `${option.color}`, value: option };
    });

  const setMetalOrderChange = (e, metalOrderIndex) => {
    let name = { label: e.label, value: e.value._id };

    console.log("this is the e", e);
    setValue(`metalOrder[${metalOrderIndex}].metalPackage.name`, name);
    setValue(`metalOrder[${metalOrderIndex}].metalPackage.items`, e.value.standardItems);
    setValue(`metalOrder[${metalOrderIndex}].metalPackage._id`, e.value._id);
    let theMetalPakage = getValues(`metalOrder[${metalOrderIndex}].metalPackage`);
  };

  const setSerialNumber = (serialNumber, metalOrderIndex) => {
    setValue(`metalOrder[${metalOrderIndex}].serialNumber`, serialNumber);
  };

  const setZone = (category, index) => {
    // Set the shedCategory field in the form
    setValue(`metalOrder[${index}].shedCategory`, category.value._id);
    getMetalComponents(category.value._id, currentShop._id);
    setZoneSelected(false);
  };

  const setRoofColorChange = (e, metalOrderIndex) => {
    setValue(`metalOrder[${metalOrderIndex}].roofColor`, e);
  };
  const setSidingColorChange = (e, metalOrderIndex) => {
    setValue(`metalOrder[${metalOrderIndex}].sidingColor`, e);
  };
  const setTrimColorChange = (e, metalOrderIndex) => {
    setValue(`metalOrder[${metalOrderIndex}].trimColor`, e);
    // console.log("This is e", e);
  };

  const [zoneSelected, setZoneSelected] = useState(true);

  // metalOrder.value && setValue(`metalOrder[${metalOrderIndex}].value.label`, metalOrder.value.title);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // useEffect(() => {
  //   const newSelectedOptions = fields.map((field) => {
  //     const initialShedCategoryId = field.shedCategory;
  //     return shedCategoryOptions?.find((option) => option.value._id === initialShedCategoryId);
  //   });

  //   // Check if newSelectedOptions is different from the current selectedOptions
  //   if (JSON.stringify(newSelectedOptions) !== JSON.stringify(selectedOptions)) {
  //     setSelectedOptions(newSelectedOptions);
  //   }
  // }, [fields, shedCategoryOptions, selectedOptions]);

  const handleDeleteLine = (e, fieldId) => {
    e.preventDefault();
    const index = fields.findIndex((field) => field.id === fieldId);
    remove(index);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record, index) => {
        return (
          // <Controller
          //   render={({ field }) => (
          //     <ReactSelect
          //       {...field}
          //       options={categoryOptions}
          //       menuPortalTarget={document.body}
          //       menuPosition={"fixed"}
          //       value={(field.value && { label: field.value.name, value: field.value._id }) || null}
          //       onChange={(e) => {
          //         field.onChange(e.value);
          //         setZone(e);
          //       }}
          //     />
          //   )}
          //   name={`metalOrder[${index}].category`}
          //   control={control}
          // />
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={shedCategoryOptions}
                value={selectedOptions[index] || null}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                onChange={(e) => {
                  field.onChange(e.value._id);
                  setZone(e, index);
                  const newSelectedOptions = [...selectedOptions];
                  newSelectedOptions[index] = e;
                  setSelectedOptions(newSelectedOptions);
                  // Update the corresponding field in the fields array
                  const newFields = [...fields];
                  newFields[index].shedCategory = e.value._id;
                  setValue("metalOrder", newFields);
                }}
              />
            )}
            name={`metalOrder[${index}].shedCategory`}
            control={control}
          />
        );
      },
    },

    {
      title: "Metal Package",
      dataIndex: "metalPackage",
      key: "metalPackage",
      render: (text, record, index) => (
        <Controller
          render={({ field }) => (
            <ReactSelect
              {...field}
              isDisabled={!metalPakOptions}
              options={metalPakOptions}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => setMetalOrderChange(e, index)}
            />
          )}
          name={`metalOrder[${index}].metalPackage.name`}
          control={control}
        />
      ),
    },

    {
      title: "Serial Number",
      dataIndex: "metalPackage",
      key: "metalPackage",
      render: (text, record, index) => (
        <Controller
          render={({ value, field, onChange, name, ...restProps }) => (
            <input
              className="form-control inputbg"
              name={name}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => {
                const serialNum = e.target.value;
                setSerialNumber(serialNum);
              }}
              required
              {...restProps}
              {...field}
            />
          )}
          control={control}
          name={`metalOrder[${index}].serialNumber`}
        />
      ),
    },
    {
      title: "Roof Color",
      dataIndex: "roofColor",
      key: "roofColor",
      render: (text, record, index) => (
        <Controller
          render={({ field }) => (
            <ReactSelect
              {...field}
              options={roofColorOptions}
              isClearable={true}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => setRoofColorChange(e, index)}
            />
          )}
          name={`metalOrder[${index}].roofColor`}
          control={control}
        />
      ),
    },
    {
      title: "Siding Color",
      dataIndex: "sidingColor",
      key: "sidingColor",
      render: (text, record, index) => (
        <Controller
          render={({ field }) => (
            <ReactSelect
              {...field}
              options={sidingColorOptions}
              isClearable={true}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => setSidingColorChange(e, index)}
            />
          )}
          name={`metalOrder[${index}].sidingColor`}
          control={control}
        />
      ),
    },
    {
      title: "Trim Color",
      dataIndex: "trimColor",
      key: "trimColor",
      render: (text, record, index) => (
        <Controller
          render={({ field }) => (
            <ReactSelect
              {...field}
              options={trimColorOptions}
              isClearable={true}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => setTrimColorChange(e, index)}
            />
          )}
          name={`metalOrder[${index}].trimColor`}
          control={control}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record, index) => (
        <button className="btn btn-danger float-end" onClick={(e) => handleDeleteLine(e, index)}>
          Delete
        </button>
      ),
    },
  ];

  return (
    <div className="container mt-4 ">
      <Table
        dataSource={fields}
        // loading={categoryOptions === null}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record, index) => (
            <NestedItems control={control} name={`metalOrder[${index}].metalPackage.items`} allItems={allItems} />
          ),
          rowExpandable: (record) => true,
        }}
      />

      <button
        className="btn btn-info mb-2 mt-2 float-end"
        onClick={(e) => {
          e.preventDefault();
          append({ metalPackage: { items: [] } });
        }}
      >
        Add Metal Package
      </button>
    </div>
  );
}

function NestedItems({ control, name, allItems }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  // const itemOptions = allItems && allItems.map((item) => ({ label: item.name, value: item }));

  const nestedColumns = [
    {
      title: "Item Name",
      dataIndex: "item",
      key: "name",
      render: (text, record, index) => {
        return (
          <>
            <Controller
              control={control}
              name={`${name}[${index}].item.name`}
              render={({ field }) => <input {...field} className="form-control custom-input readonlyinput" />}
            />
            {/* 
            <Controller
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  options={itemOptions}
                  value={(field.value && { label: field.value.name, value: field.value }) || null}
                  // isClearable={true}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                />
              )}
              name={`${name}[${index}].item`}
              control={control}
            /> */}
          </>
        );
      },
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record, index) => (
        <Controller
          control={control}
          name={`${name}[${index}].quantity`}
          render={({ field }) => <input {...field} className="form-control custom-input readonlyinput" />}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record, index) => (
        <button className="btn btn-danger float-end" onClick={() => remove(index)}>
          Delete Item
        </button>
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={fields} columns={nestedColumns} rowKey="id" pagination={false} />
      <button
        className="btn btn-info mt-2 float-end"
        onClick={(e) => {
          e.preventDefault();
          append({ item: { name: "" }, quantity: 0 });
        }}
      >
        Add Item
      </button>
    </div>
  );
}
