import React, { Fragment, useEffect, useState, useContext } from "react";
import ShedModelContext from "../../../../../../../context/shedModel/shedModelContext";
import ShedCategoryContext from "../../../../../../../context/shedCategory/shedCategoryContext";
import ShedLaborContext from "../../../../../../../context/shedLabor/shedLaborContext";
import AuthContext from "../../../../../../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const AllShedLaborModels = () => {
  const navigate = useNavigate();
  const shedModelContext = useContext(ShedModelContext);
  const { getShedModels, shedModels, setCurrentShedModel } = shedModelContext;
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const shedLaborContext = useContext(ShedLaborContext);
  const { importShedLabor, getAllShedLabors, allShedLabors } = shedLaborContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    getShedModels(currentShedCategory._id);
    getAllShedLabors(currentShedCategory._id);
  }, []);

  useEffect(() => {}, [shedModels]);

  const columns = [
    {
      title: "Name",
      width: "20%",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },
    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewModel(record)}>
            View
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewModel = (model) => {
    setCurrentShedModel(model);
    navigate("/company/sheds/labor/model/view");
  };

  const [newFile, setNewFile] = useState("");

  const importFile = () => {
    importShedLabor(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  // let itemsForExport = [];

  // shedModels &&
  //   shedModels.map((model) => {
  //     let newItem = {};
  //     newItem.Id = model._id;
  //     newItem.name = model.name;

  //     itemsForExport.push(newItem);
  //   });

  // const exportFile = () => {
  //   const ws = utils.json_to_sheet(itemsForExport);
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, "Data");
  //   writeFileXLSX(wb, `${currentShedCategory.name}_models.xlsx`);
  // };

  let laborsForExportByModel = {};

  // Assuming allLabors is the array containing all your labor data
  allShedLabors &&
    allShedLabors.forEach((labor) => {
      // Group labors by model
      if (!laborsForExportByModel[labor.model.name]) {
        laborsForExportByModel[labor.model.name] = [];
      }
      let newLabor = {};
      // newLabor.Id = labor._id;
      // newLabor.baseStyle = labor.baseStyle;
      // newLabor.Model = labor.model.code;
      newLabor.Width = labor.width;
      newLabor.Length = labor.length;

      // Check if we found a shopValue before trying to access its properties
      newLabor.Build_Base = labor.standardBuildBase;
      newLabor.Builder_Percent = labor.baseStyle.standardValues.buildPercent.value;
      newLabor.Builder_Cost = (labor.baseStyle.standardValues.buildPercent.value / 100) * labor.standardBuildBase;
      newLabor.Roof_Base = labor.standardRoofBase;
      newLabor.Roof_Percent = labor.baseStyle.standardValues.roofPercent.value;
      newLabor.Roof_Cost = (labor.baseStyle.standardValues.roofPercent.value / 100) * labor.standardRoofBase;
      newLabor.Truss_Cost = labor.baseStyle.standardValues.trussCost.value * labor.standardTrussCount;
      newLabor.Paint_Cost = labor.baseStyle.standardValues.paintCost.value * labor.standardPaintHours;
      newLabor.Door_Cost = labor.standardDoorCost;
      newLabor.Total_Labor = labor.standardTotalLaborCost;

      laborsForExportByModel[labor.model.name].push(newLabor);
    });

  const exportFile = () => {
    const wb = utils.book_new();
    // Create a new worksheet for each model
    for (let model in laborsForExportByModel) {
      const ws = utils.json_to_sheet(laborsForExportByModel[model]);
      const sanitizedModel = sanitizeSheetName(model);
      utils.book_append_sheet(wb, ws, sanitizedModel);
    }
    writeFileXLSX(wb, `Shed_Labors.xlsx`);
  };

  function sanitizeSheetName(name) {
    // Replace invalid characters with underscore
    return name.replace(/[:\\/?*[\]]/g, "_");
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-3">
          <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
        </div>
        <div className="col-md-2">
          <button
            className={user.viewOnly ? "viewOnly" : "mb-2 me-2 btn btn-primary"}
            disabled={user.viewOnly}
            // className="mb-2 me-2 btn btn-primary"
            onClick={() => importFile()}
          >
            Import Excel
          </button>
        </div>
        {/* <div className="col-md-2">
          <button
            className={user.viewOnly ? "viewOnly" : "mb-2 ms-4 btn btn-primary"}
            disabled={user.viewOnly}
            // className="mb-2 ms-4 btn btn-primary"
            onClick={() => exportFile()}
          >
            Export Excel
          </button>
        </div> */}
        <div className="col-2">
          {allShedLabors && allShedLabors.length > 0 && (
            <button className="mb-2 ms-4 btn btn-primary" onClick={() => exportFile()}>
              Export Excel
            </button>
          )}
        </div>
      </div>

      <Table
        rowClassName={() => "hover-row"}
        // loading={!loadingProduct && productResults ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
        dataSource={shedModels}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default AllShedLaborModels;
