import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function OrderPieReportChart({ data }) {
  return (
    <Doughnut
      data={data}
      options={{
        plugins: { legend: { display: false } },
      }}
    />
  );
}
