import React, { Fragment } from "react";
import { View, StyleSheet, Page, Text } from "@react-pdf/renderer";

const tableRowsCount = 11;
const borderColor = "#90e5fc";

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    // backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    // height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexWrap: "wrap",
    paddingBottom: 2,
    paddingTop: 2,
  },

  item: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: "30%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const CustomAddOns = ({ invoice }) => {
  const rows = invoice.invoice.order.customAddOns.map((addOn) => (
    <View key={addOn._id} break>
      <View style={styles.row}>
        <Text wrap style={styles.item}>
          {addOn.name}
        </Text>
        <Text style={styles.qty}>{addOn.quantity}</Text>
        {/* <Text style={styles.qty}>{addOn.addOn.quantity}</Text> */}
        <Text style={styles.qty}>
          {addOn.totalSalePrice.shop.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </Text>
      </View>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default CustomAddOns;
