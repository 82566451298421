import React from "react";
import { Table } from "antd";

function StandardExpenseGpTable({ formValues }) {
  const expenseColumns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Standard Labor",
      dataIndex: "standardLabor",
      key: "standardLabor",
    },
    {
      title: "Standard Material",
      dataIndex: "standardMaterial",
      key: "standardMaterial",
    },
    {
      title: "Standard Cost",
      dataIndex: "standardCost",
      key: "standardCost",
    },
    {
      title: "Standard Profit",
      dataIndex: "standardProfit",
      key: "standardProfit",
    },
  ];
  const expenseDataSource = [
    {
      key: "1",
      category: "Builder Labor",
      standardLabor:
        formValues &&
        formValues.builderLabor.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "2",
      category: "Roof Labor",
      standardLabor:
        formValues &&
        formValues.roofLabor.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "3",
      category: "Truss Labor",
      standardLabor:
        formValues &&
        formValues.trussLabor.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "4",
      category: "Door Labor",
      standardLabor:
        formValues &&
        formValues.doorLabor.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "5",
      category: "Paint Labor",
      standardLabor:
        formValues &&
        formValues.paintLabor.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "6",
      category: "Shed Total Labor",
      standardLabor:
        formValues &&
        formValues.shedTotalLabor.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "7",
      category: "Add-On Total Labor",
      standardLabor:
        formValues &&
        formValues.addOnsTotalLabor.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "8",
      category: "Custom Add-ON Total Labor",
      standardLabor:
        formValues &&
        formValues.customAddOnsTotalLabor.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "9",
      category: "Total Labor",
      standardLabor:
        formValues &&
        formValues.totalLabor.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "10",
      category: "Shed Material Cost",
      standardMaterial:
        formValues &&
        formValues.shedMaterialCost.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "11",
      category: "Add-Ons Material Cost",
      standardMaterial:
        formValues &&
        formValues.addOnsMaterialCostTotal.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "12",
      category: "Custom Add-Ons Material Cost",
      standardMaterial:
        formValues &&
        formValues.customAddOnsMaterialCostTotal.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "13",
      category: "Total Material Cost",
      standardMaterial:
        formValues &&
        formValues.totalMaterialCost.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "14",
      category: "Shed Total Cost",
      standardCost:
        formValues &&
        formValues.shedTotalCost.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "15",
      category: "Add-Ons Total Cost",
      standardCost:
        formValues &&
        formValues.addOnsTotalCost.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "16",
      category: "Custom Add-Ons Total Cost",
      standardCost:
        formValues &&
        formValues.customAddOnsTotalCost.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "166",
      category: "Overhead",
      standardCost:
        formValues &&
        formValues.overhead.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "17",
      category: "Total Cost",
      standardCost:
        formValues &&
        formValues.totalCost.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "18",
      category: "Shed GP",
      standardProfit:
        formValues &&
        formValues.shedProfitDollars.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "188",
      category: "Shed GP %",
      standardProfit:
        formValues &&
        formValues.shedProfitPercent.standard.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 }),
    },
    {
      key: "19",
      category: "Add-Ons GP",
      standardProfit:
        formValues &&
        formValues.addOnsProfitDollars.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "199",
      category: "Add-Ons GP %",
      standardProfit:
        formValues &&
        formValues.addOnsProfitPercent.standard.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 }),
    },
    {
      key: "202",
      category: "Custom Add-Ons GP",
      standardProfit:
        formValues &&
        formValues.customAddOnsProfitDollars.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "20",
      category: "Custom Add-Ons GP %",
      standardProfit:
        formValues &&
        formValues.customAddOnsProfitPercent.standard.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 }),
    },

    {
      key: "21",
      category: "Total GP",
      standardProfit:
        formValues &&
        formValues.profitDollars.standard.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "22",
      category: "Total GP &",
      standardProfit:
        formValues && formValues.profitPercent.standard.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 }),
    },
  ];

  return <Table columns={expenseColumns} dataSource={expenseDataSource} pagination={false} />;
}

export default StandardExpenseGpTable;
