import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import RepairInvoiceContext from "../../../../context/repairInvoice/repairInvoiceContext";
import ItemContext from "../../../../context/item/itemContext";
import ShopContext from "../../../../context/shop/shopContext";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "antd";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Tag, List, Modal } from "antd";
import ItemArray from "./ItemArr";
import ReactSelect from "react-select";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./pdf/Invoice";

const defaultValues = {
  invoiceNumber: 0,
  invoicedDate: moment(),
};

const CompanyRepairViewInvoiceForm = ({ setModalVisible }) => {
  const navigate = useNavigate();

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const repairInvoiceContext = useContext(RepairInvoiceContext);
  const { updateRepairInvoice, currentRepairInvoice, deleteRepairInvoice } = repairInvoiceContext;
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const itemContext = useContext(ItemContext);
  const { getAllShopItems, allItems, clearItems } = itemContext;

  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;
  const [messages, setMessages] = useState();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (currentRepairInvoice) {
      setValue("_id", currentRepairInvoice._id);
      setValue("invoicedDate", moment(currentRepairInvoice.invoicedDate));
      setValue("invoiceNumber", currentRepairInvoice.invoiceNumber);
      setValue(`items`, currentRepairInvoice.items);
      setValue(`invoiceTotal`, currentRepairInvoice.invoiceTotal && currentRepairInvoice.invoiceTotal);
      setValue(`standardInvoiceTotal`, currentRepairInvoice.standardInvoiceTotal);
      setValue(`messages`, currentRepairInvoice.messages);
      setValue(`billTo`, currentRepairInvoice.billTo);
      setValue(`shop`, currentRepairInvoice.shop._id);
      setValue(`status`, currentRepairInvoice.status);
      setMessages(currentRepairInvoice.messages);
      setReady(true);
    }
  }, [currentRepairInvoice]);

  const onSubmit = (data) => {
    // console.log("this is the form data", data);
    // const msgs = [];
    let theDate = new Date();
    const newMsg = {
      madeBy: user.userName,
      date: theDate,
      msg: `Invoice updated by ${user.name}.`,
    };
    data.messages.push(newMsg);
    updateRepairInvoice(data);
  };

  const dateFormat = "MM/DD/YYYY";

  const statusOptions = [
    { label: "New", value: "New" },
    { label: "Approved", value: "Approved" },
    { label: "Paid", value: "Paid" },
  ];

  const setStatusChange = (status) => {
    setValue("status", status);
  };

  // const handleDeleteInvoice = (e) => {
  //   e.preventDefault();
  //   deleteRepairInvoice(currentRepairInvoice);
  //   console.log("Delete Invoice");
  //   navigate(-1);
  // };

  const handleDeleteInvoice = () => {
    Modal.confirm({
      title: "Are you sure you want to Delete this Invoice.",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteRepairInvoice(currentRepairInvoice);
      },
    });
  };

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid bidformbackground border  " style={{ borderRadius: "8px" }}>
          <div className="col-md-12">
            {ready && (
              <PDFDownloadLink
                document={<Invoice data={currentRepairInvoice && currentRepairInvoice} />}
                fileName={`Invoice.pdf`}
                className="btn btn-outline-success "
                type="button"
                // style={{
                // textDecoration: "none",
                // padding: "10px",
                // color: "#ffffff",
                // backgroundColor: "#033699",
                // border: "1px solid #4a4a4a"
                // }}
              >
                {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download Invoice")}
              </PDFDownloadLink>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    <div className="form-group"></div>
                  </div>

                  <div className="col-md-7"></div>
                </div>

                <div className="row mb-4 text-center">
                  <h2>Invoice</h2>
                </div>

                <div className="row mb-2 ">
                  <div className="col-md-12">
                    <button className="btn btn-outline-danger float-end " type="button" onClick={(e) => handleDeleteInvoice(e)}>
                      Delete Invoice
                    </button>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">INVOICE #</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="invoiceNumber" {...register("invoiceNumber", { required: true })} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                  <div className="col-md-4">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Status</label>
                      <div className="col-sm-9">
                        <Controller
                          render={({ field }) => {
                            return (
                              <ReactSelect
                                {...field}
                                options={statusOptions}
                                menuPosition={"fixed"}
                                isDisabled={!statusOptions}
                                value={(field.value && { label: field.value, value: field.value }) || null}
                                onChange={(e) => setStatusChange(e.value)}
                              />
                            );
                          }}
                          name={`status`}
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">DATE</label>
                      <div className="col-sm-9">
                        <Controller
                          render={({ field, name }) => (
                            <DatePicker
                              format={dateFormat}
                              className="form-control"
                              name={name}
                              {...field}
                              disabledDate={(currentDate) => {
                                const now = moment();
                                const today = moment().startOf("day");
                                const friday = today.clone().day(-2).hour(0).minute(0).second(0);
                                const monday = today.clone().day(1).hour(10).minute(0).second(0);

                                if (now.isBefore(monday)) {
                                  // Disable all dates except for Friday to Sunday
                                  const sunday = today.clone().day(0).hour(23).minute(59).second(59);
                                  return !currentDate.isBetween(friday, sunday);
                                } else if (now.isSame(today, "day") && now.isBefore(monday.clone().subtract(1, "second"))) {
                                  // Disable all dates except for today before 10am
                                  return !currentDate.isSame(today, "day");
                                } else {
                                  // Disable all dates except for today
                                  return true;
                                }
                              }}
                            />
                          )}
                          control={control}
                          name={`invoicedDate`}
                          defaultValue={moment()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">BILL TO:</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          name="billTo"
                          defaultValue={"StorMor"}
                          {...register("billTo", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-3">
                    <label>Item</label>
                  </div>
                  <div className="col-md-1">
                    <label>Qty</label>
                  </div>
                  <div className="col-md-2">
                    <label>Rate</label>
                  </div>
                  <div className="col-md-2">
                    <label>Standard Rate</label>
                  </div>
                  <div className="col-md-2">
                    <label>Amount</label>
                  </div>
                  <div className="col-md-2">
                    <label>Standard Amount</label>
                  </div>
                </div>

                <div className="row border bglight mb-2 ">
                  <ItemArray
                    {...{
                      control,
                      watch,
                      register,
                      defaultValues,
                      getValues,
                      setValue,
                      errors,
                      getValues,
                      allItems,
                    }}
                  />
                </div>
                <div className="row mb-4 mt-4">
                  <div className="col-md-4"></div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <div className="buttons float-end">
                      <button className="btn btn-outline-primary " type="submit">
                        Update Invoice
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8"></div>
          <div className="col-md-4">
            <List
              itemLayout="horizontal"
              dataSource={messages}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta title={item.madeBy} description={new Date(item.date).toLocaleString()} />
                  <List.Item.Meta title={item.msg} />
                </List.Item>
              )}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompanyRepairViewInvoiceForm;
