import React from "react";
import { Page, Document, View, Text, StyleSheet } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import Invoices from "./Invoices";
import InvoicesTable from "./InvoicesTable";

const borderColor = "#90e5fc";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 15,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  headerRow: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
  },
  headerItem: {
    width: "50%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerQty: {
    width: "25%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  grandTotal: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  memo: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "row",
  },
});

const Invoice = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle title="Payment" />

        <BillTo values={data} />
        <View style={styles.container}>
          <View style={styles.headerRow}>
            <Text style={styles.headerItem}>Invoice #</Text>
            {/* <Text style={styles.headerQty}></Text> */}
            {/* <Text style={styles.headerQty}>Rate</Text> */}
            <Text style={styles.headerQty}>Total</Text>
            <Text style={styles.headerQty}>GP %</Text>
          </View>
        </View>
        <InvoicesTable values={data} />
        <View style={styles.memo}>
          <Text>{data.memo && data.memo}</Text>
        </View>
        <View style={styles.grandTotal}>
          <Text>
            Sub Total:{" "}
            {data.paymentSubTotal.shop.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}
          </Text>
        </View>
        <View style={styles.grandTotal}>
          <Text>
            Adjustments:{" "}
            {data.shopsPaymentAdjustment &&
              data.shopsPaymentAdjustment.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}{" "}
          </Text>
        </View>
        <View style={styles.grandTotal}>
          <Text>
            Grand Total:{" "}
            {data.paymentTotal.shop.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}
          </Text>
        </View>

        <Invoices values={data} />
      </Page>
    </Document>
  );
};

export default Invoice;
