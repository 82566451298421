import React, { useState, useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import AddonParentCategoryContext from "../../../../../../context/addonParentCategory/addonParentCategoryContext";
const initialState = {
  name: "",
  newBuild: false,
  offLot: false,
  _id: null,
};

const AddAddonParentCategory = () => {
  // const navigate = useNavigate();

  const addOnParentCategoryContext = useContext(AddonParentCategoryContext);
  const { addAddonParentCategory, currentAddonParentCategory, updateAddonParentCategory } = addOnParentCategoryContext;

  const [values, setValues] = useState(initialState);
  // destructure
  const { name, offLot, newBuild, _id } = values;

  console.log("currentAddonParentCategory", currentAddonParentCategory);

  // use useEffect to load the currentAddonParentCategory into the form
  useEffect(() => {
    if (currentAddonParentCategory) {
      setValues({
        name: currentAddonParentCategory.name,
        newBuild: currentAddonParentCategory.newBuild,
        offLot: currentAddonParentCategory.offLot,
        _id: currentAddonParentCategory._id,
      });
    }
  }, [currentAddonParentCategory]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentAddonParentCategory) {
      // update the currentAddonParentCategory
      updateAddonParentCategory(values);
    } else {
      addAddonParentCategory(values);
    }
    // Make a copy of the company's roofColors array
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.checked });
  };
  return (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center" }}>Addon Parent Category Name</h4>

      <div className="form-group">
        <label>Name</label>
        <input type="string" name="name" className="form-control inputbg" value={name} onChange={handleChange} />
      </div>
      <div className="form-group mt-4">
        <label>New Build</label>
        <input type="checkbox" name="newBuild" className="ms-4 p-2" checked={newBuild} onChange={handleCheckboxChange} />
      </div>

      <div className="form-group">
        <label>Off Lot</label>
        <input type="checkbox" name="offLot" className="ms-4 p-2" checked={offLot} onChange={handleCheckboxChange} />
      </div>
      <button className="btn btn-outline-info float-end mb-2 mt-4">Save Addon Parent Category</button>
    </form>
  );
};

export default AddAddonParentCategory;
