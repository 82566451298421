import {
  ADD_HAULBASE,
  DELETE_HAULBASE,
  CLEAR_HAULBASES,
  SET_CURRENT_HAULBASE,
  CLEAR_CURRENT_HAULBASE,
  UPDATE_HAULBASE,
  HAULBASE_ERROR,
  GET_HAULBASES,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_HAULBASES:
      return {
        ...state,
        haulBases: action.payload,
        loading: false,
      };

    case ADD_HAULBASE:
      return {
        ...state,
        haulBase: action.payload,
        loading: false,
      };
    case UPDATE_HAULBASE:
      return {
        ...state,
        haulBases: state.haulBases && state.haulBases.map((labor) => (labor._id === action.payload._id ? action.payload : labor)),
        loading: false,
      };
    case DELETE_HAULBASE:
      return {
        ...state,
        haulBases: state.haulBases.filter((labor) => labor._id !== action.payload),
        loading: false,
      };
    case CLEAR_HAULBASES:
      return {
        ...state,
        haulBases: null,
        filteredHaulBase: null,
        error: null,
        currentHaulBase: null,
      };
    case SET_CURRENT_HAULBASE:
      return {
        ...state,
        currentHaulBase: action.payload,
      };

    case CLEAR_CURRENT_HAULBASE:
      return {
        ...state,
        currentHaulBase: null,
      };

    case HAULBASE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
