import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import AuthContext from "../../../../../context/auth/authContext";
import ShopContext from "../../../../../context/shop/shopContext";

const initialState = {
  name: "",
  email: "",
  password: "",
  role: "",
};

const AddUser = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState(initialState);
  // destructure
  const { name, email, password, role } = values;
  const authContext = useContext(AuthContext);
  const { addShopUser, updateUser, currentUser } = authContext;
  const shopContext = useContext(ShopContext);
  const { currentShop, setCurrentShop, clearShops } = shopContext;

  useEffect(() => {
    if (currentUser) {
      setValues({
        name: currentUser.name,
        email: currentUser.email,
        password: currentUser.password,
        role: currentUser.role,
        shop: values.currentShop,
      });
    } else {
      setValues({
        shop: currentShop,
      });
    }
  }, [currentUser]);

  const handleUserSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentUser) {
      updateUser(currentUser._id, values);
    } else {
      addShopUser(values);
      setCurrentShop({
        ...currentShop,
        shopUsers: [...currentShop.shopUsers, values],
      });
    }
    // console.log(values);
    // setValues(initialState);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleUserSubmit}>
      <h4 style={{ textAlign: "center" }}>User</h4>

      <div className="form-group">
        <label>User Name</label>
        <input type="string" name="name" className="form-control inputbg" value={name} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label>Email</label>
        <input type="string" name="email" className="form-control inputbg" value={email} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Password</label>
        <input type="string" name="password" className="form-control inputbg" value={password} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Role</label>
        <input type="string" name="role" className="form-control inputbg" value={role} onChange={handleChange} />
      </div>

      <button className="btn btn-outline-info float-end mb-2 mt-4">Save User</button>
    </form>
  );
};

export default AddUser;
