import React, { Fragment } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Input, Button, Row, Col, Card, Typography } from "antd";

const { Title } = Typography;

const AddOns = ({ standardAddOn, standardAddOnIndex, control, remove, setValue, getValues, allAddOns, register }) => {
  const setAddOnCount = (count) => {
    setValue(`standardAddOns[${standardAddOnIndex}].quantity`, count);
  };

  const onRemovestandardAddOn = () => {};

  const setAddOnChange = (e) => {
    setValue(`standardAddOns[${standardAddOnIndex}].standardAddOn`, e);
    setValue(`standardAddOns[${standardAddOnIndex}].quantity`, 0);
  };

  const theAddOnOptions =
    allAddOns &&
    allAddOns.map((standardAddOn) => {
      return { label: `${standardAddOn.name}`, value: standardAddOn };
    });

  return (
    <Card
      style={{ marginBottom: "8px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", border: "1px solid #e8e8e8", borderRadius: "8px" }}
    >
      <Row gutter={8} align="middle">
        <Col span={8}>
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                value={(field.value && { label: field.value.name, value: field.value }) || null}
                isClearable={true}
                options={theAddOnOptions}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                onChange={(e) => {
                  field.onChange(e.value);
                  setAddOnChange(e.value);
                }}
              />
            )}
            key={standardAddOn.id}
            name={`standardAddOns[${standardAddOnIndex}].standardAddOn`}
            control={control}
          />
        </Col>
        <Col span={4}>
          <Controller
            render={({ field, name }) => (
              <Input
                type="number"
                name={name}
                {...field}
                onChange={(e) => {
                  const count = e.target.value;
                  setAddOnCount(count);
                }}
              />
            )}
            key={standardAddOn.id}
            control={control}
            name={`standardAddOns[${standardAddOnIndex}].quantity`}
          />
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <Button
            type="danger"
            onClick={() => {
              remove(standardAddOnIndex);
              onRemovestandardAddOn();
            }}
          >
            Delete
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default function Fields({ control, register, setValue, getValues, allAddOns }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "standardAddOns",
  });

  return (
    <div className="container-fluid">
      <Row justify="center">
        <Col span={24}>
          <Title level={4} className="text-center">
            Add-Ons
          </Title>
        </Col>
      </Row>
      <Row gutter={8} align="middle" style={{ marginBottom: "8px" }}>
        <Col span={8} className="text-center">
          <strong>Name</strong>
        </Col>
        <Col span={4} className="text-center">
          <strong>Count</strong>
        </Col>
        <Col span={4} className="text-center">
          <strong>Actions</strong>
        </Col>
      </Row>
      {fields.map((standardAddOn, standardAddOnIndex) => (
        <AddOns
          key={standardAddOn.id}
          standardAddOn={standardAddOn}
          standardAddOnIndex={standardAddOnIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          allAddOns={allAddOns}
        />
      ))}
      <Row justify="center">
        <Col>
          <Button
            type="dashed"
            onClick={(e) => {
              e.preventDefault();
              append({ value: "0" });
            }}
          >
            Add Add-On
          </Button>
        </Col>
      </Row>
    </div>
  );
}
