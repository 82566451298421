import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Modal, Form, Input, List } from "antd";
import CommentContext from "../../../context/comment/commentContext";
import ShopContext from "../../../context/shop/shopContext";

const CommentUserPage = () => {
  const navigate = useNavigate();
  const commentContext = useContext(CommentContext);
  const { getShopComments, getComments, comments } = commentContext;

  const shopContext = useContext(ShopContext);
  const { getShops, shops, setCurrentShop } = shopContext;

  useEffect(() => {
    getShops();
    getComments();
  }, []);

  const figureUnreadMessagesCount = (record) => {
    let filteredComments = comments && comments.filter((comment) => comment.commentFrom === record._id);
    return filteredComments.length;
  };

  const columns = [
    {
      title: `Shop`,
      render: (text, record, rowIndex) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: `Unread Messages`,
      render: (text, record) => (
        <span className={figureUnreadMessagesCount(record) > 0 ? "unread-message-count" : ""}>
          {comments && figureUnreadMessagesCount(record)}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleShowMessages(record)}>
          Show Messages
        </Button>
      ),
    },
  ];

  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState(null);

  const handleShowMessages = (record) => {
    setCurrentShop(record);
    getShopComments(record._id);
    navigate("/company/messages/shop");
  };

  return (
    <>
      <Table pagination={{ defaultPageSize: 20 }} dataSource={shops} columns={columns} />
    </>
  );
};

export default CommentUserPage;
