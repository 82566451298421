import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import AuthContext from "../../../../../../context/auth/authContext";
import { read, utils, writeFileXLSX } from "xlsx";
import ShedContext from "../../../../../../context/shed/shedContext";

import "antd/dist/antd.css";
import { Table, Modal } from "antd";

import ShedModelForm from "./ShedModelForm";

const AllShedModels = () => {
  const navigate = useNavigate();
  const shedModelContext = useContext(ShedModelContext);
  const { getShedModels, shedModels, setCurrentShedModel, clearShedModels, copyShedModelAndSheds, deleteShedModel } =
    shedModelContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const shedContext = useContext(ShedContext);
  const { getShedsByCategory, allShedsInCategory, importSheds } = shedContext;

  // console.log("This is sheds", allShedsInCategory);

  useEffect(() => {
    getShedModels(currentShedCategory._id);
    getShedsByCategory(currentShedCategory._id);

    return () => {
      clearShedModels();
    };
  }, []);

  useEffect(() => {}, [shedModels]);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  allShedsInCategory &&
    allShedsInCategory.map((shed) => {
      let newItem = {};
      // i need to include the category, model, baseStyle
      newItem.Id = shed._id;
      newItem.ssId = shed.ssId;

      newItem.category = shed.category?.name;
      newItem.model = shed.model?.name;
      // newItem.shedLabor = shed.shedLabor
      // newItem.haulBase = shed.haulBase?._id;

      newItem.Width = shed.width;
      newItem.Length = shed.length;
      newItem.Retail_Price = shed.retailPrice;

      // now i need to loop through the components and add them to the object along with the quantity, the labels will be component_[index] quantity_[index],

      shed.components.forEach((component, index) => {
        newItem[`name_${index}`] = component.component?.name;
        newItem[`component_${index}`] = component.component?._id;
        newItem[`quantity_${index}`] = component.quantity;
      });

      // now i need to do the same thing for the standardAddOns

      // shed.standardAddOns.forEach((addOn, index) => {
      //   newItem[`addOn_${index}`] = addOn.standardAddOn._id;
      //   newItem[`addOnQuantity_${index}`] = addOn.quantity;
      // });

      // newItem.Item_Id = item.item._id;
      itemsForExport.push(newItem);
    });

  const exportFile = () => {
    const ws = utils.json_to_sheet(itemsForExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `${currentShedCategory?.name}_Sheds.xlsx`);
  };

  const importFile = () => {
    importSheds(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const columns = [
    {
      title: "Name",
      render: (record) => <Fragment>{record?.name}</Fragment>,
    },
    {
      title: "Code",
      render: (record) => <Fragment>{record.code}</Fragment>,
    },
    {
      title: "Id",
      render: (record) => <Fragment>{record._id}</Fragment>,
    },

    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShedModel(record)}>
            View
          </button>
          <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditShedModel(record)}>
            Edit
          </button>
          {/* <button
            className="btn btn-xs btn-outline-secondary ms-2"
            type="button"
            onClick={() => handleCopyShedModelAndSheds(record)}
          >
            Copy
          </button> */}

          {/* <button
            className="btn btn-xs btn-outline-danger ms-2"
            type="button"
            onClick={() => handleDeleteShedModelAndSheds(record)}
          >
            Delete
          </button> */}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddShedModel = () => {
    navigate("/company/shed/model/form");
  };

  const handleViewShedModel = (shedModel) => {
    setCurrentShedModel(shedModel);
    navigate("/company/shed/all");
  };

  const handleEditShedModel = (shedModel) => {
    setCurrentShedModel(shedModel);
    // navigate("/company/shed/model/form");
    showShedModelModal();
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleCopyShedModelAndSheds = async (shedModel) => {
    await copyShedModelAndSheds(shedModel._id);
    navigate("/company/shed/category/all");
  };

  const handleDeleteShedModelAndSheds = (shedModel) => {
    console.log("Delete", shedModel);
    deleteShedModel(shedModel._id);
  };

  const [isShedModelModalVisible, setIsShedModelModalVisible] = useState(false);

  // roof modal
  const showShedModelModal = () => {
    setIsShedModelModalVisible(true);
  };
  const handleShedModelOk = () => {
    setIsShedModelModalVisible(false);
  };

  const handleShedModelCancel = () => {
    setIsShedModelModalVisible(false);
  };

  return (
    <Fragment>
      <Modal
        // title="Add User"
        visible={isShedModelModalVisible}
        onOk={handleShedModelOk}
        onCancel={handleShedModelCancel}
        footer={null} // Remove the default footer buttons
        width="75%" // Set the width of the modal to 75% of the screen
        bodyStyle={{
          maxHeight: "100vh", // Adjust the height based on your needs
          overflowY: "auto",
        }}
      >
        <ShedModelForm />
      </Modal>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-success float-start"}
                  disabled={user.viewOnly}
                  // className="mb-2 btn btn-outline-success float-start"
                  onClick={() => handleAddShedModel()}
                >
                  Add Shed Model
                </button>
                {allShedsInCategory && (
                  <button
                    className={user.viewOnly ? "viewOnly" : "mb-2 me-2 ms-2 btn btn-outline-info"}
                    disabled={user.viewOnly}
                    // className="mb-2 me-2 btn btn-outline-info float-end"
                    onClick={() => exportFile()}
                  >
                    Download Sheds
                  </button>
                )}
                <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
                  Back
                </button>
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              // loading={!loadingProduct && productResults ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
              dataSource={shedModels}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
            <div className="row">
              <div className="col-md-4">
                <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
              </div>
              <div className="col-md-4">
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 me-2 btn btn-outline-info"}
                  disabled={user.viewOnly}
                  // className="mb-2 me-2 btn btn-outline-info"
                  onClick={() => importFile()}
                >
                  Upload Sheds
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllShedModels;
