import {
  ADD_INVOICE,
  DELETE_INVOICE,
  CLEAR_INVOICES,
  SET_CURRENT_INVOICE,
  CLEAR_CURRENT_INVOICE,
  UPDATE_INVOICE,
  SET_FILTERED_INVOICES,
  CLEAR_FILTERED_INVOICES,
  INVOICE_ERROR,
  GET_INVOICES,
  STATUS_CHANGE_INVOICE,
  GET_INVOICE_BY_SHOP_STATUS,
  SET_SEARCHED_INVOICE,
  CLEAR_SEARCHED_INVOICE,
  SWITCH_LOADINGINVOICE,
  SET_INVOICE_LOADING,
  CLEAR_FILTER_INVOICES_BY_SHOP,
  SET_SHOP_FOR_FILTER,
  SET_INVOICE_NUMBER_SEARCH,
  SET_CUT_OFF_DATE,
  SET_INVOICE_PAGINATION,
  GET_INVOICE_COUNT,
  CLEAR_INVOICE_COUNT,
  SET_INVOICE_IS_CANCELED,
  GET_ADJUSTED_INVOICES,
  UPDATE_ADJUSTED_INVOICE,
  GET_INVOICES_CHART,
  UPDATE_INVOICE_ORDER,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        invoiceLoading: false,
      };
    case GET_ADJUSTED_INVOICES:
      return {
        ...state,
        adjustedInvoices: action.payload,
        invoiceLoading: false,
      };
    case UPDATE_ADJUSTED_INVOICE:
      return {
        ...state,
        adjustedInvoices: state.adjustedInvoices.map((invoice) =>
          invoice._id === action.payload._id ? action.payload : invoice
        ),
        invoiceLoading: false,
      };
    case UPDATE_INVOICE_ORDER:
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          order: action.payload,
        },
      };
    case ADD_INVOICE:
      return {
        ...state,
        invoice: action.payload,
        invoiceLoading: false,
      };
    case UPDATE_INVOICE:
      return {
        ...state,
        invoices: state.invoices
          ? state.invoices.map((invoice) => (invoice._id === action.payload._id ? action.payload : invoice))
          : [],
        filteredInvoices: state.filteredInvoices
          ? state.filteredInvoices.map((invoice) => (invoice._id === action.payload._id ? action.payload : invoice))
          : [],
        invoiceLoading: false,
      };
    case DELETE_INVOICE:
      return {
        ...state,
        invoices: state.invoices.filter((invoice) => invoice._id !== action.payload),
        invoiceLoading: false,
      };
    case CLEAR_INVOICES:
      return {
        ...state,
        invoices: null,
        filteredInvoices: null,
        error: null,
      };
    case SET_CURRENT_INVOICE:
      return {
        ...state,
        currentInvoice: action.payload,
      };
    case CLEAR_CURRENT_INVOICE:
      return {
        ...state,
        currentInvoice: null,
      };
    case SET_FILTERED_INVOICES:
      return {
        ...state,
        filteredInvoices: action.payload,
      };
    // case GET_INVOICE_BY_SHOP_STATUS:
    //   return {
    //     ...state,
    //     filteredInvoicesByShop: action.payload,
    //     invoiceLoading: false,
    //     // filteredInvoicesByShop: state.invoices.filter((invoice) => {
    //     //   let invoiceId = invoice.madeBy._id;
    //     //   return invoiceId.match(action.payload.value);
    //     // }),
    //     // shop: action.payload,
    //   };
    // case SET_SHOP_FOR_FILTER:
    //   return {
    //     ...state,
    //     shop: action.payload,
    //   };
    case CLEAR_FILTERED_INVOICES:
      return {
        ...state,
        filterededInvoices: null,
      };
    // case CLEAR_FILTER_INVOICES_BY_SHOP:
    //   return {
    //     ...state,
    //     filteredInvoicesByShop: null,
    //   };
    case INVOICE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case STATUS_CHANGE_INVOICE:
      return {
        ...state,
        invoices: state.invoices && state.invoices.filter((invoice) => invoice._id !== action.payload._id),
        adjustedInvoices:
          state.adjustedInvoices && state.adjustedInvoices.filter((invoice) => invoice._id !== action.payload._id),
        filteredInvoicesByShop:
          state.filteredInvoicesByShop && state.filteredInvoicesByShop.filter((invoice) => invoice._id !== action.payload._id),
        filteredInvoice: state.filteredInvoice && state.filteredInvoice.filter((invoice) => invoice._id !== action.payload._id),
        loading: false,
      };
    case SET_SEARCHED_INVOICE:
      return {
        ...state,
        searchedInvoice: action.payload,
        invoiceLoading: false,
      };
    case CLEAR_SEARCHED_INVOICE:
      return {
        ...state,
        searchedInvoice: null,
      };

    // case SWITCH_LOADINGINVOICE:
    //   return {
    //     ...state,
    //     invoiceLoading: true,
    //   };
    case SET_INVOICE_LOADING:
      return {
        ...state,
        invoiceLoading: action.payload,
      };

    case SET_INVOICE_NUMBER_SEARCH:
      return {
        ...state,
        invoiceNumber: action.payload,
      };
    case SET_CUT_OFF_DATE:
      return {
        ...state,
        cutOffDate: action.payload,
      };
    case GET_INVOICES_CHART:
      return {
        ...state,
        chartInvoices: action.payload,
      };
    // case SET_INVOICE_PAGINATION:
    //   return {
    //     ...state,
    //     pagination: action.payload,
    //   };
    // case GET_INVOICE_COUNT:
    //   return {
    //     ...state,
    //     invoiceCount: action.payload,
    //   };
    // case CLEAR_INVOICE_COUNT:
    //   return {
    //     ...state,
    //     invoiceCount: 0,
    //   };
    // case SET_INVOICE_IS_CANCELED:
    //   return {
    //     ...state,
    //     isCancelled: action.payload,
    //   };
    default:
      return state;
  }
};
