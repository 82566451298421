import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CompanyContext from "../../../../../context/company/companyContext";
const initialState = {
  trimColor: { color: "" },
};

const AddTrimColor = ({ selectedTrimColor, setSelectedTrimColor }) => {
  const navigate = useNavigate();

  const [values, setValues] = useState(initialState);
  // destructure
  const { trimColor } = values;
  const companyContext = useContext(CompanyContext);
  const { company, updateCompany } = companyContext;

  useEffect(() => {
    if (selectedTrimColor) {
      setValues({
        trimColor: selectedTrimColor,
      });
    }
  }, [selectedTrimColor]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Make a copy of the company's trimColors array
    let updatedTrimColors = [...company.trimColors];
    // If a color is selected, update it
    if (selectedTrimColor) {
      const oldColorId = selectedTrimColor._id; // the old color's _id
      const newColor = values.trimColor.color; // the new color
      // Find the index of the old color
      let index = updatedTrimColors.findIndex((color) => color._id === oldColorId);
      // Replace the old color with the new color
      if (index !== -1) {
        updatedTrimColors[index].color = newColor;
      }
    } else {
      // If no color is selected, add a new one
      const newColor = values.trimColor.color; // the new color
      updatedTrimColors.push({ color: newColor });
    }
    // Update the company
    let updatedCompany = { ...company, trimColors: updatedTrimColors };
    updateCompany(company._id, updatedCompany);
    // Reset the form values
    setSelectedTrimColor(null);
    setValues(initialState);
  };

  const handleChange = (e) => {
    const [field, nestedField] = e.target.name.split(".");
    setValues((prevValues) => ({
      ...prevValues,
      [field]: { ...prevValues[field], [nestedField]: e.target.value },
    }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center" }}>Trim Color</h4>

      <div className="form-group">
        <label>Color</label>
        <input
          type="string"
          name="trimColor.color"
          className="form-control inputbg"
          value={trimColor.color}
          onChange={handleChange}
        />
      </div>
      <button className="btn btn-outline-info float-end mb-2 mt-4">Save Trim Color</button>
    </form>
  );
};

export default AddTrimColor;
