import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ItemContext from "../../../../../context/item/itemContext";
import ItemCategoryContext from "../../../../../context/itemCategory/itemCategoryContext";
import ShopContext from "../../../../../context/shop/shopContext";
import AuthContext from "../../../../../context/auth/authContext";
import { Table, Modal } from "antd";
import ItemFilter from "../../../../../context/item/ItemFilter";
import MetalUpdateForm from "./forms/MetalUpdateForm";

const AllItems = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const itemContext = useContext(ItemContext);
  const { getItemsByCategory, deleteItem, setCurrentItem, items, filteredItems, loadingItem, clearItems } = itemContext;

  const itemCategoryContext = useContext(ItemCategoryContext);
  const { currentItemCategory } = itemCategoryContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    currentShop && currentItemCategory && getItemsByCategory(currentItemCategory._id, currentShop._id);
    return () => {
      clearItems();
    };
  }, []);

  useEffect(() => {}, [items]);

  const handleRemove = (item) => {
    let answer = window.confirm(`Are you sure you want to delete ${item.value.name}?`);
    if (answer) {
      deleteItem(item);
    }
  };

  const handleViewProduct = (item) => {
    setCurrentItem(item);
    navigate(`/company/items/update`);
  };

  const handleAddItem = () => {
    navigate(`/company/items/add`);
  };

  const columns = [
    {
      title: "Name",
      width: "20%",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "Sub-Total",
      width: "8%",
      render: (text, record) => (
        <span>
          {record &&
            record.standardValues.subTotal.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },
    {
      title: "Freight",
      width: "8%",
      render: (text, record) => (
        <span>
          {record &&
            record.standardValues.freight.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },
    {
      title: "Total",
      width: "8%",
      render: (text, record) => (
        <span>
          {record &&
            record.standardValues.total.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          {
            <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewProduct(record)}>
              View
            </button>
          }
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleBack = () => {
    clearItems();
    navigate(-1);
  };

  const handleViewMetalFormUpdate = (e) => {
    e.preventDefault();
    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-success"}
                  disabled={user.viewOnly}
                  // className="mb-2 btn btn-outline-success"
                  onClick={() => handleAddItem()}
                >
                  Add Item
                </button>
                {currentItemCategory && currentItemCategory.name === "Metal" ? (
                  <button
                    className="btn btn-outline-primary ms-4 mb-2"
                    type="button"
                    onClick={(e) => handleViewMetalFormUpdate(e)}
                  >
                    Update Metal Items
                  </button>
                ) : null}
                <button
                  // className={user.viewOnly ? "viewOnly" : "ml-2 mb-2 btn btn-outline-danger float-end"}
                  // disabled={user.viewOnly}
                  className=" ml-2 mb-2 btn btn-outline-danger float-end"
                  onClick={() => handleBack()}
                >
                  Back
                </button>
              </div>
            </div>

            <ItemFilter />
            <Modal
              // title="Add User"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null} // Remove the default footer buttons
              bodyStyle={{
                maxHeight: "50vh", // Adjust the height based on your needs
                overflowY: "auto",
              }}
            >
              <MetalUpdateForm />
            </Modal>

            <Table
              rowClassName={() => "hover-row"}
              loading={!loadingItem && items ? false : true}
              columns={columns}
              dataSource={filteredItems && filteredItems !== null ? filteredItems : items && items}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllItems;
