import React, { Fragment, useEffect, useState, useContext } from "react";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllComponentCategories = () => {
  const navigate = useNavigate();
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;
  const componentCategoryContext = useContext(ComponentCategoryContext);

  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;

  const {
    getComponentCategorys,
    componentCategorys,
    currentComponentCategory,
    loadingComponentCategory,
    setCurrentComponentCategory,
    // clearCurrentComponent,
    updateAllComponentCategories,
  } = componentCategoryContext;

  useEffect(() => {
    // clearCurrentComponent();
    getShop();
    getComponentCategorys(currentShop.defaultCategory._id);
  }, []);

  useEffect(() => {}, [componentCategorys]);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  // componentCategorys &&
  //   componentCategorys.map((componentCategory) => {
  //     componentCategory.value.items.forEach((item) => {
  //       let newItem = {};
  //       newItem.Component_Id = componentCategory._id;
  //       newItem.Component_Name = componentCategory.value.name;
  //       newItem.Item = item.item.value.name;
  //       newItem.Quantity = item.count;
  //       newItem.Item_Id = item.item._id;
  //       // newItem.Id = item._id;
  //       itemsForExport.push(newItem);
  //     });
  //   });

  const exportFile = () => {
    const ws = utils.json_to_sheet(itemsForExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, "Components_BOM.xlsx");
  };

  const importFile = () => {
    updateAllComponentCategories(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const columns = [
    {
      title: "Name",
      width: "20%",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          {componentCategorys && (
            <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewComponent(record)}>
              View
            </button>
          )}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewComponent = (componentCategory) => {
    setCurrentComponentCategory(componentCategory);
    navigate("/shop/component/all");
  };
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <button className="mb-2 btn btn-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        loading={componentCategorys ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        dataSource={componentCategorys && componentCategorys}
        // dataSource={testModels}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllComponentCategories;
