import React, { useReducer } from "react";
import AddonCategoryContext from "./addonCategoryContext";
import addonCategoryReducer from "./addonCategoryReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_ADDON_CATEGORY,
  DELETE_ADDON_CATEGORY,
  CLEAR_ADDON_CATEGORYS,
  SET_CURRENT_ADDON_CATEGORY,
  CLEAR_CURRENT_ADDON_CATEGORY,
  UPDATE_ADDON_CATEGORY,
  ADDON_CATEGORY_ERROR,
  GET_ADDON_CATEGORYS,
} from "../types";

const AddonCategoryState = (props) => {
  const initialState = {
    addonCategorys: null,
    currentAddonCategory: null,
    error: null,
    loadingAddonCategory: true,
  };

  const [state, dispatch] = useReducer(addonCategoryReducer, initialState);

  // get All
  const getAddonCategorys = async (parentCategory) => {
    try {
      const res = await axios.get(`/api/addon/category/${parentCategory}`);
      dispatch({ type: GET_ADDON_CATEGORYS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDON_CATEGORY_ERROR });
    }
  };

  const addAddonCategory = async (addonCategory) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/addon/category/", addonCategory, config);
      dispatch({ type: ADD_ADDON_CATEGORY, payload: res.data });
      toast.success(`Option is created`);
    } catch (err) {
      dispatch({ type: ADDON_CATEGORY_ERROR });
      toast.error(`Addon ${err}`);
    }
  };

  const deleteAddonCategory = async (id) => {
    try {
      const res = await axios.delete(`/api/addon/category/delete/${id}`);
      dispatch({ type: DELETE_ADDON_CATEGORY, payload: id });
      toast.success(`AddonCategory is deleted`);
    } catch (err) {
      dispatch({ type: ADDON_CATEGORY_ERROR });
      toast.error(`AddonCategory ${err}`);
    }
  };

  // update AddonCategory
  const updateAddonCategory = async (addonCategory) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/addon/category/update/${addonCategory._id}`, addonCategory, config);
      dispatch({ type: UPDATE_ADDON_CATEGORY, payload: res.data });
      toast.success(`Addon is updated`);
    } catch (err) {
      dispatch({ type: ADDON_CATEGORY_ERROR });
      toast.error(`Addon ${err}`);
    }
  };

  const clearAddonCategorys = () => {
    dispatch({ type: CLEAR_ADDON_CATEGORYS });
  };

  const setCurrentAddonCategory = (addonCategory) => {
    dispatch({ type: SET_CURRENT_ADDON_CATEGORY, payload: addonCategory });
  };

  const clearCurrentAddonCategory = () => {
    dispatch({ type: CLEAR_CURRENT_ADDON_CATEGORY });
  };

  return (
    <AddonCategoryContext.Provider
      value={{
        addonCategorys: state.addonCategorys,
        currentAddonCategory: state.currentAddonCategory,
        error: state.error,
        loadingAddonCategory: state.loadingAddonCategory,
        addAddonCategory,
        deleteAddonCategory,
        setCurrentAddonCategory,
        clearCurrentAddonCategory,
        updateAddonCategory,
        clearAddonCategorys,
        getAddonCategorys,
      }}
    >
      {props.children}
    </AddonCategoryContext.Provider>
  );
};

export default AddonCategoryState;
