import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { List, Spin, Modal, Form, Input, Button } from "antd";
import AuthContext from "../../../context/auth/authContext";
import CommentContext from "../../../context/comment/commentContext";
import axios from "axios";

const AllShopCommentsPage = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const commentContext = useContext(CommentContext);
  const { shopComments, updateComment, loadingComments, getShopComments, setUpdatedComments, deleteComment, addComment } =
    commentContext;

  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    user && getShopComments(user.shop);
  }, []);

  const handleCommentRead = (comment) => {
    updateComment(comment);
    const updatedComment = { ...comment, read: true };
    const updatedComments = shopComments.map((com) => (comment._id === com._id ? updatedComment : com));
    setUpdatedComments(updatedComments);
  };

  const setExit = () => {
    navigate(-1);
  };

  const handleCommentDelete = (comment) => {
    deleteComment(comment);
  };

  const addNewMessage = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmitMessage = async (event) => {
    event.preventDefault();
    // Your logic to add a new comment here
    // ...
    try {
      const values = form.getFieldsValue();
      let today = new Date();
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const data = await axios.post(
        "/api/comment",
        {
          author: user.userName,
          content: values.message,
          commentFrom: user,
          commentFor: user.companyId,
          datetime: today.toLocaleString(),
        },
        config
      );
      const newComment = {
        author: user.userName,
        sender: user,
        content: values.message,
        commentFrom: user,
        commentFor: user.companyId,
        datetime: today.toLocaleString(),
      };
      addComment(newComment);
      handleCloseModal();
    } catch (error) {
      handleCloseModal();
      console.log("there is a error", error);
    }
  };

  // Group comments by serial number and sort by date (most recent first)
  const commentsBySerialNumber = shopComments.reduce((groups, comment) => {
    const key = comment.order ? comment.order.serialNumber : "no-serial-number";
    const group = groups.get(key) || [];
    group.push(comment);
    groups.set(key, group);
    return groups;
  }, new Map());

  const noSerialNumberGroup = commentsBySerialNumber.get("no-serial-number");
  const sortedCommentsBySerialNumber = Array.from(commentsBySerialNumber.entries())
    .filter(([key]) => key !== "no-serial-number")
    .map(([key, group]) => ({
      serialNumber: key,
      comments: group.sort((a, b) => new Date(b.datetime) - new Date(a.datetime)),
    }));
  if (noSerialNumberGroup) {
    sortedCommentsBySerialNumber.unshift({
      serialNumber: "No Serial Number",
      comments: noSerialNumberGroup.sort((a, b) => new Date(b.datetime) - new Date(a.datetime)),
    });
  }

  // Color groups by alternating between light and dark gray
  const groupStyleMap = sortedCommentsBySerialNumber.reduce((map, { serialNumber }, index) => {
    const backgroundColor = index % 2 === 0 ? "#e1f1f7" : "#e0e0e0";
    const padding = "30px";
    map[serialNumber] = { backgroundColor, padding };
    return map;
  }, {});

  return (
    <>
      <div className="col-md-12">
        <Modal footer={null} title="New Message" visible={showModal} onCancel={() => handleCloseModal(false)}>
          <Form form={form} layout="vertical">
            <Form.Item
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please enter a message",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button className="float-end" type="primary" htmlType="submit" onClick={handleSubmitMessage}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-center">Messages</h2>
                {/* <button className="float-end mb-2 mt-2 btn btn-primary " onClick={() => addNewMessage()}>
                  New Message
                </button> */}
              </div>
            </div>

            {loadingComments ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin size="large" />
              </div>
            ) : (
              <List
                itemLayout="vertical"
                dataSource={sortedCommentsBySerialNumber}
                renderItem={({ serialNumber, comments }) => (
                  <div style={groupStyleMap[serialNumber]}>
                    <h3>Serial Number: {serialNumber}</h3>
                    <List
                      dataSource={comments}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            className={`message ${item.commentFromType === "Shop" ? "current-user" : "other-user"}`}
                            title={`${item.order ? `${item.order.serialNumber} - - - -  ${item.content}` : item.content}`}
                            description={`${item.datetime && new Date(item.datetime).toLocaleString()} - - - - ${
                              item.sender.name
                            }`}
                          />
                          {item.commentFor && item.commentFor._id === user.shop && !item.read && (
                            <button className="pr-2 btn  btn-success" type="button" onClick={() => handleCommentRead(item)}>
                              Mark Read
                            </button>
                          )}
                          {/* <button
                            className="pr-2 btn btn-xs btn-outline-danger mt-2"
                            type="button"
                            onClick={() => handleCommentDelete(item)}
                          >
                            Delete
                          </button> */}
                        </List.Item>
                      )}
                    />
                  </div>
                )}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllShopCommentsPage;
