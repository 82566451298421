import React, { Fragment, useEffect, useContext } from "react";
import RoofColorColumns from "./RoofColorColumns";

const AllRoofColors = ({ roofColors, setSelectedRoofColor, selectedRoofColor }) => {
  const theTitle = "Roof Colors";

  return (
    <RoofColorColumns
      roofColors={roofColors}
      setSelectedRoofColor={setSelectedRoofColor}
      selectedRoofColor={selectedRoofColor}
      theTitle={theTitle}
    />
  );
};

export default AllRoofColors;
