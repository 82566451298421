import React, { useState } from "react";
import { Checkbox, Form, Input, InputNumber, Card, Typography, Button } from "antd";

const { Title } = Typography;

const ItemCreateForm = ({ handleSubmit, handleChange, setValues, setCategory, values }) => {
  // destructure
  const { name, type, subTotal, freight, inventoryItem } = values;

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Card
        style={{
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          border: "1px solid #e8e8e8",
          borderRadius: "8px",
        }}
      >
        <Title level={4} style={{ marginBottom: "20px", textAlign: "center" }}>
          Create Item
        </Title>
        <Form.Item label="Name">
          <Input name="name" value={name} onChange={handleChange} placeholder="Enter item name" required />
        </Form.Item>

        <Form.Item label="Type">
          <Input name="type" value={type} onChange={handleChange} placeholder="Enter item type" required />
        </Form.Item>

        <Form.Item label="Sub-Total">
          <InputNumber
            name="subTotal"
            value={subTotal}
            onChange={(value) => setValues({ ...values, subTotal: value })}
            style={{ width: "100%" }}
            step={0.01}
            min={0}
            required
          />
        </Form.Item>

        <Form.Item label="Freight">
          <InputNumber
            name="freight"
            value={freight}
            onChange={(value) => setValues({ ...values, freight: value })}
            style={{ width: "100%" }}
            step={0.01}
            min={0}
            // required
          />
        </Form.Item>

        <Form.Item label="Total">
          <InputNumber value={+subTotal + +freight} style={{ width: "100%" }} readOnly disabled />
        </Form.Item>

        <Form.Item>
          <Checkbox name="inventoryItem" checked={inventoryItem} onChange={handleChange}>
            Inventory Item
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{
              width: "100%",
              height: "48px",
              fontSize: "16px",
            }}
          >
            Create Item
          </Button>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default ItemCreateForm;
