import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import AuthContext from "../../../../../../context/auth/authContext";
import AddonCategoryForm from "./AddonCategoryForm";
import AddonParentCategoryContext from "../../../../../../context/addonParentCategory/addonParentCategoryContext";
import CopyAddonParentCategory from "./CopyAddonParentCategory";
import AddOnContext from "../../../../../../context/addon/addOnContext";
import "antd/dist/antd.css";
import { Table, Modal } from "antd";

import { read, utils, writeFileXLSX } from "xlsx";

const AllAddonCategories = () => {
  const navigate = useNavigate();
  const addonCategoryContext = useContext(AddonCategoryContext);
  const { getAddonCategorys, addonCategorys, setCurrentAddonCategory, clearCurrentAddonCategory } = addonCategoryContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const addonParentCategoryContext = useContext(AddonParentCategoryContext);
  const { currentAddonParentCategory } = addonParentCategoryContext;

  const addOnContext = useContext(AddOnContext);
  const { allAddOns, updateAllAddons, getAllAddonsByParentCat } = addOnContext;

  // console.log("This is currentAddonParentCategory", currentAddonParentCategory);

  useEffect(() => {
    getAddonCategorys(currentAddonParentCategory._id);
    getAllAddonsByParentCat(currentAddonParentCategory._id);
    // getAddonCategorys();
  }, []);

  useEffect(() => {}, [addonCategorys]);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  allAddOns &&
    allAddOns.map((addOn) => {
      let addOnItem = {};
      addOnItem.id = addOn._id;
      addOnItem.Name = addOn.name;
      addOnItem.Retail_Price = addOn.retailPrice;
      addOnItem.Shop_Percent = addOn.shopPercent;
      addOnItem.ssId = addOn.ssId;

      itemsForExport.push(addOnItem);
    });

  const exportFile = () => {
    const ws = utils.json_to_sheet(itemsForExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, "Addons.xlsx");
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const importUpdateFile = () => {
    updateAllAddons(newFile);
  };

  const columns = [
    {
      title: "View Addons",
      width: "10%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddonCategory(record)}>
            View
          </button>
          {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditAddonCategory(record)}>
            Edit
          </button> */}
        </span>
      ),
    },
    {
      title: "Name",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "ID",
      render: (record) => <Fragment>{record._id}</Fragment>,
    },

    {
      title: "Edit Category",
      width: "10%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditAddonCategory(record)}>
            Edit
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddAddonCategory = () => {
    clearCurrentAddonCategory();
    handleShowModal();
  };

  const handleViewAddonCategory = (category) => {
    setCurrentAddonCategory(category);
    navigate("/company/addon/parent-categories/shop/category/addons");
  };

  const handleEditAddonCategory = (category) => {
    setCurrentAddonCategory(category);
    // navigate("/company/addon/category/form");
    handleShowModal();
  };

  const handleBack = () => {
    navigate(-1);
  };

  const [isAddonCategoryModalVisible, setIsAddonCategoryModalVisible] = useState(false);

  const handleShowModal = () => {
    showAddonCategoryModal();
  };

  // roof modal
  const showAddonCategoryModal = () => {
    setIsAddonCategoryModalVisible(true);
  };
  const handleAddonCategoryOk = () => {
    setIsAddonCategoryModalVisible(false);
  };

  const handleAddonCategoryCancel = () => {
    clearCurrentAddonCategory();
    setIsAddonCategoryModalVisible(false);
  };

  const [isCopyAddonCategoryModalVisible, setIsCopyAddonCategoryModalVisible] = useState(false);

  const handleCopyShowModal = () => {
    showCopyAddonCategoryModal();
  };

  // roof modal
  const showCopyAddonCategoryModal = () => {
    setIsCopyAddonCategoryModalVisible(true);
  };
  const handleCopyAddonCategoryOk = () => {
    setIsCopyAddonCategoryModalVisible(false);
  };

  const handleCopyAddonCategoryCancel = () => {
    clearCurrentAddonCategory();
    setIsCopyAddonCategoryModalVisible(false);
  };

  const handleCopyAddonCategory = () => {
    handleCopyShowModal();
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-success float-start"}
                  disabled={user.viewOnly}
                  // className="mb-2 btn btn-outline-success float-start"
                  onClick={() => handleAddAddonCategory()}
                >
                  Add Addon Category
                </button>

                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 ms-2 btn btn-outline-success float-start"}
                  disabled={user.viewOnly}
                  // className="mb-2 btn btn-outline-success float-start"
                  onClick={() => handleCopyAddonCategory()}
                >
                  Copy all categories and addons
                </button>

                {allAddOns && (
                  <button
                    className={user.viewOnly ? "viewOnly" : "mb-2 ms-2 me-2 btn btn-outline-info"}
                    disabled={user.viewOnly}
                    // className="mb-2 me-2 btn btn-outline-info float-end"
                    onClick={() => exportFile()}
                  >
                    Download
                  </button>
                )}

                <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
                  Back
                </button>
              </div>
            </div>

            <Modal
              // title="Add User"
              visible={isAddonCategoryModalVisible}
              onOk={handleAddonCategoryOk}
              onCancel={handleAddonCategoryCancel}
              footer={null} // Remove the default footer buttons
              bodyStyle={{
                maxHeight: "50vh", // Adjust the height based on your needs
                overflowY: "auto",
              }}
            >
              <AddonCategoryForm />
            </Modal>

            <Modal
              // title="Add User"
              visible={isCopyAddonCategoryModalVisible}
              onOk={handleCopyAddonCategoryOk}
              onCancel={handleCopyAddonCategoryCancel}
              footer={null} // Remove the default footer buttons
              bodyStyle={{
                maxHeight: "50vh", // Adjust the height based on your needs
                overflowY: "auto",
              }}
            >
              <CopyAddonParentCategory />
            </Modal>

            <Table
              rowClassName={() => "hover-row"}
              // loading={!loadingProduct && productResults ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
              dataSource={addonCategorys}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
            <div className="row">
              <div className="col-md-4">
                <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
              </div>
              <div className="col-md-4">
                {/* <button className="mb-2 me-2 btn btn-outline-info" onClick={() => importFile()}>
              Upload Addons
            </button> */}
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 me-2 btn btn-outline-info"}
                  disabled={user.viewOnly}
                  // className="mb-2 me-2 btn btn-outline-info"
                  onClick={() => importUpdateFile()}
                >
                  Update Addons
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllAddonCategories;
