import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import AddonParentCategoryContext from "../../../../../../context/addonParentCategory/addonParentCategoryContext";
import ReactSelect from "react-select";

const defaultValues = {};
const AddonCategoryForm = () => {
  const navigate = useNavigate();

  const addonCategoryContext = useContext(AddonCategoryContext);
  const { addAddonCategory, currentAddonCategory, updateAddonCategory, clearCurrentAddonCategory } = addonCategoryContext;

  const addonParentCategoryContext = useContext(AddonParentCategoryContext);
  const { addonParentCategorys, getAddonParentCategorys } = addonParentCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const addonParentCategoryOptions = addonParentCategorys.map((addonParentCategory) => {
    return { value: addonParentCategory._id, label: addonParentCategory.name };
  });

  // if there is a currentAddonCategory, set the form fields to the currentAddonCategory
  console.log("currentAddonCategory", currentAddonCategory);
  useEffect(() => {
    getAddonParentCategorys();
    // clearCurrentAddonCategory();
    setValue("name", "");
    setValue("addonParentCategory", "");
    if (currentAddonCategory) {
      setValue("name", currentAddonCategory.name);
      setValue("_id", currentAddonCategory._id);
      if (currentAddonCategory.addonParentCategory) {
        const selectedAddonParentCategory = addonParentCategoryOptions.find(
          (option) => option.value === currentAddonCategory.addonParentCategory
        );
        setValue("addonParentCategory", selectedAddonParentCategory);
      }
    } else {
      setValue("name", "");
      setValue("addonParentCategory", "");
    }
  }, [currentAddonCategory]);

  const onSubmit = (data) => {
    if (!currentAddonCategory) {
      addAddonCategory(data);
      // clearCurrentAddonCategory();
      // navigate(-1);
    } else {
      updateAddonCategory(data);
      // navigate(-1);
      clearCurrentAddonCategory();
    }
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentAddonCategory();
  };

  const handleAddonParentCategoryChange = (e) => {
    setValue("addonParentCategory", e);
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">Addon Category Form</h4>
          {/* {currentDoor && (
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          )} */}
        </div>
      </div>
      <div className="row">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 mt-4">
                      <div className="form-group"></div>
                    </div>

                    <div className="col-md-8"></div>

                    <div className="col-md-1">
                      <div className="buttons float-end  mr-3 mt-3">
                        <button className="btn btn-outline-primary " type="submit">
                          Submit
                          {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Name</label>
                        <input className="form-control inputbg" name="name" {...register("name", { required: true })} />
                      </div>
                    </div>
                  </div>
                  <label htmlFor="baseStyle">Parent Category</label>
                  <Controller
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        isClearable={true}
                        options={addonParentCategoryOptions}
                        // menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        onChange={(e) => {
                          handleAddonParentCategoryChange(e);
                        }}
                      />
                    )}
                    name="addonParentCategory"
                    control={control}
                    // className="shedlaborforminput"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddonCategoryForm;
