import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ItemContext from "../../../../../context/item/itemContext";
import ItemCategoryContext from "../../../../../context/itemCategory/itemCategoryContext";
import ShopContext from "../../../../../context/shop/shopContext";
import { Table, Modal } from "antd";
import ItemFilter from "../../../../../context/item/ItemFilter";
import MetalUpdateForm from "./MetalUpdateForm";

const ShopAllItems = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const itemContext = useContext(ItemContext);
  const { getItemsByCategory, deleteItem, setCurrentItem, items, filteredItems, loadingItem, copyForShops, clearItems } =
    itemContext;

  const itemCategoryContext = useContext(ItemCategoryContext);
  const { currentItemCategory } = itemCategoryContext;

  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;

  useEffect(() => {
    getShop();
    return () => {
      clearItems();
    };
  }, []);

  useEffect(() => {
    currentShop && currentItemCategory && getItemsByCategory(currentItemCategory._id, currentShop._id);
  }, [currentShop]);

  useEffect(() => {}, [items]);

  const handleRemove = (item) => {
    let answer = window.confirm(`Are you sure you want to delete ${item.value.name}?`);
    if (answer) {
      deleteItem(item);
    }
  };

  const handleViewProduct = (item) => {
    setCurrentItem(item);
    navigate(`/shop/items/update`);
  };

  const getColor = (p) => {
    // console.log(p);
    if (p.quantityInCurrent < p.orderPointCritical) return "red";
    if (p.quantityInCurrent < p.orderPointWarning) return "orange";
    return "";
  };
  const getTextColor = (p) => {
    if (p.quantityInCurrent < p.orderPointCritical) return "white";
    return "";
  };

  const columns = [
    {
      title: "Name",
      render: (record) => (
        <span
          style={{
            backgroundColor: getColor(record.shopValues),
            color: getTextColor(record.shopValues),
            padding: "5px 5px",
            borderRadius: "3px",
          }}
        >
          {record.shopValues.name}
        </span>
      ),
    },

    {
      title: "Ordered Quantity",
      render: (record) => <Fragment>{record.shopValues.quantityOnOrder}</Fragment>,
    },
    {
      title: "Recieved Quantity",
      render: (record) => <Fragment>{record.shopValues.quantityInRecieved}</Fragment>,
    },
    {
      title: "Current Quantity",
      render: (record) => <Fragment>{record.shopValues.quantityInCurrent}</Fragment>,
    },

    {
      title: "Sub-Total",
      render: (text, record) => (
        <span>
          {record &&
            record.shopValues.subTotal.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },
    {
      title: "Freight",
      render: (text, record) => (
        <span>
          {record.shopValues.freight?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
    {
      title: "Total",
      render: (text, record) => (
        <span>
          {record.shopValues.total.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          {
            <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewProduct(record)}>
              View
            </button>
          }
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleBack = () => {
    clearItems();
    navigate(-1);
  };

  const handleViewMetalFormUpdate = (e) => {
    e.preventDefault();
    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-6">
          <ItemFilter />
        </div>
        <Modal
          // title="Add User"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null} // Remove the default footer buttons
          bodyStyle={{
            maxHeight: "50vh", // Adjust the height based on your needs
            overflowY: "auto",
          }}
        >
          <MetalUpdateForm />
        </Modal>
        {currentItemCategory && currentItemCategory.name === "Metal" ? (
          <div className="col-md-2">
            <button className="btn btn-outline-primary ms-4 mb-2" type="button" onClick={(e) => handleViewMetalFormUpdate(e)}>
              Update Metal Items
            </button>
          </div>
        ) : (
          <div className="col-md-2"> </div>
        )}
        <div className="col-md-4">
          <button className="mb-2 btn btn-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        loading={!loadingItem && items ? false : true}
        columns={columns}
        dataSource={filteredItems && filteredItems !== null ? filteredItems : items && items}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllItems;
