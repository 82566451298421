import React, { useReducer } from "react";
import VendorContext from "./vendorContext";
import vendorReducer from "./vendorReducer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ADD_VENDOR,
  DELETE_VENDOR,
  CLEAR_VENDORS,
  SET_CURRENT_VENDOR,
  CLEAR_CURRENT_VENDOR,
  UPDATE_VENDOR,
  FILTER_VENDORS,
  CLEAR_FILTER_VENDOR,
  VENDOR_ERROR,
  GET_VENDORS,
} from "../types";

const VendorState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    vendors: null,
    currentVendor: null,
    filteredVendor: null,
    error: null,
  };

  const [state, dispatch] = useReducer(vendorReducer, initialState);

  // get customers
  const getVendors = async () => {
    try {
      const res = await axios.get("/api/vendor");
      dispatch({ type: GET_VENDORS, payload: res.data });
    } catch (err) {
      dispatch({ type: VENDOR_ERROR });
    }
  };

  // add Vendor
  const addVendor = async (vendor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/vendor", { vendor }, config);
      dispatch({ type: ADD_VENDOR, payload: res.data });
      toast.success(`Vendor is created`);
    } catch (err) {
      dispatch({ type: VENDOR_ERROR });
      toast.error(`Vendor ${err}`);
    }
  };

  // delete vendor
  const deleteVendor = async (vendor) => {
    try {
      const res = await axios.delete(`/api/vendor/delete/${vendor._id}`);
      dispatch({ type: DELETE_VENDOR, payload: vendor._id });
      toast.success(`Vendor is deleted`);
    } catch (err) {
      dispatch({ type: VENDOR_ERROR });
      toast.error(`Vendor ${err}`);
    }
  };

  // update Vendor
  const updateVendor = async (vendor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(`/api/vendor/update/${vendor._id}`, vendor, config);

      dispatch({ type: UPDATE_VENDOR, payload: res.data });

      toast.success(`Vendor is updated`);
      navigate(-1);
    } catch (err) {
      dispatch({ type: VENDOR_ERROR });
      toast.error(`Vendor ${err}`);
    }
  };

  const clearVendors = () => {
    dispatch({ type: CLEAR_VENDORS });
  };

  const setCurrentVendor = (vendor) => {
    dispatch({ type: SET_CURRENT_VENDOR, payload: vendor });
  };

  const clearCurrentVendor = () => {
    dispatch({ type: CLEAR_CURRENT_VENDOR });
  };

  const filterVendors = (text) => {
    dispatch({ type: FILTER_VENDORS, payload: text });
  };

  const clearFilterVendor = () => {
    dispatch({ type: CLEAR_FILTER_VENDOR });
  };

  return (
    <VendorContext.Provider
      value={{
        vendors: state.vendors,
        currentVendor: state.currentVendor,
        filteredVendor: state.filteredVendor,
        error: state.error,
        getVendors,
        addVendor,
        deleteVendor,
        setCurrentVendor,
        clearCurrentVendor,
        updateVendor,
        filterVendors,
        clearFilterVendor,
        clearVendors,
      }}
    >
      {props.children}
    </VendorContext.Provider>
  );
};

export default VendorState;
