import {
  ADD_ADDON_LABOR,
  DELETE_ADDON_LABOR,
  CLEAR_ADDON_LABORS,
  SET_CURRENT_ADDON_LABOR,
  CLEAR_CURRENT_ADDON_LABOR,
  UPDATE_ADDON_LABOR,
  FILTER_ADDON_LABORS,
  CLEAR_FILTER_ADDON_LABOR,
  ADDON_LABOR_ERROR,
  GET_ADDON_LABORS,
  GET_STANDARD_ADDON_LABORS,
  // GET_ALL_ADDON_LABORS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ADDON_LABORS:
      return {
        ...state,
        addOnLabors: action.payload,
        loadingAddOnLabor: false,
      };
    case GET_STANDARD_ADDON_LABORS:
      return {
        ...state,
        standardAddOnLabors: action.payload,
        loadingAddOnLabor: false,
      };
    // case GET_ALL_ADDON_LABORS:
    //   return {
    //     ...state,
    //     allAddOnLabors: action.payload,
    //     loadingAddOnLabor: false,
    //   };
    case ADD_ADDON_LABOR:
      return {
        ...state,
        addOnLabor: action.payload,
        loading: false,
      };
    case UPDATE_ADDON_LABOR:
      const updatedAddOnLabors = state.addOnLabors.map((addOnLabor) =>
        addOnLabor._id === action.payload._id ? action.payload : addOnLabor
      );
      return {
        ...state,
        addOnLabors: updatedAddOnLabors,
        loading: false,
      };
    case DELETE_ADDON_LABOR:
      return {
        ...state,
        addOnLabors: state.addOnLabors.filter((addonlabor) => addonlabor._id !== action.payload),
        loading: false,
      };
    case CLEAR_ADDON_LABORS:
      return {
        ...state,
        addOnLabors: null,
        filteredAddOnLabor: null,
        error: null,
        currentAddOnLabor: null,
      };
    case SET_CURRENT_ADDON_LABOR:
      return {
        ...state,
        currentAddOnLabor: action.payload,
      };
    case CLEAR_CURRENT_ADDON_LABOR:
      return {
        ...state,
        currentAddOnLabor: null,
      };
    case FILTER_ADDON_LABORS:
      return {
        ...state,
        filteredAddOnLabor: state.AddOnLabors.filter((addOnLabor) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return addOnLabor.customer.value.name.match(regex);
        }),
      };
    case CLEAR_FILTER_ADDON_LABOR:
      return {
        ...state,
        filteredAddOnLabor: null,
      };
    case ADDON_LABOR_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
