import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const InvoicesChartOne = ({ invoices }) => {
  const labels = [];
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      //   legend: {
      //     position: "top",
      //   },
      title: {
        display: true,
        text: `Invoices`,
        font: {
          size: 20,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: `Count`,
        data: [
          { x: "New", y: invoices?.filter((invoice) => invoice.status === "New").length },
          { x: "Denied", y: invoices?.filter((invoice) => invoice.status === "Denied").length },
          { x: "Adjusted", y: invoices?.filter((invoice) => invoice.status === "Adjusted").length },
          { x: "Approved", y: invoices?.filter((invoice) => invoice.status === "Approved").length },
          // { x: "Paid", y: invoices?.filter((invoice) => invoice.status === "Paid").length },
        ],
        backgroundColor: [
          "rgba(170, 235, 255)",
          "rgba(255, 0, 0)",
          "rgba(255, 95, 0)",
          "rgba(102, 203, 126)",
          "rgba(51, 186, 83)",
        ],
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "400px", paddingLeft: "20px" }}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default InvoicesChartOne;
