import React from 'react';
import NewPaymentForm from './NewPaymentForm';
import { Modal } from 'antd';


const NewPaymentModal = ({ setModalVisible, modalVisible, selectedInvoices, setSelectedInvoices, loadingPayments }) => {
  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedInvoices([]);
  };
  return (
    <Modal
      // title='Modal 1000px width'
      centered
      visible={modalVisible}
      // onOk={() => setModalVisible(false)}
      onCancel={handleModalCancel}
      bodyStyle={{ height: '80vh', overflowX: 'scroll' }}
      width={1200}
      footer={null}
    ><NewPaymentForm setModalVisible={setModalVisible} selectedInvoices={selectedInvoices} setSelectedInvoices={setSelectedInvoices} />
      
    </Modal>
  );
};

export default NewPaymentModal;
