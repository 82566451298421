import {
  ADD_SHEDLABOR,
  DELETE_SHEDLABOR,
  CLEAR_SHEDLABORS,
  SET_CURRENT_SHEDLABOR,
  CLEAR_CURRENT_SHEDLABOR,
  UPDATE_SHEDLABOR,
  SHEDLABOR_ERROR,
  GET_SHEDLABORS,
  GET_ALL_SHEDLABORS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_SHEDLABORS:
      return {
        ...state,
        shedLabors: action.payload,
        loading: false,
      };
    case GET_ALL_SHEDLABORS:
      return {
        ...state,
        allShedLabors: action.payload,
        loading: false,
      };
    case ADD_SHEDLABOR:
      return {
        ...state,
        shedLabor: action.payload,
        loading: false,
      };
    case UPDATE_SHEDLABOR:
      return {
        ...state,
        shedLabors:
          state.shedLabors && state.shedLabors.map((labor) => (labor._id === action.payload._id ? action.payload : labor)),
        loading: false,
      };
    case DELETE_SHEDLABOR:
      return {
        ...state,
        shedLabors: state.shedLabors.filter((labor) => labor._id !== action.payload),
        loading: false,
      };
    case CLEAR_SHEDLABORS:
      return {
        ...state,
        shedLabors: null,
        filteredShedLabor: null,
        error: null,
        currentShedLabor: null,
      };
    case SET_CURRENT_SHEDLABOR:
      return {
        ...state,
        currentShedLabor: action.payload,
      };

    case CLEAR_CURRENT_SHEDLABOR:
      return {
        ...state,
        currentShedLabor: null,
      };

    case SHEDLABOR_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
