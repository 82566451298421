// import { useContext } from 'react';
// import { useLocation, Navigate, Outlet } from 'react-router-dom';
// import AuthContext from '../context/auth/authContext';

// const RequireAuth = ({ children }) => {
//   const authContext = useContext(AuthContext);
//   const { isAuthenticated, loading } = authContext;

//   const { location } = useLocation();

//   return !isAuthenticated && !loading ? <Navigate to='/login' state={{ from: location }} replace /> : <Outlet />;
// };

// export default RequireAuth;

// import React from "react";
// import useAuth from "../hooks/useAuth";
// import { Navigate, Outlet } from "react-router-dom";

// const ProtectedRoutes = (roleRequired, superRole) => {
//   const { user, loading } = useAuth();
//   // if the role required is there or not
//   if (user && superRole.superRole && superRole.superRole === user.superRole) {
//     return !user && !loading ? <Navigate to="/denied" /> : <Outlet />;
//   } else if (user && roleRequired.roleRequired && roleRequired.roleRequired === user.role) {
//     return !user && !loading ? <Navigate to="/login" /> : <Outlet />;
//   } else if (user && user) {
//     return <Navigate to="/denied" />;
//   } else {
//     return !user && !loading ? <Navigate to="/login" /> : <Outlet />;
//   }
// };

// export default ProtectedRoutes;
import Spinner from "../components/layout/Spinner";
import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const ProtectedRoutes = ({ children, rolesRequired = [] }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if loading has finished and user is defined
    if (!loading) {
      if (!user && location.pathname !== "/login") {
        navigate("/login");
      } else if (rolesRequired.length > 0 && !rolesRequired.includes(user.role) && location.pathname !== "/denied") {
        navigate("/denied");
      }
    }
  }, [user, navigate, location.pathname, rolesRequired, loading]); // Include 'loading' in the dependencies

  // If user is loading, show spinner
  if (loading) {
    return <Spinner />;
  }

  return <Outlet>{children}</Outlet>;
};

export default ProtectedRoutes;
