import React, { Fragment, useEffect, useContext } from "react";
import UserColumns from "./UserColumns";
import ShopContext from "../../../../../context/shop/shopContext";

const AllUsers = () => {
  const theTitle = "Users";
  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  useEffect(() => {}, [currentShop]);

  return <UserColumns shopUsers={currentShop && currentShop.shopUsers} theTitle={theTitle} />;
};

export default AllUsers;
