import { DELETE_LOG, LOG_ERROR, GET_LOGS } from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_LOGS:
      return {
        ...state,
        logs: action.payload,
        loading: false,
      };
    case DELETE_LOG:
      return {
        ...state,
        logs: state.logs.filter((log) => log._id !== action.payload),
        loading: false,
      };

    case LOG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
