import React, { useEffect, useContext } from "react";
import ShedContext from "../../../../../../context/shed/shedContext";
import ShedLaborContext from "../../../../../../context/shedLabor/shedLaborContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ComponentContext from "../../../../../../context/component/componentContext";
import AddOnContext from "../../../../../../context/addon/addOnContext";
import HaulBaseContext from "../../../../../../context/haulBase/haulBaseContext";
import BaseStyleContext from "../../../../../../context/baseStyle/baseStyleContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import AddOnArray from "./ShedFormAddOnArr";
import ComponentArray from "./ShedFormComponentArr";
import ReactSelect from "react-select";
import { Form, Input, Button, Card, Typography, Row, Col } from "antd";

const { Title } = Typography;

const defaultValues = {};

const ShopShedForm = () => {
  const navigate = useNavigate();
  const shedContext = useContext(ShedContext);
  const shedLaborContext = useContext(ShedLaborContext);
  const shedCategoryContext = useContext(ShedCategoryContext);
  const componentContext = useContext(ComponentContext);
  const addOnContext = useContext(AddOnContext);
  const haulBaseContext = useContext(HaulBaseContext);
  const { getHaulBasesByModel, haulBases } = haulBaseContext;

  const { currentShedCategory } = shedCategoryContext;
  // const { getAllComponents, components } = componentContext;
  const { getAllAddOns, addOns } = addOnContext;

  const { addShed, currentShed, updateShed, clearCurrentShed } = shedContext;
  const { getShedLaborsByModel, shedLabors } = shedLaborContext;

  const shedModelContext = useContext(ShedModelContext);
  const { currentShedModel } = shedModelContext;

  const baseStyleContext = useContext(BaseStyleContext);
  const { baseStyles, getBaseStyles } = baseStyleContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  // const formattedShedLabors =
  //   shedLabors &&
  //   shedLabors.map((shedLabor) => {
  //     return {
  //       value: shedLabor._id,
  //       label: `${shedLabor.width} x ${shedLabor.length}`,
  //     };
  //   });

  // const formattedHaulBases =
  //   haulBases &&
  //   haulBases.map((haulBase) => {
  //     return {
  //       value: haulBase._id,
  //       label: `${haulBase.width} x ${haulBase.length}`,
  //     };
  //   });

  // console.log("This is formattedHaulBases", formattedHaulBases);

  const baseStylesOptions =
    baseStyles &&
    baseStyles.map((baseStyle) => ({
      value: baseStyle._id,
      label: baseStyle.name,
    }));

  useEffect(() => {
    getBaseStyles();
    // getShedLaborsByModel(currentShedModel);
    // getAllComponents(currentShop.defaultCategory._id);
    // getHaulBasesByModel(currentShedModel, currentShop.defaultCategory._id);
    // getAllAddOns();
    return () => {
      clearCurrentShed();
    };
  }, []);

  useEffect(() => {
    if (currentShed) {
      reset(currentShed);
    } else {
      reset(defaultValues);
    }
  }, [currentShed, reset]);

  useEffect(() => {
    if (currentShedCategory) {
      setValue("category", currentShedCategory._id);
    }
    if (currentShedModel) {
      setValue("model", currentShedModel._id);
    }
  }, [currentShedCategory, currentShedModel, setValue]);

  useEffect(() => {
    // if (currentShed && currentShed.shedLabor && formattedShedLabors) {
    //   const selectedShedLabor = formattedShedLabors.find((option) => option.value === currentShed.shedLabor._id);
    //   setValue("shedLabor", selectedShedLabor);
    // }
    // if (currentShed && currentShed.haulBase && formattedHaulBases) {
    //   const selectedHaulBase = formattedHaulBases.find((option) => option.value === currentShed.haulBase);
    //   setValue("haulBase", selectedHaulBase);
    // }
    if (currentShed && currentShed.baseStyle && baseStylesOptions) {
      const selectedBaseStyle = baseStylesOptions.find((option) => option.value === currentShed.baseStyle);
      setValue("baseStyle", selectedBaseStyle);
    }
    if (currentShed) {
      setValue("width", currentShed.width);
      setValue("length", currentShed.length);
      setValue("retailPrice", currentShed.retailPrice);
    }
  }, [currentShed, baseStylesOptions, setValue]);

  const onSubmit = (data) => {
    if (!currentShed) {
      addShed(data);
      navigate(-1);
    } else {
      updateShed(data);
      navigate(-1);
      clearCurrentShed();
    }
  };

  const setExit = () => {
    navigate(-1);
  };

  const handleLaborChange = (e) => {
    setValue("labor", e);
  };

  const handleShedLaborChange = (e) => {
    setValue("shedLabor", e);
  };

  const handleHaulBaseChange = (e) => {
    setValue("haulBase", e);
  };

  const handleBaseStylesOptionChange = (e) => {
    setValue("baseStyle", e);
  };

  return (
    <div className="container-fluid pb-4">
      <Row justify="center">
        <Col span={24}>
          <Button type="danger" className="float-end" onClick={setExit}>
            Exit
          </Button>
        </Col>
      </Row>
      <Row justify="center" className="mt-4">
        <Col span={20}>
          <Card style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)", border: "1px solid #e8e8e8", borderRadius: "8px" }}>
            <Title level={4} className="text-center">
              {currentShed ? "Update Shed" : "Add Shed"}
            </Title>
            <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
              <Form.Item label="Base Style">
                <Controller
                  name="baseStyle"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      isClearable={true}
                      isDisabled={true}
                      // options={baseStylesOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={handleBaseStylesOptionChange}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item label="Width">
                <Controller name="width" control={control} render={({ field }) => <Input {...field} readOnly />} />
              </Form.Item>
              <Form.Item label="Length">
                <Controller name="length" control={control} render={({ field }) => <Input {...field} readOnly />} />
              </Form.Item>
              <Form.Item label="Retail Price">
                <Controller name="retailPrice" control={control} render={({ field }) => <Input {...field} readOnly />} />
              </Form.Item>
              {/* <Form.Item label="Shed Labor Item">
                <Controller
                  name="shedLabor"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      isClearable={true}
                      isDisabled={true}
                      // options={formattedShedLabors}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={handleShedLaborChange}
                    />
                  )}
                />
              </Form.Item> */}
              {/* <Form.Item label="Haul Base Item">
                <Controller
                  name="haulBase"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      isDisabled={true}
                      isClearable={true}
                      // options={formattedHaulBases}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={handleHaulBaseChange}
                    />
                  )}
                />
              </Form.Item> */}
              <ComponentArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  // components,
                }}
              />
              <AddOnArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  addOns,
                }}
              />
              <Form.Item>
                {/* <Button type="primary" htmlType="submit" className="float-end">
                  Save
                </Button> */}
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ShopShedForm;
