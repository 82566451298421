import React, { useReducer } from "react";
import addOnLaborReducer from "./addOnLaborReducer";
import AddOnLaborContext from "./addOnLaborContext";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_ADDON_LABOR,
  DELETE_ADDON_LABOR,
  CLEAR_ADDON_LABORS,
  SET_CURRENT_ADDON_LABOR,
  CLEAR_CURRENT_ADDON_LABOR,
  UPDATE_ADDON_LABOR,
  FILTER_ADDON_LABORS,
  CLEAR_FILTER_ADDON_LABOR,
  ADDON_LABOR_ERROR,
  GET_ADDON_LABORS,
  GET_STANDARD_ADDON_LABORS,
} from "../types";

const AddOnLaborState = (props) => {
  const initialState = {
    addOnLabors: null,
    standardAddOnLabors: null,
    currentAddOnLabor: null,
    filteredAddOnLabor: null,
    error: null,
    loadingAddOnLabor: true,
  };

  const [state, dispatch] = useReducer(addOnLaborReducer, initialState);

  // get AddOnLabors
  const getAddOnLabors = async (id) => {
    try {
      const res = await axios.get(`/api/addonlabor/${id}`, {});
      dispatch({ type: GET_ADDON_LABORS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDON_LABOR_ERROR });
    }
  };

  // get AddOnLabors
  const getShopAddOnLabors = async (id) => {
    try {
      const res = await axios.get(`/api/addonlabor/shop/${id}`, {});
      dispatch({ type: GET_ADDON_LABORS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDON_LABOR_ERROR });
    }
  };

  const copyForShops = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/addonlabor/copy", {}, config);
      toast.success(`ADDON_LABOR are copied`);
    } catch (err) {
      dispatch({ type: ADDON_LABOR_ERROR });
    }
  };

  const getAlLStandardAddOnLabors = async () => {
    try {
      const res = await axios.get(`/api/addonlabor/standard/all`);
      dispatch({ type: GET_STANDARD_ADDON_LABORS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDON_LABOR_ERROR });
    }
  };

  const addAddOnLabor = async (addOnLabor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/addonlabor/", addOnLabor, config);
      dispatch({ type: ADD_ADDON_LABOR, payload: res.data });
      toast.success(`Option is created`);
    } catch (err) {
      dispatch({ type: ADDON_LABOR_ERROR });
      toast.error(`AddonLabor ${err}`);
    }
  };

  const deleteAddOnLabor = async (id) => {
    try {
      const res = await axios.delete(`/api/addonlabor/delete/${id}`);
      dispatch({ type: DELETE_ADDON_LABOR, payload: id });
      toast.success(`AddOnLabor is deleted`);
    } catch (err) {
      dispatch({ type: ADDON_LABOR_ERROR });
      toast.error(`AddOnLabor ${err}`);
    }
  };

  // update AddOnLabor
  const updateAddOnLabor = async (addOnLabor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/addonlabor/update/${addOnLabor._id}`, addOnLabor, config);
      dispatch({ type: UPDATE_ADDON_LABOR, payload: res.data });
      toast.success(`AddonLabor is updated`);
    } catch (err) {
      dispatch({ type: ADDON_LABOR_ERROR });
      toast.error(`AddonLabor ${err}`);
    }
  };

  // update AddOnLabor
  const updateShopAddOnLabor = async (addOnLabor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/addonlabor/shop/update/${addOnLabor._id}`, addOnLabor, config);
      dispatch({ type: UPDATE_ADDON_LABOR, payload: res.data });
      toast.success(`AddonLabor is updated`);
    } catch (err) {
      dispatch({ type: ADDON_LABOR_ERROR });
      toast.error(`AddonLabor ${err}`);
    }
  };

  const clearAddOnLabors = () => {
    dispatch({ type: CLEAR_ADDON_LABORS });
  };

  const setCurrentAddOnLabor = (addOnLabor) => {
    dispatch({ type: SET_CURRENT_ADDON_LABOR, payload: addOnLabor });
  };

  const clearCurrentAddOnLabor = () => {
    dispatch({ type: CLEAR_CURRENT_ADDON_LABOR });
  };

  const filterAddOnLabors = (text) => {
    dispatch({ type: FILTER_ADDON_LABORS, payload: text });
  };

  const clearFilterAddOnLabor = () => {
    dispatch({ type: CLEAR_FILTER_ADDON_LABOR });
  };

  return (
    <AddOnLaborContext.Provider
      value={{
        addOnLabors: state.addOnLabors,
        standardAddOnLabors: state.standardAddOnLabors,
        currentAddOnLabor: state.currentAddOnLabor,
        filteredAddOnLabor: state.filteredAddOnLabor,
        error: state.error,
        loadingAddOnLabor: state.loadingAddOnLabor,
        getAddOnLabors,
        addAddOnLabor,
        deleteAddOnLabor,
        setCurrentAddOnLabor,
        clearCurrentAddOnLabor,
        updateAddOnLabor,
        filterAddOnLabors,
        clearFilterAddOnLabor,
        clearAddOnLabors,
        getShopAddOnLabors,
        copyForShops,
        getAlLStandardAddOnLabors,
        updateShopAddOnLabor,
      }}
    >
      {props.children}
    </AddOnLaborContext.Provider>
  );
};

export default AddOnLaborState;
