import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import RepairInvoiceContext from "../../../../context/repairInvoice/repairInvoiceContext";
import { Table } from "antd";
import axios from "axios";
import CompanyRepairNewInvoiceModal from "./CompanyRepairNewInvoiceModal";

const CompanyRepairPaidInvoices = () => {
  const navigate = useNavigate();
  const repairInvoiceContext = useContext(RepairInvoiceContext);
  const {
    getRepairInvoicesByStatus,
    repairInvoices,
    getRepairInvoiceById,
    clearRepairInvoices,
    deleteRepairInvoice,
    repairInvoiceLoading,
  } = repairInvoiceContext;
  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getRepairInvoicesByStatus({ status: "Paid", type: "Repair" });
    return () => {
      clearRepairInvoices();
      // cancelToken.cancel();
    };
  }, []);

  const handleViewInvoice = (invoice) => {
    getRepairInvoiceById(invoice._id);
  };

  const handleDeleteInvoice = (invoice) => {
    deleteRepairInvoice(invoice);
  };

  const columns = [
    {
      title: `Invoice Number`,
      render: (text, record) => <span>{record.invoiceNumber}</span>,
    },
    {
      title: `Invoiced Date`,
      render: (text, record) => <span>{record.invoicedDate && new Date(record.invoicedDate).toLocaleDateString()}</span>,
    },
    {
      title: `Amount`,
      render: (text, record) => (
        <span>
          {record &&
            record.invoiceTotal.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },

    // {
    //   title: `Total Cost`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order &&
    //         record.order.shopTotalCost.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Profit $`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order &&
    //         record.order.shopProfitDollars.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Profit %`,
    //   render: (text, record) => <span>{record.order && record.order.shopProfitPercent.toFixed(2)}%</span>,
    // },
    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <Fragment>
          <button className="btn btn-xs btn-outline-secondary" type="button" onClick={(e) => handleViewInvoice(record, e)}>
            View Invoice
          </button>
          {/* <br />
          <button className="btn btn-xs btn-outline-danger" type="button" onClick={(e) => handleDeleteInvoice(record)}>
            Delete
          </button> */}
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleMakeInvoice = () => {
    setModalVisible(true);
  };

  return (
    <div className="row">
      <CompanyRepairNewInvoiceModal setModalVisible={setModalVisible} modalVisible={modalVisible} />
      <div className="col-md-2">
        {/* <button className="btn btn-outline-primary " type="button" onClick={(e) => handleMakeInvoice()}>
          Create Invoice
        </button> */}
      </div>
      <div className="col-md-9 text-center">
        <h4>{repairInvoices && repairInvoices.length > 0 ? repairInvoices.length + " Paid Invoices" : "No Paid Invoices"}</h4>
      </div>

      {/* <OrderFilter /> */}
      <Table
        rowClassName={() => "hover-row"}
        className="mt-2"
        pagination={{ pageSize: 200 }}
        loading={(!repairInvoiceLoading && repairInvoices) || !repairInvoiceLoading ? false : true}
        columns={columns}
        dataSource={repairInvoices && repairInvoices}
        rowKey="_id"
        onChange={onChange}
      />
    </div>
  );
};

export default CompanyRepairPaidInvoices;
