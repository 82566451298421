import React, { Fragment } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";

const Items = ({ item, itemIndex, control, remove, setValue, getValues, itemOptions, register }) => {
  const setTotals = () => {
    const items = getValues("items");
    const theitemCost = items && items.reduce((acc, curr) => acc + parseFloat(curr.amount || 0), 0);
    const standardItemCost = items && items.reduce((acc, curr) => acc + parseFloat(curr.standardAmount || 0), 0);
    setValue(`invoiceTotal`, theitemCost.toFixed(2));
    setValue(`standardInvoiceTotal`, standardItemCost.toFixed(2));
  };

  const setItemCount = (quantity) => {
    const price = getValues(`items[${itemIndex}].rate`);
    const standardPrice = getValues(`items[${itemIndex}].standardRate`);
    setValue(`items[${itemIndex}].quantity`, quantity);
    setValue(`items[${itemIndex}].amount`, (quantity * price).toFixed(2));
    setValue(`items[${itemIndex}].standardAmount`, (quantity * standardPrice).toFixed(2));
    setTotals();
  };

  const setItemRate = (rate) => {
    const quantity = getValues(`items[${itemIndex}].quantity`);
    setValue(`items[${itemIndex}].rate`, rate);
    setValue(`items[${itemIndex}].amount`, (quantity * rate).toFixed(2));
    setTotals();
  };

  const onRemoveItem = () => {
    setTotals();
  };

  const setItemChange = (e) => {
    console.log("this is the item", e);
    setValue(`items[${itemIndex}].item`, e);
    setValue(`items[${itemIndex}].quantity`, 1);
    // need to find the correct shop values in the item

    setValue(`items[${itemIndex}].rate`, e.shopValues.total.toFixed(2));
    setValue(`items[${itemIndex}].standardRate`, e.standardValues.total.toFixed(2));
    setValue(`items[${itemIndex}].amount`, (e.shopValues.total * 1).toFixed(2));
    setValue(`items[${itemIndex}].standardAmount`, (e.standardValues.total * 1).toFixed(2));

    setTotals();
  };

  const productOptions =
    itemOptions &&
    itemOptions.map((item) => {
      const actualItem = item.item ? item.item : item;
      return { label: actualItem.name, value: actualItem };
    });

  return (
    <Fragment>
      <div>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-5 ">
              <div className="form-group">
                <Controller
                  render={({ field }) => {
                    // const selectedItemName = field.value?.name;
                    // const selectedItem = field.value
                    //   ? productOptions && productOptions.find((option) => option.value._id === field.value._id)
                    //   : null;
                    return (
                      <ReactSelect
                        {...field}
                        options={productOptions}
                        // menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        // value={selectedItem} // Add default value here
                        isDisabled={!productOptions}
                        value={(field.value && { label: field.value.name, value: field.value }) || null}
                        onChange={(e) => setItemChange(e.value)}
                        // styles={{
                        //   control: (base) => ({
                        //     ...base,
                        //     backgroundColor: getSelectBackgroundColor(selectedItemName),
                        //   }),
                        //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        // }}
                      />
                    );
                  }}
                  key={item.id}
                  name={`items[${itemIndex}].item`}
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => {
                    // const hasMismatch = isQuantityMismatched(field.value, itemIndex);

                    return (
                      <input
                        className={`form-control inputbg}`}
                        type="number"
                        step={0.01}
                        name={name}
                        {...field}
                        onChange={(e) => {
                          const quantity = e.target.value;
                          setItemCount(quantity);
                        }}
                      />
                    );
                  }}
                  key={item.id}
                  control={control}
                  name={`items[${itemIndex}].quantity`}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => {
                    // const hasMismatch = isQuantityMismatched(field.value, itemIndex);

                    return (
                      <input
                        className={`form-control inputbg}`}
                        type="number"
                        step={0.01}
                        name={name}
                        {...field}
                        onChange={(e) => {
                          const quantity = e.target.value;
                          setItemRate(quantity);
                        }}
                      />
                    );
                  }}
                  key={item.id}
                  control={control}
                  name={`items[${itemIndex}].rate`}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => {
                    // const hasMismatch = isQuantityMismatched(field.value, itemIndex);

                    return (
                      <input
                        readOnly
                        className={`form-control inputbg}`}
                        type="number"
                        step={0.01}
                        name={name}
                        {...field}
                        // onChange={(e) => {
                        //   const quantity = e.target.value;
                        //   setItemCount(quantity);
                        // }}
                      />
                    );
                  }}
                  key={item.id}
                  control={control}
                  name={`items[${itemIndex}].amount`}
                />
              </div>
            </div>

            <div className="col-md-1">
              <div className="form-group float-end">
                <button
                  className="btn btn-outline-danger "
                  type="button"
                  onClick={() => {
                    remove(itemIndex);
                    onRemoveItem();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, allItems }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  return (
    <div className="container-fluid border" style={{ borderRadius: "5px" }}>
      {fields.map((item, itemIndex) => (
        <Items
          key={item.id}
          item={item}
          itemIndex={itemIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          itemOptions={allItems}
        />
      ))}
      <div className="row">
        <div className="col-md-7">
          <button
            className="float-start btn btn-outline-info mb-2 mt-2 "
            onClick={(e) => {
              e.preventDefault();
              append({ value: "0" });
            }}
          >
            Add Item
          </button>
        </div>
        <div className="col-md-2 ">
          <label className="form-label float-end mt-3">Invoice Total</label>
        </div>
        <div className="col-md-3 ">
          <div className="form-group  mb-2 mt-2">
            <Controller
              render={({ field, name }) => {
                return <input readOnly className={`form-control inputbg}`} type="number" step={0.01} name={name} {...field} />;
              }}
              control={control}
              name={`invoiceTotal`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
