import React, { useState, useContext, useEffect } from "react";
import OrderContext from "../../../../context/order/orderContext";
import AlertContext from "../../../../context/alert/alertContext";
import NewOrders from "./NewOrders";
import { Modal } from "antd";

const AddMissingOrders = () => {
  const [newOrders, setNewOrders] = useState();
  const orderContext = useContext(OrderContext);
  const { addMissingOrder, loadingOrders, orders, updateNewOrders, clearOrders, getOrders, setLoadingOrders } = orderContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  useEffect(() => {
    clearOrders();
    getOrders("New");
  }, []);

  useEffect(() => {}, [orders]);

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   const data = e.target.result;
      //   const workbook = read(data, { type: 'array' });
      //   const sheetName = workbook.SheetNames[0];
      //   const worksheet = workbook.Sheets[sheetName];
      //   const json = utils.sheet_to_json(worksheet);
      //   console.log(JSON.stringify(json, null, 2));
      //   // let jsonOrders = JSON.stringify(json);
      //   setNewOrders(json);
      // };
      // reader.readAsArrayBuffer(e.target.files[0]);
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        setNewOrders(e.target.result);
      };
    }
  };

  const handleUploadNewOrders = () => {
    Modal.confirm({
      title: "Are you sure you want to upload this file? This action cannot be reversed",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        setLoadingOrders(true);
        addMissingOrder(newOrders);
      },
    });
  };

  const handlePushToShops = async () => {
    try {
      let myAlerts = [];
      let ordersWithNoAlerts = [];

      const myOrders = await Promise.all(
        orders.map(async (order) => {
          let noAlert = true;
          order.alerts.map(async (alert) => {
            if (!alert.accepted) {
              noAlert = false;
              myAlerts.push(alert);
              setAlert(`${order.serialNumber} ${alert.alert} `, "danger", alert._id);
            }
          });
          if (noAlert === true) {
            ordersWithNoAlerts.push(order);
          }
        })
      );
      setLoadingOrders(true);
      updateNewOrders(ordersWithNoAlerts);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      {/* {!loadingOrders ? ( */}
      <div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-4">
            <input type="file" name="upload" id="file" className="form-control" onChange={readUploadFile} accept=".json" />
          </div>
          <div className="col-md-2">
            <button className="btn btn-outline-primary" onClick={handleUploadNewOrders}>
              Upload
            </button>
          </div>
          <div className="col-md-3">
            <button className="btn btn-outline-primary float-end" onClick={handlePushToShops}>
              Push To Shops
            </button>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3"></div>
          <div className="col-md-9">
            <ul>
              <li>Make sure to select a time frame that is close to around the missing orders</li>
              <li>Preferably only filter by the shop that has the missing Orders</li>
              <li>No Building Stage filter is needed</li>
              <li>The less orders to filter through the better as this will take longer than the other import.</li>
              <li>If it seems like there is way to many New Orders, just Delete New Orders and reset filters.</li>
            </ul>
          </div>
        </div>
        <br />
        <NewOrders />
      </div>
    </div>
  );
};

export default AddMissingOrders;
