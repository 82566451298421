import React, { useEffect, useContext } from "react";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";

const defaultValues = {};
const ShedShedCategoryForm = () => {
  const navigate = useNavigate();
  const shedCategoryContext = useContext(ShedCategoryContext);
  const {
    addShedCategory,
    updateShedCategory,
    currentShedCategory,
    clearCurrentShedCategory,
    getShedCategorys,
    shedCategorys,
    copyExistingCategoryData,
  } = shedCategoryContext;
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    // currentShedCategory && reset(currentShedCategory);
    getShedCategorys();
  }, []);

  const onSubmit = (data) => {
    // if (!currentShedCategory) {
    // addShedCategory(data);
    copyExistingCategoryData(data);
    navigate(-1);
    // } else {
    //   updateShedCategory(data);
    //   navigate(-1);
    //   clearCurrentShedCategory();
    // }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentShedCategory();
  };

  const shedCategoryOptions = shedCategorys.map((shedCategory) => {
    return { value: shedCategory._id, label: shedCategory.name };
  });

  const handleCatOptionChange = (e) => {
    setValue("existingCategoryId", e);
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">Shed Category Form</h4>
          {/* {currentDoor && (
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form className="haulBaseForm" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="baseStyle">Existing Category to copy:</label>
            <Controller
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  isClearable={true}
                  options={shedCategoryOptions}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  onChange={(e) => {
                    handleCatOptionChange(e);
                  }}
                />
              )}
              name="existingCategoryId"
              control={control}
              className="shedlaborforminput"
            />

            <label className="mt-3" htmlFor="newCategoryName">
              New Category Name
            </label>
            <input
              className="shedlaborforminput"
              type="string"
              {...register("newCategoryName", { required: true })}
              id="newCategoryName"
            />
            <label htmlFor="codePrefix">Prefix for Model Code:</label>
            <input className="shedlaborforminput" type="string" {...register("codePrefix", { required: true })} id="codePrefix" />

            <label htmlFor="modelSuffix">Suffix for Model Name:</label>
            <input
              className="shedlaborforminput"
              type="string"
              {...register("modelSuffix", { required: true })}
              id="modelSuffix"
            />

            <button
              // className={user.viewOnly ? "viewOnly" : "shedlaborformbtn"}
              // disabled={user.viewOnly || !isBaseStyleSelected}
              className="shedlaborformbtn"
              type="submit"
              // disabled={!isBaseStyleSelected}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShedShedCategoryForm;
