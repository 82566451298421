import React, { useEffect, useContext, useState } from "react";
import AddOnContext from "../../../../../../context/addon/addOnContext";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import AddOnLaborContext from "../../../../../../context/addOnLabor/addOnLaborContext";
import ItemContext from "../../../../../../context/item/itemContext";
import AuthContext from "../../../../../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ItemArray from "./AddOnFormItemArr";
import ReactSelect from "react-select";
import { Checkbox, Form, Input, Button, Card, Typography, Row, Col } from "antd";

const { Title } = Typography;

const defaultValues = {
  name: "",
  category: null,
  standardItems: [{}],
};

const ShopAddOnForm = () => {
  const navigate = useNavigate();
  const [nonInventoryValue, setNonInventoryValue] = useState(false);

  const itemContext = useContext(ItemContext);
  const { getItems, allItems, clearItems } = itemContext;

  const addOnContext = useContext(AddOnContext);
  const { addAddOn, currentAddOn, clearCurrentAddOn, updateAddOn, updateCompanyAddOn } = addOnContext;

  const addonCategoryContext = useContext(AddonCategoryContext);
  const { currentAddonCategory } = addonCategoryContext;

  const addOnLaborContext = useContext(AddOnLaborContext);
  const { getAddOnLabors, addOnLabors } = addOnLaborContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    currentAddOn && reset(currentAddOn);
    currentAddOn && setNonInventoryValue(currentAddOn.nonInventory);
    currentAddonCategory && setValue("category", currentAddonCategory._id);
    currentAddonCategory && getAddOnLabors(currentAddonCategory._id);
    getItems();
    return () => {
      clearItems();
    };
  }, []);

  const onSubmit = (data) => {
    if (!currentAddOn) {
      addAddOn(data);
      navigate(-1);
    } else {
      updateAddOn(data);
      navigate(-1);
      clearCurrentAddOn();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentAddOn();
  };

  const handleLaborChange = (e) => {
    setValue("addOnLabor", e);
  };

  const laborOptions =
    addOnLabors &&
    addOnLabors.map((item) => {
      return { label: item.name, value: item._id };
    });

  useEffect(() => {
    if (currentAddOn && currentAddOn.addOnLabor && laborOptions) {
      const selectedLabor = laborOptions.find((option) => option.value === currentAddOn.addOnLabor._id);
      setValue("addOnLabor", selectedLabor);
    }
    if (currentAddOn && currentAddOn.type && typeOptions) {
      const selectedType = typeOptions.find((option) => option.value === currentAddOn.type);
      setValue("type", selectedType);
    }
  }, [currentAddOn, laborOptions, setValue]);

  const typeOptions = [
    { label: "Piece", value: "piece" },
    { label: "Percent", value: "percent" },
    { label: "SqFt", value: "sqft" },
    { label: "Linear Ft", value: "linearFt" },
  ];

  const handletypeChange = (e) => {
    setValue("type", e);
  };

  const updateCompanyValues = () => {
    const values = getValues();
    updateCompanyAddOn(values);
  };

  const handleCopy = () => {
    const values = getValues();
    const newValues = { ...values, _id: null };
    addAddOn(newValues);
  };

  return (
    <div className="container-fluid pb-4">
      <Row justify="center">
        <Col span={24}>
          <Title level={3} className="text-center">
            {currentAddOn ? "Update AddOn" : "Add AddOn"}
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={20}>
          <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", border: "1px solid #e8e8e8", borderRadius: "8px" }}>
            <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
              <Card
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  border: "1px solid #e8e8e8",
                  borderRadius: "8px",
                }}
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Name" required>
                      <Controller name="name" control={control} render={({ field }) => <Input {...field} readOnly />} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Type" required>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            isClearable
                            isDisabled
                            options={typeOptions}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            onChange={handletypeChange}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Shed-Suite ID">
                      <Controller name="ssId" control={control} render={({ field }) => <Input {...field} readOnly />} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Retail Price" required>
                      <Controller name="retailPrice" control={control} render={({ field }) => <Input {...field} readOnly />} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Shop Percent" required>
                      <Controller name="shopPercent" control={control} render={({ field }) => <Input {...field} readOnly />} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <ItemArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  allItems,
                }}
              />
              <Row justify="end">
                <Col>
                  <Button type="primary" htmlType="submit" disabled={user.viewOnly}>
                    Save
                  </Button>
                  {/* <Button type="default" className="ms-2" onClick={handleCopy}>
                    Copy
                  </Button> */}
                  <Button type="danger" className="ms-2" onClick={setExit}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ShopAddOnForm;
