import React, { useEffect, useContext } from "react";
import ShopContext from "../../../../../context/shop/shopContext";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const defaultValues = {};
const MetalUpdateForm = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const shopContext = useContext(ShopContext);
  const { currentShop, updateShopMetal } = shopContext;

  useEffect(() => {
    currentShop && reset(currentShop);
  }, []);

  const onSubmit = (data) => {
    updateShopMetal(currentShop._id, data);
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentDoor();
  };

  return (
    <div className="container-fluid pb-4">
      {/* <div className="row">
        <div className="col-md-12">
          <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div> */}
      <div className="row mt-4">
        <div className="col-md-12">
          <h4 className="text-center">Update Metal Items</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row mb-2 mt-2">
                  <div className="form-group mt-2">
                    <label>29 Gauge Panel (Per Linear FT)</label>
                    <input className="form-control inputbg" name="metalPanel" {...register("metalPanel", { required: true })} />
                  </div>
                  <div className="form-group mt-2">
                    <label>Rake (Per Linear FT)</label>
                    <input className="form-control inputbg" name="woodRake" {...register("woodRake", { required: true })} />
                  </div>
                  <div className="form-group mt-2">
                    <label>Metal Rake (Per Linear FT)</label>
                    <input className="form-control inputbg" name="metalRake" {...register("metalRake", { required: true })} />
                  </div>
                  <div className="form-group mt-2">
                    <label>Ridge Cap (Per Linear FT)</label>
                    <input className="form-control inputbg" name="ridgeCap" {...register("ridgeCap", { required: true })} />
                  </div>
                  <div className="form-group mt-2">
                    <label>J Channel (Per Linear FT)</label>
                    <input className="form-control inputbg" name="jChan" {...register("jChan", { required: true })} />
                  </div>
                  <div className="form-group mt-2">
                    <label>2x2 Angle (Per Linear FT)</label>
                    <input
                      className="form-control inputbg"
                      name="angleTwoByTwo"
                      {...register("angleTwoByTwo", { required: true })}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label>3x3 OA (Per Linear FT)</label>
                    <input
                      className="form-control inputbg"
                      name="oaThreeByThree"
                      {...register("oaThreeByThree", { required: true })}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label>3x3 Corner (Per Linear FT)</label>
                    <input
                      className="form-control inputbg"
                      name="cornerThreeByThree"
                      {...register("cornerThreeByThree", { required: true })}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label>Drip Edge (Per Linear FT)</label>
                    <input className="form-control inputbg" name="dripEdge" {...register("dripEdge", { required: true })} />
                  </div>
                  <div className="form-group mt-2">
                    <label>Door Trim (Per Linear FT)</label>
                    <input className="form-control inputbg" name="doorTrim" {...register("doorTrim", { required: true })} />
                  </div>
                </div>

                <button className="btn btn-outline-primary float-end " type="submit">
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MetalUpdateForm;
