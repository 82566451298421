import React, { Fragment, useState } from "react";
import { Popconfirm } from "antd";

const PopConfirm = ({ record, msg, btnText, theStatus, handleStatusChange }) => {
  const [visible, setVisible] = useState(false);

  const handleShowPopConfirm = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    handleStatusChange();
  };

  return (
    <Fragment>
      <Popconfirm
        title={msg}
        visible={visible}
        onConfirm={handleOk}
        // okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
        okText="Yes"
      ></Popconfirm>
      <button className="btn btn-outline-primary " type="button" onClick={(e) => handleShowPopConfirm()}>
        {btnText}
      </button>
    </Fragment>
  );
};

export default PopConfirm;
