import React, { useReducer, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceContext from "./invoiceContext";
import invoiceReducer from "./invoiceReducer";
import AuthContext from "../auth/authContext";
import axios from "axios";
import OrderContext from "../order/orderContext";
import { toast } from "react-toastify";

import {
  ADD_INVOICE,
  DELETE_INVOICE,
  CLEAR_INVOICES,
  SET_CURRENT_INVOICE,
  CLEAR_CURRENT_INVOICE,
  UPDATE_INVOICE,
  SET_FILTERED_INVOICES,
  CLEAR_FILTERED_INVOICES,
  INVOICE_ERROR,
  GET_INVOICES,
  STATUS_CHANGE_INVOICE,
  GET_INVOICES_CHART,
  SET_SEARCHED_INVOICE,
  CLEAR_SEARCHED_INVOICE,
  SWITCH_LOADINGINVOICE,
  SET_INVOICE_LOADING,
  CLEAR_FILTER_INVOICES_BY_SHOP,
  SET_SHOP_FOR_FILTER,
  SET_INVOICE_NUMBER_SEARCH,
  SET_CUT_OFF_DATE,
  SET_INVOICE_PAGINATION,
  GET_INVOICE_COUNT,
  CLEAR_INVOICE_COUNT,
  SET_INVOICE_IS_CANCELED,
  GET_ADJUSTED_INVOICES,
  UPDATE_INVOICE_ORDER,
} from "../types";

const InvoiceState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    invoices: null,
    adjustedInvoices: null,
    invoiceCount: null,
    currentInvoice: null,
    searchedInvoice: null,
    filteredInvoice: null,
    chartInvoices: null,
    filteredInvoicesByShop: null,
    error: null,
    shop: null,
    invoiceLoading: false,
    isCancelled: false,
    invoiceNumber: null,
    cutOffDate: null,
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  const [state, dispatch] = useReducer(invoiceReducer, initialState);
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const orderContext = useContext(OrderContext);
  const { setCurrentOrder } = orderContext;

  const addInvoice = async (invoice) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/invoice", { invoice }, config);
      dispatch({ type: ADD_INVOICE, payload: res.data });
      toast.success(`Invoice is created`);
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
      toast.error(`${err.response.data.msg}`);
    }
  };

  const getInvoicesByStatus = async (status) => {
    try {
      const res = await axios.get(`/api/invoice/status/${status}`);
      dispatch({ type: GET_INVOICES, payload: res.data });
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
    }
  };

  const getInvoiceForChart = async () => {
    try {
      setInvoiceLoading(true);
      const res = await axios.get(`/api/invoice/chart/filter`);
      dispatch({ type: GET_INVOICES_CHART, payload: res.data });
      setInvoiceLoading(false);
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
    }
  };

  const getInvoiceById = async (id) => {
    try {
      setInvoiceLoading(true);
      const res = await axios.get(`/api/invoice/findbyid/${id}`);
      setCurrentOrder(res.data.order);
      dispatch({ type: SET_CURRENT_INVOICE, payload: res.data });
      if (user.role === "Office User" || user.role === "Office Admin") {
        navigate("/company/invoice/form");
      } else {
        navigate("/shop/invoice/form");
      }
      setInvoiceLoading(false);
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
    }
  };

  // const getInvoicesByStatus = async (cancelToken, status, date) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     cancelToken: cancelToken.token,
  //   };
  //   try {
  //     setInvoiceLoading(true);
  //     await axios
  //       .post(`/api/invoice/${status}`, { date }, config)
  //       .then((response) => {
  //         // if (!state.isCancelled) {
  //         dispatch({ type: GET_INVOICES, payload: response.data });
  //         // }
  //       })
  //       .catch((error) => {
  //         if (axios.isCancel(error)) {
  //           console.log("this is the cancel error", error);
  //           return;
  //         }
  //       });
  //   } catch (error) {
  //     dispatch({ type: INVOICE_ERROR });
  //     toast.error(`Invoice error ${error}`);
  //   }
  // };

  // const getPaidInvoices = async (pagination) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     setInvoiceLoading(true);
  //     const res = await axios.post(`/api/invoice/status/paid`, { pagination }, config);
  //     dispatch({ type: GET_INVOICES, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: INVOICE_ERROR });
  //     toast.error(`Invoice ${err}`);
  //   }
  // };

  // const getInvoicesForChartOne = async () => {
  //   try {
  //     const res = await axios.get(`/api/invoice`);
  //     dispatch({ type: GET_INVOICES, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: INVOICE_ERROR });
  //   }
  // };

  // const getAdjustedInvoices = async () => {
  //   try {
  //     const res = await axios.get(`/api/invoice/adjusted`);
  //     dispatch({ type: GET_ADJUSTED_INVOICES, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: INVOICE_ERROR });
  //   }
  // };

  // const getOpenInvoices = async (shop) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.post("/api/invoice/report/open", { shop }, config);
  //     dispatch({ type: GET_INVOICES, payload: res.data });
  //     setInvoiceLoading(false);
  //   } catch (err) {
  //     dispatch({ type: INVOICE_ERROR });
  //   }
  // };

  const findInvoiceByInvoiceNumber = async (invoiceNumber) => {
    try {
      dispatch({ type: SWITCH_LOADINGINVOICE });
      const res = await axios.get(`/api/invoice/search/${invoiceNumber}`);
      dispatch({ type: SET_SEARCHED_INVOICE, payload: res.data });
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
    }
  };

  const deleteInvoice = async (invoice) => {
    try {
      const res = await axios.delete(`/api/invoice/delete/${invoice._id}`);
      dispatch({ type: DELETE_INVOICE, payload: invoice._id });
      toast.success(`Invoice is deleted`);
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
      toast.error(`Invoice ${err}`);
    }
  };

  const updateInvoice = async (invoice) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/invoice/update/${invoice._id}`, invoice, config);
      dispatch({ type: UPDATE_INVOICE, payload: invoice });
      toast.success(`Invoice is updated`);
      return res;
    } catch (err) {
      dispatch({ type: INVOICE_ERROR });
      toast.error(`Invoice ${err}`);
    }
  };
  // const updateAdjustedInvoice = async (invoice) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.put(`/api/invoice/update/${invoice._id}`, invoice, config);
  //     dispatch({ type: UPDATE_ADJUSTED_INVOICE, payload: invoice });
  //     toast.success(`Invoice is updated`);
  //   } catch (err) {
  //     dispatch({ type: INVOICE_ERROR });
  //     toast.error(`Invoice ${err}`);
  //   }
  // };

  // const updateBulkOrdersToInvoiced = async () => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.put(`/api/invoice/bulk/update`, config);
  //     // dispatch({ type: UPDATE_INVOICE, payload: res.data });
  //     toast.success(`Orders are updated to Invoiced`);
  //   } catch (err) {
  //     dispatch({ type: INVOICE_ERROR });
  //     toast.error(`Invoice ${err}`);
  //   }
  // };

  // const updateInvoiceStatus = async (invoice) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     dispatch({ type: STATUS_CHANGE_INVOICE, payload: invoice });
  //     const res = await axios.put(`/api/invoice/update/${invoice._id}`, invoice, config);
  //     navigate(-1);
  //     toast.success(`Invoice is updated`);
  //   } catch (err) {
  //     dispatch({ type: INVOICE_ERROR });
  //     toast.error(`Invoice ${err}`);
  //   }
  // };

  const clearInvoices = () => {
    dispatch({ type: CLEAR_INVOICES });
  };

  const updateInvoiceOrder = (order) => {
    dispatch({ type: UPDATE_INVOICE_ORDER, payload: order });
  };

  const setCurrentInvoice = (invoice) => {
    dispatch({ type: SET_CURRENT_INVOICE, payload: invoice });
  };

  const clearCurrentInvoice = () => {
    dispatch({ type: CLEAR_CURRENT_INVOICE });
  };

  const setFilteredInvoices = (text) => {
    dispatch({ type: SET_FILTERED_INVOICES, payload: text });
  };
  const clearFilteredInvoices = () => {
    dispatch({ type: CLEAR_FILTERED_INVOICES });
  };

  // const getInvoicesByShopAndStatus = async (shop, status, date) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     setInvoiceLoading(true);
  //     const res = await axios.post("/api/invoice/status/shop", { shop, status, date }, config);
  //     dispatch({ type: GET_INVOICE_BY_SHOP_STATUS, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: INVOICE_ERROR });
  //     toast.error(`Invoice ${err}`);
  //   }
  // };

  // const getInvoiceCount = async (category) => {
  //   try {
  //     const res = await axios.get(`/api/invoice/count/${category}`);
  //     let pagination = { total: res.data };
  //     setPagination(pagination);
  //     dispatch({ type: GET_INVOICE_COUNT, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: INVOICE_ERROR });
  //   }
  // };

  const statusChange = (invoice) => {
    dispatch({ type: STATUS_CHANGE_INVOICE, payload: invoice });
  };

  const setInvoiceLoading = (bool) => {
    dispatch({ type: SET_INVOICE_LOADING, payload: bool });
  };

  // const setShopForFilter = (shop) => {
  //   dispatch({ type: SET_SHOP_FOR_FILTER, payload: shop });
  // };

  // const clearFilteredInvoicesByShop = () => {
  //   dispatch({ type: CLEAR_FILTER_INVOICES_BY_SHOP });
  // };
  const setInvoiceNumberSearch = (n) => {
    dispatch({ type: SET_INVOICE_NUMBER_SEARCH, payload: n });
  };
  const clearSearchedInvoice = () => {
    dispatch({ type: CLEAR_SEARCHED_INVOICE });
  };
  const setCutOffDate = (date) => {
    dispatch({ type: SET_CUT_OFF_DATE, payload: date });
  };

  // const setPagination = (pagination) => {
  //   dispatch({ type: SET_INVOICE_PAGINATION, payload: pagination });
  // };
  // const clearInvoiceCount = () => {
  //   dispatch({ type: CLEAR_INVOICE_COUNT });
  // };
  // const setIsCancelled = (bool) => {
  //   dispatch({ type: SET_INVOICE_IS_CANCELED, payload: bool });
  // };
  return (
    <InvoiceContext.Provider
      value={{
        invoices: state.invoices,
        currentInvoice: state.currentInvoice,
        filteredInvoices: state.filteredInvoices,
        searchedInvoice: state.searchedInvoice,
        error: state.error,
        shop: state.shop,
        invoiceLoading: state.invoiceLoading,
        invoiceNumber: state.invoiceNumber,
        cutOffDate: state.cutOffDate,
        invoiceCount: state.invoiceCount,
        isCancelled: state.isCancelled,
        adjustedInvoices: state.adjustedInvoices,
        chartInvoices: state.chartInvoices,
        // getInvoicesByStatus,
        addInvoice,
        deleteInvoice,
        setCurrentInvoice,
        clearCurrentInvoice,
        updateInvoice,
        setFilteredInvoices,
        clearFilteredInvoices,
        clearInvoices,
        statusChange,
        // getInvoicesByShopAndStatus,
        getInvoiceById,
        findInvoiceByInvoiceNumber,
        // getOpenInvoices,
        clearSearchedInvoice,
        setInvoiceLoading,
        // updateBulkOrdersToInvoiced,
        // updateInvoiceStatus,
        // getInvoicesForChartOne,
        // clearFilteredInvoicesByShop,
        // setShopForFilter,
        setInvoiceNumberSearch,
        setCutOffDate,
        // getPaidInvoices,
        // setPagination,
        // getInvoiceCount,
        // clearInvoiceCount,
        // setIsCancelled,
        // getAdjustedInvoices,
        // updateAdjustedInvoice,
        getInvoicesByStatus,
        getInvoiceForChart,
        updateInvoiceOrder,
      }}
    >
      {props.children}
    </InvoiceContext.Provider>
  );
};

export default InvoiceState;
