import React, { Fragment } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Input } from "antd";

const Invoices = ({ invoice, invoiceIndex, control, remove, setValue, getValues, invoiceOptions, register }) => {
  return (
    <Fragment>
      <div className="row mb-2 ">
        <div className="col-md-12 ">
          <div className="flexcontainer mt-1">
            <div className="paymentitem">
              <Controller
                render={({ field, name }) => <input readOnly className="form-control" name={name} {...field} />}
                key={invoice.id}
                control={control}
                name={`invoices[${invoiceIndex}].invoiceNumber`}
              />
            </div>
            <div className="paymentitem">
              <Controller
                render={({ field, name }) => (
                  <NumberFormat
                    readOnly
                    customInput={Input}
                    className="form-group form-control"
                    style={{ fontSize: 16, padding: 6 }}
                    {...field}
                    name={name}
                    thousandSeparator={true}
                    prefix={"$ "}
                    displayType="input"
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                )}
                name={`invoices[${invoiceIndex}].order.grandTotal.shop`}
                control={control}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "invoices",
  });

  return (
    <div className="container-fluid border mt-4" style={{ borderRadius: "8px" }}>
      <div className="row mb-2 mt-2 ">
        <div className="col-md-12 ">
          <div className="flexcontainer mt-1">
            <div className="paymentitem">
              <label htmlFor="">Invoice #</label>
            </div>
            <div className="paymentitem">
              <label>Amount</label>
            </div>
          </div>
        </div>
      </div>
      {fields.map((invoice, invoiceIndex) => (
        <Invoices
          key={invoice.id}
          invoice={invoice}
          invoiceIndex={invoiceIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      ))}
      {/* <button
        className='btn btn-outline-info mb-2 mt-2  '
        onClick={(e) => {
          e.preventDefault();
          append({ append: 1 });
        }}
      >
        Add invoice
      </button> */}
    </div>
  );
}
