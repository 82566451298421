import React, { useReducer } from "react";
import AddonParentCategoryContext from "./addonParentCategoryContext";
import addonParentCategoryReducer from "./addonParentCategoryReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_ADDONPARENT_CATEGORY,
  DELETE_ADDONPARENT_CATEGORY,
  CLEAR_ADDONPARENT_CATEGORYS,
  SET_CURRENT_ADDONPARENT_CATEGORY,
  CLEAR_CURRENT_ADDONPARENT_CATEGORY,
  UPDATE_ADDONPARENT_CATEGORY,
  ADDONPARENT_CATEGORY_ERROR,
  GET_ADDONPARENT_CATEGORYS,
} from "../types";

const AddonParentCategoryState = (props) => {
  const initialState = {
    addonParentCategorys: null,
    currentAddonParentCategory: null,
    error: null,
    loadingAddonParentCategory: true,
  };

  const [state, dispatch] = useReducer(addonParentCategoryReducer, initialState);

  // get All
  const getAddonParentCategorys = async (newBuildChecked, offLotChecked) => {
    try {
      const res = await axios.get(`/api/addon-parent-category`, {
        params: {
          newBuildChecked,
          offLotChecked,
        },
      });
      dispatch({ type: GET_ADDONPARENT_CATEGORYS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDONPARENT_CATEGORY_ERROR });
    }
  };

  const addAddonParentCategory = async (addonParentCategory) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/addon-parent-category/", addonParentCategory, config);
      dispatch({ type: ADD_ADDONPARENT_CATEGORY, payload: res.data });
      toast.success(`AddonParentCategory is created`);
    } catch (err) {
      dispatch({ type: ADDONPARENT_CATEGORY_ERROR });
      toast.error(`AddonParentCategory ${err}`);
    }
  };

  const copyExistingCategoryData = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/addon-parent-category/copy/", data, config);
      dispatch({ type: ADD_ADDONPARENT_CATEGORY, payload: res.data });
      toast.success(`AddonParentCategory is created`);
    } catch (err) {
      dispatch({ type: ADDONPARENT_CATEGORY_ERROR });
      toast.error(`AddonParentCategory ${err}`);
    }
  };

  const deleteAddonParentCategory = async (id) => {
    try {
      const res = await axios.delete(`/api/addon-parent-category/delete/${id}`);
      dispatch({ type: DELETE_ADDONPARENT_CATEGORY, payload: id });
      toast.success(`AddonParentCategory is deleted`);
    } catch (err) {
      dispatch({ type: ADDONPARENT_CATEGORY_ERROR });
      toast.error(`AddonParentCategory ${err}`);
    }
  };

  // update AddonParentCategory
  const updateAddonParentCategory = async (addonParentCategory) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/addon-parent-category/update/${addonParentCategory._id}`, addonParentCategory, config);
      dispatch({ type: UPDATE_ADDONPARENT_CATEGORY, payload: res.data });
      toast.success(`AddonParentCategory is updated`);
    } catch (err) {
      dispatch({ type: ADDONPARENT_CATEGORY_ERROR });
      toast.error(`AddonParentCategory ${err}`);
    }
  };

  const clearAddonParentCategorys = () => {
    dispatch({ type: CLEAR_ADDONPARENT_CATEGORYS });
  };

  const setCurrentAddonParentCategory = (addonParentCategory) => {
    dispatch({ type: SET_CURRENT_ADDONPARENT_CATEGORY, payload: addonParentCategory });
  };

  const clearCurrentAddonParentCategory = () => {
    dispatch({ type: CLEAR_CURRENT_ADDONPARENT_CATEGORY });
  };

  return (
    <AddonParentCategoryContext.Provider
      value={{
        addonParentCategorys: state.addonParentCategorys,
        currentAddonParentCategory: state.currentAddonParentCategory,
        error: state.error,
        loadingAddonParentCategory: state.loadingAddonParentCategory,
        addAddonParentCategory,
        deleteAddonParentCategory,
        setCurrentAddonParentCategory,
        clearCurrentAddonParentCategory,
        updateAddonParentCategory,
        clearAddonParentCategorys,
        getAddonParentCategorys,
        copyExistingCategoryData,
      }}
    >
      {props.children}
    </AddonParentCategoryContext.Provider>
  );
};

export default AddonParentCategoryState;
