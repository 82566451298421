import React, { Fragment, useEffect, useContext, useState } from "react";
import LogContext from "../../../context/log/logContext";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";

const Logs = () => {
  const navigate = useNavigate();
  const logContext = useContext(LogContext);
  const { getLogs, logs, deleteLog } = logContext;

  useEffect(() => {
    getLogs();
  }, []);

  const handleDeleteLog = (log) => {
    deleteLog(log._id);
  };

  const columns = [
    {
      title: `Function`,
      render: (text, record) => <span>{record.functionName}</span>,
    },
    {
      title: `User`,
      render: (text, record) => <span>{record.user}</span>,
    },
    {
      title: "Message",
      render: (text, record) => (
        <>
          {record.message.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index !== record.message.split("\n").length - 1 && <br />}
            </React.Fragment>
          ))}
        </>
      ),
    },
    {
      title: `Date`,
      render: (text, record) => <span>{record.timestamp && new Date(record.timestamp).toLocaleString()}</span>,
    },
    {
      title: "Actions",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <button className="btn btn-xs btn-outline-danger mb-2" type="button" onClick={(e) => handleDeleteLog(record)}>
          Delete
        </button>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <div>
      <div className="row"></div>

      <div className="row">
        <Table
          // loading={
          //   !logs
          //     ? false
          //     : true
          // }
          columns={columns}
          dataSource={logs}
          rowKey="_id"
          bordered={true}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Logs;
