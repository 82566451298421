import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import DealerContext from "./dealerContext";
import dealerReducer from "./dealerReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_DEALER,
  DELETE_DEALER,
  CLEAR_DEALERS,
  SET_CURRENT_DEALER,
  CLEAR_CURRENT_DEALER,
  UPDATE_DEALER,
  SET_DEALERS,
  DEALER_ERROR,
  GET_DEALERS,
  FILTER_DEALERS,
  CLEAR_FILTER_DEALER,
} from "../types";

const DealerState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    dealers: null,
    currentDealer: null,
    error: null,
    filteredDealer: null,
  };

  const [state, dispatch] = useReducer(dealerReducer, initialState);

  const getDealers = async () => {
    try {
      const res = await axios.get(`/api/dealer`);
      dispatch({ type: GET_DEALERS, payload: res.data });
    } catch (err) {
      dispatch({ type: DEALER_ERROR });
      console.log("this is the err", err);
      toast.error(`Shed ${err}`);
    }
  };

  // add
  const addDealer = async (dealer) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/dealer", { dealer }, config);
      dispatch({ type: ADD_DEALER, payload: res.data });
    } catch (err) {
      dispatch({ type: DEALER_ERROR });
    }
  };

  const importDealers = async (file) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/dealer/import", { file }, config);
      dispatch({ type: ADD_DEALER, payload: res.data });
    } catch (err) {
      dispatch({ type: DEALER_ERROR });
    }
  };

  // delete
  const deleteDealer = async (id) => {
    try {
      const res = await axios.delete(`/api/dealer/delete/${id}`);
      dispatch({ type: DELETE_DEALER, payload: id });
      toast.success(`Dealer is deleted`);
    } catch (err) {
      dispatch({ type: DEALER_ERROR });
    }
  };

  // update
  const updateDealer = async (id, dealer) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/dealer/update/${id}`, dealer, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_DEALER, payload: res.data });
      navigate(-1);
      toast.success(`Dealer is updated`);
    } catch (err) {
      dispatch({ type: DEALER_ERROR });
      toast.error(`Dealer ${err}`);
    }
  };

  // clear
  const clearDealers = () => {
    dispatch({ type: CLEAR_DEALERS });
  };

  // set current
  const setCurrentDealer = (dealer) => {
    dispatch({ type: SET_CURRENT_DEALER, payload: dealer });
  };

  const setDealers = (labors) => {
    dispatch({ type: SET_DEALERS, payload: labors });
  };

  // clear current
  const clearCurrentDealer = () => {
    dispatch({ type: CLEAR_CURRENT_DEALER });
  };

  const filterDealers = (text) => {
    dispatch({ type: FILTER_DEALERS, payload: text });
  };

  const clearFilterDealer = () => {
    dispatch({ type: CLEAR_FILTER_DEALER });
  };

  return (
    <DealerContext.Provider
      value={{
        dealers: state.dealers,
        currentDealer: state.currentDealer,
        error: state.error,
        filteredDealer: state.filteredDealer,
        getDealers,
        setDealers,
        addDealer,
        deleteDealer,
        setCurrentDealer,
        clearCurrentDealer,
        updateDealer,
        clearDealers,
        filterDealers,
        clearFilterDealer,
        importDealers,
      }}
    >
      {props.children}
    </DealerContext.Provider>
  );
};

export default DealerState;
