import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import ProductHeader from "./ProductHeader";
import InvoiceTableHeader from "./InvoiceTableHeader";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
    flexWrap: "wrap",
  },
  blankrow: {
    flexDirection: "row",
    alignItems: "center",
    height: 34,
    fontStyle: "bold",
  },
  sku: {
    width: "35%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  colorstwo: {
    width: "30%",
    textAlign: "right",
    paddingRight: 8,
  },
  title: {
    width: "80%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  description: {
    width: "45%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    paddingLeft: 8,
  },
  count: {
    width: "20%",
    borderRightColor: borderColor,

    textAlign: "left",
    paddingLeft: 8,
  },
  colors: {
    width: "100%",
    borderRightColor: borderColor,
    paddingRight: 8,
    textAlign: "right",
  },

  blanktext: {
    width: "100%",
  },
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

const InvoiceTableRow = ({ metalOrder }) => {
  const rows = metalOrder.map((order) => (
    <View key={order._id} wrap={false}>
      <View>
        <InvoiceTableHeader />

        <View style={styles.container}>
          <View style={styles.row}>
            <Text style={styles.sku}>{order.metalPackage.name.label}</Text>
            {order.serialNumber && <Text style={styles.sku}>{order.serialNumber}</Text>}
            <Text style={styles.colorstwo}>Roof: {order.roofColor && order.roofColor.value ? order.roofColor.label : "N/A"}</Text>
          </View>

          <View style={styles.row}>
            {order.trimColor?.value && <Text style={styles.colors}>Trim: {order.trimColor.label}</Text>}
          </View>
          <View style={styles.row}>
            {order.sidingColor?.value && <Text style={styles.colors}>Siding: {order.sidingColor.label}</Text>}
          </View>

          <ProductHeader />

          {order.metalPackage.items.map((item) => (
            <View style={styles.row} key={item._id}>
              <Text wrap style={styles.title}>
                {item.item.name}
              </Text>

              <Text wrap style={styles.count}>
                {item.quantity}
              </Text>
            </View>
          ))}
        </View>

        <View style={styles.blankrow}>
          <Text style={styles.blanktext}></Text>
        </View>
      </View>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
