import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Row, Col, Typography, Table } from "antd";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import VendorContext from "../../../../../context/vendor/vendorContext";
import ItemContext from "../../../../../context/item/itemContext";
import ShopContext from "../../../../../context/shop/shopContext";

const initialState = {
  name: "",
  email: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
};

const CreateVendor = () => {
  const navigate = useNavigate();
  const vendorContext = useContext(VendorContext);
  const { addVendor, updateVendor, currentVendor, clearCurrentVendor } = vendorContext;

  const itemContext = useContext(ItemContext);
  const { getItems, allItems } = itemContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const { control, handleSubmit, reset, setValue } = useForm({ defaultValues: initialState });
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    if (currentVendor) {
      reset({
        name: currentVendor.name || "",
        email: currentVendor.email || "",
        address: currentVendor.address || "",
        city: currentVendor.city || "",
        state: currentVendor.state || "",
        zipCode: currentVendor.zipCode || "",
        _id: currentVendor._id,
      });

      const updatedSelectedItems = currentVendor.defaultItems.map((item) => {
        // Find the shopValue for the current shop
        const shopValueForCurrentShop = item.shopValues.find(
          (shopValue) => shopValue.shop.toString() === currentShop._id.toString()
        );

        // Return an object with `label` and `value` for the react-select component
        return {
          value: item._id, // Use the item's ObjectId as the value
          label: shopValueForCurrentShop ? shopValueForCurrentShop.name : item.name, // Use the shop-specific name or the default name
        };
      });

      // Set the updated items in selectedItems
      setSelectedItems(updatedSelectedItems);
    }
  }, [currentVendor, reset]);

  const onSubmit = (data) => {
    const vendorData = { ...data, defaultItems: selectedItems };

    if (!currentVendor) {
      addVendor(vendorData);
    } else {
      updateVendor(vendorData);
      clearCurrentVendor();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentVendor();
  };

  const handleItemSelection = (option) => {
    if (option && !selectedItems.some((item) => item.value === option.value)) {
      setSelectedItems([...selectedItems, option]);
    }
    setSelectedOption(null); // Clear the dropdown after selection
  };

  const itemOptions = allItems?.map((item) => {
    // Find the shopValues entry that matches the current shop
    const shopValueForCurrentShop = item.shopValues.find((shopValue) => shopValue.shop.toString() === currentShop._id.toString());

    return {
      value: item._id,
      label: shopValueForCurrentShop.name,
    };
  });

  const columns = [
    {
      title: "Item Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button danger onClick={() => handleRemoveItem(record.value)}>
          Remove
        </Button>
      ),
    },
  ];

  const handleRemoveItem = (itemValue) => {
    setSelectedItems(selectedItems.filter((item) => item.value !== itemValue));
  };

  return (
    <div className="container-fluid">
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>{currentVendor ? "Update Vendor" : "Create Vendor"}</Typography.Title>
        </Col>
        <Col>
          <Button type="danger" onClick={setExit}>
            Exit
          </Button>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <Form
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            style={{
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Add shadow
              border: "1px solid #d9d9d9", // Add a subtle border
              backgroundColor: "#fff", // Ensure background is white
            }}
          >
            <Form.Item label="Vendor Name" name="name">
              <Controller name="name" control={control} render={({ field }) => <Input {...field} />} />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Controller name="email" control={control} render={({ field }) => <Input {...field} type="email" />} />
            </Form.Item>
            <Form.Item label="Address" name="address">
              <Controller name="address" control={control} render={({ field }) => <Input {...field} />} />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Controller name="city" control={control} render={({ field }) => <Input {...field} />} />
            </Form.Item>
            <Form.Item label="State" name="state">
              <Controller name="state" control={control} render={({ field }) => <Input {...field} />} />
            </Form.Item>
            <Form.Item label="Zip Code" name="zipCode">
              <Controller name="zipCode" control={control} render={({ field }) => <Input {...field} />} />
            </Form.Item>

            <Form.Item label="Assign Default Items (Save after assigning items)">
              <Controller
                name="selectedOption"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={itemOptions}
                    value={selectedOption}
                    onChange={handleItemSelection}
                    placeholder="Select items to assign"
                  />
                )}
              />
            </Form.Item>

            <Form.Item style={{ marginTop: "20px" }}>
              <Button type="primary" htmlType="submit" block>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>

        <Col span={12}>
          <Typography.Title level={5}>Selected Default Items</Typography.Title>
          <Table columns={columns} dataSource={selectedItems} pagination={false} rowKey="value" style={{ marginTop: "20px" }} />
        </Col>
      </Row>
    </div>
  );
};

export default CreateVendor;
