import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MaterialOrderContext from "../../../../../context/materialOrder/materialOrderContext";
import ItemContext from "../../../../../context/item/itemContext";
import VendorContext from "../../../../../context/vendor/vendorContext";
import ShopContext from "../../../../../context/shop/shopContext";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./material_pdf/Invoice";
import ItemArray from "./MaterialItemArray";
import ReactSelect from "react-select";
import MaterialOrderConfirmedModal from "./MaterialOrderConfirmModal";
import { Row, Col, Form, Input, Button } from "antd";

import { DatePicker } from "antd";
import moment from "moment";

const defaultValues = {
  purchaseOrderNumber: "",
  freight: 0,
  allItems: [
    {
      item: {},
      quantiy: 0,
      newPrice: 0,
      newFreight: 0,
      total: 0,
      description: "",
    },
  ],
};

const MaterialOrderForm = () => {
  const navigate = useNavigate();

  const vendorContext = useContext(VendorContext);
  const { getVendors, vendors } = vendorContext;

  const materialOrderContext = useContext(MaterialOrderContext);
  const {
    updateMaterialOrder,
    currentMaterialOrder,
    setCurrentMaterialOrder,
    addMaterialOrder,
    clearCurrentMaterialOrder,
    getMaterialOrders,
  } = materialOrderContext;

  const itemContext = useContext(ItemContext);
  const { allItems, getItems, clearItems } = itemContext;

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  const [ready, setReady] = useState(false);

  const initialValues = currentMaterialOrder
    ? {
        ...currentMaterialOrder,
        orderedDate: currentMaterialOrder.orderedDate ? moment(currentMaterialOrder.orderedDate) : null,
      }
    : defaultValues;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    getShop();
    getItems();
    getVendors();
    // currentMaterialOrder && reset(currentMaterialOrder);
    return () => {
      clearItems();
    };
  }, []);

  // const itemOptions =
  //   allItems &&
  //   allItems.map((option) => {
  //     return { label: `${option.name}`, value: option };
  //   });

  const vendorOptions =
    vendors &&
    vendors.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    getMaterialOrders();
    navigate(-1);
  };

  const onSubmit = (order) => {
    if (!currentMaterialOrder) {
      Modal.confirm({
        title: "Create Order?",
        okText: "Yes",
        okType: "success",
        onOk: () => {
          setCurrentMaterialOrder(order);
          addMaterialOrder(order);
          handleShow();
        },
      });
    } else {
      updateMaterialOrder(order);
      handleShow();
    }
  };

  const [itemOptions, setItemOptions] = useState([]);

  const setVendorChange = (e) => {
    // Set the vendor value
    setValue(`vendor`, e.value);

    // Get the default items for the selected vendor
    const defaultItems = e.value.defaultItems;

    // Map the vendor's defaultItems to itemOptions, using currentShop's item.shopValues for the label
    const itemOptions = defaultItems.map((item) => {
      const shopValueForCurrentShop = item.shopValues.find(
        (shopValue) => shopValue.shop.toString() === currentShop._id.toString()
      );

      return {
        label: shopValueForCurrentShop ? shopValueForCurrentShop.name : item.name,
        value: item,
      };
    });

    // Now, you can use these itemOptions in your component
    setItemOptions(itemOptions);
  };

  const setOrderFreight = (value) => {
    setValue(`freight`, value);
  };

  const setExit = () => {
    navigate(-1);
  };

  const dateFormat = "MM/DD/YYYY";

  return (
    <div className="container-fluid">
      <MaterialOrderConfirmedModal handleClose={handleClose} handleShow={handleShow} show={show} />
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mt-2 mb-2 btn btn-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>

      <div className="row">
        <form
          className="p-6 bg-white shadow-lg rounded-lg"
          style={{
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "95%",
            margin: "0 auto",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <h4 className="text-center mt-4 mb-4 text-xl font-semibold">Material Purchase Order Form</h4>

          <Row gutter={16} align="middle" className="mb-4">
            <Col span={6}>
              <div className="">
                <label>Vendor</label>
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      options={vendorOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      // className="form-control"
                      value={(vendorOptions && vendorOptions.find((option) => option.value._id === field.value?._id)) || null}
                      onChange={(e) => setVendorChange(e)}
                    />
                  )}
                  name="vendor"
                  control={control}
                />
              </div>
            </Col>

            <Col span={6}>
              <div className="form-group">
                <label>Po#</label>
                <input
                  className="form-control inputbg"
                  name="purchaseOrderNumber"
                  {...register("purchaseOrderNumber", { required: true })}
                />
              </div>
            </Col>

            <Col span={5}>
              <div className="form-group">
                <label>Ordered Date</label>
                <Controller
                  render={({ field }) => <DatePicker format={dateFormat} className="form-control inputbg p-2" {...field} />}
                  control={control}
                  name="orderedDate"
                />
              </div>
            </Col>

            <Col span={7}>
              <div className="mt-4">
                <button className="btn btn-primary w-full px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 float-end">
                  Save
                </button>
              </div>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: "16px" }}>
            <Col span={18}></Col>
            <Col span={3}>
              <div className="form-group">
                <label className="font-medium text-gray-700">Freight</label>
                <Controller
                  render={({ value, field, onChange, name, ...restProps }) => (
                    <input
                      className="form-control w-full mt-1 px-2 py-1 border rounded-md"
                      type="number"
                      {...field}
                      name={name}
                      defaultValue={0}
                      onChange={(e) => setOrderFreight(e.target.value)}
                      {...restProps}
                    />
                  )}
                  control={control}
                  name="freight"
                  defaultValue={0}
                />
              </div>
            </Col>
            <Col span={3}>
              <div className="form-group">
                <label className="font-medium text-gray-700">Total</label>
                <Controller
                  render={({ value, field, onChange, name, ...restProps }) => (
                    <input
                      className="form-control w-full mt-1 px-2 py-1 border rounded-md"
                      type="number"
                      {...field}
                      name={name}
                      defaultValue={0}
                      readOnly
                      {...restProps}
                    />
                  )}
                  control={control}
                  name="grandTotal"
                  defaultValue={0}
                />
              </div>
            </Col>
          </Row>

          <ItemArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              itemOptions,
              currentShop,
              currentMaterialOrder,
            }}
          />
        </form>
      </div>
    </div>
  );
};

export default MaterialOrderForm;
