import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ShopContext from "../../../../../../context/shop/shopContext";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllShedModels = () => {
  const navigate = useNavigate();
  const shedModelContext = useContext(ShedModelContext);
  const { getShedModels, shedModels, setCurrentShedModel, clearShedModels } = shedModelContext;

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  useEffect(() => {
    getShop();
  }, []);

  useEffect(() => {
    currentShop && getShedModels(currentShop.defaultCategory._id);
  }, [currentShop]);

  useEffect(() => {}, [shedModels]);

  const columns = [
    {
      title: "Name",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },
    {
      title: "Code",
      render: (record) => <Fragment>{record.code}</Fragment>,
    },

    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShedModel(record)}>
            View
          </button>
          {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditShedModel(record)}>
            Edit
          </button> */}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewShedModel = (shedModel) => {
    setCurrentShedModel(shedModel);
    navigate("/shop/shed/all");
  };

  return (
    <Table
      rowClassName={() => "hover-row"}
      // loading={!loadingProduct && productResults ? false : true}
      pagination={{ pageSize: 60 }}
      columns={columns}
      // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
      dataSource={shedModels}
      rowKey="_id"
      onChange={onChange}
      scroll={{ x: 1300, y: 2500 }}
    />
  );
};

export default ShopAllShedModels;
