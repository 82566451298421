import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CompanyContext from "../../../../../context/company/companyContext";
const initialState = {
  sidingColor: { color: "" },
};

const AddSidingColor = ({ selectedSidingColor, setSelectedSidingColor }) => {
  const navigate = useNavigate();

  const [values, setValues] = useState(initialState);
  // destructure
  const { sidingColor } = values;
  const companyContext = useContext(CompanyContext);
  const { company, updateCompany } = companyContext;

  useEffect(() => {
    if (selectedSidingColor) {
      setValues({
        sidingColor: selectedSidingColor,
      });
    }
  }, [selectedSidingColor]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Make a copy of the company's sidingColors array
    let updatedSidingColors = [...company.sidingColors];
    // If a color is selected, update it
    if (selectedSidingColor) {
      const oldColorId = selectedSidingColor._id; // the old color's _id
      const newColor = values.sidingColor.color; // the new color
      // Find the index of the old color
      let index = updatedSidingColors.findIndex((color) => color._id === oldColorId);
      // Replace the old color with the new color
      if (index !== -1) {
        updatedSidingColors[index].color = newColor;
      }
    } else {
      // If no color is selected, add a new one
      const newColor = values.sidingColor.color; // the new color
      updatedSidingColors.push({ color: newColor });
    }
    // Update the company
    let updatedCompany = { ...company, sidingColors: updatedSidingColors };
    updateCompany(company._id, updatedCompany);
    // Reset the form values
    setSelectedSidingColor(null);
    setValues(initialState);
  };

  const handleChange = (e) => {
    const [field, nestedField] = e.target.name.split(".");
    setValues((prevValues) => ({
      ...prevValues,
      [field]: { ...prevValues[field], [nestedField]: e.target.value },
    }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center" }}>Siding Color</h4>

      <div className="form-group">
        <label>Color</label>
        <input
          type="string"
          name="sidingColor.color"
          className="form-control inputbg"
          value={sidingColor.color}
          onChange={handleChange}
        />
      </div>
      <button className="btn btn-outline-info float-end mb-2 mt-4">Save Siding Color</button>
    </form>
  );
};

export default AddSidingColor;
