import React, { Fragment, useEffect, useState, useContext } from "react";
import ShedContext from "../../../../../../context/shed/shedContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table, Switch } from "antd";

const ShopAllSheds = () => {
  const navigate = useNavigate();

  const shedContext = useContext(ShedContext);
  const { getSheds, sheds, setCurrentShed, updateAllSheds, clearSheds, getShed, loadingSheds } = shedContext;

  // console.log("This is sheds", sheds);

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  const shedModelContext = useContext(ShedModelContext);
  const { currentShedModel } = shedModelContext;

  useEffect(() => {
    getShop();
  }, []);

  useEffect(() => {
    currentShop && getSheds(currentShop.defaultCategory._id, currentShop._id, currentShedModel._id);
    return () => {
      clearSheds();
    };
  }, [currentShop]);

  const [newFile, setNewFile] = useState("");
  // let itemsForExport = [];

  // sheds &&
  //   sheds.map((shed) => {
  //     let newItem = {};
  //     newItem.Id = shed._id;
  //     newItem.Model = shed.shedModel.name;
  //     newItem.Width = shed.width;
  //     newItem.Length = shed.length;
  //     newItem.Retail_Price = shed.retailPrice;
  //     // newItem.Item_Id = item.item._id;
  //     itemsForExport.push(newItem);
  //   });

  // const exportFile = () => {
  //   const ws = utils.json_to_sheet(itemsForExport);
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, "Data");
  //   writeFileXLSX(wb, "Sheds_Price.xlsx");
  // };

  let shedsForExportByModel = {};

  // console.log("This is sheds", sheds);

  sheds &&
    sheds.forEach((shed) => {
      // Group sheds by category
      if (!shedsForExportByModel[shed.model]) {
        shedsForExportByModel[shed.model] = [];
      }
      let newItem = {};
      newItem.width = shed.width;
      newItem.length = shed.length;
      newItem.retailPrice = shed.retailPrice;
      newItem.shopsRetailPercent = shed.shopsRetailPercent.toFixed(2);
      newItem.shopsRetailPrice = shed.shopsRetailPrice.toFixed(2);
      newItem.shopOverhead = shed.shopOverhead.toFixed(2);
      // newItem.shopMaterialCost = shed.shopMaterialCost.toFixed(2);
      // newItem.shopLabor = shed.shopLabor.toFixed(2);
      // newItem.shopTotalCost = shed.shopTotalCost.toFixed(2);
      // newItem.shopGrossProfit = shed.shopGrossProfit.toFixed(2);
      // newItem.shopGrossProfitPercent = shed.shopGrossProfitPercent.toFixed(2);
      // Find the object in shopValues where the shop is the currentShop

      shedsForExportByModel[shed.model].push(newItem);
    });

  const exportFile = () => {
    const wb = utils.book_new();
    // Create a new worksheet for each category
    for (let model in shedsForExportByModel) {
      const ws = utils.json_to_sheet(shedsForExportByModel[model]);
      const sanitizedCategory = sanitizeSheetName(model);
      utils.book_append_sheet(wb, ws, sanitizedCategory);
    }
    writeFileXLSX(wb, `Sheds.xlsx`);
  };

  function sanitizeSheetName(name) {
    // Replace invalid characters with underscore
    return name.replace(/[:\\/?*[\]]/g, "_");
  }

  const importFile = () => {
    updateAllSheds(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };
  const [showAllColumns, setShowAllColumns] = useState(false);

  const columns = [
    {
      title: "Width",
      render: (record) => <Fragment>{record.width}</Fragment>,
    },

    {
      title: "Length",
      render: (record) => <Fragment>{record.length}</Fragment>,
    },

    {
      title: "Retail Price",
      render: (record) => (
        <Fragment>
          {record &&
            record.retailPrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    // Conditionally visible columns
    showAllColumns && {
      title: "Shop Retail Percent",
      render: (record) => <Fragment>{record.shopsRetailPercent}</Fragment>,
    },
    showAllColumns && {
      title: "Shop Retail Price",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopsRetailPrice?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    showAllColumns && {
      title: "Overhead",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopOverhead?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    showAllColumns && {
      title: "Box Material Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopBoxMaterialCost?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    showAllColumns && {
      title: "Labor Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopLabor?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    showAllColumns && {
      title: "Box Total Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopBoxTotalCost?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Box GP",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopBoxGrossProfit?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },

    {
      title: "Box GP %",
      render: (record) => <Fragment>{record && record.shopBoxGrossProfitPercent?.toLocaleString("en-US")}%</Fragment>,
    },

    // {
    //   title: "Addon GP",
    //   render: (record) => (
    //     <Fragment>
    //       {record &&
    //         record.shopAddonsTotalGrossProfit?.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </Fragment>
    //   ),
    // },

    // {
    //   title: "Addon GP %",
    //   render: (record) => <Fragment>{record && record.shopAddonsTotalGrossProfitPercent?.toLocaleString("en-US")}%</Fragment>,
    // },

    // {
    //   title: "Total GP",
    //   render: (record) => (
    //     <Fragment>
    //       {record &&
    //         record.totalShopGrossProfit?.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </Fragment>
    //   ),
    // },

    // {
    //   title: "Total GP %",
    //   render: (record) => <Fragment>{record && record.totalShopGrossProfitPercent?.toLocaleString("en-US")}%</Fragment>,
    // },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShed(record)}>
            View
          </button>
          {/* <button className="btn btn-xs btn-outline-danger" type="button" onClick={() => handledeleteShed(record)}>
            Delete
          </button> */}
        </span>
      ),
    },
  ].filter(Boolean); // Filter out the falsy values (hidden columns)

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewShed = async (shed) => {
    await getShed(shed._id);
    navigate("/shop/shed/form");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
          <button className="mb-2 btn btn-outline-primary float-end me-2" onClick={() => exportFile()}>
            {" "}
            Export{" "}
          </button>
          <h4 className="text-center">{currentShedModel.name}</h4>
        </div>
      </div>

      <div>
        <label>
          Show All Columns
          <Switch checked={showAllColumns} onChange={() => setShowAllColumns(!showAllColumns)} style={{ marginLeft: 8 }} />
        </label>
      </div>

      <Table
        rowClassName={() => "hover-row"}
        loading={sheds ? loadingSheds : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        dataSource={sheds}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllSheds;
