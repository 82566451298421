import React, { useContext, Fragment, useState, useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Input, Table } from "antd";
import { setTotals } from "./OrderFunctions";

export default function Fields({ control, register, setValue, getValues, addOns, watch, shopSettings, companySettings }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "customAddOns",
  });

  const [fieldsState, setFieldsState] = useState([]);
  useEffect(() => {
    setFieldsState(fields);
  }, [fields]);

  function calculateCustomAddOnGp(shopTotalSalePrice, totalCostShop, totalCostStandard, index) {
    const shopGpDollars = shopTotalSalePrice - totalCostShop;
    const standardGpDollars = shopTotalSalePrice - totalCostStandard;
    const shopGpPercent = shopGpDollars / shopTotalSalePrice;
    const standardGpPercent = standardGpDollars / shopTotalSalePrice;
    setValue(`customAddOns[${index}].profit.shop`, shopGpDollars);
    setValue(`customAddOns[${index}].profit.standard`, standardGpDollars);
    setValue(`customAddOns[${index}].profitPercent.shop`, shopGpPercent);
    setValue(`customAddOns[${index}].profitPercent.standard`, standardGpPercent);
  }

  const handleRetailPriceChange = (value, index) => {
    setValue(`customAddOns[${index}].retailPrice`, value);
    const quantity = getValues(`customAddOns[${index}].quantity`);
    const totalSalePrice = value * quantity;
    const shopPercent = getValues(`customAddOns[${index}].shopPercent`);
    const shopTotalSalePrice = totalSalePrice * (shopPercent / 100);
    const totalCostShop = getValues(`customAddOns[${index}].totalCost.shop`);
    const totalCostStandard = getValues(`customAddOns[${index}].totalCost.standard`);
    calculateCustomAddOnGp(shopTotalSalePrice, totalCostShop, totalCostStandard, index);
    setValue(`customAddOns[${index}].totalSalePrice.company`, totalSalePrice);
    setValue(`customAddOns[${index}].totalSalePrice.shop`, shopTotalSalePrice);
    setValue(`customAddOns[${index}].totalSalePrice.order`, totalSalePrice);
    setTotals(getValues, setValue, shopSettings, companySettings);
  };

  const setCustomAddOnCount = (count, index) => {
    setValue(`customAddOns[${index}].quantity`, count);
    const retailPrice = getValues(`customAddOns[${index}].retailPrice`);
    const totalSalePrice = count * retailPrice;
    const shopPercent = getValues(`customAddOns[${index}].shopPercent`);
    const shopTotalSalePrice = totalSalePrice * (shopPercent / 100);
    const totalCostShop = getValues(`customAddOns[${index}].totalCost.shop`);
    const totalCostStandard = getValues(`customAddOns[${index}].totalCost.standard`);
    calculateCustomAddOnGp(shopTotalSalePrice, totalCostShop, totalCostStandard, index);
    setValue(`customAddOns[${index}].totalSalePrice.company`, totalSalePrice);
    setValue(`customAddOns[${index}].totalSalePrice.order`, totalSalePrice);
    setValue(`customAddOns[${index}].totalSalePrice.shop`, shopTotalSalePrice);
    setTotals(getValues, setValue, shopSettings, companySettings);
  };

  const setShopPercent = (percent, index) => {
    setValue(`customAddOns[${index}].shopPercent`, percent);
    const retailPrice = getValues(`customAddOns[${index}].retailPrice`);
    const quantity = getValues(`customAddOns[${index}].quantity`);
    const totalSalePrice = retailPrice * quantity;
    const shopTotalSalePrice = totalSalePrice * (percent / 100);
    const totalCostShop = getValues(`customAddOns[${index}].totalCost.shop`);
    const totalCostStandard = getValues(`customAddOns[${index}].totalCost.standard`);
    calculateCustomAddOnGp(shopTotalSalePrice, totalCostShop, totalCostStandard, index);
    setValue(`customAddOns[${index}].totalSalePrice.company`, totalSalePrice);
    setValue(`customAddOns[${index}].totalSalePrice.order`, totalSalePrice);
    setValue(`customAddOns[${index}].totalSalePrice.shop`, shopTotalSalePrice);
    setTotals(getValues, setValue, shopSettings, companySettings);
  };

  const columns = [
    {
      title: "Custom Addo-On Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (_, record, index) => (
        <Controller
          render={({ field, name }) => <input className="form-control  custom-input" name={name} {...field} />}
          control={control}
          name={`customAddOns[${index}].name`}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, record, index) => (
        <Controller
          render={({ field, name }) => (
            <input
              className="form-control  custom-input"
              name={name}
              type="number"
              {...field}
              onChange={(e) => {
                const count = e.target.value;
                setCustomAddOnCount(count, index);
              }}
            />
          )}
          control={control}
          name={`customAddOns[${index}].quantity`}
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "retailPrice",
      key: "retailPrice",
      render: (_, record, index) => (
        <Controller
          render={({ field, name }) => (
            <NumberFormat
              className={`form-control custom-input`}
              name={name}
              type="text"
              thousandSeparator={true}
              prefix={"$ "}
              decimalScale={2}
              fixedDecimalScale={true}
              onValueChange={({ value }) => handleRetailPriceChange(value, index)}
              onBlur={field.onBlur}
              value={field.value}
            />
          )}
          control={control}
          name={`customAddOns[${index}].retailPrice`}
          // defaultValue={addOn.retailPrice}
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "totalSalePrice",
      key: "totalSalePrice",
      render: (_, record, index) => {
        return {
          props: {
            style: {
              background: "#dbdbdb",
            },
          },
          children: (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  readOnly
                  className={`form-control custom-input readonlyinput`}
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  // onValueChange={({ value }) => handleRetailPriceChange(value, index)}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
              control={control}
              name={`customAddOns[${index}].totalSalePrice.company`}
              // defaultValue={addOn.retailPrice}
            />
          ),
        };
      },
    },
    {
      title: "Shop Percent",
      dataIndex: "shopPercent",
      key: "shopPercent",
      render: (_, record, index) => (
        <Controller
          render={({ field, name }) => (
            <input
              className="form-control  custom-input"
              name={name}
              type="number"
              {...field}
              onChange={(e) => {
                const percent = e.target.value;
                setShopPercent(percent, index);
              }}
            />
          )}
          control={control}
          name={`customAddOns[${index}].shopPercent`}
        />
      ),
    },
    {
      title: "Shop Total",
      dataIndex: "totalSalePrice",
      key: "totalSalePrice",
      render: (_, record, index) => {
        return {
          props: {
            style: {
              background: "#dbdbdb",
            },
          },
          children: (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  readOnly
                  className={`form-control custom-input readonlyinput`}
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  // onValueChange={({ value }) => handleRetailPriceChange(value, index)}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
              control={control}
              name={`customAddOns[${index}].totalSalePrice.shop`}
              // defaultValue={addOn.retailPrice}
            />
          ),
        };
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record, index) => (
        <button className="btn btn-danger float-end" onClick={(e) => handleDeleteLine(e, fields[index].id)}>
          Delete
        </button>
      ),
    },

    // ... other columns with render functions
  ];

  const data = fields.map((field, index) => {
    return {
      key: field.id,
      name: field.name,
      quantity: field.quantity,
      price: field.storMorCustomAddOnsSalePrice,
      total: field.storMorCustomAddOnsTotalSalePrice,
      shopsPercent: field.shopCustomAddOnPercentOfRetail,
      shopTotal: field.shopCustomAddOnsTotalSalePrice,
    };
  });

  const handleDeleteLine = (e, fieldId) => {
    e.preventDefault();
    const index = fields.findIndex((field) => field.id === fieldId);
    remove(index);
    setTotals(getValues, setValue, shopSettings, companySettings);
  };

  // ... other functions
  const handleAddNewLine = (e) => {
    e.preventDefault();
    append({
      name: "",
      quantity: 0,
      retailPrice: 0,
      totalSalePrice: { shop: 0, company: 0 },
      storMorCustomAddOnsTotalSalePrice: 0,
      shopPercent: 75,
      totalMaterialCost: {
        shop: 0,
        standard: 0,
      },
      totalLaborCost: {
        shop: 0,
        standard: 0,
      },
      totalCost: {
        shop: 0,
        standard: 0,
      },
      profit: {
        shop: 0,
        standard: 0,
      },
      profitPercent: {
        shop: 0,
        standard: 0,
      },
    });
  };

  const formValues = watch(); // Get the current form values

  return (
    <div className=" mt-4">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <NestedTableRow
              record={record}
              control={control}
              formValues={formValues}
              getValues={getValues}
              setValue={setValue}
              shopSettings={shopSettings}
              companySettings={companySettings}
              fields={fields}
            />
          ),
          rowExpandable: (record) => true,
        }}
      />
      <button className="btn btn-secondary mt-3 float-end" onClick={(e) => handleAddNewLine(e)}>
        Add Custom Add-On
      </button>
    </div>
  );
}

function NestedTableRow({ record, control, formValues, getValues, setValue, shopSettings, companySettings, fields }) {
  function calculateCustomAddOnGp(shopTotalSalePrice, totalCostShop, totalCostStandard, index) {
    const shopGpDollars = shopTotalSalePrice - totalCostShop;
    const standardGpDollars = shopTotalSalePrice - totalCostStandard;
    const shopGpPercent = (shopGpDollars / shopTotalSalePrice) * 100;
    const standardGpPercent = (shopGpDollars / shopTotalSalePrice) * 100;
    setValue(`customAddOns[${index}].profit.shop`, shopGpDollars);
    setValue(`customAddOns[${index}].profit.standard`, standardGpDollars);
    setValue(`customAddOns[${index}].profitPercent.shop`, shopGpPercent);
    setValue(`customAddOns[${index}].profitPercent.standard`, standardGpPercent);
  }

  const handleMaterialCostChange = (value, fieldId) => {
    const index = fields.findIndex((field) => field.id === fieldId);
    const shopTotalSalePrice = getValues(`customAddOns[${index}].totalSalePrice.shop`);
    const totalLaborCostShop = getValues(`customAddOns[${index}].totalLaborCost.shop`);
    const totalLaborCostStandard = getValues(`customAddOns[${index}].totalLaborCost.standard`);
    const totalCostShop = +value + +totalLaborCostShop;
    const totalCostStandard = +value + +totalLaborCostStandard;
    calculateCustomAddOnGp(shopTotalSalePrice, totalCostShop, totalCostStandard, index);
    setValue(`customAddOns[${index}].totalMaterialCost.shop`, +value);
    setValue(`customAddOns[${index}].totalMaterialCost.standard`, +value);
    setValue(`customAddOns[${index}].totalCost.shop`, +totalCostShop);
    setValue(`customAddOns[${index}].totalCost.standard`, +totalCostStandard);
    setTotals(getValues, setValue, shopSettings, companySettings);
  };

  const handleLaborCostChange = (value, fieldId) => {
    const index = fields.findIndex((field) => field.id === fieldId);
    const shopTotalSalePrice = getValues(`customAddOns[${index}].totalSalePrice.shop`);
    const totalMaterialCostShop = getValues(`customAddOns[${index}].totalMaterialCost.shop`);
    const totalMaterialCostStandard = getValues(`customAddOns[${index}].totalMaterialCost.standard`);
    const totalCostShop = +value + +totalMaterialCostShop;
    const totalCostStandard = +value + +totalMaterialCostStandard;
    calculateCustomAddOnGp(shopTotalSalePrice, totalCostShop, totalCostStandard, index);
    setValue(`customAddOns[${index}].totalLaborCost.shop`, +value);
    setValue(`customAddOns[${index}].totalLaborCost.standard`, +value);
    setValue(`customAddOns[${index}].totalCost.shop`, +totalCostShop);
    setValue(`customAddOns[${index}].totalCost.standard`, +totalCostStandard);
    setTotals(getValues, setValue, shopSettings, companySettings);
  };
  const nestedColumns = [
    {
      title: "Name",
      dataIndex: "attribute",
      key: "attribute",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value, nestedRecord) => {
        if (nestedRecord.key === "8") {
          return (
            <NumberFormat
              value={value}
              readOnly
              className={`form-control custom-input readonlyinput`}
              type="text"
              thousandSeparator={true}
              suffix={" % "}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          );
        } else if (nestedRecord.key === "4") {
          return (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  className={`form-control custom-input`}
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={({ value }) => handleMaterialCostChange(value, record.key)}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
              control={control}
              name={`customAddOns[${record.key}].totalMaterialCost.shop`}
              // defaultValue={addOn.retailPrice}
            />
          );
        } else if (nestedRecord.key === "5") {
          return (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  className={`form-control custom-input`}
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={({ value }) => handleLaborCostChange(value, record.key)}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
              control={control}
              name={`customAddOns[${record.key}].totalLaborCost.shop`}
              // defaultValue={addOn.retailPrice}
            />
          );
        } else {
          return (
            <NumberFormat
              value={value}
              readOnly
              className={`form-control custom-input readonlyinput`}
              type="text"
              thousandSeparator={true}
              prefix={"$ "}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          );
        }
      },
    },
  ];

  const nestedData = [
    // ...
    {
      key: "4",
      attribute: "Material Cost",
      // value: formValues.customAddOns[record.key]?.totalMaterialCost.shop,
      value: formValues.customAddOns[fields.findIndex((field) => field.id === record.key)]?.totalMaterialCost.shop,
    },
    {
      key: "5",
      attribute: "Labor Cost",
      value: formValues.customAddOns[fields.findIndex((field) => field.id === record.key)]?.totalLaborCost.shop,
    },
    {
      key: "6",
      attribute: "Total Cost",
      value: formValues.customAddOns[fields.findIndex((field) => field.id === record.key)]?.totalCost.shop,
    },
    {
      key: "7",
      attribute: "Gross Profit",
      value: formValues.customAddOns[fields.findIndex((field) => field.id === record.key)]?.profit.shop,
    },
    {
      key: "8",
      attribute: "Gross Profit Percent",
      value: formValues.customAddOns[fields.findIndex((field) => field.id === record.key)]?.profitPercent.shop,
    },
    // ...
  ];

  // Rest of the component

  return <Table columns={nestedColumns} dataSource={nestedData} pagination={false} />;
}
