import {
  ADD_SHOP,
  DELETE_SHOP,
  CLEAR_SHOPS,
  SET_CURRENT_SHOP,
  CLEAR_CURRENT_SHOP,
  UPDATE_SHOP,
  SHOP_ERROR,
  GET_SHOPS,
  SET_SHOPS,
  FILTER_SHOPS,
  CLEAR_FILTER_SHOP,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_SHOPS:
      return {
        ...state,
        shops: action.payload,
        loading: false,
      };
    case ADD_SHOP:
      return {
        ...state,
        shop: action.payload,
        loading: false,
      };
    case UPDATE_SHOP:
      return {
        ...state,
        shops: state.shops && state.shops.map((shop) => (shop._id === action.payload._id ? action.payload : shop)),
        loading: false,
      };
    case DELETE_SHOP:
      return {
        ...state,
        shops: state.shops.filter((shop) => shop._id !== action.payload),
        loading: false,
      };
    case CLEAR_SHOPS:
      return {
        ...state,
        shops: null,
        filteredShop: null,
        error: null,
        currentShop: null,
      };
    case SET_CURRENT_SHOP:
      return {
        ...state,
        currentShop: action.payload,
      };

    case SET_SHOPS:
      return {
        ...state,
        shops: action.payload,
      };
    case CLEAR_CURRENT_SHOP:
      return {
        ...state,
        currentShop: null,
      };

    case SHOP_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FILTER_SHOPS:
      return {
        ...state,
        filteredShop: state.shops.filter((shop) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          console.log("this is the search shop", action.payload, shop);
          return shop.name.value.match(regex);
        }),
      };
    case CLEAR_FILTER_SHOP:
      return {
        ...state,
        filteredProduct: null,
      };
    default:
      return state;
  }
};
