import React, { Fragment, useContext, useEffect, useState } from "react";
import AlertContext from "../../context/alert/alertContext";

import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

const Alerts = () => {
  const [counter, setCounter] = useState(0);
  const alertContext = useContext(AlertContext);
  const { alerts, removeAlert, invoiceAlerts, updateInvoiceAlert, getInvoiceAlerts } = alertContext;

  const handleClearAlert = (id) => {
    updateInvoiceAlert(id);
  };
  const handleRemoveAlert = (id) => {
    removeAlert(id);
  };

  useEffect(() => {
    getInvoiceAlerts();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter(counter + 1);
      getInvoiceAlerts();
    }, 3600000);

    return () => clearInterval(intervalId);
  }, [counter]);

  return (
    <Fragment>
      {invoiceAlerts &&
        invoiceAlerts.length > 0 &&
        invoiceAlerts.map((alert) => (
          <div key={alert.id} className={`alert alert-${alert.type}`}>
            <div className="row">
              <div className="col-md-1">
                <InfoIcon fontSize="medium" />
              </div>
              <div className="col-md-8 myFont">{alert.msg}</div>

              <div className="col-md-3">
                <CloseIcon onClick={() => handleClearAlert(alert.id)} className="float-end pointer" />
              </div>
            </div>
          </div>
        ))}
      {alerts &&
        alerts.length > 0 &&
        alerts.map((alert) => (
          <div key={alert.id} className={`alert alert-${alert.type}`}>
            <div className="row">
              <div className="col-md-1">
                <InfoIcon fontSize="medium" />
              </div>
              <div className="col-md-8 myFont">{alert.msg}</div>

              <div className="col-md-3">
                <CloseIcon onClick={() => handleRemoveAlert(alert.id)} className="float-end pointer" />
              </div>
            </div>
          </div>
        ))}
    </Fragment>
  );
};

export default Alerts;
