import React, { Fragment, useState, useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Checkbox, Card, Button, Row, Col, Typography } from "antd";

const { Title } = Typography;

const Items = ({ item, itemIndex, control, remove, setValue, getValues, itemOptions, register }) => {
  const [replaceItemValue, setReplaceItemValue] = useState(false);

  const setItemCount = (quantity) => {
    setValue(`shopItems[${itemIndex}].quantity`, quantity);
  };

  useEffect(() => {
    setReplaceItemValue(item.replaceItem);
  }, [item]);

  const onRemoveItem = () => {};

  const setItemChange = (e) => {
    setValue(`shopItems[${itemIndex}].item`, e);
    setValue(`shopItems[${itemIndex}].quantity`, 0);
  };

  const setReplacementItemChange = (e) => {
    setValue(`shopItems[${itemIndex}].replacementItem`, e);
  };

  const productOptions =
    itemOptions &&
    itemOptions.map((item) => {
      const actualItem = item.item ? item.item : item;
      return { label: actualItem.name, value: actualItem };
    });

  return (
    <Card
      style={{ marginBottom: "2px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", border: "1px solid #e8e8e8", borderRadius: "8px" }}
    >
      <Row gutter={16}>
        <Col span={6}>
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={productOptions}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={(field.value && { label: field.value.name, value: field.value }) || null}
                onChange={(e) => setItemChange(e.value)}
              />
            )}
            key={item.id}
            name={`shopItems[${itemIndex}].item`}
            control={control}
          />
        </Col>
        <Col span={4}>
          <Controller
            render={({ field, name }) => (
              <input
                className="form-control inputbg"
                type="number"
                name={name}
                {...field}
                onChange={(e) => {
                  const quantity = e.target.value;
                  setItemCount(quantity);
                }}
              />
            )}
            key={item.id}
            control={control}
            name={`shopItems[${itemIndex}].quantity`}
          />
        </Col>
        <Col span={2} className="text-center">
          <Controller
            control={control}
            name={`shopItems[${itemIndex}].replaceItem`}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Checkbox
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                  setReplaceItemValue(e.target.checked);
                }}
                checked={value}
                inputRef={ref}
              />
            )}
          />
        </Col>
        {replaceItemValue ? (
          <Col span={6}>
            <Controller
              render={({ field }) => {
                const selectedItem = field.value
                  ? productOptions && productOptions.find((option) => option.value._id === field.value._id)
                  : null;
                return (
                  <ReactSelect
                    {...field}
                    options={productOptions}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    value={selectedItem}
                    onChange={(e) => setReplacementItemChange(e.value)}
                  />
                );
              }}
              key={item.id}
              name={`shopItems[${itemIndex}].replacementItem`}
              control={control}
            />
          </Col>
        ) : (
          <Col span={6}></Col>
        )}
        <Col span={6} className="text-end">
          <Button
            type="danger"
            onClick={() => {
              remove(itemIndex);
              onRemoveItem();
            }}
          >
            Delete
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default function Fields({ control, register, setValue, getValues, items }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "shopItems",
  });

  return (
    <div className="container-fluid">
      <Row className="mt-2">
        <Col span={6} className="text-center">
          <Title level={5}>Item Name</Title>
        </Col>
        <Col span={4} className="text-center">
          <Title level={5}>Count</Title>
        </Col>
        <Col span={2} className="text-center">
          <Title level={5}>Replace Item</Title>
        </Col>
        <Col span={6} className="text-center">
          <Title level={5}>Replace This Item</Title>
          <p>(This item will be replaced in all the components)</p>
        </Col>
        <Col span={6} className="text-end">
          <Title level={5}>Actions</Title>
        </Col>
      </Row>

      {fields.map((item, itemIndex) => (
        <Items
          key={item.id}
          item={item}
          itemIndex={itemIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          itemOptions={items}
        />
      ))}
      <Button
        type="dashed"
        className="mb-2 mt-2"
        onClick={(e) => {
          e.preventDefault();
          append({ value: "0" });
        }}
      >
        Add Item
      </Button>
    </div>
  );
}
