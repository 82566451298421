import React, { Fragment, useEffect, useContext } from "react";
import ShopContext from "../../../../context/shop/shopContext";
import ShopColumns from "./ShopColumns";

const AllShops = () => {
  const shopContext = useContext(ShopContext);
  const { getShops, shops, filteredShop } = shopContext;

  useEffect(() => {
    getShops();
  }, []);

  useEffect(() => {}, [filteredShop]);

  const theTitle = "Shops";

  return (
    <Fragment>
      <div>
        <ShopColumns shops={shops} theTitle={theTitle} filteredShop={filteredShop} />
      </div>
    </Fragment>
  );
};

export default AllShops;
