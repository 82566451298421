import React from "react";
import NewInvoiceForm from "./CompanyRepairNewInvoiceForm";
import { Modal } from "antd";

const CompanyRepairNewInvoiceModal = ({ setModalVisible, modalVisible }) => {
  const handleModalCancel = () => {
    setModalVisible(false);
  };
  return (
    <Modal
      // title='Modal 1000px width'
      centered
      visible={modalVisible}
      // onOk={() => setModalVisible(false)}
      onCancel={handleModalCancel}
      bodyStyle={{ height: "80vh" }}
      width={1200}
      footer={null}
    >
      <NewInvoiceForm setModalVisible={setModalVisible} />
    </Modal>
  );
};

export default CompanyRepairNewInvoiceModal;
