import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CompanyContext from "../../../../context/company/companyContext";
import SettingsForm from "./SettingsForm";

const defaultValues = {
  nonInvAddOns: [],
};

const Settings = () => {
  const navigate = useNavigate();
  const companyContext = useContext(CompanyContext);
  const { getCompany } = companyContext;

  useEffect(() => {
    getCompany();
  }, []);

  return <SettingsForm />;
};

export default Settings;
