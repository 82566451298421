import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    display: "flex",
  },
  row: {
    flexDirection: "row",
  },

  boxone: {
    paddingTop: 20,
    order: 1,
    flex: 2,

    fontFamily: "Helvetica",
  },

  boxtwo: {
    paddingTop: 20,
    order: 2,
    flex: 1,
    justifyContent: "flex-end",
  },
  textrow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 10,
    marginTop: 2,
  },
  textrowtwo: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 10,
    marginTop: 2,
    fontFamily: "Helvetica-Bold",
  },

  image: {
    width: "75px",
    height: "75px",
    padding: 5,
  },
});

const BillTo = ({ values, currentShop }) => {
  const { createdAt } = values;
  const theDate = new Date();

  const date = new Date((createdAt && createdAt) || theDate).toLocaleDateString();

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={styles.boxone}>
          <Text style={styles.textrowtwo}>COMPANY</Text>
          <Text style={styles.textrow}>{currentShop.name} </Text>
          <Text style={styles.textrow}>{currentShop.streetAddress} </Text>
          <Text style={styles.textrow}>
            {currentShop.city} {currentShop.state}, {currentShop.zipCode}{" "}
          </Text>
        </View>
        <View style={styles.boxtwo}>
          <Text style={styles.textrowtwo}>VENDOR</Text>
          <Text style={styles.textrow}>{values.vendor.name} </Text>
          <Text style={styles.textrow}>{values.vendor.address}</Text>
          <Text style={styles.textrow}>
            {values.vendor.city} {values.vendor.state}, {values.vendorzipCode}
          </Text>
          <Text style={styles.textrow}>{values.vendor.email}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.boxone}>
          <Text style={styles.textrow}>PO #: {values.purchaseOrderNumber} </Text>
          <Text style={styles.textrow}>Date: {date} </Text>
        </View>
      </View>
    </View>
  );
};

export default BillTo;
