import React, { useEffect, useContext } from "react";
import AddOnLaborContext from "../../../../../../context/addOnLabor/addOnLaborContext";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Form, Input, Button, Card, Typography, Row, Col } from "antd";

const { Title } = Typography;

const defaultValues = {
  name: "",
  category: null,
  standardLaborType: "",
};

const ShopAddOnLaborForm = () => {
  const navigate = useNavigate();
  const addOnLaborContext = useContext(AddOnLaborContext);
  const { addAddOnLabor, currentAddOnLabor, clearCurrentAddOnLabor, updateShopAddOnLabor } = addOnLaborContext;
  const addonCategoryContext = useContext(AddonCategoryContext);
  const { currentAddonCategory } = addonCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    currentAddOnLabor && reset(currentAddOnLabor);
    currentAddonCategory && setValue("category", currentAddonCategory._id);
  }, []);

  const onSubmit = (data) => {
    if (!currentAddOnLabor) {
      addAddOnLabor(data);
      navigate(-1);
    } else {
      updateShopAddOnLabor(data);
      navigate(-1);
      clearCurrentAddOnLabor();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentAddOnLabor();
  };

  const handleLaborTypeChange = (e) => {
    setValue("shopValues.laborType", e);
  };

  const laborTypeOptions = [
    { label: "Piece", value: "piece" },
    { label: "Percent", value: "percent" },
    { label: "SqFt", value: "sqft" },
    { label: "Linear Ft", value: "linearFt" },
  ];

  useEffect(() => {
    if (currentAddOnLabor && currentAddOnLabor.shopValues.laborType && laborTypeOptions) {
      const selectedShedLabor = laborTypeOptions.find((option) => option.value === currentAddOnLabor.shopValues.laborType);
      setValue("shopValues.laborType", selectedShedLabor);
    }
  }, [currentAddOnLabor]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid pb-4">
      <Row justify="center">
        <Col span={24}>
          <Button type="danger" className="float-end" onClick={setExit}>
            Back
          </Button>
        </Col>
      </Row>
      <Row justify="center" className="mt-4">
        <Col span={12}>
          <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", border: "1px solid #e8e8e8", borderRadius: "8px" }}>
            <Title level={4} className="text-center">
              {currentAddOnLabor ? "Update Add-On Labor" : "Add Add-On Labor"}
            </Title>
            <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
              <Form.Item label="Name">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => <Input {...field} readOnly />}
                  rules={{ required: true }}
                />
              </Form.Item>
              <Form.Item label="Labor Type">
                <Controller
                  name="shopValues.laborType"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      isClearable={true}
                      options={laborTypeOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={handleLaborTypeChange}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item label="Labor">
                <Controller
                  name="shopValues.laborTotal"
                  control={control}
                  render={({ field }) => <Input {...field} />}
                  rules={{ required: true }}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="float-end">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ShopAddOnLaborForm;
