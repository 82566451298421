import {
  ADD_PAYMENT,
  DELETE_PAYMENT,
  CLEAR_PAYMENTS,
  SET_CURRENT_PAYMENT,
  CLEAR_CURRENT_PAYMENT,
  UPDATE_PAYMENT,
  FILTER_PAYMENTS,
  CLEAR_FILTER_PAYMENT,
  PAYMENT_ERROR,
  GET_PAYMENTS,
  STATUS_CHANGE_PAYMENT,
  FILTER_PAYMENTS_BY_SHOP,
  SET_LOADING_PAYMENTS,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
        loading: false,
      };
    case ADD_PAYMENT:
      return {
        ...state,
        payment: action.payload,
        loading: false,
      };
    case UPDATE_PAYMENT:
      return {
        ...state,
        payments: state.payments.map((payment) => (payment._id === action.payload._id ? action.payload : payment)),
        loading: false,
      };
    case DELETE_PAYMENT:
      return {
        ...state,
        payments: state.payments.filter((payment) => payment._id !== action.payload),
        loading: false,
      };
    case CLEAR_PAYMENTS:
      return {
        ...state,
        payments: null,
        filtererdPayment: null,
        error: null,
        currentPayment: null,
      };
    case SET_CURRENT_PAYMENT:
      return {
        ...state,
        currentPayment: action.payload,
      };
    case CLEAR_CURRENT_PAYMENT:
      return {
        ...state,
        currentPayment: null,
      };
    case FILTER_PAYMENTS:
      return {
        ...state,
        filteredPayment: state.payments.filter((payment) => {
          const regex = new RegExp(`${action.payload}`, 'gi');
          return payment.customer.value.name.match(regex);
        }),
      };
    case FILTER_PAYMENTS_BY_SHOP:
      return {
        ...state,
        filteredPayment: state.payments.filter((payment) => {
          const regex = new RegExp(`${action.payload}`, 'gi');
          return payment.madeBy.name.match(regex);
        }),
        shop: action.payload,
      };
    case CLEAR_FILTER_PAYMENT:
      return {
        ...state,
        filteredPayment: null,
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case STATUS_CHANGE_PAYMENT:
      return {
        ...state,
        payments: state.payments.filter((payment) => payment._id !== action.payload._id),
        filteredPayment: state.filteredPayment.filter((payment) => payment._id !== action.payload._id),
        loading: false,
      };
    case SET_LOADING_PAYMENTS:
      return {
        ...state,
        loadingPayments: action.payload,
      };
    default:
      return state;
  }
};
