import React, { Fragment } from "react";
import { View, StyleSheet, Page, Text } from "@react-pdf/renderer";
import AddOns from "./AddOns";
import CustomAddOns from "./CustomAddOns";

const borderColor = "#90e5fc";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,

    borderColor: "#bff0fd",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    // backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    // height: 24,
    textAlign: "center",
    fontStyle: "bold",
    paddingBottom: 10,
    paddingTop: 4,
  },
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  sku: {
    width: "35%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  textrow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 10,
    marginTop: 2,
  },
  boxone: {
    paddingTop: 20,
    order: 1,
    flex: 2,
    fontFamily: "Helvetica",
  },

  titleContainer: {
    flexDirection: "row",
    marginTop: 5,
  },
  title: {
    color: "#61dafb",
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
  },
  headerRow: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
  },
  headerItem: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerQty: {
    width: "30%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  item: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  grandTotal: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  qty: {
    width: "30%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const InvoiceItemsTable = ({ values }) => {
  // console.log("this is the payment", values);

  const rows = values.invoices.map((invoice) => (
    <View key={invoice._id} break>
      <View>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>Invoice</Text>
        </View>

        <View style={styles.boxone}>
          <Text style={styles.textrow}>Invoice #: {invoice.invoice.invoiceNumber} </Text>
          <Text style={styles.textrow}>Date: {new Date(invoice.invoice.invoicedDate).toLocaleDateString()} </Text>
          <Text style={styles.textrow}>Bill To: {invoice.invoice.billTo} </Text>
        </View>

        <View style={styles.headerRow}>
          <Text style={styles.headerItem}>Item</Text>
          <Text style={styles.headerQty}>QTY</Text>
          {/* <Text style={styles.headerQty}>Rate</Text> */}
          <Text style={styles.headerQty}>Total</Text>
        </View>
        <View style={styles.container}>
          <View style={styles.row}>
            <Text style={styles.item}>{invoice.invoice.order.serialNumber}</Text>
            <Text style={styles.qty}>1</Text>
            <Text style={styles.qty}>
              {invoice.invoice.order.basePrice.shop.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Text>
          </View>
          <AddOns invoice={invoice} />
          <CustomAddOns invoice={invoice} />
        </View>
        <View style={styles.grandTotal}>
          <Text>
            Sub Total:{" "}
            {invoice.invoice.order.subTotal?.shop.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}
          </Text>
        </View>
        <View style={styles.grandTotal}>
          <Text>
            Adjustments:{" "}
            {invoice.invoice.order.adjustment.shop &&
              invoice.invoice.order.adjustment.shop.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}{" "}
          </Text>
        </View>
        <View style={styles.grandTotal}>
          <Text>
            Grand Total:{" "}
            {invoice.invoice.order.grandTotal.shop.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}
          </Text>
        </View>
      </View>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
  // <Page size="A4" style={styles.page} wrap>
  //   <View style={styles.tableContainer}>
  //     <InvoiceTableRow payment={values} />
  //   </View>
  // </Page>
};

export default InvoiceItemsTable;
