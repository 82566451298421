import React, { Fragment, useState, useContext } from "react";
import { Popconfirm } from "antd";
import OrderContext from "../../../../../context/order/orderContext";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";

const PopConfirm = ({ record }) => {
  const [visible, setVisible] = useState(false);
  const [onHoldVisible, setOnHoldVisible] = useState(false);
  const [onHoldBuildVisible, setOnHoldBuildVisible] = useState(false);
  const [finishVisible, setFinishVisible] = useState(false);
  const orderContext = useContext(OrderContext);
  const { updateOrder } = orderContext;

  let message = `Move to ${
    record.status === "InQueue"
      ? "Building"
      : record.status === "On Hold"
      ? "Building"
      : record.status === "Building"
      ? "Finished"
      : record.status === "Finished"
      ? "Invoiced"
      : ""
  }`;

  const handleShowPopConfirm = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    handleStatusChange();
  };

  const handleShowPopConfirmPutOnHold = () => {
    setOnHoldVisible(true);
  };
  const handleShowPopConfirmBuild = () => {
    setOnHoldBuildVisible(true);
  };
  const handleShowPopConfirmFinish = () => {
    setFinishVisible(true);
  };
  const handleOnHoldBuildCancel = () => {
    setOnHoldBuildVisible(false);
  };

  const handleOnHoldBuildOk = () => {
    handleOnHoldStatusBuildChange();
  };
  const handleOnHoldCancel = () => {
    setOnHoldVisible(false);
  };

  const handleOnHoldOk = () => {
    handleOnHoldStatusChange();
  };

  const handleOnHoldStatusBuildChange = () => {
    if (record.status === "On Hold") {
      let newRecord = {
        ...record,
        status: "Building",
        isBuilding: true,
        originalOrder: { ...record },
      };
      updateOrder(newRecord);
    }
  };

  const handleOnHoldStatusChange = () => {
    if (record.status === "InQueue") {
      let newRecord = {
        ...record,
        status: "On Hold",
        originalOrder: { ...record },
      };
      updateOrder(newRecord);
    } else if (record.status === "On Hold") {
      let newRecord = {
        ...record,
        status: "InQueue",
        originalOrder: { ...record },
      };
      updateOrder(newRecord);
    }
  };

  const handleStatusChange = () => {
    if (record.status === "InQueue") {
      let newRecord = {
        ...record,
        status: "Building",
        isBuilding: true,
        originalOrder: { ...record },
      };
      updateOrder(newRecord);
    } else if (record.status === "On Hold") {
      let newRecord = {
        ...record,
        status: "Building",
        isBuilding: true,
        originalOrder: { ...record },
      };
      updateOrder(newRecord);
    } else if (record.status === "Building") {
      console.log("this is the order in building");
      let theDate = new Date();
      let newRecord = {
        ...record,
        status: "Finished",
        dateFinished: theDate,
        originalOrder: { ...record },
      };
      updateOrder(newRecord);
    } else {
      let newRecord = {
        ...record,
        // status: "Invoiced",
        originalOrder: { ...record },
      };
      updateOrder(newRecord);
    }
  };
  let menuItems = [];

  if (record.status === "InQueue") {
    menuItems = [
      {
        label: (
          <Fragment>
            <Popconfirm
              title={"Move to On Hold"}
              visible={onHoldVisible}
              onConfirm={handleOnHoldStatusChange}
              onCancel={handleOnHoldCancel}
              okText="Yes"
            ></Popconfirm>
            <button type="button" className="btn btn-info btn-sm" onClick={(e) => handleShowPopConfirmPutOnHold()}>
              Move to On Hold
            </button>
          </Fragment>
        ),
        key: "1",
      },
      {
        label: (
          <Fragment>
            <Popconfirm
              title={"Move to Building"}
              visible={visible}
              onConfirm={handleOk}
              onCancel={handleCancel}
              okText="Yes"
            ></Popconfirm>
            <button type="button" className="btn btn-success btn-sm" onClick={(e) => handleShowPopConfirm()}>
              Move to Building
            </button>
          </Fragment>
        ),
        key: "2",
      },
    ];
  } else if (record.status === "On Hold") {
    menuItems = [
      {
        label: (
          <Fragment>
            <Popconfirm
              title={"Move to InQueue"}
              visible={onHoldVisible}
              onConfirm={handleOnHoldStatusChange}
              onCancel={handleOnHoldCancel}
              okText="Yes"
            ></Popconfirm>
            <button type="button" className="btn btn-info btn-sm" onClick={(e) => handleShowPopConfirmPutOnHold()}>
              Move to InQueue
            </button>
          </Fragment>
        ),
        key: "1",
      },
      {
        label: (
          <Fragment>
            <Popconfirm
              title={"Move to Building"}
              visible={onHoldBuildVisible}
              onConfirm={handleOnHoldStatusBuildChange}
              onCancel={handleOnHoldBuildCancel}
              okText="Yes"
            ></Popconfirm>
            <button type="button" className="btn btn-success btn-sm" onClick={(e) => handleShowPopConfirmBuild()}>
              Move to Building
            </button>
          </Fragment>
        ),
        key: "2",
      },
    ];
  } else if (record.status === "Building") {
    menuItems = [
      {
        label: (
          <Fragment>
            <Popconfirm
              title={"Move to Finished"}
              visible={finishVisible}
              onConfirm={handleOk}
              onCancel={handleCancel}
              okText="Yes"
            ></Popconfirm>
            <button className="btn btn-success btn-sm" type="button" onClick={(e) => handleShowPopConfirmFinish()}>
              Move to Finished
            </button>
          </Fragment>
        ),
        key: "0",
      },
    ];
  }

  return (
    <Fragment>
      {record.status !== "Invoiced" && record.status !== "Finished" && (
        <Dropdown className="btn btn-xs  btn-outline-primary " overlay={<Menu items={menuItems} />} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              Change Status
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      )}
    </Fragment>
  );
};

export default PopConfirm;
