import {
  ADD_ADDONPARENT_CATEGORY,
  DELETE_ADDONPARENT_CATEGORY,
  CLEAR_ADDONPARENT_CATEGORYS,
  SET_CURRENT_ADDONPARENT_CATEGORY,
  CLEAR_CURRENT_ADDONPARENT_CATEGORY,
  UPDATE_ADDONPARENT_CATEGORY,
  FILTER_ADDONPARENT_CATEGORYS,
  CLEAR_FILTER_ADDONPARENT_CATEGORY,
  ADDONPARENT_CATEGORY_ERROR,
  GET_ADDONPARENT_CATEGORYS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ADDONPARENT_CATEGORYS:
      return {
        ...state,
        addonParentCategorys: action.payload,
        loadingAddonParentCategory: false,
      };

    case ADD_ADDONPARENT_CATEGORY:
      return {
        ...state,
        addonParentCategory: action.payload,
        loading: false,
      };
    case UPDATE_ADDONPARENT_CATEGORY:
      const updatedAddonParentCategorys = state.addonParentCategorys.map((addonParentCategory) =>
        addonParentCategory._id === action.payload._id ? action.payload : addonParentCategory
      );
      return {
        ...state,
        addonParentCategorys: updatedAddonParentCategorys,
        loading: false,
      };
    case DELETE_ADDONPARENT_CATEGORY:
      return {
        ...state,
        addonParentCategorys: state.addonParentCategorys.filter((addonParent) => addonParent._id !== action.payload),
        loading: false,
      };
    case CLEAR_ADDONPARENT_CATEGORYS:
      return {
        ...state,
        addonParentCategorys: null,
        filteredAddonParentCategory: null,
        error: null,
        currentAddonParentCategory: null,
      };
    case SET_CURRENT_ADDONPARENT_CATEGORY:
      return {
        ...state,
        currentAddonParentCategory: action.payload,
      };
    case CLEAR_CURRENT_ADDONPARENT_CATEGORY:
      return {
        ...state,
        currentAddonParentCategory: null,
      };
    case FILTER_ADDONPARENT_CATEGORYS:
      return {
        ...state,
        filteredAddonParentCategory: state.AddonParentCategorys.filter((addonParentCategory) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return addonParentCategory.customer.value.name.match(regex);
        }),
      };
    case CLEAR_FILTER_ADDONPARENT_CATEGORY:
      return {
        ...state,
        filteredAddonParentCategory: null,
      };
    case ADDONPARENT_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
