import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import ShopContext from "../../../../context/shop/shopContext";
import PopConfirm from "./PopConfirm";
import ShopFilter from "../../../../context/invoice/ShopFilter";
import { Table, Tag, Typography } from "antd";
import GlobalInvoiceSearch from "../../../../context/invoice/GlobalInvoiceSearch";
import axios from "axios";

const CompanyAdjustedInvoices = () => {
  const navigate = useNavigate();
  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const invoiceContext = useContext(InvoiceContext);
  const { getInvoicesByStatus, invoices, getInvoiceById, clearInvoices, searchedInvoice, invoiceLoading, filteredInvoices } =
    invoiceContext;

  let theInvoices = invoices;

  if (currentShop) {
    theInvoices =
      invoices &&
      invoices.filter((invoice) => {
        return invoice.shop._id === currentShop._id;
      });
  }

  useEffect(() => {}, [filteredInvoices, invoices, searchedInvoice]);

  useEffect(() => {
    getInvoicesByStatus("Adjusted");
    return () => {
      clearInvoices();
      // cancelToken.cancel();
    };
  }, []);

  const handleViewInvoice = (invoice) => {
    getInvoiceById(invoice._id);
  };

  const calculateTotalAmount = (data) => {
    return data.reduce((total, invoice) => total + (invoice.order?.grandTotal?.shop || 0), 0);
  };

  const columns = [
    {
      title: `Shop`,
      sorter: (a, b) => b.shop.name > a.shop.name,
      defaultSortOrder: ["descend"],
      render: (text, record) => <span>{record.shop.name}</span>,
    },
    {
      title: `Invoice Number`,
      render: (text, record) => <span>{record.invoiceNumber}</span>,
    },
    {
      title: `Invoiced Date`,
      sorter: (a, b) => b.invoicedDate > a.invoicedDate,
      defaultSortOrder: ["descend"],
      render: (text, record) => <span>{record.invoicedDate && new Date(record.invoicedDate).toLocaleDateString()}</span>,
    },
    {
      title: "Status",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          <Tag className="" color="volcano">
            {record && record.status}
          </Tag>
        </Fragment>
      ),
    },
    {
      title: `Amount`,
      dataIndex: ["order", "grandTotal", "shop"],
      render: (text, record) => (
        <span>
          {record.order?.grandTotal?.shop?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
    // {
    //   title: `Standard Total Cost`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order.standardTotalCost.toLocaleString("en-US", {
    //         style: "currency",
    //         currency: "USD",
    //       })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Standard Profit $`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order.standardProfitDollars.toLocaleString('en-US', {
    //         style: 'currency',
    //         currency: 'USD',
    //       })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Standard Profit %`,
    //   render: (text, record) => <span>{record.order && record.order.standardProfitPercent.toFixed(2)}%</span>,
    // },

    {
      title: "Actions",
      width: "15%",
      align: "center",
      render: (text, record) => (
        <Fragment>
          <div className="flexcontainer">
            <PopConfirm
              record={record}
              msg={"Approve Invoice?"}
              btnText={"Approve"}
              theStatus={"Approved"}
              myClasses={"btn btn-xs btn-outline-success"}
            />
            <PopConfirm
              record={record}
              msg={"Deny Invoice?"}
              btnText={"Deny"}
              theStatus={"Denied"}
              myClasses={"btn btn-xs btn-outline-danger"}
            />

            <button className="btn btn-xs btn-outline-primary" type="button" onClick={(e) => handleViewInvoice(record, e)}>
              View
            </button>
          </div>
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const summary = (pageData) => {
    const total = calculateTotalAmount(pageData);
    return (
      <>
        <Table.Summary.Row>
          {columns.map((column, index) => {
            if (column.title === "Shop") {
              return (
                <Table.Summary.Cell index={index} key={index}>
                  <Typography.Text strong>Total</Typography.Text>
                </Table.Summary.Cell>
              );
            }
            if (column.title === "Amount") {
              return (
                <Table.Summary.Cell index={index} key={index}>
                  <Typography.Text strong>
                    {total.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography.Text>
                </Table.Summary.Cell>
              );
            }
            return <Table.Summary.Cell index={index} key={index} />;
          })}
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 text-center">
          {filteredInvoices && (
            <h4>
              {filteredInvoices.length > 0
                ? `${currentShop && currentShop.name} has ${filteredInvoices.length} Adjusted Invoices`
                : "No Adjusted Invoices"}
            </h4>
          )}
          {!filteredInvoices && (
            <h4>{theInvoices && theInvoices.length > 0 ? theInvoices.length + " Adjusted Invoices" : "No Adjusted Invoices"}</h4>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <ShopFilter status={"Adjusted"} />
        </div>

        <div className="col-md-2"></div>

        <div className="col-md-6 mt-4">
          <GlobalInvoiceSearch />
        </div>
      </div>

      <div className="row">
        <Table
          rowClassName={() => "hover-row"}
          loading={!invoiceLoading && (searchedInvoice || filteredInvoices || theInvoices) ? false : true}
          columns={columns}
          dataSource={searchedInvoice || filteredInvoices || theInvoices}
          rowKey="_id"
          onChange={onChange}
          pagination={false}
          summary={summary}
        />
      </div>
    </div>
  );
};

export default CompanyAdjustedInvoices;
