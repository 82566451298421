import React, { useEffect, useContext, Fragment, useState } from "react";
import ItemContext from "../../../../../context/item/itemContext";
import ShopContext from "../../../../../context/shop/shopContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Checkbox, Button, Form, Input, InputNumber, Card, Typography } from "antd";
import RecieveItemIntoInventory from "./RecieveItemIntoInventory";

const { Title } = Typography;

const defaultValues = {};
const ShopItemUpdate = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const itemContext = useContext(ItemContext);
  const { updateItem, currentItem, clearCurrentItem, clearFilterItem } = itemContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const [theItem, setTheItem] = useState();

  useEffect(() => {
    currentItem && reset(currentItem);
    currentItem && setTheItem(currentItem);
  }, [currentItem]);

  const onSubmit = (data) => {
    updateItem(data, currentShop._id);
    navigate(-1);
  };

  const setItemSubTotal = (subTotal) => {
    const freight = getValues("shopValues.freight");
    setValue(`shopValues.subTotal`, +subTotal);
    setValue(`shopValues.total`, +subTotal + +freight);
  };

  const setItemFreight = (freight) => {
    const subTotal = getValues("shopValues.subTotal");
    setValue(`shopValues.freight`, +freight);
    setValue(`shopValues.total`, +subTotal + +freight);
  };

  const setExit = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-md-12">
          <Button type="primary" danger onClick={() => setExit()} style={{ float: "right", marginBottom: "20px" }}>
            Back
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Card
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              border: "1px solid #e8e8e8",
              borderRadius: "8px",
            }}
          >
            <Title level={4} style={{ marginBottom: "20px", textAlign: "center" }}>
              Update Item
            </Title>
            <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
              <Form.Item label="Name">
                <Controller
                  name="shopValues.name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>

              <Form.Item label="Type">
                <Controller name="type" control={control} render={({ field }) => <Input {...field} readOnly />} />
              </Form.Item>

              <Form.Item label="Sub-Total">
                <Controller
                  name="shopValues.subTotal"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      style={{ width: "100%" }}
                      step={0.01}
                      onChange={(value) => {
                        field.onChange(value);
                        setItemSubTotal(value);
                      }}
                    />
                  )}
                />
              </Form.Item>

              <Form.Item label="Freight">
                <Controller
                  name="shopValues.freight"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      style={{ width: "100%" }}
                      step={0.01}
                      onChange={(value) => {
                        field.onChange(value);
                        setItemFreight(value);
                      }}
                    />
                  )}
                />
              </Form.Item>

              <Form.Item label="Total">
                <Controller
                  name="shopValues.total"
                  control={control}
                  render={({ field }) => <InputNumber {...field} style={{ width: "100%" }} readOnly />}
                />
              </Form.Item>

              <Form.Item>
                <Controller
                  name="shopValues.inventoryItem"
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value}>
                      Inventory Item
                    </Checkbox>
                  )}
                />
              </Form.Item>

              <Form.Item label="Order Point Warning">
                <Controller
                  name="shopValues.orderPointWarning"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <InputNumber {...field} style={{ width: "100%" }} />}
                />
              </Form.Item>

              <Form.Item label="Order Point Critical">
                <Controller
                  name="shopValues.orderPointCritical"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <InputNumber {...field} style={{ width: "100%" }} />}
                />
              </Form.Item>

              <Form.Item label="Full Point">
                <Controller
                  name="shopValues.fullPoint"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <InputNumber {...field} style={{ width: "100%" }} />}
                />
              </Form.Item>

              <Form.Item label="On Order Quantity">
                <Controller
                  name="shopValues.quantityOnOrder"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <InputNumber {...field} style={{ width: "100%" }} />}
                />
              </Form.Item>

              <Form.Item label="Received Quantity">
                <Controller
                  name="shopValues.quantityInRecieved"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <InputNumber {...field} style={{ width: "100%" }} />}
                />
              </Form.Item>

              <Form.Item label="Current Quantity">
                <Controller
                  name="shopValues.quantityInCurrent"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <InputNumber {...field} style={{ width: "100%" }} />}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    width: "100%",
                    height: "48px",
                    fontSize: "16px",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>

        <div className="col-md-6">
          <RecieveItemIntoInventory theItem={theItem} currentShop={currentShop} />
        </div>
      </div>
    </Fragment>
  );
};

export default ShopItemUpdate;
