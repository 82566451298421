import React, { useState, useEffect, Fragment, useContext } from "react";
import { Table, Checkbox, Card, Input, Select as AntSelect } from "antd";
import styled from "styled-components";

import ShopContext from "../../../../context/shop/shopContext";
import ShedCategoryContext from "../../../../context/shedCategory/shedCategoryContext";
import ShedModelContext from "../../../../context/shedModel/shedModelContext";
import ShedContext from "../../../../context/shed/shedContext";

const StyledInput = styled(Input)`
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  padding: 8px 12px;
  transition: all 0.3s;

  &:hover,
  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const StyledSelect = styled(AntSelect)`
  width: 100%;

  .ant-select-selector {
    border-radius: 6px !important;
    padding: 8px 12px !important;
    height: auto !important;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 4px;
  }
`;

const InputLabel = styled.label`
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const CardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const Section = styled.div`
  flex: 1;
  min-width: 300px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
`;

const ShopPriceProjectionSheds = () => {
  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { shedCategorys, getShedCategorys } = shedCategoryContext;

  const shedModelContext = useContext(ShedModelContext);
  const { shedModels, getShedModels } = shedModelContext;

  const shedContext = useContext(ShedContext);
  const { getSheds, sheds, clearSheds, loadingSheds } = shedContext;

  const [eightWideRetailPercent, setEightWideRetailPercent] = useState();
  const [fourteenWideRetailPercent, setFourteenWideRetailPercent] = useState();
  const [newEightWideRetailPercent, setNewEightWideRetailPercent] = useState(60);
  const [newFourteenWideRetailPercent, setNewFourteenWideRetailPercent] = useState(60);
  const [newGrossProfitPercent, setNewGrossProfitPercent] = useState(15);
  const [discountChecked, setDiscountChecked] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [storMorSpecific, setStorMorSpecific] = useState(false);

  const [shedCategory, setShedCategory] = useState();
  const [shedModel, setShedModel] = useState();

  const handleDiscountPercentChange = (e) => {
    setDiscountPercent(e.target.value);
  };
  const handleDiscountCheck = (e) => {
    setDiscountChecked(e.target.checked);
  };
  const handleStorMorSpecific = (e) => {
    setStorMorSpecific(e.target.checked);
  };

  useEffect(() => {
    getShop();
    getShedCategorys();
    clearSheds();
  }, []);

  useEffect(() => {
    if (currentShop) {
      setEightWideRetailPercent(currentShop.eightTenTwelveINVPercent);
      setFourteenWideRetailPercent(currentShop.fourteenSixteenINVPercent);
    }
  }, [currentShop]);

  const handleGrossProfitPercent = (e) => {
    setNewGrossProfitPercent(e.target.value);
  };

  const handleEightWidePercent = (e) => {
    setEightWideRetailPercent(e.target.value);
  };

  const handleFourteenWidePercent = (e) => {
    setFourteenWideRetailPercent(e.target.value);
  };
  const handleNewEightWidePercent = (e) => {
    setNewEightWideRetailPercent(e.target.value);
  };
  const handleNewFourteenWidePercent = (e) => {
    setNewFourteenWideRetailPercent(e.target.value);
  };

  const categoryOptions = shedCategorys && shedCategorys.map((category) => ({ label: category.name, value: category._id }));

  const handleCategoryChange = (selectedCategoryId) => {
    const selectedCategory = shedCategorys.find((category) => category._id === selectedCategoryId);
    setShedCategory(selectedCategory);
    getShedModels(selectedCategoryId);
  };

  const modelOptions = shedModels && shedModels.map((model) => ({ label: model.name, value: model._id }));

  const handleModelChange = (selectedModelId) => {
    const selectedModel = shedModels.find((model) => model._id === selectedModelId);
    setShedModel(selectedModel);
    getSheds(shedCategory._id, currentShop._id, selectedModelId);
  };

  const currentRetailSection = () => (
    <Section>
      <SectionTitle>Current Retail Percentages</SectionTitle>
      <FormGroup>
        <InputLabel>Current % for 8, 10, 12 Wide</InputLabel>
        <StyledInput type="number" step=".01" value={eightWideRetailPercent} onChange={handleEightWidePercent} />
      </FormGroup>
      <FormGroup>
        <InputLabel>Current % for 14, 16 Wide</InputLabel>
        <StyledInput type="number" step=".01" value={fourteenWideRetailPercent} onChange={handleFourteenWidePercent} />
      </FormGroup>
    </Section>
  );

  const projectedRetailSection = () => (
    <Section>
      <SectionTitle>Projected Retail Percentages</SectionTitle>
      <FormGroup>
        <InputLabel>Proposed % for 8, 10, 12 Wide</InputLabel>
        <StyledInput type="number" step=".01" value={newEightWideRetailPercent} onChange={handleNewEightWidePercent} />
      </FormGroup>
      <FormGroup>
        <InputLabel>Proposed % for 14, 16 Wide</InputLabel>
        <StyledInput type="number" step=".01" value={newFourteenWideRetailPercent} onChange={handleNewFourteenWidePercent} />
      </FormGroup>
    </Section>
  );

  const grossProfitSection = () => (
    <Section>
      <SectionTitle>Gross Profit</SectionTitle>
      <FormGroup>
        <InputLabel>Desired Gross Profit %</InputLabel>
        <StyledInput
          type="number"
          step=".01"
          value={newGrossProfitPercent}
          onChange={handleGrossProfitPercent}
          readOnly={discountChecked}
        />
      </FormGroup>
    </Section>
  );

  const discountSection = () => (
    <Section>
      <SectionTitle>Discount Options</SectionTitle>
      <FormGroup>
        <StyledCheckbox checked={discountChecked} onChange={handleDiscountCheck}>
          Figure New Retail based on Discount %
        </StyledCheckbox>
      </FormGroup>
      <FormGroup>
        <InputLabel>Discount %</InputLabel>
        <StyledInput
          readOnly={!discountChecked}
          type="number"
          step=".01"
          value={discountPercent}
          onChange={handleDiscountPercentChange}
        />
      </FormGroup>
    </Section>
  );

  const categoryModelSection = () => (
    <Section>
      <SectionTitle>Category and Model</SectionTitle>
      <FormGroup>
        <InputLabel>Select Category</InputLabel>
        <StyledSelect
          placeholder="Select Category"
          options={categoryOptions}
          onChange={handleCategoryChange}
          value={shedCategory ? shedCategory._id : undefined}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel>Select Model</InputLabel>
        <StyledSelect
          placeholder="Select Model"
          disabled={!shedCategory}
          options={modelOptions}
          onChange={handleModelChange}
          value={shedModel ? shedModel._id : undefined}
        />
      </FormGroup>
    </Section>
  );

  const additionalOptionsSection = () => (
    <Section>
      <SectionTitle>Additional Options</SectionTitle>
      <FormGroup>
        <StyledCheckbox checked={storMorSpecific} onChange={handleStorMorSpecific}>
          100% Stor-Mor
        </StyledCheckbox>
      </FormGroup>
    </Section>
  );

  const figureSuggestedRetail = (record) => {
    if (discountChecked) {
      let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
      let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
      return roundedUp;
    } else {
      if (record.width < 14) {
        let totalCost = record.shopTotalCost;
        let newRetailPrice = (totalCost / (1 - newGrossProfitPercent / 100) / (newEightWideRetailPercent / 100)).toFixed(2);
        let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
        return roundedUp;
      } else {
        let totalCost = record.shopTotalCost;
        let newRetailPrice = (totalCost / (1 - newGrossProfitPercent / 100) / (newFourteenWideRetailPercent / 100)).toFixed(2);
        let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
        return roundedUp;
      }
    }
  };

  const figureNewGrossProfit = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (newEightWideRetailPercent / 100);
        let theTotalCost = record.shopTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      } else {
        let percentOfRetail = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let theTotalCost = record.shopTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newEightWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newFourteenWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      } else {
        if (record.width < 14) {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newEightWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newFourteenWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      }
    }
  };

  const figureNewGrossProfitPercent = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (newEightWideRetailPercent / 100);
        let theGrossProfit = figureNewGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      } else {
        let percentOfRetail = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let theGrossProfit = figureNewGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newEightWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newFourteenWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      } else {
        if (record.width < 14) {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newEightWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newFourteenWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      }
    }
  };

  const figureCurrentGrossProfitPercent = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
        let theGrossProfit = figureCurrentGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      } else {
        let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
        let theGrossProfit = figureCurrentGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (eightWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (fourteenWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      } else {
        if (record.width < 14) {
          let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      }
    }
  };

  const figureCurrentGrossProfit = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
        let theTotalCost = record.shopTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      } else {
        let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
        let theTotalCost = record.shopTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (eightWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (fourteenWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      } else {
        if (record.width < 14) {
          let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      }
    }
  };

  const columns = [
    {
      title: "Width",
      dataIndex: "width",
    },

    {
      title: "Length",
      dataIndex: "length",
    },

    {
      title: "Current Retail Price",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#bdffb6",
            },
          },
          children: <div>$ {record.retailPrice.toLocaleString("en-US")}</div>,
        };
      },
    },
    {
      title: "Suggested Retail Price",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#a9ff9f",
            },
          },
          children: <div>${figureSuggestedRetail(record).toLocaleString("en-US")}</div>,
        };
      },
    },

    {
      title: "Current GP %",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#f3ffa7",
            },
          },
          children: <div>{figureCurrentGrossProfitPercent(record).toLocaleString("en-US")}%</div>,
        };
      },
    },

    {
      title: "Projected GP %",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#f3ffa7",
            },
          },
          children: <div>{figureNewGrossProfitPercent(record).toLocaleString("en-US")}%</div>,
        };
      },
    },
  ];

  const data = sheds && sheds;

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <Card style={{ border: "1px solid #d9d9d9", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "16px" }}>
        <CardContent>
          {currentRetailSection()}
          {projectedRetailSection()}
          {grossProfitSection()}
          {discountSection()}
          {categoryModelSection()}
          {additionalOptionsSection()}
        </CardContent>
      </Card>
      <Table
        rowClassName={() => "hover-row"}
        loading={!loadingSheds ? false : true}
        columns={columns}
        dataSource={data}
        rowKey="_id"
        onChange={onChange}
        scroll={{ y: "70vh" }}
      />
    </Fragment>
  );
};

export default ShopPriceProjectionSheds;
