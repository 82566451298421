import React, { useState, useEffect, useContext, Fragment } from "react";
import OrderContext from "../../../../context/order/orderContext";
import ShopContext from "../../../../context/shop/shopContext";
import GlobalOrderSearch from "../../../../context/order/GlobalOrderSearch";
import { Checkbox, Table, Tag, Typography } from "antd";
import Select from "react-select";
import PopConfirm from "./comments_alerts/PopConfirm";
import DetailModal from "./DetailModal";

const InQueueOrders = () => {
  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;

  const orderContext = useContext(OrderContext);
  const {
    getOrderById,
    // deleteOrder,
    getOrders,
    orders,
    updateOrder,
    // filteredOrder,
    clearOrders,
    setLoadingOrders,
    loadingOrders,
    pagination,
    setPagination,
    clearCurrentOrder,
    setCurrentOrder,
    searchedOrder,
    deleteOrder,
  } = orderContext;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    clearCurrentOrder();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    clearCurrentOrder();
  };
  const handleShowModal = (record) => {
    setCurrentOrder(record);
    showModal(true);
  };

  const options =
    currentShop &&
    currentShop.shopUsers &&
    currentShop.shopUsers
      .filter((user) => user.role === "Builder")
      .map((option) => {
        return { label: `${option.name}`, value: option };
      });

  const setEditOrder = (order) => {
    // setCurrentOrder(order);
    // e.preventDefault();
    getOrderById(order._id);
  };

  useEffect(() => {
    clearOrders();
    getShop();
    getOrders("InQueue");
    return () => {
      clearOrders();
    };
  }, []);

  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    const inQueueOrders = orders && orders.filter((order) => order.status === "InQueue");
    setFilteredOrders(inQueueOrders);
  }, [orders]);

  // const handleRemoveOrder = (order) => {
  //   Modal.confirm({
  //     title: 'Are you sure you want to delete this Order?',
  //     okText: 'Yes',
  //     okType: 'danger',
  //     onOk: () => {
  //       deleteOrder(order);
  //     },
  //   });
  // };

  const onMetalOrderChange = (e, o) => {
    let checked = e.target.checked;
    let newRecord = { ...o, metalOrdered: checked };
    console.log("This is newRecord", newRecord);

    updateOrder(newRecord);
  };

  const handleBuilderChange = (o, e) => {
    let newRecord = { ...o, builder: e.value };
    updateOrder(newRecord);
  };

  const handleDeleteOrder = (order) => {
    deleteOrder(order);
  };

  // const handleToggleShowModal = (record) => {
  //   if (isModalVisible) {
  //     setIsModalVisible(false);
  //   } else {
  //     setCurrentOrder(record);
  //     setIsModalVisible(true);
  //   }
  // };

  const calculateShopTotal = (data) => {
    return data.reduce((total, order) => total + (order.grandTotal?.shop || 0), 0);
  };

  const columns = [
    {
      title: `Serial Number`,
      render: (text, record, rowIndex) => (
        // <div className="buttonLink" onMouseOver={() => handleToggleShowModal(record)}>
        //   {record.serialNumber}
        // </div>
        <button className="btn btn-link" type="button" onClick={() => handleShowModal(record)}>
          {record.serialNumber}
        </button>
      ),
    },
    {
      title: "Ordered Date",
      render: (text, record) => <span>{new Date(record.dateOrdered).toLocaleDateString()}</span>,
    },
    {
      title: `Dealer`,
      render: (text, record, rowIndex) => <Fragment>{record.dealer && record.dealer.name}</Fragment>,
    },
    {
      title: `Customer`,
      render: (text, record) => <span>{record.customer}</span>,
    },

    {
      title: "Metal Ordered",
      width: "7%",
      align: "center",
      render: (text, record) => (
        <Fragment>
          <Checkbox checked={record.metalOrdered} onChange={(e) => onMetalOrderChange(e, record)} />
        </Fragment>
      ),
    },
    {
      title: "Builder",
      render: (text, record) => (
        <Fragment>
          <Select
            className="basic-single"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            value={(options && options.find((option) => option.value._id === record.builder?._id)) || null}
            name="builder"
            options={options}
            onChange={(e) => handleBuilderChange(record, e)}
          />
        </Fragment>
      ),
    },
    {
      title: "Status",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          <Tag className="" color="volcano">
            {record && record.status}
          </Tag>
        </Fragment>
      ),
    },
    {
      title: "Shop Total",
      dataIndex: "grandTotal",
      render: (text, record) => (
        <span>
          {record.grandTotal?.shop?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
    {
      title: "Actions",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <Fragment>
          <button className="btn btn-xs btn-outline-secondary mb-2" type="button" onClick={(e) => setEditOrder(record)}>
            View
          </button>
          <br />
          {/* <button className="btn btn-xs btn-outline-danger mb-2" type="button" onClick={(e) => handleDeleteOrder(record)}>
            Delete
          </button>
          <br /> */}

          <PopConfirm record={record} />
          {/* {record && record.builder === undefined ? <div></div> : <PopConfirm record={record} />} */}
        </Fragment>
      ),
    },
  ];

  const summary = (pageData) => {
    const total = calculateShopTotal(pageData);
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={columns.length - 2}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              {total.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} />
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <div className="row">
      <div className="row mb-2">
        <div className="col-md-4">
          <h4>{filteredOrders && filteredOrders.length + " Orders InQueue"}</h4>
        </div>
        <div className="col-md-8">
          <GlobalOrderSearch />
        </div>
      </div>

      {/* <OrderFilter /> */}

      <DetailModal isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />

      <Table
        rowClassName={() => "hover-row"}
        // onRow={(record, rowIndex) => {
        //   return {
        //     // onMouseEnter: (event) => {
        //     //   handleRowClick(record, rowIndex);
        //     // }, // click row
        //     // onMouseLeave: (event) => {
        //     //   setShowClickedRow();
        //     // },
        //   };
        // }}
        // onRow={(record, recordIndex) => ({
        //   onClick: (event) => {
        //     setEditOrder(event, record);
        //     // console.log("onRow onClick", event.target, event.target.className, record, recordIndex);
        //   },
        // })}
        pagination={false}
        // loading={!loadingOrders && orders ? false : true}
        columns={columns}
        // dataSource={filteredOrder && filteredOrder !== null ? filteredOrder : orders && orders}
        loading={(!loadingOrders && filteredOrders) || (!loadingOrders && searchedOrder) ? false : true}
        dataSource={(searchedOrder && searchedOrder) || (filteredOrders && filteredOrders)}
        rowKey="_id"
        summary={summary}
        scroll={{ y: "60vh" }}
      />
    </div>
  );
};

export default InQueueOrders;
