import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MetalOrderContext from "../../../../../context/metalOrder/metalOrderContext";
import ComponentContext from "../../../../../context/component/componentContext";
import VendorContext from "../../../../../context/vendor/vendorContext";
import AddOnContext from "../../../../../context/addon/addOnContext";
import ShedCategoryContext from "../../../../../context/shedCategory/shedCategoryContext";
import ShopContext from "../../../../../context/shop/shopContext";
import CompanyContext from "../../../../../context/company/companyContext";
import { useForm, Controller } from "react-hook-form";
import PakArray from "./RHFMetalOrderPakArray";
import { Modal } from "antd";
import MetalOrderModal from "./MetalOrderModal";
import ReactSelect from "react-select";
import { DatePicker } from "antd";
import moment from "moment";

const defaultValues = {
  purchaseOrderNumber: "",
  metalOrder: [
    {
      serialNumber: "",
      title: "",
      metalPackage: {
        roofColor: {
          label: "",
          value: "",
        },
        trimColor: {
          label: "",
          value: "",
        },
        sidingColor: {
          label: "",
          value: "",
        },
        items: [
          {
            item: {
              name: "",
            },
          },
        ],
      },
    },
  ],
};

const RHFMetalOrderForm = () => {
  const navigate = useNavigate();
  const vendorContext = useContext(VendorContext);
  const { getVendors, vendors } = vendorContext; //, currentVendor, setCurrentVendor, addVendor, updateVendor, clearCurrentVendor, clearVendors } = vendorContext;

  const metalOrderContext = useContext(MetalOrderContext);
  const { updateMetalOrder, currentMetalOrder, setCurrentMetalOrder, addMetalOrder, clearCurrentMetalOrder, clearMetals } =
    metalOrderContext;

  const componentContext = useContext(ComponentContext);
  const { components, getMetalComponents } = componentContext;

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  const companyContext = useContext(CompanyContext);
  const { company, getCompany } = companyContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { getShedCategorys, shedCategorys } = shedCategoryContext;

  const addOnContext = useContext(AddOnContext);
  const { addOns, getSteeperPitchAddOns } = addOnContext;

  const initialValues = currentMetalOrder
    ? {
        ...currentMetalOrder,
        orderedDate: currentMetalOrder.orderedDate ? moment(currentMetalOrder.orderedDate) : null,
      }
    : defaultValues;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues: initialValues,
  });

  const [formChanged, setFormChanged] = useState(false);

  const [windowModalVisible, setWindowModalVisible] = useState(false);

  console.log("This is currentShop", currentShop);

  useEffect(() => {
    getVendors();
    getShedCategorys();
    getShop();
    getCompany();
    getSteeperPitchAddOns(currentShop && currentShop.nbAddonCategory._id);

    // currentMetalOrder && reset(currentMetalOrder);
  }, []);

  const handleWindowModalOk = () => {
    // Save the data
    setWindowModalVisible(false);
  };

  const handleWindowModalCancel = () => {
    // Discard the data
    setWindowModalVisible(false);
  };

  const handleChange = (e) => {
    setFormChanged(true);
    // your form change logic here
    // console.log("setting the form state changed");
  };

  const onSubmit = (metal) => {
    Modal.confirm({
      title: "Save this Order?",
      okText: "Yes",
      okType: "primary",
      onOk: () => {
        if (!currentMetalOrder) {
          addMetalOrder(metal);
          setCurrentMetalOrder(metal);
          handleShow();
        } else {
          updateMetalOrder(metal);
          setCurrentMetalOrder(metal);
          handleShow();
        }
      },
    });
  };

  const [ready, setReady] = useState(false);

  // const downloadPdf = () => {
  //   const values = getValues();

  //   if (!currentMetalOrder) {
  //     let answer = window.confirm(`Save & Generate PDF?`);
  //     if (answer) {
  //       setCurrentMetalOrder(values);
  //       setReady(true);
  //       addMetalOrder(values);
  //     }
  //   } else {
  //     setCurrentMetalOrder(values);
  //     setReady(true);
  //     updateMetalOrder(values);
  //   }
  // };

  const vendorOptions =
    vendors &&
    vendors.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  // const setVendorChange = (e) => {
  //   setValue(`vendor`, e);
  //   handleChange();
  // };

  const setExit = () => {
    navigate(-1);
    clearCurrentMetalOrder();
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    navigate(-1);
    clearCurrentMetalOrder();
  };

  // check to see if i have components and addOns and if so add them together concat the two arrays

  console.log("This is components", components);
  console.log("This is addOns", addOns);
  let newComponents = components && addOns && components.concat(addOns);

  console.log("This is newComponents", newComponents);

  const dateFormat = "MM/DD/YYYY";

  return (
    <div className="container-fluid">
      <Modal
        title="Save Data"
        visible={windowModalVisible}
        onOk={handleWindowModalOk}
        onCancel={handleWindowModalCancel}
        okText="Save"
        cancelText="Discard"
      >
        <p>Do you want to save the data before leaving?</p>
      </Modal>
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mt-2 mb-2 btn btn-outline-danger " onClick={() => setExit()}>
            Exit
          </button>
        </div>
      </div>

      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div className="   border mt-2 " style={{ borderRadius: "5px" }}>
          <h4 className="text-center mt-2">Metal Order Form</h4>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    <div className="form-group">
                      <label>Vendor</label>
                      <Controller
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            options={vendorOptions}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            // value={
                            //   (vendorOptions && vendorOptions.find((option) => option.value._id === field?.value?._id)) || null
                            // }
                            value={(field.value && { label: field.value.name, value: field.value }) || null}
                            // onChange={(e) => setVendorChange(e)}
                            onChange={(e) => {
                              field.onChange(e.value);
                              setValue(`vendor`, e.value);
                            }}
                          />
                        )}
                        rules={{ required: true }}
                        name={`vendor`}
                        control={control}
                      />
                    </div>
                  </div>

                  <div className="col-md-3 mt-4">
                    <div className="form-group">
                      <label>Po#</label>
                      <input
                        className="form-control inputbg"
                        name="purchaseOrderNumber"
                        {...register("purchaseOrderNumber", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="col-md-3 mt-4">
                    <div className="form-group">
                      <label className="">Ordered Date</label>
                      <Controller
                        render={({ field, name }) => (
                          <DatePicker format={dateFormat} className="form-control" name={name} {...field} />
                        )}
                        control={control}
                        name={`orderedDate`}
                      />
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  {/* {ready ? (
                    <div className="col-md-2 mt-3">
                      <PDFDownloadLink
                        className="btn btn-outline-info"
                        document={<Invoice data={currentMetalOrder && currentMetalOrder} currentShop={currentShop} />}
                        fileName={`${currentMetalOrder && currentMetalOrder.purchaseOrderNumber} metal_order.pdf`}
                        // style={{
                        // textDecoration: "none",
                        // padding: "10px",
                        // color: "#4a4a4a",
                        // backgroundColor: "#f2f2f2",
                        // border: "1px solid #4a4a4a"
                        // }}
                      >
                        {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download Pdf")}
                      </PDFDownloadLink>
                    </div>
                  ) : (
                    <div className="col-md-2"></div>
                  )} */}

                  {/* <div className='col-md-2'>
                    <div className='buttons mt-3'>
                      <button className='btn btn-outline-primary' type='button' onClick={() => downloadPdf()}>
                        Save & Generate PDF
                      </button>
                    </div>
                  </div> */}

                  <div className="col-md-1">
                    <div className="buttons float-end  mr-3 mt-3">
                      <button className="btn btn-primary " type="submit">
                        Save
                        {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PakArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              getValues,
              clearMetals,
              newComponents,
              company,
              shedCategorys,
              currentShop,
              getMetalComponents,
            }}
          />
        </div>
      </form>
      {currentMetalOrder && <MetalOrderModal handleClose={handleClose} handleShow={handleShow} show={show} />}
    </div>
  );
};

export default RHFMetalOrderForm;
