import React, { Fragment, useContext, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Modal, Card, Button, Row, Col, Typography } from "antd";
import ShopAddOnForm from "../../addons/addon/ShopAddOnForm";
import AddOnContext from "../../../../../../context/addon/addOnContext";
import AuthContext from "../../../../../../context/auth/authContext";

const { Title } = Typography;

const AddOns = ({ standardAddOn, standardAddOnIndex, control, remove, setValue, getValues, addOns, register }) => {
  const setAddOnCount = (count) => {
    setValue(`standardAddOns[${standardAddOnIndex}].quantity`, count);
  };

  const addOnContext = useContext(AddOnContext);
  const { setCurrentAddOn } = addOnContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const viewAddOn = () => {
    const newComponent = standardAddOn.standardAddOn;
    const filteredShopItems = newComponent.shopItems.filter((shopItem) => shopItem.shop === user.shop);
    const itemsArray = filteredShopItems.map((shopItem) => shopItem.items).flat();
    const updatedComponent = {
      ...newComponent,
      shopItems: itemsArray,
    };
    setCurrentAddOn(updatedComponent);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const setAddOnChange = (e) => {
    setValue(`standardAddOns[${standardAddOnIndex}].standardAddOn`, e.value);
    setValue(`standardAddOns[${standardAddOnIndex}].quantity`, 0);
  };

  const theAddOnOptions =
    addOns &&
    addOns.map((standardAddOn) => {
      return { label: `${standardAddOn.name}`, value: standardAddOn };
    });

  const selectedAddOn = theAddOnOptions && theAddOnOptions.find((option) => option.value._id === standardAddOn.standardAddOn);

  return (
    <Fragment>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: "12.5%" }}
        width="75vw"
        bodyStyle={{ height: "75vh", overflow: "auto", maxWidth: "100%" }}
        footer={null}
      >
        <ShopAddOnForm setIsModalVisible={setIsModalVisible} />
      </Modal>
      <Card
        style={{
          marginBottom: "8px",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
          border: "1px solid #e8e8e8",
          borderRadius: "8px",
        }}
      >
        <Row gutter={8}>
          <Col span={16}>
            <Controller
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  value={(field.value && { label: field.value.name, value: field.value }) || null}
                  isDisabled={true}
                  isClearable={true}
                  options={theAddOnOptions}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  onChange={(e) => {
                    field.onChange(e.value);
                    setValue(`standardAddOns[${standardAddOnIndex}].standardAddOn`, e.value);
                    setValue(`standardAddOns[${standardAddOnIndex}].quantity`, 0);
                  }}
                />
              )}
              key={standardAddOn.id}
              name={`standardAddOns[${standardAddOnIndex}].standardAddOn`}
              control={control}
            />
          </Col>
          <Col span={4}>
            <Controller
              render={({ field, name }) => (
                <input
                  readOnly
                  className="form-control inputbg"
                  type="number"
                  name={name}
                  {...field}
                  onChange={(e) => {
                    const count = e.target.value;
                    setAddOnCount(count);
                  }}
                />
              )}
              key={standardAddOn.id}
              control={control}
              name={`standardAddOns[${standardAddOnIndex}].quantity`}
            />
          </Col>
          <Col span={4} className="text-end">
            <Button type="primary" onClick={viewAddOn}>
              View
            </Button>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, addOns }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "standardAddOns",
  });

  return (
    <div className="container-fluid">
      {fields.length > 0 && (
        <Row className="mt-2">
          <Col span={16} className="text-center">
            <Title level={5}>Standard Add-On Name</Title>
          </Col>
          <Col span={4} className="text-center">
            <Title level={5}>Count</Title>
          </Col>
          <Col span={4} className="text-end">
            <Title level={5}>View</Title>
          </Col>
        </Row>
      )}

      {fields.map((standardAddOn, standardAddOnIndex) => (
        <AddOns
          key={standardAddOn.id}
          standardAddOn={standardAddOn}
          standardAddOnIndex={standardAddOnIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          addOns={addOns}
        />
      ))}
    </div>
  );
}
