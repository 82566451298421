import React, { useState, useEffect, useContext, Fragment } from "react";
import { Table, Space, Typography } from "antd";
import AddOnReportFilter from "./AddOnReportFilter";
import AddOnContext from "../../../../context/addon/addOnContext";
import ShopContext from "../../../../context/shop/shopContext";
import AddonParentCategoryContext from "../../../../context/addonParentCategory/addonParentCategoryContext";
import AddonCategoryContext from "../../../../context/addonCategory/addonCategoryContext";
import styled from "styled-components";
import CompanyContext from "../../../../context/company/companyContext";

const { Title } = Typography;

const StyledTable = styled(Table)`
  .ant-table-container {
    border-top: 1px solid #f0f0f0;
  }
  .ant-table-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }
`;

const AddOnReports = () => {
  const [filteredAddOns, setFilteredAddOns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedParentCategory, setSelectedParentCategory] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const addOnContext = useContext(AddOnContext);
  const shopContext = useContext(ShopContext);
  const addonParentCategoryContext = useContext(AddonParentCategoryContext);
  const addonCategoryContext = useContext(AddonCategoryContext);
  const companyContext = useContext(CompanyContext);
  const { company } = companyContext;
  const { addOns, getAddOnsForReport } = addOnContext;
  const { shops, getShops } = shopContext;
  const { addonParentCategorys, getAddonParentCategorys } = addonParentCategoryContext;
  const { addonCategorys, getAddonCategorys } = addonCategoryContext;

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getShops(), getAddonParentCategorys()]);
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedParentCategory) {
      getAddonCategorys(selectedParentCategory);
    }
  }, [selectedParentCategory]);

  function calculateCompanyAddonProfit(record) {
    if (!record) {
      return 0;
    }

    const dealerCost = +record.retailPrice * (+company.dealerCommissionPercent / 100);
    const salesRepCost = +record.retailPrice * (+company.salesRepCommissionPercent / 100);
    const companyOverhead = +record.retailPrice * (+company.overheadPercentCompany / 100);
    const companyExpense = +dealerCost + +salesRepCost + +companyOverhead + +record.retailPrice * (record.shopPercent / 100);

    const profit = record.retailPrice - companyExpense;
    const gpPercent = profit / record.retailPrice;

    return isNaN(gpPercent) ? 0 : gpPercent;
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Category",
    //   dataIndex: "category",
    //   key: "category",
    // },
    // {
    //   title: "Standard Gross Profit %",
    //   dataIndex: "standardGrossProfit",
    //   key: "standardGrossProfit",
    //   render: (text) => `${(text * 100).toFixed(2)}%`,
    // },
    {
      title: "Retail Price",
      render: (record) => (
        <Fragment>
          {record.retailPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </Fragment>
      ),
    },
    {
      title: "Shop%",
      //   align: "center",
      render: (text, record, rowIndex) => <Fragment>{record.shopPercent}%</Fragment>,
    },
    {
      title: "Standard Cost",
      render: (record) => (
        <Fragment>
          {record.addOnStandardTotalCost &&
            record.addOnStandardTotalCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Standard Gross Profit %",
      //   align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record.standardAddOnGrossProfitPercent.toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    {
      title: "Stor-Mor Addon GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {calculateCompanyAddonProfit(record).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    // {
    //   title: "Stormor Gross Profit %",
    //   dataIndex: "stormorGrossProfit",
    //   key: "stormorGrossProfit",
    //   render: (text) => `${(text * 100).toFixed(2)}%`,
    // },
  ];

  const handleFilterChange = (filters) => {
    console.log("filters", filters);
    setLoading(true);
    getAddOnsForReport(filters.category, filters.shop).then(() => {
      //   setFilteredAddOns(addOns);
      setLoading(false);
    });
  };

  const handleParentCategoryChange = (parentCategoryId) => {
    setSelectedParentCategory(parentCategoryId);
  };

  if (!dataLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Title level={2}>Add-On Reports</Title>

      <AddOnReportFilter
        shops={shops}
        addonParentCategorys={addonParentCategorys}
        addonCategorys={addonCategorys}
        onFilterChange={handleFilterChange}
        onParentCategoryChange={handleParentCategoryChange}
      />

      <StyledTable
        columns={columns}
        dataSource={addOns}
        loading={loading}
        rowKey="_id"
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)" }}
        sticky
      />
    </Space>
  );
};

export default AddOnReports;
