import React, { useReducer } from "react";
import LogContext from "./logContext";
import logReducer from "./logReducer";
import axios from "axios";

import { toast } from "react-toastify";

import { DELETE_LOG, GET_LOGS, LOG_ERROR } from "../types";

const LogState = (props) => {
  const initialState = {
    logs: null,
    error: null,
  };

  const [state, dispatch] = useReducer(logReducer, initialState);

  // get Logs
  const getLogs = async () => {
    try {
      const res = await axios.get(`/api/log/`);
      dispatch({ type: GET_LOGS, payload: res.data });
    } catch (err) {
      dispatch({ type: LOG_ERROR });
    }
  };

  const deleteLog = async (id) => {
    try {
      const res = await axios.delete(`/api/log/${id}`);
      dispatch({ type: DELETE_LOG, payload: id });
      toast.success(`Log is deleted`);
    } catch (err) {
      dispatch({ type: LOG_ERROR });
    }
  };

  return (
    <LogContext.Provider
      value={{
        logs: state.logs,
        error: state.error,
        getLogs,
        deleteLog,
      }}
    >
      {props.children}
    </LogContext.Provider>
  );
};

export default LogState;
