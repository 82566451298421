import React, { Fragment } from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const tableRowsCount = 11;
const borderColor = "#90e5fc";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,

    borderColor: "#bff0fd",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    // backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
  },

  sku: {
    width: "35%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  textrow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 10,
    marginTop: 2,
  },
  boxone: {
    paddingTop: 20,
    order: 1,
    flex: 2,
    fontFamily: "Helvetica",
  },

  titleContainer: {
    flexDirection: "row",
    marginTop: 5,
  },
  title: {
    color: "#61dafb",
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
  },

  item: {
    width: "50%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderLeftColor: borderColor,
  },
  grandTotal: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  qty: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const InvoicesTable = ({ values }) => {
  // console.log("this is the payment", values);

  const rows = values.invoices.map((invoice) => (
    <View key={invoice._id}>
      <View>
        <View style={styles.row}>
          {/* <Text style={styles.item}>{invoice.invoice.order.serialNumber}</Text> */}

          <Text style={styles.item}>{invoice.invoice.invoiceNumber}</Text>
          {/* <Text style={styles.qty}></Text> */}
          {/* <Text style={styles.qty}>{invoice.invoice.order.shopGrandTotal}</Text> */}
          <Text style={styles.qty}>
            {invoice.invoice.order.grandTotal.shop.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Text>
          <Text style={styles.qty}>
            {invoice.invoice.order.profitPercent.standard.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
      </View>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoicesTable;
