import React, { useState, Fragment, useContext, useEffect } from "react";
import { Table, Input, Button, Space, Tag, Card, Col, Row, Statistic, Spin } from "antd";
import OrderContext from "../../../../context/order/orderContext";
import CompanyContext from "../../../../context/company/companyContext";
import TopOrderFilter from "./TopOrderFilter";
import styled from "styled-components";

const StyledTable = styled(Table)`
  .ant-table-container {
    border-top: 1px solid #f0f0f0;
  }
  .ant-table-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }
`;

const TopOrderReport = () => {
  const [eightPercent, setEightPercent] = useState(60);
  const [fourteenPercent, setFourteenPercent] = useState(62.5);
  const [overHeadPercent, setOverHeadPercent] = useState(5);
  const [percentOfChange, setPercentOfChange] = useState(0);
  // const [dealerCommission, setDealerCommission] = useState();
  // const [salesRepCommission, setSalesRepCommission] = useState();
  // const [storMorOverhead, setStorMorOverhead] = useState();
  // const [shopToLot, setShopToLot] = useState();
  // const [lotToCustomer, setLotToCustomer] = useState();
  // const [fourteenEscort, setFourteenEscort] = useState();
  // const [sixteenEscort, setSixteenEscort] = useState();

  const orderContext = useContext(OrderContext);
  const { topOrders, loadingOrders, clearOrders } = orderContext;

  const companyContext = useContext(CompanyContext);
  const { getCompany, company } = companyContext;

  // useEffect(() => {
  //   if (company) {
  //     setDealerCommission(company.dealerCommissionPercent);
  //     setSalesRepCommission(company.salesRepCommissionPercent);
  //     setStorMorOverhead(company.overheadPercentCompany);
  //     setShopToLot(5);
  //     setLotToCustomer(company.lotToCustomerPercent);
  //     setFourteenEscort(company.fourteenWideEscort);
  //     setSixteenEscort(company.sixteenWideEscort);
  //   }
  // }, [company]);

  useEffect(() => {
    // getTop100Report();
    getCompany();
    return () => {
      clearOrders();
    };
  }, []);

  const [totals, setTotals] = useState({
    totalCount: 0,
    eightCount: 0,
    fourteenCount: 0,
    standardBoxAverage: 0,
    standardAddOnAverage: 0,
    standardTotalAverage: 0,
    stormorBoxAverage: 0,
    stormorAddOnAverage: 0,
    stormorTotalAverage: 0,

    standardBoxWeightedAvgEight: 0,
    standardAddonWeightedAvgEight: 0,
    standardTotalWeightedAvgEight: 0,

    standardBoxWeightedAvgFourteen: 0,
    standardAddonWeightedAvgFourteen: 0,
    standardTotalWeightedAvgFourteen: 0,

    storMorBoxWeightedAvgEight: 0,
    storMorAddonWeightedAvgEight: 0,
    storMorTotalWeightedAvgEight: 0,

    storMorBoxWeightedAvgFourteen: 0,
    storMorAddonWeightedAvgFourteen: 0,
    storMorTotalWeightedAvgFourteen: 0,

    // projStandardAverage: 0,
    // projStormorAverage: 0,
    // projStandardWeightedAvgEight: 0,
    // projStorMorWeightedAvgEight: 0,
    // projStandardWeightedAvgFourteen: 0,
    // projStorMorWeightedAvgFourteen: 0,
  });

  const calculateAverage = (profitFunction, widthCondition) => {
    let totalProfitPercent = 0;
    let count = 0;
    topOrders.forEach((order) => {
      if (widthCondition(order.shed.width)) {
        totalProfitPercent += profitFunction(order) * order.count;
        count += order.count;
      }
    });
    if (count === 0) return 0;
    return totalProfitPercent / count;
  };

  const calculateCount = (widthCondition) => {
    let count = 0;
    topOrders.forEach((order) => {
      if (widthCondition(order.shed.width)) {
        count += order.count;
      }
    });
    return count;
  };

  useEffect(() => {
    // whenever the orders change, re-calculate the totals
    setTotals({
      totalCount: calculateCount(() => true),
      eightCount: calculateCount((width) => width <= 12),
      fourteenCount: calculateCount((width) => width >= 13),
      standardBoxAverage: calculateAverage(calculateStandardProfit, () => true) / 100,
      standardAddOnAverage: calculateAverage(calculateStandardAddonGPPercent, () => true) / 100,
      standardTotalAverage: calculateAverage(calculateTotalStandardGP, () => true) / 100,
      stormorBoxAverage: calculateAverage(calculateCompanyProfit, () => true) / 100,
      stormorAddOnAverage: calculateAverage(calculateCompanyAddonProfit, () => true) / 100,
      stormorTotalAverage: calculateAverage(calculateCompanyTotalProfit, () => true) / 100,
      standardBoxWeightedAvgEight: calculateAverage(calculateStandardProfit, (width) => width <= 12),
      standardAddonWeightedAvgEight: calculateAverage(calculateStandardAddonGPPercent, (width) => width <= 12),
      standardTotalWeightedAvgEight: calculateAverage(calculateTotalStandardGP, (width) => width <= 12),
      storMorBoxWeightedAvgEight: calculateAverage(calculateCompanyProfit, (width) => width <= 12),
      storMorAddonWeightedAvgEight: calculateAverage(calculateCompanyAddonProfit, (width) => width <= 12),
      storMorTotalWeightedAvgEight: calculateAverage(calculateCompanyTotalProfit, (width) => width <= 12),
      standardBoxWeightedAvgFourteen: calculateAverage(calculateStandardProfit, (width) => width >= 13),
      standardAddonWeightedAvgFourteen: calculateAverage(calculateStandardAddonGPPercent, (width) => width >= 13),
      standardTotalWeightedAvgFourteen: calculateAverage(calculateTotalStandardGP, (width) => width >= 13),
      storMorBoxWeightedAvgFourteen: calculateAverage(calculateCompanyProfit, (width) => width >= 13),
      storMorAddonWeightedAvgFourteen: calculateAverage(calculateCompanyAddonProfit, (width) => width >= 13),
      storMorTotalWeightedAvgFourteen: calculateAverage(calculateCompanyTotalProfit, (width) => width >= 13),

      // projStandardAverage: calculateAverage(calculateProjectedStandardProfit, () => true) / 100,
      // projStormorAverage: calculateAverage(calculateProjectedCompanyProfit, () => true) / 100,
      // projStandardWeightedAvgEight: calculateAverage(calculateProjectedStandardProfit, (width) => width <= 12),
      // projStorMorWeightedAvgEight: calculateAverage(calculateProjectedCompanyProfit, (width) => width <= 12),
      // projStandardWeightedAvgFourteen: calculateAverage(calculateProjectedStandardProfit, (width) => width >= 13),
      // projStorMorWeightedAvgFourteen: calculateAverage(calculateProjectedCompanyProfit, (width) => width >= 13),
    });
  }, [
    topOrders,
    eightPercent,
    fourteenPercent,
    overHeadPercent,
    percentOfChange,
    // dealerCommission,
    // salesRepCommission,
    // storMorOverhead,
    // shopToLot,
    // lotToCustomer,
    // fourteenEscort,
    // sixteenEscort,
  ]);

  function calculateStandardProfit(record) {
    if (!record) {
      return "";
    }
    console.log("record", record);
    const percent = record.shed.width <= 12 ? eightPercent : fourteenPercent;
    let totalBaseShedPriceCompany = record.shed.retailPrice * record.count;
    const shopBaseShedPrice = totalBaseShedPriceCompany * (percent / 100);
    const shopOverhead = shopBaseShedPrice * (overHeadPercent / 100);
    const standardExpense = record.totalShopShedExpense + shopOverhead;
    const profit = shopBaseShedPrice - standardExpense;
    const profitPercent = profit / shopBaseShedPrice;
    return profitPercent;
  }

  function calculateCompanyProfit(record) {
    if (!record) {
      return "";
    }
    const totalBaseShedPriceCompany = record.shed.retailPrice * record.count;
    const percent = record.shed.width <= 12 ? eightPercent : fourteenPercent;
    const shopBaseShedPrice = totalBaseShedPriceCompany * (percent / 100);
    const dealerCost = +totalBaseShedPriceCompany * (+company.dealerCommissionPercent / 100);
    const salesRepCost = +totalBaseShedPriceCompany * (+company.salesRepCommissionPercent / 100);
    const companyOverhead = +totalBaseShedPriceCompany * (+company.overheadPercentCompany / 100);
    const customerHaul = +record.shed.haulBase * (+company.lotToCustomerPercent / 100) * record.count;
    const lotHaul = +record.shed.haulBase * 0.05 * +record.count;
    const totalHaul = +customerHaul + +lotHaul;
    const fuelSurchage = +company.fuelSurcharge * +record.count;
    const companyExpense =
      +totalHaul + +record.escort + +shopBaseShedPrice + +dealerCost + +salesRepCost + +companyOverhead + +fuelSurchage; // need to add dealercommission sales rep commission and overhead
    const profit = totalBaseShedPriceCompany - companyExpense;
    const profitPercent = profit / totalBaseShedPriceCompany;
    return profitPercent;
  }

  function calculateStandardAddonGPPercent(record) {
    if (!record) {
      return "";
    }

    const addonRetailTotal = record.addOnShopRetailTotal || 0;
    const standardAddonCost = record.totalShopStandardAddOnCost || 0;

    if (addonRetailTotal === 0) {
      return 0; // Avoid division by zero
    }

    const profit = addonRetailTotal - standardAddonCost;
    const gpPercent = profit / addonRetailTotal;

    return gpPercent; // Return the percentage with 2 decimal places
  }

  function calculateTotalStandardGP(record) {
    const standardGP = calculateStandardProfit(record);
    const standardAddonGP = calculateStandardAddonGPPercent(record);

    // Calculate the total cost and total price
    const percent = record.shed.width <= 12 ? eightPercent : fourteenPercent;
    let totalBaseShedPriceCompany = record.shed.retailPrice * record.count;
    const shopBaseShedPrice = totalBaseShedPriceCompany * (percent / 100);
    const addonPrice = record.addOnShopRetailTotal || 0;

    const totalPrice = shopBaseShedPrice + addonPrice;

    // Calculate weighted GP
    const weightedStandardGP = (standardGP * shopBaseShedPrice) / totalPrice;
    const weightedAddonGP = (standardAddonGP * addonPrice) / totalPrice;

    const totalWeightedGP = weightedStandardGP + weightedAddonGP;

    return totalWeightedGP;
  }

  function calculateCompanyAddonProfit(record) {
    if (!record) {
      return 0;
    }
    const addonRetailTotal = record.addOnRetailTotal || 0;
    if (addonRetailTotal === 0) {
      return 0;
    }
    const dealerCost = +addonRetailTotal * (+company.dealerCommissionPercent / 100);
    const salesRepCost = +addonRetailTotal * (+company.salesRepCommissionPercent / 100);
    const companyOverhead = +addonRetailTotal * (+company.overheadPercentCompany / 100);
    const companyExpense = +dealerCost + +salesRepCost + +companyOverhead + +record.addOnShopRetailTotal;

    const profit = addonRetailTotal - companyExpense;
    const gpPercent = profit / addonRetailTotal;

    return isNaN(gpPercent) ? 0 : gpPercent;
  }

  function calculateCompanyTotalProfit(record) {
    if (!record) {
      return 0;
    }
    const companyProfit = calculateCompanyProfit(record);
    const companyAddonProfit = calculateCompanyAddonProfit(record);

    // Calculate total price
    const totalBaseShedPriceCompany = record.shed.retailPrice * record.count;
    const addonRetailTotal = record.addOnRetailTotal || 0;
    const totalPrice = totalBaseShedPriceCompany + addonRetailTotal;

    if (totalPrice === 0) {
      return 0;
    }

    // Calculate weighted profits
    const weightedCompanyProfit = (companyProfit * totalBaseShedPriceCompany) / totalPrice;
    const weightedCompanyAddonProfit = (companyAddonProfit * addonRetailTotal) / totalPrice;

    const totalWeightedProfit = weightedCompanyProfit + weightedCompanyAddonProfit;
    return isNaN(totalWeightedProfit) ? 0 : totalWeightedProfit;
  }

  const columns = [
    {
      title: "Model",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{record && record.shed.model.name}</Fragment>,
    },
    {
      title: "Size",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{`${record && record.shed.width}x${record.shed.length}`}</Fragment>,
    },
    {
      title: "Current Retail",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record &&
            record.shed.retailPrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },

    {
      title: "Count",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{record && record.count}</Fragment>,
    },

    {
      title: "Box GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {calculateStandardProfit(record).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    {
      title: "Addon GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {calculateStandardAddonGPPercent(record).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    {
      title: "Total GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {calculateTotalStandardGP(record).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    // {
    //   title: "Stor-Mor Box GP",
    //   align: "center",
    //   render: (text, record, rowIndex) => (
    //     <Fragment>
    //       {calculateCompanyProfit(record).toLocaleString("en-US", {
    //         style: "percent",
    //         minimumFractionDigits: 2,
    //       })}
    //     </Fragment>
    //   ),
    // },
    // {
    //   title: "Stor-Mor Addon GP",
    //   align: "center",
    //   render: (text, record, rowIndex) => (
    //     <Fragment>
    //       {calculateCompanyAddonProfit(record).toLocaleString("en-US", {
    //         style: "percent",
    //         minimumFractionDigits: 2,
    //       })}
    //     </Fragment>
    //   ),
    // },

    // {
    //   title: "Stor-Mor Total GP",
    //   align: "center",
    //   render: (text, record, rowIndex) => (
    //     <Fragment>
    //       {calculateCompanyTotalProfit(record).toLocaleString("en-US", {
    //         style: "percent",
    //         minimumFractionDigits: 2,
    //       })}
    //     </Fragment>
    //   ),
    // },
  ];

  const currentCardContents = (
    <>
      <h4 className="text-center">Shop Percentages</h4>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic title="Count" value={totals.totalCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Box Average"
            value={(totals.standardBoxAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Addon Average"
            value={(totals.standardAddOnAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Total Average"
            value={(totals.standardTotalAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
      <Row className="mt-4" gutter={16}>
        <Col span={6}>
          <Statistic title="8, 10, 12 Count" value={totals.eightCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Box Weighted Average 8,10,12"
            value={totals.standardBoxWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Addon Weighted Average 8,10,12"
            value={totals.standardAddonWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Total Weighted Average 8,10,12"
            value={totals.standardTotalWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
      <Row className="mt-4" gutter={16}>
        <Col span={6}>
          <Statistic title="14, 16 Count" value={totals.fourteenCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Box Weighted Average 14,16"
            value={totals.standardBoxWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Addon Weighted Average 14,16"
            value={totals.standardAddonWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Total Weighted Average 14,16"
            value={totals.standardTotalWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
    </>
  );

  const stormorCardContents = (
    <>
      <h4 className="text-center">Stor-Mor</h4>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic title="Count" value={totals.totalCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Box Average"
            value={(totals.stormorBoxAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Addon Average"
            value={(totals.stormorAddOnAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Total Average"
            value={(totals.stormorTotalAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
      <Row className="mt-4" gutter={16}>
        <Col span={6}>
          <Statistic title="8, 10, 12 Count" value={totals.eightCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Box Weighted Average 8,10,12"
            value={totals.storMorBoxWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Addon Weighted Average 8,10,12"
            value={totals.storMorAddonWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Total Weighted Average 8,10,12"
            value={totals.storMorTotalWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
      <Row className="mt-4" gutter={16}>
        <Col span={6}>
          <Statistic title="14, 16 Count" value={totals.fourteenCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Box Weighted Average 14,16"
            value={totals.storMorBoxWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Addon Weighted Average 14,16"
            value={totals.storMorAddonWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Total Weighted Average 14,16"
            value={totals.storMorTotalWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <Fragment>
      <div className="row mb-4">
        <div className="col-md-6">
          <TopOrderFilter />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-2 ">
          <label>8, 10, 12 Percent</label>
          <input className="form-control" type="number" value={eightPercent} onChange={(e) => setEightPercent(e.target.value)} />
        </div>
        <div className="col-md-2">
          <label>14, 16 Percent:</label>
          <input
            className="form-control"
            type="number"
            value={fourteenPercent}
            onChange={(e) => setFourteenPercent(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <label>Overhead</label>
          <input
            className="form-control"
            type="number"
            value={overHeadPercent}
            onChange={(e) => setOverHeadPercent(e.target.value)}
          />
        </div>
      </div>

      <div className="card-container">
        {!loadingOrders ? (
          <Fragment>
            <Card className="custom-card">{currentCardContents}</Card>
            {/* <Card className="custom-card">{stormorCardContents}</Card> */}
          </Fragment>
        ) : (
          <Spin className="mb-4" tip="Loading" size="large">
            <div className="content" />
          </Spin>
        )}
      </div>
      <StyledTable
        className="mt-4 shop-all-items-table"
        rowClassName={() => "hover-row"}
        pagination={false}
        loading={!loadingOrders && topOrders ? false : true}
        columns={columns}
        dataSource={topOrders && topOrders}
        rowKey="_id"
        // scroll={{ x: 1300 }}
        sticky
      />
    </Fragment>
  );
};

export default TopOrderReport;
