import {
  ADD_MATERIALORDER,
  DELETE_MATERIALORDER,
  CLEAR_MATERIALORDERS,
  SET_CURRENT_MATERIALORDER,
  CLEAR_CURRENT_MATERIALORDER,
  UPDATE_MATERIALORDER,
  FILTER_MATERIALORDERS,
  CLEAR_FILTER_MATERIALORDER,
  MATERIALORDER_ERROR,
  GET_MATERIALORDERS,
  GET_RECIEVED_MATERIALORDERS,
  SET_LOADING_MATERIALORDERS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_MATERIALORDERS:
      return {
        ...state,
        materialOrders: action.payload,
        loading: false,
      };
    case GET_RECIEVED_MATERIALORDERS:
      return {
        ...state,
        materialOrders: action.payload,
        loading: false,
      };
    case ADD_MATERIALORDER:
      return {
        ...state,
        materialOrders: [action.payload, ...state.materialOrders],
        loading: false,
      };
    case UPDATE_MATERIALORDER:
      return {
        ...state,
        materialOrders: state.materialOrders?.map((materialOrder) =>
          materialOrder._id === action.payload._id ? action.payload : materialOrder
        ),
        loading: false,
      };
    case DELETE_MATERIALORDER:
      return {
        ...state,
        materialOrders: state.materialOrders.filter((materialOrder) => materialOrder._id !== action.payload._id),
        loading: false,
      };
    case CLEAR_MATERIALORDERS:
      return {
        ...state,
        materialOrders: [],
        filteredMaterialOrder: null,
        error: null,
        // currentMaterialOrder: null,
      };
    case SET_CURRENT_MATERIALORDER:
      return {
        ...state,
        currentMaterialOrder: action.payload,
      };
    case CLEAR_CURRENT_MATERIALORDER:
      return {
        ...state,
        currentMaterialOrder: null,
      };
    case FILTER_MATERIALORDERS:
      return {
        ...state,
        filteredMaterialOrder: state.materialOrders.filter((materialOrder) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return materialOrder.customer.value.name.match(regex);
        }),
      };
    case CLEAR_FILTER_MATERIALORDER:
      return {
        ...state,
        filteredMaterialOrder: null,
      };
    case MATERIALORDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING_MATERIALORDERS:
      return {
        ...state,
        loadingMaterialOrders: action.payload,
      };
    default:
      return state;
  }
};
