import React, { Fragment, useState, useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Checkbox, Input, Button, Row, Col, Card, Typography } from "antd";

const { Title } = Typography;

const Items = ({ item, itemIndex, control, remove, setValue, getValues, itemOptions, register }) => {
  const [replaceItemValue, setReplaceItemValue] = useState(false);

  const setItemCount = (quantity) => {
    setValue(`standardItems[${itemIndex}].quantity`, quantity);
  };

  useEffect(() => {
    setReplaceItemValue(item.replaceItem);
  }, [item]);

  const onRemoveItem = () => {};

  const setItemChange = (e) => {
    setValue(`standardItems[${itemIndex}].item`, e);
    setValue(`standardItems[${itemIndex}].quantity`, 0);
  };

  const setReplacementItemChange = (e) => {
    setValue(`standardItems[${itemIndex}].replacementItem`, e);
  };

  const productOptions =
    itemOptions &&
    itemOptions.map((item) => {
      const actualItem = item.item ? item.item : item;
      return { label: actualItem.name, value: actualItem };
    });

  return (
    <Card style={{}}>
      <Row gutter={8} align="middle">
        <Col span={8}>
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={productOptions}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={(field.value && { label: field.value.name, value: field.value }) || null}
                onChange={(e) => setItemChange(e.value)}
              />
            )}
            key={item.id}
            name={`standardItems[${itemIndex}].item`}
            control={control}
          />
        </Col>
        <Col span={4}>
          <Controller
            render={({ field, name }) => (
              <Input
                type="number"
                step={0.01}
                name={name}
                {...field}
                onChange={(e) => {
                  const quantity = e.target.value;
                  setItemCount(quantity);
                }}
              />
            )}
            key={item.id}
            control={control}
            name={`standardItems[${itemIndex}].quantity`}
          />
        </Col>
        <Col span={4} style={{ textAlign: "center" }}>
          <Controller
            control={control}
            name={`standardItems[${itemIndex}].replaceItem`}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Checkbox
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                  setReplaceItemValue(e.target.checked);
                }}
                checked={value}
                inputRef={ref}
              />
            )}
          />
        </Col>
        {replaceItemValue ? (
          <Col span={6}>
            <Controller
              render={({ field }) => {
                const selectedItem = field.value
                  ? productOptions && productOptions.find((option) => option.value._id === field.value._id)
                  : null;
                return (
                  <ReactSelect
                    {...field}
                    options={productOptions}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    value={selectedItem}
                    onChange={(e) => setReplacementItemChange(e.value)}
                  />
                );
              }}
              key={item.id}
              name={`standardItems[${itemIndex}].replacementItem`}
              control={control}
            />
          </Col>
        ) : (
          <Col span={6}></Col>
        )}
        <Col span={2} style={{ textAlign: "right" }}>
          <Button
            type="danger"
            onClick={() => {
              remove(itemIndex);
              onRemoveItem();
            }}
          >
            Delete
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default function Fields({ control, register, setValue, getValues, allItems }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "standardItems",
  });

  return (
    <div className="container-fluid">
      <Row justify="center">
        <Col span={24}>
          <Title level={4} className="text-center">
            Standard Items
          </Title>
        </Col>
      </Row>
      <Row gutter={8} align="middle" style={{ marginBottom: "8px" }}>
        <Col span={8} className="text-center">
          <strong>Item Name</strong>
        </Col>
        <Col span={4} className="text-center">
          <strong>Count</strong>
        </Col>
        <Col span={4} className="text-center">
          <strong>Replace Item</strong>
        </Col>
        <Col span={6} className="text-center">
          <strong>Replace This Item</strong>
        </Col>
        <Col span={2} className="text-center">
          <strong>Actions</strong>
        </Col>
      </Row>
      {fields.map((item, itemIndex) => (
        <Items
          key={item.id}
          item={item}
          itemIndex={itemIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          itemOptions={allItems}
        />
      ))}
      <Row justify="center">
        <Col>
          <Button
            type="dashed"
            onClick={(e) => {
              e.preventDefault();
              append({ value: "0" });
            }}
          >
            Add Item
          </Button>
        </Col>
      </Row>
    </div>
  );
}
