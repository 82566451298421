import React, { useContext, useEffect, useState } from "react";
import InvoiceContext from "./invoiceContext";

const GlobalInvoiceSearch = () => {
  const invoiceContext = useContext(InvoiceContext);
  const { clearSearchedInvoice, findInvoiceByInvoiceNumber, setInvoiceNumberSearch, invoiceNumber } = invoiceContext;

  const handleSubmit = (e) => {
    e.preventDefault();
    findInvoiceByInvoiceNumber(invoiceNumber);
  };

  const handleChange = (e) => {
    // setValues({ ...values, [e.target.name]: e.target.value });
    setInvoiceNumberSearch(e.target.value);
  };

  const handleClear = () => {
    clearSearchedInvoice();
    setInvoiceNumberSearch("");
  };

  return (
    <form onSubmit={handleSubmit} className="pb-2">
      <div className="row">
        <div className="col-md-6">
          <input
            className="form-control"
            type="text"
            name="invoiceNumber"
            value={invoiceNumber}
            placeholder="Global Invoice Search by Invoice #..."
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <button className="float-end btn btn-outline-primary ">Search Database</button>
        </div>
        <div className="col-md-2">
          <button className="float-end btn btn-outline-warning " type="button" onClick={handleClear}>
            Clear Search
          </button>
        </div>
      </div>
    </form>
  );
};

export default GlobalInvoiceSearch;
