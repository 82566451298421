import React, { useState, useEffect } from "react";
import { Modal, Tag, Button, Space, Typography, Select } from "antd";
import styled from "styled-components";

const { Title } = Typography;
const { Option } = Select;

// Styled components for custom styling
const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    background-color: #f0f2f5;
  }
  .ant-modal-title {
    font-weight: 600;
  }
`;

const FilterContainer = styled.div`
  margin-bottom: 20px;
`;

const FilterTitle = styled(Title)`
  margin-bottom: 16px !important;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 16px;
`;

const TagContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const AddOnReportFilter = ({
  shops = [],
  addonParentCategorys = [],
  addonCategorys = [],
  onFilterChange,
  onParentCategoryChange,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filters, setFilters] = useState({
    parentCategory: null,
    category: [],
    shop: null, // Changed from array to single value
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (filters.shop) {
      setIsModalVisible(false);
      onFilterChange(filters);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFilterChange = (filterKey, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));

    if (filterKey === "parentCategory") {
      onParentCategoryChange(value);
      // Reset category when parent category changes
      setFilters((prev) => ({ ...prev, category: [] }));
    }

    // Close the select dropdown after selection
    if (filterKey === "category" || filterKey === "shop") {
      const selectElement = document.querySelector(`#${filterKey}-select`);
      if (selectElement) {
        selectElement.blur();
      }
    }
  };

  const clearFilter = (filterType, filterValue) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      if (filterType === "parentCategory") {
        newFilters.parentCategory = null;
        newFilters.category = [];
      } else if (filterType === "shop") {
        newFilters.shop = null; // Clear shop selection
      } else {
        newFilters[filterType] = newFilters[filterType].filter((value) => value !== filterValue);
      }
      return newFilters;
    });
    onFilterChange(filters);
  };

  const isSubmitDisabled = !filters.shop;

  return (
    <FilterContainer>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Button type="primary" onClick={showModal} size="large">
          Select Filters
        </Button>

        <StyledModal
          title="Select Filters"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={700}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk} disabled={isSubmitDisabled}>
              Apply Filters
            </Button>,
          ]}
        >
          <FilterTitle level={5}>Add-On Parent Category</FilterTitle>
          <StyledSelect
            id="parentCategory-select"
            placeholder="Select Parent Category"
            onChange={(value) => handleFilterChange("parentCategory", value)}
            value={filters.parentCategory}
          >
            {addonParentCategorys &&
              addonParentCategorys.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.name}
                </Option>
              ))}
          </StyledSelect>

          <FilterTitle level={5}>Add-On Category</FilterTitle>
          <StyledSelect
            id="category-select"
            mode="multiple"
            placeholder="Select Add-On Categories"
            onChange={(value) => handleFilterChange("category", value)}
            value={filters.category}
            disabled={!filters.parentCategory}
          >
            {addonCategorys &&
              addonCategorys.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.name}
                </Option>
              ))}
          </StyledSelect>

          <FilterTitle level={5}>Shop (Required)</FilterTitle>
          <StyledSelect
            id="shop-select"
            placeholder="Select Shop"
            onChange={(value) => handleFilterChange("shop", value)}
            value={filters.shop}
          >
            {shops &&
              shops.map((shop) => (
                <Option key={shop._id} value={shop._id}>
                  {shop.name}
                </Option>
              ))}
          </StyledSelect>
        </StyledModal>

        <TagContainer>
          <Title level={5} style={{ marginRight: "16px", marginBottom: "0" }}>
            Active filters:
          </Title>
          {filters.parentCategory && addonParentCategorys && (
            <Tag color="magenta" closable onClose={() => clearFilter("parentCategory", filters.parentCategory)}>
              {addonParentCategorys.find((c) => c._id === filters.parentCategory)?.name || "Unknown Parent Category"}
            </Tag>
          )}
          {filters.category.map((categoryId) => {
            const category = addonCategorys && addonCategorys.find((c) => c._id === categoryId);
            return (
              <Tag color="blue" key={categoryId} closable onClose={() => clearFilter("category", categoryId)}>
                {category ? category.name : "Unknown Category"}
              </Tag>
            );
          })}
          {filters.shop && (
            <Tag color="green" key={filters.shop} closable onClose={() => clearFilter("shop", filters.shop)}>
              {shops.find((s) => s._id === filters.shop)?.name || "Unknown Shop"}
            </Tag>
          )}
        </TagContainer>
      </Space>
    </FilterContainer>
  );
};

export default AddOnReportFilter;
