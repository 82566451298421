import React from "react";
import { Table } from "antd";

function ShopExpenseGpTable({ formValues }) {
  const expenseColumns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Shop Labor",
      dataIndex: "shopLabor",
      key: "shopLabor",
    },
    {
      title: "Shop Material",
      dataIndex: "shopMaterial",
      key: "shopMaterial",
    },
    {
      title: "Shop Cost",
      dataIndex: "shopCost",
      key: "shopCost",
    },
    {
      title: "Shop Profit",
      dataIndex: "shopProfit",
      key: "shopProfit",
    },
  ];
  const expenseDataSource = [
    {
      key: "1",
      category: "Builder Labor",
      shopLabor:
        formValues &&
        formValues.labor?.builderLaborTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "2",
      category: "Roof Labor",
      shopLabor:
        formValues &&
        formValues.labor?.roofLaborTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "3",
      category: "Truss Labor",
      shopLabor:
        formValues &&
        formValues.labor?.trussLaborTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "4",
      category: "Door Labor",
      shopLabor:
        formValues &&
        formValues.labor?.doorLaborTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "5",
      category: "Paint Labor",
      shopLabor:
        formValues &&
        formValues.labor?.paintLaborTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "6",
      category: "Shed Total Labor",
      shopLabor:
        formValues &&
        formValues.labor?.shedsLaborTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "7",
      category: "Add-On Total Labor",
      shopLabor:
        formValues &&
        formValues.addOnsLaborTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "8",
      category: "Custom Add-ON Total Labor",
      shopLabor:
        formValues &&
        formValues.customAddOnsLaborTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "9",
      category: "Total Labor",
      shopLabor:
        formValues &&
        formValues.laborTotalCost?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "10",
      category: "Shed Material Cost",
      shopMaterial:
        formValues &&
        formValues.shedsMaterialTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "11",
      category: "Add-Ons Material Cost",
      shopMaterial:
        formValues &&
        formValues.addOnsMaterialTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "12",
      category: "Custom Add-Ons Material Cost",
      shopMaterial:
        formValues &&
        formValues.customAddOnsMaterialTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "13",
      category: "Total Material Cost",
      shopMaterial:
        formValues &&
        formValues.materialTotalCost?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "14",
      category: "Shed Total Cost",
      shopCost:
        formValues &&
        formValues.baseShedsTotalCost?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "15",
      category: "Add-Ons Total Cost",
      shopCost:
        formValues &&
        formValues.addOnsTotalCost?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "16",
      category: "Custom Add-Ons Total Cost",
      shopCost:
        formValues &&
        formValues.customAddOnsTotalCost?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "166",
      category: "Overhead",
      shopCost:
        formValues &&
        formValues.overheadTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "17",
      category: "Total Cost",
      shopCost:
        formValues &&
        formValues.totalCost?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "18",
      category: "Shed GP",
      shopProfit:
        formValues &&
        formValues.baseShedsProfitDollars?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "188",
      category: "Shed GP %",
      shopProfit:
        formValues &&
        formValues.baseShedsAverageProfitPercent?.shop?.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 }),
    },
    {
      key: "19",
      category: "Add-Ons GP",
      shopProfit:
        formValues &&
        formValues.addOnsProfitDollars?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "199",
      category: "Add-Ons GP %",
      shopProfit:
        formValues &&
        formValues.addOnsAverageProfitPercent.shop.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 }),
    },
    {
      key: "202",
      category: "Custom Add-Ons GP",
      shopProfit:
        formValues &&
        formValues.customAddOnsProfitDollars?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "20",
      category: "Custom Add-Ons GP %",
      shopProfit:
        formValues &&
        formValues.customAddOnsAverageProfitPercent?.shop?.toLocaleString("en-US", {
          style: "percent",
          minimumFractionDigits: 2,
        }),
    },

    {
      key: "21",
      category: "Total GP",
      shopProfit:
        formValues &&
        formValues.profitDollarsTotal?.shop?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "22",
      category: "Total GP %",
      shopProfit:
        formValues &&
        formValues.averageProfitPercent?.shop?.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 }),
    },
  ];

  return <Table columns={expenseColumns} dataSource={expenseDataSource} pagination={false} />;
}

export default ShopExpenseGpTable;
