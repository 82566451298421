import React, { Fragment, useEffect, useState, useContext } from "react";
import ShedModelContext from "../../../../../../../context/shedModel/shedModelContext";
import ShedLaborContext from "../../../../../../../context/shedLabor/shedLaborContext";
import ShopContext from "../../../../../../../context/shop/shopContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllShedLaborModels = () => {
  const navigate = useNavigate();
  const shedModelContext = useContext(ShedModelContext);
  const { getShedModels, shedModels, setCurrentShedModel } = shedModelContext;

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  const shedLaborContext = useContext(ShedLaborContext);
  const { getAllShedLabors, allShedLabors, updateShopLabors, getShedLaborsByModel, calcLaborBase } = shedLaborContext;

  useEffect(() => {
    getShop();
  }, []);

  useEffect(() => {
    currentShop && getAllShedLabors(currentShop.defaultCategory._id);
    currentShop && getShedModels(currentShop.defaultCategory._id);
  }, [currentShop]);

  useEffect(() => {}, [shedModels]);

  const columns = [
    {
      title: "Name",
      width: "20%",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewModel(record)}>
            View
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewModel = (model) => {
    // getShedLaborsByModel(model);
    setCurrentShedModel(model);
    navigate("/shop/sheds/labor/model/view");
  };

  let laborsForExportByModel = {};

  // Assuming allLabors is the array containing all your labor data
  allShedLabors &&
    allShedLabors.forEach((labor) => {
      // Group labors by model
      if (!laborsForExportByModel[labor.model.name]) {
        laborsForExportByModel[labor.model.name] = [];
      }
      let newLabor = {};
      newLabor.Id = labor._id;
      newLabor.baseStyle = labor.baseStyle;
      newLabor.Model = labor.model.code;
      newLabor.Width = labor.width;
      newLabor.Length = labor.length;

      // Check if we found a shopValue before trying to access its properties
      newLabor.buildBase = labor.shopValues.buildBase;
      newLabor.doorCost = labor.shopValues.doorCost;
      newLabor.paintHours = labor.shopValues.paintHours;
      newLabor.roofBase = labor.shopValues.roofBase;
      newLabor.trussCount = labor.shopValues.trussCount;
      newLabor.totalLabor = labor.shopValues.totalLaborCost;

      laborsForExportByModel[labor.model.name].push(newLabor);
    });

  const exportFile = () => {
    const wb = utils.book_new();
    // Create a new worksheet for each model
    for (let model in laborsForExportByModel) {
      const ws = utils.json_to_sheet(laborsForExportByModel[model]);
      const sanitizedModel = sanitizeSheetName(model);
      utils.book_append_sheet(wb, ws, sanitizedModel);
    }
    writeFileXLSX(wb, `Shed_Labors.xlsx`);
  };

  function sanitizeSheetName(name) {
    // Replace invalid characters with underscore
    return name.replace(/[:\\/?*[\]]/g, "_");
  }
  const importFile = () => {
    updateShopLabors(newFile);
  };
  const [newFile, setNewFile] = useState("");

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      // Initialize an empty array to hold all items
      let allItems = [];
      // Iterate over all sheets in the workbook
      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        // Append items from this sheet to the allItems array
        allItems = [...allItems, ...json];
      });
      setNewFile(allItems);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const calcBuildBase = () => {
    calcLaborBase();
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-2">
          {allShedLabors && allShedLabors.length > 0 && (
            <button className="mb-2 ms-4 btn btn-primary" onClick={() => exportFile()}>
              Export Excel
            </button>
          )}
        </div>
        <div className="col-md-3">
          <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
        </div>

        <div className="col-md-2">
          <button className="mb-2 me-2 btn btn-primary" onClick={() => importFile()}>
            Import Excel
          </button>
          {/* <button className="mb-2 me-2 btn btn-primary" onClick={() => calcBuildBase()}>
            Calculate Build Base Off Retail Price
          </button> */}
        </div>
        <div className="col-md-5"></div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        // loading={!loadingProduct && productResults ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
        dataSource={shedModels}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllShedLaborModels;
