import React, { Fragment, useEffect, useContext } from "react";
import ShopContext from "../../../../../context/shop/shopContext";
import UserColumns from "./UserColumns";

const AllUsers = () => {
  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const theTitle = "Users";

  return <UserColumns shopUsers={currentShop && currentShop.shopUsers} theTitle={theTitle} />;
};

export default AllUsers;
