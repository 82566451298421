import React, { useEffect, useContext } from "react";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import AddonParentCategoryContext from "../../../../../../context/addonParentCategory/addonParentCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";

const defaultValues = {};
const EditShedCategory = () => {
  const navigate = useNavigate();
  const shedCategoryContext = useContext(ShedCategoryContext);
  const {
    addShedCategory,
    updateShedCategory,
    currentShedCategory,
    clearCurrentShedCategory,
    getShedCategorys,
    shedCategorys,
    copyExistingCategoryData,
  } = shedCategoryContext;

  const addOnParentCategoryContext = useContext(AddonParentCategoryContext);
  const { getAddonParentCategorys, addonParentCategorys } = addOnParentCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const addonParentCategoryOptions = addonParentCategorys?.map((addonParentCategory) => {
    return { value: addonParentCategory._id, label: addonParentCategory.name };
  });

  // Ensure this useEffect runs only once to fetch addon parent categories
  useEffect(() => {
    getAddonParentCategorys();
  }, []);

  // Ensure this useEffect runs when currentShedCategory changes to reset the form
  useEffect(() => {
    if (currentShedCategory) {
      reset(currentShedCategory);
    }
  }, [currentShedCategory]);

  // Ensure this useEffect runs when addonParentCategorys or currentShedCategory changes
  useEffect(() => {
    if (addonParentCategorys && currentShedCategory) {
      const matchedAddonParentCategories = addonParentCategorys.filter((addonParentCategory) =>
        currentShedCategory.addonParentCategories.includes(addonParentCategory._id)
      );

      const selectedOptions = matchedAddonParentCategories.map((cat) => ({
        value: cat._id,
        label: cat.name,
      }));

      setValue("addonParentCategories", selectedOptions);
    }
  }, [addonParentCategorys, currentShedCategory, setValue]);

  const onSubmit = (data) => {
    // if (!currentShedCategory) {
    // addShedCategory(data);
    // copyExistingCategoryData(data);
    // navigate(-1);
    // } else {
    updateShedCategory(data);
    //   navigate(-1);
    clearCurrentShedCategory();
    // }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentShedCategory();
  };

  const handleAddonParentCategoryChange = (e) => {
    setValue("addonParentCategories", e);
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">Edit Shed Category Form</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form className="haulBaseForm" onSubmit={handleSubmit(onSubmit)}>
            <label className="mt-3" htmlFor="newCategoryName">
              Shed Category Name
            </label>
            <input className="form-control" type="string" {...register("name", { required: true })} id="name" />

            <label htmlFor="baseStyle">Addon Parent Categories</label>
            <Controller
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  isClearable={true}
                  isMulti
                  isSearchable
                  options={addonParentCategoryOptions}
                  // menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  onChange={(e) => {
                    handleAddonParentCategoryChange(e);
                  }}
                />
              )}
              name="addonParentCategories"
              control={control}
              className="shedlaborforminput"
            />

            <button
              // className={user.viewOnly ? "viewOnly" : "shedlaborformbtn"}
              // disabled={user.viewOnly || !isBaseStyleSelected}
              className="shedlaborformbtn mt-4"
              type="submit"
              // disabled={!isBaseStyleSelected}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditShedCategory;
