import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PaymentLineChart = ({ paymentData }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const sortedData = paymentData.sort((a, b) => new Date(a.date) - new Date(b.date));

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Payment Totals, Gross Profit %, and Invoice Count (Last 3 Months)",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Total Amount ($)",
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: "Gross Profit (%)",
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y2: {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: "Invoice Count",
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const data = {
    labels: sortedData.map((d) => formatDate(d.date)),
    datasets: [
      {
        label: "Total Amount",
        data: sortedData.map((d) => d.totalAmount),
        borderColor: "rgb(0, 123, 255)", // Darker blue
        backgroundColor: "rgba(0, 123, 255, 0.5)",
        yAxisID: "y",
      },
      {
        label: "Gross Profit %",
        data: sortedData.map((d) => d.grossProfitPercentage),
        borderColor: "rgb(40, 167, 69)", // Green (unchanged)
        backgroundColor: "rgba(40, 167, 69, 0.5)",
        yAxisID: "y1",
      },
      {
        label: "Invoice Count",
        data: sortedData.map((d) => d.invoiceCount),
        borderColor: "rgb(255, 193, 7)", // Amber (complementary to blue and green)
        backgroundColor: "rgba(255, 193, 7, 0.5)",
        yAxisID: "y2",
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default PaymentLineChart;
