import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  blankrow: {
    flexDirection: "row",
    alignItems: "center",
    height: 34,
    fontStyle: "bold",
  },

  title: {
    width: "40%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },

  quantity: {
    width: "20%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    textAlign: "left",
    paddingLeft: 8,
  },

  container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

const InvoiceTableRow = ({ values }) => {
  const rows = values.items.map((item) => (
    <Fragment key={item._id}>
      <View wrap={false}>
        <View style={styles.container}>
          <View style={styles.row}>
            <Text style={styles.title}>{item.item.name}</Text>
            <Text style={styles.quantity}>{item.quantity}</Text>
            <Text style={styles.quantity}>
              {item.rate.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Text>
            <Text style={styles.quantity}>
              {item.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Text>
          </View>
        </View>
      </View>
    </Fragment>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
