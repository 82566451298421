import React, { Fragment, useState, useContext } from "react";
import AuthContext from "../../../../context/auth/authContext";
import { Popconfirm } from "antd";
import RepairInvoiceContext from "../../../../context/repairInvoice/repairInvoiceContext";

const PopConfirm = ({ record, msg, btnText, theStatus, myClasses }) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const repairInvoiceContext = useContext(RepairInvoiceContext);
  const { updateRepairInvoice, statusChange } = repairInvoiceContext;
  const [visible, setVisible] = useState(false);

  const handleShowPopConfirm = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    handleStatusChange();
  };

  const handleStatusChange = () => {
    let theDate = new Date();
    const newMsg = {
      madeBy: user.name,
      date: theDate,
      msg: `This Invoice has been ${theStatus}.`,
    };
    record.status = theStatus;
    record.messages.push(newMsg);
    updateRepairInvoice(record);
    statusChange(record);
  };

  return (
    <Fragment>
      <Popconfirm
        title={msg}
        visible={visible}
        onConfirm={handleOk}
        // okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
        okText="Yes"
      ></Popconfirm>
      <button className={myClasses} type="button" onClick={(e) => handleShowPopConfirm()}>
        {btnText}
      </button>
    </Fragment>
  );
};

export default PopConfirm;
