import {
  ADD_COMPANY,
  DELETE_COMPANY,
  CLEAR_COMPANYS,
  SET_CURRENT_COMPANY,
  CLEAR_CURRENT_COMPANY,
  UPDATE_COMPANY,
  COMPANY_ERROR,
  GET_COMPANYS,
  SET_COMPANYS,
  FILTER_COMPANYS,
  CLEAR_FILTER_COMPANY,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_COMPANYS:
      return {
        ...state,
        company: action.payload,
        loading: false,
      };
    case ADD_COMPANY:
      return {
        ...state,
        company: action.payload,
        loading: false,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        company: action.payload,
        loading: false,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        companys: state.companys.filter((company) => company._id !== action.payload),
        loading: false,
      };
    case CLEAR_COMPANYS:
      return {
        ...state,
        companys: null,
        filteredCompany: null,
        error: null,
        currentCompany: null,
      };
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.payload,
      };

    case SET_COMPANYS:
      return {
        ...state,
        companys: action.payload,
      };
    case CLEAR_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: null,
      };

    case COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FILTER_COMPANYS:
      return {
        ...state,
        filteredCompany: state.companys.filter((company) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          console.log("this is the search company", action.payload, company);
          return company.name.value.match(regex);
        }),
      };
    case CLEAR_FILTER_COMPANY:
      return {
        ...state,
        filteredProduct: null,
      };
    default:
      return state;
  }
};
