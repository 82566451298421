import {
  ADD_DEALER,
  DELETE_DEALER,
  CLEAR_DEALERS,
  SET_CURRENT_DEALER,
  CLEAR_CURRENT_DEALER,
  UPDATE_DEALER,
  DEALER_ERROR,
  GET_DEALERS,
  SET_DEALERS,
  FILTER_DEALERS,
  CLEAR_FILTER_DEALER,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_DEALERS:
      return {
        ...state,
        dealers: action.payload,
        loading: false,
      };
    case ADD_DEALER:
      return {
        ...state,
        dealer: action.payload,
        loading: false,
      };
    case UPDATE_DEALER:
      return {
        ...state,
        dealers: state.dealers && state.dealers.map((dealer) => (dealer._id === action.payload._id ? action.payload : dealer)),
        loading: false,
      };
    case DELETE_DEALER:
      return {
        ...state,
        dealers: state.dealers.filter((dealer) => dealer._id !== action.payload),
        loading: false,
      };
    case CLEAR_DEALERS:
      return {
        ...state,
        dealers: null,
        filteredDealer: null,
        error: null,
        currentDealer: null,
      };
    case SET_CURRENT_DEALER:
      return {
        ...state,
        currentDealer: action.payload,
      };

    case SET_DEALERS:
      return {
        ...state,
        dealers: action.payload,
      };
    case CLEAR_CURRENT_DEALER:
      return {
        ...state,
        currentDealer: null,
      };

    case DEALER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FILTER_DEALERS:
      return {
        ...state,
        filteredDealer: state.dealers.filter((dealer) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return dealer.name.match(regex);
        }),
      };
    case CLEAR_FILTER_DEALER:
      return {
        ...state,
        filteredProduct: null,
      };
    default:
      return state;
  }
};
