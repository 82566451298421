import React from "react";
import { Page, Document, Image, StyleSheet, View, Text } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceItemsTable from "./InvoiceItemsTable";
// import logo from '../../../src/logo.png'

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 15,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
  grandTotal: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

const Invoice = ({ data, shop }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <InvoiceTitle title="Invoice" />

        <BillTo values={data} shop={shop} />
        <InvoiceItemsTable values={data} />
        <View style={styles.grandTotal}>
          <Text>
            Grand Total:{" "}
            {data.invoiceTotal.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
