import React, { Fragment, useEffect, useState, useContext } from "react";
import ComponentContext from "../../../../../../context/component/componentContext";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllComponents = () => {
  const navigate = useNavigate();
  const componentContext = useContext(ComponentContext);
  const {
    getComponents,
    components,
    filteredComponents,
    loadingComponent,
    setCurrentComponent,
    clearCurrentComponent,
    updateAllComponents,
    clearComponents,
  } = componentContext;

  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { currentComponentCategory } = componentCategoryContext;

  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  useEffect(() => {
    clearCurrentComponent();
    getShop();
    // clear components with cleanup
    return () => {
      clearComponents();
    };
  }, []);

  useEffect(() => {
    currentShop && getComponents(currentShop.defaultCategory._id, currentComponentCategory._id, currentShop._id);
  }, [currentShop]);

  useEffect(() => {}, [components]);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  // components &&
  //   components.map((component) => {
  //     component.value.items.forEach((item) => {
  //       let newItem = {};
  //       newItem.Component_Id = component._id;
  //       newItem.Component_Name = component.value.name;
  //       newItem.Item = item.item.value.name;
  //       newItem.Quantity = item.count;
  //       newItem.Item_Id = item.item._id;
  //       // newItem.Id = item._id;
  //       itemsForExport.push(newItem);
  //     });
  //   });

  const exportFile = () => {
    const ws = utils.json_to_sheet(itemsForExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, "Components_BOM.xlsx");
  };

  const importFile = () => {
    updateAllComponents(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const columns = [
    {
      title: "Name",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },
    {
      title: "Standard Cost",
      render: (record) => (
        <Fragment>
          {record.componentStandardTotalCost &&
            record.componentStandardTotalCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Shop Cost",
      render: (record) => (
        <Fragment>
          {record.componentShopTotalCost &&
            record.componentShopTotalCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          {components && (
            <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewComponent(record)}>
              View
            </button>
          )}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewComponent = (component) => {
    setCurrentComponent(component);
    navigate("/shop/component/form");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <button className="mb-2 btn btn-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        loading={components ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        dataSource={filteredComponents && filteredComponents !== null ? filteredComponents : components && components}
        // dataSource={testModels}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllComponents;
