import React, { useContext, Fragment, useState, useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import NumberFormat from "react-number-format";
import { Table } from "antd";
import AuthContext from "../../../context/auth/authContext";

import { handlePriceChange, setTotals, handleShopPercentChange } from "./OrderFunctions";

export default function Fields({ control, register, setValue, getValues, allAddOns, watch }) {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "addOns",
  });

  const [fieldsState, setFieldsState] = useState([]);
  useEffect(() => {
    setFieldsState(fields);
  }, [fields]);

  console.log("This is fields", fields);

  const handleRetailPriceChange = (fieldName, value, index) => {
    handlePriceChange(fieldName, value, index, "retailPrice", getValues, setValue);
  };

  const handleQuantityChange = (fieldName, value, index) => {
    handlePriceChange(fieldName, value, index, "quantity", getValues, setValue);
  };

  const handleAddOnChange = (addOn, index) => {
    if (!addOn) {
      return;
    }
    const quantity = getValues(`addOns[${index}].quantity`);
    const totalSalePrice = {
      company: addOn.retailPrice * quantity,
      shop: addOn.retailPrice * quantity * (addOn.shopPercent / 100),
    };
    setValue(`addOns[${index}].name`, addOn.name);
    setValue(`addOns[${index}].shopPercent`, addOn.shopPercent);
    setValue(`addOns[${index}].totalSalePrice`, totalSalePrice);
    setValue(`addOns[${index}].addOn.type`, addOn.type);
    // handlePriceChange(`addOns[${index}].retailPrice`, addOn.retailPrice || 0, index, "retailPrice", getValues, setValue, () =>
    //   setTotals(getValues, setValue, shopSettings, companySettings)
    // );
    handlePriceChange(`addOns[${index}].retailPrice`, addOn.retailPrice, index, "retailPrice", getValues, setValue);
  };

  const setShopPercent = (percent, index) => {
    setValue(`addOns[${index}].shopPercent`, percent);
    // const retailPrice = getValues(`addOns[${index}].retailPrice`);
    // const quantity = getValues(`addOns[${index}].quantity`);
    // const totalSalePrice = retailPrice * quantity;
    // const shopTotalSalePrice = totalSalePrice * (percent / 100);
    // setValue(`addOns[${index}].totalSalePrice.company`, totalSalePrice);
    // setValue(`addOns[${index}].totalSalePrice.shop`, shopTotalSalePrice);
    handleShopPercentChange(`addOns[${index}].shopPercent`, percent, index, getValues, setValue);
    // setTotals(getValues, setValue);
  };

  const theAddOnOptions =
    allAddOns &&
    allAddOns.map((standardAddOn) => {
      return { label: `${standardAddOn.name}`, value: standardAddOn };
    });

  const columns = [
    {
      title: "Add-On Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (_, record, index) => (
        <Fragment>
          <p>
            Shed-Suite Add-On Name: {record.name} ID: {record.addOn?.ssId || record.ssId || "N/A"}
          </p>
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                // value={
                //   (theAddOnOptions &&
                //     theAddOnOptions.find((option) => option.value._id === (field.value?._id || record.addOn))) ||
                //   null
                // }
                // see if allAddOns is not null and if it is not make read only false
                isDisabled={allAddOns ? false : true}
                value={(field.value && { label: field.value.name, value: field.value }) || null}
                isClearable={true}
                options={theAddOnOptions}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                onChange={(e) => {
                  field.onChange(e.value);
                  setValue(`addOns[${index}].addOn`, e.value);
                  handleAddOnChange(e.value, index);
                }}
              />
            )}
            name={`addOns[${index}].addOn`}
            control={control}
          />
        </Fragment>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, record, index) => (
        <Controller
          render={({ field, name }) => (
            <input
              className="form-control  custom-input "
              name={name}
              type="number"
              {...field}
              onChange={(e) => {
                const count = e.target.value;
                handleQuantityChange(field.name, count, index);
              }}
            />
          )}
          control={control}
          name={`addOns[${index}].quantity`}
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "retailPrice",
      key: "retailPrice",
      render: (_, record, index) => {
        const readOnly = false; // Set the readOnly condition based on your requirements
        const addOns = getValues().addOns;
        const isPercentType = addOns[index]?.addOn?.type === "percent";
        return {
          props: {
            style: {
              background: readOnly ? "#dbdbdb" : "",
            },
          },
          children: (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  className={`form-control custom-input`}
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={isPercentType ? "" : "$ "}
                  suffix={isPercentType ? "%" : ""}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={({ value }) => handleRetailPriceChange(field.name, value, index)}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
              control={control}
              name={`addOns[${index}].retailPrice`}
              // defaultValue={addOn.retailPrice}
            />
          ),
        };
      },
    },

    {
      title: "Total",
      dataIndex: "totalSalePrice",
      key: "totalSalePrice",
      render: (_, record, index) => {
        return {
          props: {
            style: {
              background: "#dbdbdb",
            },
          },
          children: (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  className="form-control custom-input readonlyinput"
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  // onValueChange={({ value }) => handleValueChange(field.name, value)}
                  onBlur={field.onBlur}
                  value={field.value}
                  readOnly
                />
              )}
              control={control}
              name={`addOns[${index}].totalSalePrice.company`}
              // defaultValue={addOn.retailPrice}
            />
          ),
        };
      },
    },
    {
      title: "Shop Percent",
      dataIndex: "shopPercent",
      key: "shopPercent",
      render: (_, record, index) => (
        <Controller
          render={({ field, name }) => (
            <input
              className="form-control  custom-input"
              name={name}
              type="number"
              {...field}
              onChange={(e) => {
                const percent = e.target.value;
                setShopPercent(percent, index);
              }}
            />
          )}
          control={control}
          name={`addOns[${index}].shopPercent`}
        />
      ),
    },

    {
      title: "Shop Total",
      dataIndex: "totalSalePrice",
      key: "totalSalePrice",
      render: (_, record, index) => {
        return {
          props: {
            style: {
              background: "#dbdbdb",
            },
          },
          children: (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  readOnly
                  className={`form-control custom-input readonlyinput`}
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  // onValueChange={({ value }) => handleRetailPriceChange(value, index)}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
              control={control}
              name={`addOns[${index}].totalSalePrice.shop`}
              // defaultValue={addOn.retailPrice}
            />
          ),
        };
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record, index) => (
        <button className="btn btn-danger float-end" onClick={(e) => handleDeleteLine(e, fields[index].id)}>
          Delete
        </button>
      ),
    },
  ];

  const data = fields.map((field, index) => {
    return {
      key: field.id, // Use the field's id as the key
      name: field.name,
      ssId: field.addOn?.ssId,
      // ... other fields
    };
  });

  const handleAddNewLine = (e) => {
    e.preventDefault();
    append({
      name: "",
      quantity: 0,
      retailPrice: 0,
      totalSalePrice: { shop: 0, company: 0 },
      storMorCustomAddOnsTotalSalePrice: 0,
      shopPercent: 75,
      totalMaterialCost: {
        shop: 0,
        standard: 0,
      },
      totalLaborCost: {
        shop: 0,
        standard: 0,
      },
      totalCost: {
        shop: 0,
        standard: 0,
      },
      profit: {
        shop: 0,
        standard: 0,
      },
      profitPercent: {
        shop: 0,
        standard: 0,
      },
    });
  };

  const handleDeleteLine = (e, fieldId) => {
    e.preventDefault();
    const index = fields.findIndex((field) => field.id === fieldId);
    remove(index);
    setTotals(getValues, setValue);
  };

  const formValues = watch(); // Get the current form values

  return (
    <div className="mt-4">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="key"
        expandable={{
          expandedRowRender: (record) =>
            user.role === "Shop User" || user.role === "Shop Admin" ? (
              <ShopNestedTableRow record={record} control={control} formValues={formValues} fields={fields} />
            ) : (
              <StandardNestedTableRow record={record} control={control} formValues={formValues} fields={fields} />
            ),
          rowExpandable: (record) => true,
        }}
      />

      <button className="btn btn-secondary mt-3 float-end" onClick={(e) => handleAddNewLine(e)}>
        Add Add-On
      </button>
    </div>
  );
}

function ShopNestedTableRow({ record, control, formValues, fields }) {
  const nestedColumns = [
    {
      title: "Name",
      dataIndex: "attribute",
      key: "attribute",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value, nestedRecord) => {
        if (nestedRecord.key === "8") {
          return (
            <NumberFormat
              value={value}
              readOnly
              className={`form-control custom-input readonlyinput`}
              // type="text"
              thousandSeparator={true}
              suffix={" % "}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          );
        } else {
          return (
            <NumberFormat
              value={value}
              readOnly
              className={`form-control custom-input readonlyinput`}
              // type="text"
              thousandSeparator={true}
              prefix={"$ "}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          );
        }
      },
    },
  ];

  const nestedData = [
    // ...
    {
      key: "4",
      attribute: "Material Cost",
      value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.totalMaterialCost.shop,
    },
    {
      key: "5",
      attribute: "Labor Cost",
      value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.totalLaborCost.shop,
    },
    {
      key: "6",
      attribute: "Total Cost",
      value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.totalCost.shop,
    },
    {
      key: "7",
      attribute: "Gross Profit",
      value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.profit.shop,
    },
    {
      key: "8",
      attribute: "Gross Profit Percent",
      value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.profitPercent.shop,
    },
    // ...
  ];

  // Rest of the component

  return <Table columns={nestedColumns} dataSource={nestedData} pagination={false} />;
}

function StandardNestedTableRow({ record, control, formValues, fields }) {
  const nestedColumns = [
    {
      title: "Name",
      dataIndex: "attribute",
      key: "attribute",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value, nestedRecord) => {
        if (nestedRecord.key === "8") {
          return (
            <NumberFormat
              value={value}
              readOnly
              className={`form-control custom-input readonlyinput`}
              // type="text"
              thousandSeparator={true}
              suffix={" % "}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          );
        } else {
          return (
            <NumberFormat
              value={value}
              readOnly
              className={`form-control custom-input readonlyinput`}
              // type="text"
              thousandSeparator={true}
              prefix={"$ "}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          );
        }
      },
    },
  ];

  const nestedData = [
    // ...
    {
      key: "4",
      attribute: "Standard Material Cost",
      value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.totalMaterialCost.standard,
    },
    {
      key: "5",
      attribute: "Standard Labor Cost",
      value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.totalLaborCost.standard,
    },
    {
      key: "6",
      attribute: "Standard Total Cost",
      value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.totalCost.standard,
    },
    {
      key: "7",
      attribute: "Standard Gross Profit",
      value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.profit.standard,
    },
    {
      key: "8",
      attribute: "Standard Gross Profit Percent",
      value: formValues.addOns[fields.findIndex((field) => field.id === record.key)]?.profitPercent.standard,
    },
    // ...
  ];

  // Rest of the component

  return <Table columns={nestedColumns} dataSource={nestedData} pagination={false} />;
}
