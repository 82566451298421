import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import BaseStyleContext from "./baseStyleContext";
import baseStyleReducer from "./baseStyleReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_BASESTYLE,
  DELETE_BASESTYLE,
  CLEAR_BASESTYLES,
  SET_CURRENT_BASESTYLE,
  CLEAR_CURRENT_BASESTYLE,
  UPDATE_BASESTYLE,
  SET_BASESTYLES,
  BASESTYLE_ERROR,
  GET_BASESTYLES,
  FILTER_BASESTYLES,
  CLEAR_FILTER_BASESTYLE,
} from "../types";

const BaseStyleState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    baseStyles: null,
    currentBaseStyle: null,
    error: null,
    filteredBaseStyle: null,
  };

  const [state, dispatch] = useReducer(baseStyleReducer, initialState);

  const getBaseStyles = async () => {
    try {
      const res = await axios.get(`/api/basestyle/`);
      dispatch({ type: GET_BASESTYLES, payload: res.data });
    } catch (err) {
      dispatch({ type: BASESTYLE_ERROR });
      console.log("this is the err", err);
      toast.error(`Shed ${err}`);
    }
  };

  const getShopBaseStyles = async () => {
    try {
      const res = await axios.get(`/api/basestyle/shop`);
      dispatch({ type: GET_BASESTYLES, payload: res.data });
    } catch (err) {
      dispatch({ type: BASESTYLE_ERROR });
      console.log("this is the err", err);
      toast.error(`Shed ${err}`);
    }
  };

  // add
  const addBaseStyle = async (baseStyle) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/basestyle", baseStyle, config);
      dispatch({ type: ADD_BASESTYLE, payload: res.data });
      navigate(-1);
    } catch (err) {
      dispatch({ type: BASESTYLE_ERROR });
    }
  };

  // delete
  const deleteBaseStyle = async (id) => {
    try {
      const res = await axios.delete(`/api/basestyle/delete/${id}`);
      dispatch({ type: DELETE_BASESTYLE, payload: id });
      toast.success(`BaseStyle is deleted`);
    } catch (err) {
      dispatch({ type: BASESTYLE_ERROR });
    }
  };

  // update
  const updateShopBaseStyle = async (baseStyle) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/basestyle/shop/update/`, baseStyle, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_BASESTYLE, payload: res.data });
      toast.success(`BaseStyle is updated`);
    } catch (err) {
      dispatch({ type: BASESTYLE_ERROR });
      toast.error(`BaseStyle ${err}`);
    }
  };

  // clear
  const clearBaseStyles = () => {
    dispatch({ type: CLEAR_BASESTYLES });
  };

  // set current
  const setCurrentBaseStyle = (baseStyle) => {
    dispatch({ type: SET_CURRENT_BASESTYLE, payload: baseStyle });
  };

  const setBaseStyles = (labors) => {
    dispatch({ type: SET_BASESTYLES, payload: labors });
  };

  // clear current
  const clearCurrentBaseStyle = () => {
    dispatch({ type: CLEAR_CURRENT_BASESTYLE });
  };

  const filterBaseStyles = (text) => {
    dispatch({ type: FILTER_BASESTYLES, payload: text });
  };

  const clearFilterBaseStyle = () => {
    dispatch({ type: CLEAR_FILTER_BASESTYLE });
  };

  return (
    <BaseStyleContext.Provider
      value={{
        baseStyles: state.baseStyles,
        currentBaseStyle: state.currentBaseStyle,
        error: state.error,
        filteredBaseStyle: state.filteredBaseStyle,

        setBaseStyles,
        addBaseStyle,
        deleteBaseStyle,
        setCurrentBaseStyle,
        clearCurrentBaseStyle,
        updateShopBaseStyle,
        clearBaseStyles,
        filterBaseStyles,
        clearFilterBaseStyle,
        getBaseStyles,
        getShopBaseStyles,
      }}
    >
      {props.children}
    </BaseStyleContext.Provider>
  );
};

export default BaseStyleState;
