import React, { useContext, useEffect, useState } from "react";
import InvoiceContext from "./invoiceContext";
import ShopContext from "../shop/shopContext";
import Select from "react-select";
import axios from "axios";
import moment from "moment";

const ShopFilter = ({ status }) => {
  const invoiceContext = useContext(InvoiceContext);
  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
  const { cutOffDate, invoices, setFilteredInvoices, clearFilteredInvoices } = invoiceContext;

  const shopContext = useContext(ShopContext);
  const { shops, getShops, setCurrentShop, currentShop, clearCurrentShop } = shopContext;

  useEffect(() => {
    getShops();
    return () => {};
  }, []);

  useEffect(() => {
    filterInvoices();
  }, [cutOffDate]);

  const shopNames =
    shops &&
    shops.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const filterInvoices = (shop) => {
    let filteredInvoices = invoices;

    if (cutOffDate) {
      filteredInvoices =
        invoices &&
        invoices.filter((invoice) => {
          const isSameOrBefore = moment(invoice.invoicedDate).isSameOrBefore(cutOffDate);
          const hasSameShop = !shop || invoice.shop._id === shop._id;
          return isSameOrBefore && hasSameShop;
        });
      setFilteredInvoices(filteredInvoices);
    } else if (shop) {
      filteredInvoices = invoices.filter((invoice) => {
        return invoice.shop._id === shop._id;
      });
      setFilteredInvoices(filteredInvoices);
    } else {
      setFilteredInvoices(null);
    }
  };

  const onChange = (e) => {
    if (e && e.value !== null) {
      setCurrentShop(e.value);
      filterInvoices(e.value);
    } else {
      clearFilteredInvoices();
      clearCurrentShop();
      filterInvoices();
    }
  };

  return (
    <form className="pb-2">
      <label>Filter By Shop</label>
      <Select
        className=""
        classNamePrefix="select"
        name="shop"
        options={shopNames}
        onChange={onChange}
        isClearable={true}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        value={(shopNames && shopNames.find((option) => option.value._id === currentShop?._id)) || null}
      />
    </form>
  );
};

export default ShopFilter;
