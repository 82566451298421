import React, { useReducer } from "react";
import CommentContext from "./commentContext";
import commentReducer from "./commentReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_COMMENT,
  DELETE_COMMENT,
  CLEAR_COMMENTS,
  UPDATE_COMMENT,
  GET_COMMENTS,
  COMMENT_ERROR,
  SET_COMMENT_DRAWER,
  GET_COMMENTS_FOR_ORDER,
  SET_NOTIFICATION,
  ADD_COMMENT_TO_CONVERSATION,
  GET_SHOP_COMMENTS,
  SET_LOADING_COMMENTS,
  SET_CURRENT_SHOP,
  ADD_ORDER_COMMENT,
} from "../types";

const CommentState = (props) => {
  const initialState = {
    comments: [],
    shopComments: [],
    currentShop: null,
    orderComments: null,
    commentDrawerVisible: "",
    loadingComments: true,
    notification: [],
  };

  const [state, dispatch] = useReducer(commentReducer, initialState);

  // get unread
  const getComments = async () => {
    try {
      const res = await axios.get("/api/comment/unread");
      dispatch({ type: GET_COMMENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  // get unread
  const getShopComments = async (id) => {
    try {
      setLoadingComments(true);
      const res = await axios.get(`/api/comment/shop/${id}`);
      dispatch({ type: GET_SHOP_COMMENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  // get all
  const getAllComments = async () => {
    try {
      const res = await axios.get("/api/comment/all");
      dispatch({ type: GET_COMMENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  const getCommentsForOrder = async (order) => {
    try {
      const res = await axios.get(`/api/comment/order`, {
        params: {
          orderId: order._id,
        },
      });
      dispatch({ type: GET_COMMENTS_FOR_ORDER, payload: res.data });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  const addComment = async (comment) => {
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };
    try {
      // const res = await axios.post("/api/comment/", { comment }, config);
      dispatch({ type: ADD_COMMENT, payload: comment });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  const addOrderComment = async (comment) => {
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };
    try {
      // const res = await axios.post("/api/comment/", { comment }, config);
      dispatch({ type: ADD_ORDER_COMMENT, payload: comment });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  const deleteComment = async (comment) => {
    try {
      const res = await axios.delete(`/api/comment/delete/${comment._id}`);
      console.log("this is the res", res.data.msg);
      dispatch({ type: DELETE_COMMENT, payload: comment._id });
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
    }
  };

  // update Product
  const updateComment = async (comment) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/comment/update/read/${comment._id}`, comment, config);
      dispatch({ type: UPDATE_COMMENT, payload: comment });
      // toast.success(`Comment is updated to read`);
    } catch (err) {
      dispatch({ type: COMMENT_ERROR });
      toast.error(`Comment is not updated`);
    }
  };

  const clearComments = () => {
    dispatch({ type: CLEAR_COMMENTS });
  };

  const setCommentDrawerVisible = (visible) => {
    dispatch({ type: SET_COMMENT_DRAWER, payload: visible });
  };

  const setNotification = (note) => {
    dispatch({ type: SET_NOTIFICATION, payload: note });
  };

  const addCommentToConversation = (note) => {
    dispatch({ type: ADD_COMMENT_TO_CONVERSATION, payload: note });
  };
  const setLoadingComments = (bool) => {
    dispatch({ type: SET_LOADING_COMMENTS, payload: bool });
  };

  const setCurrentShop = (shop) => {
    dispatch({ type: SET_CURRENT_SHOP, payload: shop });
  };

  const setUpdatedComments = (comments) => {
    dispatch({ type: GET_SHOP_COMMENTS, payload: comments });
  };

  return (
    <CommentContext.Provider
      value={{
        comments: state.comments,
        orderComments: state.orderComments,
        shopComments: state.shopComments,
        commentDrawerVisible: state.commentDrawerVisible,
        loadingComments: state.loadingComments,
        notification: state.notification,
        currentShop: state.currentShop,
        setCurrentShop,
        getComments,
        addComment,
        deleteComment,
        updateComment,
        clearComments,
        setCommentDrawerVisible,
        getCommentsForOrder,
        getAllComments,
        setNotification,
        addCommentToConversation,
        getShopComments,
        setLoadingComments,
        setUpdatedComments,
        addOrderComment,
      }}
    >
      {props.children}
    </CommentContext.Provider>
  );
};

export default CommentState;
