import {
  ADD_METALORDER,
  DELETE_METALORDER,
  CLEAR_METALORDERS,
  SET_CURRENT_METALORDER,
  CLEAR_CURRENT_METALORDER,
  UPDATE_METALORDER,
  FILTER_METALORDERS,
  CLEAR_FILTER_METALORDER,
  METALORDER_ERROR,
  GET_METALORDERS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_METALORDERS:
      return {
        ...state,
        metalOrders: action.payload,
        loading: false,
      };
    case ADD_METALORDER:
      return {
        ...state,
        metalOrders: [action.payload, ...(state.metalOrders || [])],
        loading: false,
      };
    case UPDATE_METALORDER:
      return {
        ...state,
        metalOrders: state.metalOrders?.map((metalOrder) =>
          metalOrder._id === action.payload._id ? action.payload : metalOrder
        ),
        loading: false,
      };
    case DELETE_METALORDER:
      return {
        ...state,
        metalOrders: state.metalOrders?.filter((metalOrder) => metalOrder._id !== action.payload),
        loading: false,
      };
    case CLEAR_METALORDERS:
      return {
        ...state,
        metalOrders: null,
        filteredMetalOrder: null,
        error: null,
        currentMetalOrder: null,
      };
    case SET_CURRENT_METALORDER:
      return {
        ...state,
        currentMetalOrder: action.payload,
      };
    case CLEAR_CURRENT_METALORDER:
      return {
        ...state,
        currentMetalOrder: null,
      };
    case FILTER_METALORDERS:
      return {
        ...state,
        filteredMetalOrder: state.metalOrders.filter((metalOrder) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return metalOrder.customer.value.name.match(regex);
        }),
      };
    case CLEAR_FILTER_METALORDER:
      return {
        ...state,
        filteredMetalOrder: null,
      };
    case METALORDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
