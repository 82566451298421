import React from "react";
import { useUpdateCheck } from "react-update-notification";

const UpdateApp = ({ customMessage }) => {
  const { status, reloadPage } = useUpdateCheck({
    type: "interval",
    interval: 300000,
  });

  if (status === "checking" || status === "current") {
    return null;
  }

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)", // Lighter tint
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start", // Align to top
      zIndex: 1000,
      padding: "20px 0",
      pointerEvents: "none", // Allow clicking through the overlay
    },
    card: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "16px 24px",
      width: "80%",
      maxWidth: "800px",
      pointerEvents: "auto", // Re-enable pointer events for the card
    },
    messageContainer: {
      marginBottom: "16px",
    },
    mainMessage: {
      color: "#333",
      margin: "0 0 8px 0",
      fontSize: "18px",
      fontWeight: "bold",
    },
    subMessage: {
      color: "#666",
      margin: "0 0 8px 0",
      fontSize: "14px",
    },
    customMessage: {
      backgroundColor: "#e6f7ff",
      border: "1px solid #91d5ff",
      borderRadius: "4px",
      padding: "8px 12px",
      color: "#0050b3",
      fontSize: "14px",
      fontWeight: "bold",
    },
    updateButton: {
      backgroundColor: "#4CAF50",
      border: "none",
      color: "white",
      padding: "10px 20px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "16px",
      cursor: "pointer",
      borderRadius: "4px",
      transition: "background-color 0.3s",
    },
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.card}>
        <div style={styles.messageContainer}>
          <p style={styles.mainMessage}>A new version of SHED-PRO is available.</p>
          <p style={styles.subMessage}>Please update at your earliest convenience.</p>
          {customMessage && <p style={styles.customMessage}>{customMessage}</p>}
        </div>
        <button
          style={styles.updateButton}
          onClick={reloadPage}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#4CAF50")}
        >
          Update Now
        </button>
      </div>
    </div>
  );
};

export default UpdateApp;
