import React, { useContext, Fragment } from "react";
import { Modal } from "antd";
import OrderContext from "../../../../context/order/orderContext";

const DetailModal = ({ isModalVisible, handleOk, handleCancel }) => {
  const orderContext = useContext(OrderContext);

  const { currentOrder } = orderContext;
  console.log("This is currentOrder", currentOrder);

  const showDetails = () => {
    const details = [];
    const addOns = currentOrder && currentOrder.addOns && currentOrder.addOns.map((p) => <p key={p._id}>{p.addOn.name}</p>);
    const customAddOns =
      currentOrder && currentOrder.customAddOns && currentOrder.customAddOns.map((p) => <p key={p._id}>{p.name}</p>);
    const customAddOnH5 = (
      <Fragment>
        <hr />
        <h5 className="popoverh5">Custom AddOns</h5>
      </Fragment>
    );

    const colors = [
      <div className="row">
        <div className="col-md-10"></div>
        <div className="col-md-2"></div>
      </div>,
      <h5 className="popoverh5">Serial Number: {currentOrder && currentOrder.serialNumber}</h5>,
      <h5 className="popoverh5">Colors</h5>,
      <p>Roof: {currentOrder && currentOrder.roofColor} </p>,
      <p>Siding: {currentOrder && currentOrder.sidingColor && currentOrder.sidingColor} </p>,
      <p>Trim: {currentOrder && currentOrder.trimColor && currentOrder.trimColor} </p>,
      <hr />,
      currentOrder && currentOrder.addOns.length > 0 && <h5 className="popoverh5">AddOns</h5>,
    ];

    details.push(colors);
    details.push(addOns);
    currentOrder && customAddOns.length > 0 && details.push(customAddOnH5);
    details.push(customAddOns);
    return details;
  };

  return (
    <Modal title="Order Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
      {showDetails()}
    </Modal>
  );
};

export default DetailModal;
