import React, { useState, Fragment, useContext, useEffect } from "react";
import { Table, Input, Button, Space, Tag } from "antd";
import OrderContext from "../../../../context/order/orderContext";
import InventoryFilter from "./InventoryFilter";
import OrderPieReportChart from "../charts/OrderPieReportChart";

const InventoryReport = () => {
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();

  const orderContext = useContext(OrderContext);
  const { reportOrders, loadingOrders, clearOrders } = orderContext;

  // console.log("This is orders", orders);

  useEffect(() => {
    // getOrders("Invoiced");

    return () => {
      clearOrders();
    };
  }, []); // Dependency array includes 'location'

  const columns = [
    // {
    //   title: "",
    //   key: "serialNumber",
    //   width: "30%",
    //   dataIndex: "serialNumber",
    // },
    {
      title: "Item",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{record && record.item.name}</Fragment>,
    },
    {
      title: "Quantity",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{record && record.quantity}</Fragment>,
    },
    // {
    //   title: "Company Profit",
    //   align: "center",
    //   render: (text, record, rowIndex) => (
    //     <Fragment>
    //       {record && record.profitPercent.company.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 })}
    //     </Fragment>
    //   ),
    // },
    // {
    //   title: "Status",
    //   width: "8%",
    //   align: "center",
    //   render: (text, record, rowIndex) => (
    //     <Fragment>
    //       <Tag className="" color="volcano">
    //         {record && record.status}
    //       </Tag>
    //     </Fragment>
    //   ),
    // },
  ];

  // const statusLabels = ["New", "InQueue", "On Hold", "Building", "Finished", "Invoiced", "Canceled"];
  // const statusCounts = statusLabels.map((label) => reportOrders?.filter((order) => order.status === label).length);
  // const statusData = {
  //   labels: statusLabels,
  //   datasets: [
  //     {
  //       label: `Count`,
  //       data: statusCounts,
  //       backgroundColor: [
  //         "rgba(170, 235, 255)",
  //         "rgba(255, 0, 0)",
  //         "rgba(255, 95, 0)",
  //         "rgba(102, 203, 126)",
  //         "rgba(155, 0, 0)",
  //         "rgba(51, 186, 83)",
  //       ],
  //     },
  //   ],
  // };

  // const typeLabels = ["Inventory", "Customer"];
  // const typeCounts = typeLabels.map((label) => reportOrders?.filter((order) => order.type === label).length);
  // const typeData = {
  //   labels: typeLabels,
  //   datasets: [
  //     {
  //       label: `Count`,
  //       data: typeCounts,
  //       backgroundColor: [
  //         "rgba(170, 235, 255)",
  //         "rgba(255, 0, 0)",
  //         "rgba(255, 95, 0)",
  //         "rgba(102, 203, 126)",
  //         "rgba(155, 0, 0)",
  //         "rgba(51, 186, 83)",
  //       ],
  //     },
  //   ],
  // };

  // const modelNames = reportOrders?.map((order) => order.model?.name);
  // // Get unique model names
  // const uniqueModelNames = [...new Set(modelNames)];
  // // Count how many orders are associated with each model
  // const modelCounts = uniqueModelNames.map((model) => reportOrders?.filter((order) => order.model?.name === model).length);
  // // Create data for the pie chart
  // const modelData = {
  //   labels: uniqueModelNames,
  //   datasets: [
  //     {
  //       label: `Count`,
  //       data: modelCounts,
  //       backgroundColor: [
  //         // Add colors for each model. Ensure you have as many colors as models.
  //         "rgba(255, 99, 132)",
  //         "rgba(54, 162, 235)",
  //         "rgba(255, 206, 86)",
  //         "rgba(75, 192, 192)",
  //         "rgba(153, 102, 255)",
  //         "rgba(255, 159, 64)",
  //         // Add more colors if you have more models...
  //       ],
  //     },
  //   ],
  // };

  // const shopNames = reportOrders?.map((order) => order.shop?.name);
  // // Get unique shop names
  // const uniqueShopNames = [...new Set(shopNames)];
  // // Count how many orders are associated with each shop
  // const shopCounts = uniqueShopNames.map((shop) => reportOrders?.filter((order) => order.shop?.name === shop).length);
  // // Create data for the pie chart
  // const shopData = {
  //   labels: uniqueShopNames,
  //   datasets: [
  //     {
  //       label: `Count`,
  //       data: shopCounts,
  //       backgroundColor: [
  //         // Add colors for each model. Ensure you have as many colors as models.
  //         "rgba(255, 99, 132)",
  //         "rgba(54, 162, 235)",
  //         "rgba(255, 206, 86)",
  //         "rgba(75, 192, 192)",
  //         "rgba(153, 102, 255)",
  //         "rgba(255, 159, 64)",
  //         // Add more colors if you have more models...
  //       ],
  //     },
  //   ],
  // };

  return (
    <Fragment>
      <div className="row mb-4">
        {/* <div className="col-md-4">
          <h4>{" Invoiced Orders"}</h4>
        </div> */}
        <div className="col-md-6">
          <InventoryFilter />
        </div>
        {/* <div className="col-md-2" style={{ height: "200px", width: "200px" }}>
          <h4>{"Shop"}</h4>
          <OrderPieReportChart data={shopData} />
        </div>
        <div className="col-md-2" style={{ height: "200px", width: "200px" }}>
          <h4>{"Type"}</h4>
          <OrderPieReportChart data={typeData} />
        </div>
        <div className="col-md-2" style={{ height: "200px", width: "200px" }}>
          <h4>{"Status"}</h4>
          <OrderPieReportChart data={statusData} />
        </div>
        <div className="col-md-2" style={{ height: "200px", width: "200px" }}>
          <h4>{"Models"}</h4>
          <OrderPieReportChart data={modelData} />
        </div> */}
      </div>
      <Table
        rowClassName={() => "hover-row"}
        pagination={false}
        // onChange={handleTableChange}
        loading={!loadingOrders && reportOrders ? false : true}
        columns={columns}
        dataSource={reportOrders && reportOrders}
        rowKey="_id"
      />
    </Fragment>
  );
};

export default InventoryReport;
