import React, { useContext } from "react";
import OrderContext from "./orderContext";

const GlobalOrderSearch = () => {
  // const [values, setValues] = useState({});

  const orderContext = useContext(OrderContext);
  const { clearSearchedOrder, findOrderBySerialNumber, setSerialNumberSearch, serialNumber, setLoadingOrders } = orderContext;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingOrders(true);
    findOrderBySerialNumber(serialNumber);
  };

  const handleChange = (e) => {
    setSerialNumberSearch(e.target.value);
  };

  const handleClear = () => {
    clearSearchedOrder();
    setSerialNumberSearch("");
  };

  return (
    <form onSubmit={handleSubmit} className="pb-2">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-9">
            <input
              className="form-control"
              type="text"
              name="serialNumber"
              value={serialNumber}
              placeholder="Order Search by Serial #..."
              onChange={handleChange}
            />
          </div>
          <div className="col-md-2">
            <button className="float-end btn btn-outline-primary ">Search Database</button>
          </div>
          <div className="col-md-1">
            <button className="float-end btn btn-outline-warning " type="button" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default GlobalOrderSearch;
