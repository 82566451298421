import React, { Fragment, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MaterialOrderContext from "../../../../../context/materialOrder/materialOrderContext";
import { Link } from "react-router-dom";
import moment from "moment";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Checkbox, Modal, Table } from "antd";

const MaterialPurchaseOrders = () => {
  const navigate = useNavigate();
  const materialOrderContext = useContext(MaterialOrderContext);
  const {
    getMaterialOrders,
    deleteMaterialOrder,
    updateMaterialOrder,
    materialOrders,
    setCurrentMaterialOrder,
    clearMaterialOrders,
    updateMaterialOrderRecieved,
    clearCurrentMaterialOrder,
  } = materialOrderContext;

  useEffect(() => {
    getMaterialOrders();
    clearCurrentMaterialOrder();
    // clear materialOrders on unmount
    return () => {
      // clearMaterialOrders();
    };
  }, []);

  useEffect(() => {}, [materialOrders]);

  const theTitle = "Material Orders";

  const handleRemoveMaterialOrder = (materialOrder) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Material Order?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteMaterialOrder(materialOrder);
        // clearMaterialOrders();
        // getMaterialOrders();
      },
    });
  };

  const handleViewMaterialOrder = (materialOrder) => {
    setCurrentMaterialOrder(materialOrder);
    navigate(`/shop/purchase/material/form`);
  };

  const onOrderChange = (e, record) => {
    let checked = e.target.checked;
    if (checked === true) {
      let TheRecievedDate = new Date();
      let newRecord = { ...record, recieved: checked, recievedDate: TheRecievedDate };
      updateMaterialOrderRecieved(newRecord);
    } else {
      let TheRecievedDate = null;
      let newRecord = { ...record, recieved: checked, recievedDate: TheRecievedDate };
      updateMaterialOrderRecieved(newRecord);
    }
  };

  const columns = [
    {
      title: "PO#",
      dataIndex: "purchaseOrderNumber",
    },
    {
      title: "Vendor",
      render: (text, record) => <span>{record && record.vendor.name}</span>,
    },
    {
      title: "Ordered Date",
      dataIndex: "orderedDate",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.orderedDate).unix() - moment(b.orderedDate).unix(),
      render: (value, row, index) => {
        return <span>{value && new Date(value).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Recieved Date",
      dataIndex: "recievedDate",
      render: (value, row, index) => {
        return <span>{value && new Date(value).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Recieved",
      render: (text, record) => (
        <span>{<Checkbox checked={record && record.recieved} onChange={(e) => onOrderChange(e, record)} />}</span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "slug",
      key: "slug",
      render: (text, record) => (
        <Fragment>
          <DeleteOutlined
            onClick={() => handleRemoveMaterialOrder(record)}
            className="text-danger  pr-2"
            style={{ fontSize: "18px" }}
          />
          {/* <Link to={`/user/metal/orders/order/${slug}`}><DownCircleOutlined className='text-primary pl-2'/></Link> */}
          <EyeOutlined
            onClick={() => handleViewMaterialOrder(record)}
            className="text-info pl-2"
            style={{ fontSize: "20px", paddingLeft: "15px" }}
          />
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              {/* { loading ? (<h4 className='text-danger text-center'>Loading...</h4>) : (<h4 className='text-center'>{theTitle}</h4>)} */}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Link className="btn btn-outline-info float-end mb-2 " to="/shop/purchase/material/form">
                Add New
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                rowClassName={() => "hover-row"}
                loading={materialOrders ? false : true}
                pagination={{ pageSize: 40 }}
                columns={columns}
                dataSource={materialOrders}
                rowKey="_id"
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MaterialPurchaseOrders;
