import {
  ADD_SHED_MODEL,
  DELETE_SHED_MODEL,
  CLEAR_SHED_MODELS,
  SET_CURRENT_SHED_MODEL,
  CLEAR_CURRENT_SHED_MODEL,
  UPDATE_SHED_MODEL,
  SHED_MODEL_ERROR,
  GET_SHED_MODELS,
  SET_LOADING_SHED_MODEL,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_SHED_MODELS:
      return {
        ...state,
        shedModels: action.payload,
      };
    case ADD_SHED_MODEL:
      return {
        ...state,
        shedModel: action.payload,
        loading: false,
      };
    case UPDATE_SHED_MODEL:
      const updatedShedModels = state.shedModels.map((shedModel) =>
        shedModel._id === action.payload._id ? action.payload : shedModel
      );
      return {
        ...state,
        shedModels: updatedShedModels,
        loading: false,
      };
    case DELETE_SHED_MODEL:
      return {
        ...state,
        shedModels: state.shedModels.filter((shedModel) => shedModel._id !== action.payload),
        loading: false,
      };
    case CLEAR_SHED_MODELS:
      return {
        ...state,
        shedModels: null,
        filteredShedModels: null,
        error: null,
      };
    case SET_CURRENT_SHED_MODEL:
      return {
        ...state,
        currentShedModel: action.payload,
      };
    case CLEAR_CURRENT_SHED_MODEL:
      return {
        ...state,
        currentShedModel: null,
      };
    case SHED_MODEL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING_SHED_MODEL:
      return {
        ...state,
        loadingshedModel: action.payload,
      };

    default:
      return state;
  }
};
