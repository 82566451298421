import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import RepairInvoiceContext from "../../../../context/repairInvoice/repairInvoiceContext";
import ItemContext from "../../../../context/item/itemContext";
import ShopContext from "../../../../context/shop/shopContext";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "antd";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Input } from "antd";
import ItemArray from "./ItemArr";

const defaultValues = {
  invoiceNumber: 0,
  invoicedDate: moment(),
};

const CompanyDriverNewInvoiceForm = ({ setModalVisible }) => {
  const navigate = useNavigate();

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const repairInvoiceContext = useContext(RepairInvoiceContext);
  const { addRepairInvoice } = repairInvoiceContext;
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const itemContext = useContext(ItemContext);
  const { getAllShopItems, allItems, clearItems } = itemContext;

  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;

  useEffect(() => {
    getShop();
  }, []);

  useEffect(() => {
    // console.log("This is currentShop", currentShop);
    currentShop && getAllShopItems(currentShop._id);
    return () => {
      clearItems();
    };
  }, [currentShop]);

  const onSubmit = (data) => {
    console.log("this is the form data", data);
    const msgs = [];
    let theDate = new Date();
    const newMsg = {
      madeBy: user.userName,
      date: theDate,
      msg: `Invoice created by ${user.name}.`,
    };
    msgs.push(newMsg);
    data.messages = msgs;
    setModalVisible(false);
    addRepairInvoice(data);
  };

  const dateFormat = "MM/DD/YYYY";

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid bidformbackground border  " style={{ borderRadius: "8px" }}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    <div className="form-group"></div>
                  </div>

                  <div className="col-md-7"></div>
                </div>

                <div className="row mb-4 text-center">
                  <h2>Invoice</h2>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">INVOICE #</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="invoiceNumber" {...register("invoiceNumber", { required: true })} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">DATE</label>
                      <div className="col-sm-9">
                        <Controller
                          render={({ field, name }) => (
                            <DatePicker
                              format={dateFormat}
                              className="form-control"
                              name={name}
                              {...field}
                              disabledDate={(currentDate) => {
                                const now = moment();
                                const today = moment().startOf("day");
                                const friday = today.clone().day(-2).hour(0).minute(0).second(0);
                                const monday = today.clone().day(1).hour(10).minute(0).second(0);

                                if (now.isBefore(monday)) {
                                  // Disable all dates except for Friday to Sunday
                                  const sunday = today.clone().day(0).hour(23).minute(59).second(59);
                                  return !currentDate.isBetween(friday, sunday);
                                } else if (now.isSame(today, "day") && now.isBefore(monday.clone().subtract(1, "second"))) {
                                  // Disable all dates except for today before 10am
                                  return !currentDate.isSame(today, "day");
                                } else {
                                  // Disable all dates except for today
                                  return true;
                                }
                              }}
                            />
                          )}
                          control={control}
                          name={`invoicedDate`}
                          defaultValue={moment()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">BILL TO:</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          name="billTo"
                          defaultValue={"StorMor"}
                          {...register("billTo", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-5">
                    <label>ITEM</label>
                  </div>
                  <div className="col-md-2">
                    <label>QTY</label>
                  </div>
                  <div className="col-md-2">
                    <label>RATE</label>
                  </div>
                  <div className="col-md-2">
                    <label>AMOUNT</label>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row border bglight mb-2 ">
                  <ItemArray
                    {...{
                      control,
                      watch,
                      register,
                      defaultValues,
                      getValues,
                      setValue,
                      errors,
                      getValues,
                      allItems,
                    }}
                  />
                </div>
                <div className="row mb-4 mt-4">
                  <div className="col-md-4"></div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <div className="buttons float-end">
                      <button className="btn btn-outline-primary " type="submit">
                        Create Invoice
                        {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompanyDriverNewInvoiceForm;
