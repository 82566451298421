import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import { Table } from "antd";
import axios from "axios";

import * as XLSX from "xlsx";

const PaidInvoices = () => {
  const navigate = useNavigate();
  const invoiceContext = useContext(InvoiceContext);
  const { getInvoicesByStatus, invoices, getInvoiceById, clearInvoices, deleteInvoice, invoiceLoading } = invoiceContext;
  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());

  useEffect(() => {
    getInvoicesByStatus("Paid");
    return () => {
      clearInvoices();
      // cancelToken.cancel();
    };
  }, []);

  const handleViewInvoice = (invoice) => {
    getInvoiceById(invoice._id);
  };

  const handleDeleteInvoice = (invoice) => {
    deleteInvoice(invoice);
  };

  const exportToExcel = () => {
    if (!invoices || invoices.length === 0) return;

    const exportData = invoices.map((invoice) => ({
      "Invoice Number": invoice.invoiceNumber,
      "Invoiced Date": invoice.invoicedDate ? new Date(invoice.invoicedDate).toLocaleDateString() : "",
      "Shop Total": invoice.order.grandTotal.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Builder Labor": invoice.order.builderLabor.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Roof Labor": invoice.order.roofLabor.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Truss Labor": invoice.order.trussLabor.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Door Labor": invoice.order.doorLabor.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Paint Labor": invoice.order.paintLabor.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Shed Total Labor": invoice.order.shedTotalLabor.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Add Ons Total Labor": invoice.order.addOnsTotalLabor.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Custom Add Ons Total Labor": invoice.order.customAddOnsTotalLabor.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Total Labor": invoice.order.totalLabor.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Shed Material Cost": invoice.order.shedMaterialCost.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Add Ons Material Cost": invoice.order.addOnsMaterialCostTotal.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Custom Add Ons Material Cost": invoice.order.customAddOnsMaterialCostTotal.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Total Material Cost": invoice.order.totalMaterialCost.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Shed Total Cost": invoice.order.shedTotalCost.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Add Ons Total Cost": invoice.order.addOnsTotalCost.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Custom Add Ons Total Cost": invoice.order.customAddOnsTotalCost.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      Overhead: invoice.order.overhead.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "Total Cost": invoice.order.totalCost.shop.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Paid Invoices");
    XLSX.writeFileXLSX(workbook, "paid_invoices.xlsx");
  };

  const columns = [
    {
      title: `Invoice Number`,
      render: (text, record) => <span>{record.invoiceNumber}</span>,
    },
    {
      title: `Invoiced Date`,
      render: (text, record) => <span>{record.invoicedDate && new Date(record.invoicedDate).toLocaleDateString()}</span>,
    },
    {
      title: `Amount`,
      render: (text, record) => (
        <span>
          {record.order.grandTotal.shop.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },

    // {
    //   title: `Total Cost`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order &&
    //         record.order.shopTotalCost.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Profit $`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order &&
    //         record.order.shopProfitDollars.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Profit %`,
    //   render: (text, record) => <span>{record.order && record.order.shopProfitPercent.toFixed(2)}%</span>,
    // },

    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <Fragment>
          <button className="btn btn-xs btn-outline-secondary" type="button" onClick={(e) => handleViewInvoice(record, e)}>
            View Invoice
          </button>
          {/* <button className="btn btn-xs btn-outline-danger" type="button" onClick={(e) => handleDeleteInvoice(record)}>
            Delete
          </button> */}
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <div className="row">
      <div className="col text-center">
        {/* <h4>{invoices && invoices.length > 0 ? invoices.length + " Paid Invoices" : "No Paid Invoices"}</h4> */}
        <h4>{invoices && invoices.length > 0 ? invoices.length + " Paid Invoices" : "No Paid Invoices"}</h4>

        {invoices && invoices.length > 0 && (
          <button className="btn btn-success mb-3" onClick={exportToExcel}>
            Export to Excel
          </button>
        )}
        {/* <OrderFilter /> */}
        <Table
          rowClassName={() => "hover-row"}
          className="mt-2"
          pagination={{ pageSize: 200 }}
          loading={(!invoiceLoading && invoices) || !invoiceLoading ? false : true}
          columns={columns}
          dataSource={invoices && invoices}
          rowKey="_id"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default PaidInvoices;
