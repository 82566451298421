import React, { useEffect, useContext } from "react";
import ShedContext from "../../../../../../context/shed/shedContext";
import ShedLaborContext from "../../../../../../context/shedLabor/shedLaborContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ComponentContext from "../../../../../../context/component/componentContext";
import AddOnContext from "../../../../../../context/addon/addOnContext";
import HaulBaseContext from "../../../../../../context/haulBase/haulBaseContext";
import BaseStyleContext from "../../../../../../context/baseStyle/baseStyleContext";
import AuthContext from "../../../../../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import AddOnArray from "./ShedFormAddOnArr";
import ComponentArray from "./ShedFormComponentArr";
import ReactSelect from "react-select";
import { Checkbox, Form, Input, Button, Card, Typography, Row, Col } from "antd";

const { Title } = Typography;

const defaultValues = {
  width: "",
  length: "",
  ssId: "",
  retailPrice: "",
  baseStyle: null,
  shedLabor: null,
  haulBase: null,
  components: [],
  standardAddOns: [],
};

const ShedForm = () => {
  const navigate = useNavigate();
  const shedContext = useContext(ShedContext);
  const shedLaborContext = useContext(ShedLaborContext);
  const shedCategoryContext = useContext(ShedCategoryContext);
  const componentContext = useContext(ComponentContext);
  const addOnContext = useContext(AddOnContext);
  const haulBaseContext = useContext(HaulBaseContext);
  const { getHaulBasesByModel, haulBases } = haulBaseContext;

  const { currentShedCategory } = shedCategoryContext;

  const { getAllComponents, components } = componentContext;
  const { getAddonsByShedCategory, allAddOns } = addOnContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const { addShed, currentShed, updateShed, clearCurrentShed, updateAllModelsShed } = shedContext;
  const { getShedLaborsByModel, shedLabors } = shedLaborContext;

  const shedModelContext = useContext(ShedModelContext);
  const { currentShedModel } = shedModelContext;

  const baseStyleContext = useContext(BaseStyleContext);
  const { baseStyles, getBaseStyles } = baseStyleContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const formattedShedLabors =
    shedLabors &&
    shedLabors.map((shedLabor) => {
      return {
        value: shedLabor._id,
        label: `${shedLabor.width} x ${shedLabor.length}`,
      };
    });

  const formattedHaulBases =
    haulBases &&
    haulBases.map((haulBase) => {
      return {
        value: haulBase._id,
        label: `${haulBase.width} x ${haulBase.length}`,
      };
    });

  const baseStylesOptions =
    baseStyles &&
    baseStyles.map((baseStyle) => ({
      value: baseStyle._id,
      label: baseStyle.name,
    }));

  useEffect(() => {
    getBaseStyles();
    getShedLaborsByModel(currentShedModel);
    getAllComponents(currentShedCategory._id);
    getHaulBasesByModel(currentShedModel, currentShedCategory._id);
    getAddonsByShedCategory(currentShedCategory._id);
    return () => {
      clearCurrentShed();
    };
  }, []);

  useEffect(() => {
    if (currentShed) {
      reset(currentShed);
    } else {
      reset(defaultValues);
    }
  }, [currentShed, reset]);

  useEffect(() => {
    if (currentShedCategory) {
      setValue("category", currentShedCategory._id);
    }
    if (currentShedModel) {
      setValue("model", currentShedModel._id);
    }
  }, [currentShedCategory, currentShedModel, setValue]);

  useEffect(() => {
    if (currentShed && currentShed.shedLabor && formattedShedLabors) {
      const selectedShedLabor = formattedShedLabors.find((option) => option.value === currentShed.shedLabor._id);
      setValue("shedLabor", selectedShedLabor);
    }
    if (currentShed && currentShed.haulBase && formattedHaulBases) {
      const selectedHaulBase = formattedHaulBases.find((option) => option.value === currentShed.haulBase._id);
      setValue("haulBase", selectedHaulBase);
    }
    if (currentShed && currentShed.baseStyle && baseStylesOptions) {
      const selectedBaseStyle = baseStylesOptions.find((option) => option.value === currentShed.baseStyle);
      setValue("baseStyle", selectedBaseStyle);
    }
  }, [currentShed, formattedShedLabors, baseStylesOptions, setValue]);

  const onSubmit = (data) => {
    if (!currentShed) {
      addShed(data);
      navigate(-1);
    } else {
      updateShed(data);
      navigate(-1);
      clearCurrentShed();
    }
  };

  const setExit = () => {
    navigate(-1);
  };

  const handleLaborChange = (e) => {
    setValue("labor", e);
  };

  const handleShedLaborChange = (e) => {
    setValue("shedLabor", e);
  };

  const handleHaulBaseChange = (e) => {
    setValue("haulBase", e);
  };

  const handleBaseStylesOptionChange = (e) => {
    setValue("baseStyle", e);
  };

  const copyShed = () => {
    const values = getValues();
    delete values._id;
    delete values.createdAt;
    delete values.updatedAt;
    delete values.__v;
    addShed(values);
  };

  const updateAllModels = () => {
    const values = getValues();
    updateAllModelsShed(values);
  };

  return (
    <div className="container-fluid pb-4">
      <Button type="danger" className="ms-2 float-end" onClick={setExit}>
        Back
      </Button>
      <Row justify="center">
        <Col span={24}>
          <Title level={3} className="text-center">
            {currentShed ? "Update Shed" : "Add Shed"}
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={20}>
          <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", border: "1px solid #e8e8e8", borderRadius: "8px" }}>
            <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
              <Card
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  border: "1px solid #e8e8e8",
                  borderRadius: "8px",
                }}
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Base Style">
                      <Controller
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            isClearable={true}
                            options={baseStylesOptions}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            onChange={handleBaseStylesOptionChange}
                          />
                        )}
                        name="baseStyle"
                        control={control}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Width">
                      <Controller name="width" control={control} render={({ field }) => <Input {...field} />} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Length">
                      <Controller name="length" control={control} render={({ field }) => <Input {...field} />} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Shed Suite ID">
                      <Controller name="ssId" control={control} render={({ field }) => <Input {...field} />} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Retail Price">
                      <Controller name="retailPrice" control={control} render={({ field }) => <Input {...field} />} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Shed Labor Item">
                      <Controller
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            isClearable={true}
                            options={formattedShedLabors}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            onChange={handleShedLaborChange}
                          />
                        )}
                        name="shedLabor"
                        control={control}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Haul Base Item">
                      <Controller
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            isClearable={true}
                            options={formattedHaulBases}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            onChange={handleHaulBaseChange}
                          />
                        )}
                        name="haulBase"
                        control={control}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <ComponentArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  components,
                }}
              />
              <AddOnArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  allAddOns,
                }}
              />
              <Row justify="end">
                <Col>
                  <Button type="primary" htmlType="submit" disabled={user.viewOnly}>
                    Save
                  </Button>
                  <Button type="default" className="ms-2" onClick={copyShed}>
                    Copy
                  </Button>

                  <Button type="default" className="ms-2" onClick={updateAllModels}>
                    Update Components & Addons Across All Models
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ShedForm;
