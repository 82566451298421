import React, { useContext, useRef, useEffect } from 'react';
import DealerContext from './dealerContext';

const DealerFilter = () => {
  const dealerContext = useContext(DealerContext);
  const { filterDealers, clearFilterDealer, filteredDealer } = dealerContext;

  const text = useRef('');

  useEffect(() => {
    if (filteredDealer === null) {
      text.current.value = '';
    }
  });

  const onChange = (e) => {
    if (text.current.value !== '') {
      filterDealers(e.target.value);
    } else {
      clearFilterDealer();
    }
  };

  return (
    <div className='row'>
      <div className='col-md-4'>
        <form className='pb-2'>
          <input className='form-control' type='text' ref={text} placeholder='Search Dealers...' onChange={onChange} />
        </form>
      </div>
    </div>
  );
};

export default DealerFilter;
