import {
  ADD_SHED_CATEGORY,
  DELETE_SHED_CATEGORY,
  CLEAR_SHED_CATEGORYS,
  SET_CURRENT_SHED_CATEGORY,
  CLEAR_CURRENT_SHED_CATEGORY,
  UPDATE_SHED_CATEGORY,
  SHED_CATEGORY_ERROR,
  GET_SHED_CATEGORYS,
  SET_LOADING_SHED_CATEGORY,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_SHED_CATEGORYS:
      return {
        ...state,
        shedCategorys: action.payload,
      };
    case ADD_SHED_CATEGORY:
      return {
        ...state,
        shedCategory: action.payload,
        loading: false,
      };
    case UPDATE_SHED_CATEGORY:
      const updatedShedCategorys = state.shedCategorys.map((shedCategory) =>
        shedCategory._id === action.payload._id ? action.payload : shedCategory
      );
      return {
        ...state,
        shedCategorys: updatedShedCategorys,
        loading: false,
      };
    case DELETE_SHED_CATEGORY:
      return {
        ...state,
        shedCategorys: state.shedCategorys.filter((shedCategory) => shedCategory._id !== action.payload),
        loading: false,
      };
    case CLEAR_SHED_CATEGORYS:
      return {
        ...state,
        shedCategorys: null,
        filteredShedCategorys: null,
        error: null,
        currentShedCategory: null,
      };
    case SET_CURRENT_SHED_CATEGORY:
      return {
        ...state,
        currentShedCategory: action.payload,
      };
    case CLEAR_CURRENT_SHED_CATEGORY:
      return {
        ...state,
        currentShedCategory: null,
      };
    case SHED_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING_SHED_CATEGORY:
      return {
        ...state,
        loadingshedCategory: action.payload,
      };

    default:
      return state;
  }
};
