import React, { Fragment, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MetalOrderContext from "../../../../../context/metalOrder/metalOrderContext";

import { Link } from "react-router-dom";
import { Checkbox } from "antd";
import moment from "moment";
import { Table, Modal } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

const MetalPurchaseOrders = () => {
  const navigate = useNavigate();
  const metalOrderContext = useContext(MetalOrderContext);
  const { getMetalOrders, deleteMetalOrder, metalOrders, setCurrentMetalOrder, clearMetalOrders, updateMetalOrder } =
    metalOrderContext;

  useEffect(() => {
    getMetalOrders();
  }, []);

  const theTitle = "Metal Orders";

  const handleRemoveMetalOrder = (metalOrder) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Metal Order?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteMetalOrder(metalOrder);
        // clearMetalOrders();
        getMetalOrders();
      },
    });
  };

  const handleViewMetalOrder = (metalOrder) => {
    setCurrentMetalOrder(metalOrder);
    navigate(`/shop/purchase/metal/form`);
  };

  const onOrderChange = (e, record) => {
    let checked = e.target.checked;
    if (checked === true) {
      let TheRecievedDate = new Date();
      let newRecord = { ...record, recieved: checked, recievedDate: TheRecievedDate };
      updateMetalOrder(newRecord);
    } else {
      let TheRecievedDate = null;
      let newRecord = { ...record, recieved: checked, recievedDate: TheRecievedDate };
      updateMetalOrder(newRecord);
    }
  };

  const columns = [
    {
      title: "PO#",
      dataIndex: "purchaseOrderNumber",
    },
    {
      title: "Ordered Date",
      dataIndex: "orderedDate",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.orderedDate).unix() - moment(b.orderedDate).unix(),
      render: (value, row, index) => {
        return <span>{new Date(value).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Recieved Date",
      dataIndex: "recievedDate",
      render: (value, row, index) => {
        return <span>{value && new Date(value).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Recieved",
      render: (text, record) => <span>{<Checkbox checked={record.recieved} onChange={(e) => onOrderChange(e, record)} />}</span>,
    },
    {
      title: "Actions",
      dataIndex: "slug",
      key: "slug",
      render: (text, record) => (
        <Fragment>
          <DeleteOutlined
            onClick={() => handleRemoveMetalOrder(record)}
            className="text-danger  pr-2"
            style={{ fontSize: "18px" }}
          />
          {/* <Link to={`/user/metal/orders/order/${record}`}><DownCircleOutlined className='text-primary pl-2'/></Link> */}
          <EyeOutlined
            onClick={() => handleViewMetalOrder(record)}
            className="text-primary pl-2"
            style={{ fontSize: "20px", paddingLeft: "15px" }}
          />
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              {/* { loading ? (<h4 className='text-danger text-center'>Loading...</h4>) : (<h4 className='text-center'>{theTitle}</h4>)} */}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Link className="btn btn-outline-info float-end mb-2 " to="/shop/purchase/metal/form">
                Add New
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                rowClassName={() => "hover-row"}
                loading={metalOrders ? false : true}
                pagination={{ pageSize: 40 }}
                columns={columns}
                dataSource={metalOrders}
                rowKey="_id"
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MetalPurchaseOrders;
