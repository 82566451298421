import React, { useState, useContext, useEffect, Fragment } from "react";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alert/alertContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Checkbox, Form, Input } from "antd";
import Alerts from "../../components/layout/Alerts";

const Login = () => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert } = alertContext;
  const { login, error, clearErrors, isAuthenticated, user } = authContext;

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    // if (isAuthenticated) {
    //   navigate(from, { replace: true });
    // }
    if (isAuthenticated) {
      if (user.role === "Shop Admin" || user.role === "Shop User") {
        navigate("/shop/home");
      } else {
        navigate("/company/home");
      }
    }
    if (error === "Invalid Credentials") {
      setAlert(error, "danger");
      clearErrors();
    }
    // eslint-disable-next-line
    // }, [error, isAuthenticated]);
  }, [error, user]);

  const [theUser, setTheUser] = useState({
    email: "",
    password: "",
  });

  const onChange = (e) => {
    setTheUser({ ...theUser, [e.target.name]: e.target.value });
  };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   if (email === "" || password === "") {
  //     setAlert("Please fill in all fields", "danger");
  //   } else {
  //     login({
  //       email,
  //       password,
  //     });
  //   }
  // };

  const onFinish = (values) => {
    let email = values.email;
    let password = values.password;
    login({
      email,
      password,
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <Alerts />

      <Form
        id="formlogin"
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <h2 className="text-center mb-4">Shed-Pro</h2>
        <Form.Item
          label="Username"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input onChange={onChange} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password onChange={onChange} />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" className="login-form-button">
            Login
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default Login;
