import React from "react";
import { Table } from "antd";

function CompanyExpensesGpTable({ formValues }) {
  console.log("formValues", formValues);
  const companyColumns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Expenses",
      dataIndex: "expenses",
      key: "expenses",
    },
    {
      title: "Profit",
      dataIndex: "profit",
      key: "profit",
    },
  ];
  const companyDataSource = [
    {
      key: "1",
      category: "Shop",
      expenses:
        formValues &&
        formValues.paymentTotal.shop.value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "1",
      category: "Dealer Commission",
      expenses:
        formValues &&
        formValues.dealerCommissionTotal.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "2",
      category: "Sales Rep Commission",
      expenses:
        formValues &&
        formValues.salesRepCommissionTotal.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "3",
      category: "Escort",
      expenses:
        formValues &&
        formValues.escortTotal.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "4",
      category: "Haul",
      expenses:
        formValues &&
        formValues.haulTotal.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "44",
      category: "Overhead",
      expenses:
        formValues &&
        formValues.overheadTotal.company.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "5",
      category: "Total Cost",
      expenses:
        formValues &&
        formValues.totalCost.company.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "6",
      category: "Gross Profit",
      profit:
        formValues &&
        formValues.profitDollarsTotal.company.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      key: "7",
      category: "Gross Profit %",
      profit:
        formValues &&
        formValues.averageProfitPercent.company.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 }),
    },
  ];
  return <Table columns={companyColumns} dataSource={companyDataSource} pagination={false} />;
}

export default CompanyExpensesGpTable;
