import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableHeader from "./InvoiceTableHeader";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

const InvoiceItemsTable = ({ values }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRow values={values} />
  </View>
);

export default InvoiceItemsTable;
