import React, { useReducer } from 'react'
import MetalOrderContext from './metalOrderContext'
import metalOrderReducer from './metalOrderReducer'
import axios from 'axios'

import { toast } from "react-toastify";

import {
    ADD_METALORDER,
    DELETE_METALORDER,
    CLEAR_METALORDERS,
    SET_CURRENT_METALORDER,
    CLEAR_CURRENT_METALORDER,
    UPDATE_METALORDER,
    FILTER_METALORDERS,
    CLEAR_FILTER_METALORDER,
    METALORDER_ERROR,
    GET_METALORDERS,
} from '../types'

const MetalOrderState = props => {
    const initialState = {
        metalOrders: null,
        currentMetalOrder: null,
        filteredMetalOrder: null,
        error: null
    };

    const [state, dispatch] = useReducer(metalOrderReducer, initialState)

    // get metal Orders
    const getMetalOrders = async () => {
        try {
            const res = await axios.get('/api/metalorder')
            dispatch({ type: GET_METALORDERS, payload: res.data })
        } catch (err) {
            dispatch({ type: METALORDER_ERROR})
        }
    }


    // add metal Order
    const addMetalOrder = async metalOrders => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await axios.post('/api/metalorder', {metalOrders}, config)
            dispatch({ type: ADD_METALORDER, payload: res.data })
            toast.success(`Metal Order is created`)
        } catch (err) {
            dispatch({ type: METALORDER_ERROR})
            toast.error(`Metal Order ${err}`)
        }
    }


// delete metal Order
    const deleteMetalOrder =   async metalOrder => {
        try {
            const res = await axios.delete(`/api/metalorder/delete/${metalOrder._id}`)
            dispatch({ type: DELETE_METALORDER, payload: metalOrder._id })
            toast.success(`Metal Order is deleted`)
        } catch (err) {
            dispatch({ type: METALORDER_ERROR})
            toast.error(`Metal Order ${err}`)
        }
    }


   // update metal Order
     const updateMetalOrder = async metalOrder => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await axios.put(`/api/metalorder/update/${metalOrder._id}`, metalOrder, config)
            dispatch({ type: UPDATE_METALORDER, payload: res.data })
            toast.success(`Metal Order is updated`)
        } catch (err) {
            dispatch({ type: METALORDER_ERROR})
            toast.error(`Metal Order ${err}`)
        }
    }

 
    const clearMetalOrders = () => {
        dispatch({ type: CLEAR_METALORDERS })
    }
    const setCurrentMetalOrder = metalOrder => {
            dispatch({ type: SET_CURRENT_METALORDER, payload: metalOrder })
    }
    const clearCurrentMetalOrder = () => {
            dispatch({ type: CLEAR_CURRENT_METALORDER })
    }
    const filterMetalOrders = text => {
            dispatch({ type: FILTER_METALORDERS, payload: text })
    }
    const clearFilterMetalOrder = () => {
        dispatch({ type: CLEAR_FILTER_METALORDER })
    }

    return (
        <MetalOrderContext.Provider
            value={{
                metalOrders: state.metalOrders,
                currentMetalOrder: state.currentMetalOrder,
                filteredMetalOrder: state.filteredMetalOrder,
                error: state.error,
                getMetalOrders,
                addMetalOrder,
                deleteMetalOrder, 
                setCurrentMetalOrder,
                clearCurrentMetalOrder,
                updateMetalOrder,
                filterMetalOrders, 
                clearFilterMetalOrder,
                clearMetalOrders,
            }}>
             { props.children }
        </MetalOrderContext.Provider>
    )


}

export default MetalOrderState;