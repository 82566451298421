import {
  ADD_REPAIRINVOICE,
  DELETE_REPAIRINVOICE,
  CLEAR_REPAIRINVOICES,
  SET_CURRENT_REPAIRINVOICE,
  CLEAR_CURRENT_REPAIRINVOICE,
  UPDATE_REPAIRINVOICE,
  SET_FILTERED_REPAIRINVOICES,
  CLEAR_FILTERED_REPAIRINVOICES,
  REPAIRINVOICE_ERROR,
  GET_REPAIRINVOICES,
  STATUS_CHANGE_REPAIRINVOICE,
  GET_REPAIRINVOICE_BY_SHOP_STATUS,
  SET_SEARCHED_REPAIRINVOICE,
  CLEAR_SEARCHED_REPAIRINVOICE,
  SWITCH_LOADINGREPAIRINVOICE,
  SET_REPAIRINVOICE_LOADING,
  CLEAR_FILTER_REPAIRINVOICES_BY_SHOP,
  SET_SHOP_FOR_FILTER,
  SET_REPAIRINVOICE_NUMBER_SEARCH,
  SET_CUT_OFF_DATE,
  SET_REPAIRINVOICE_PAGINATION,
  GET_REPAIRINVOICE_COUNT,
  CLEAR_REPAIRINVOICE_COUNT,
  SET_REPAIRINVOICE_IS_CANCELED,
  GET_ADJUSTED_REPAIRINVOICES,
  UPDATE_ADJUSTED_REPAIRINVOICE,
  GET_REPAIRINVOICES_CHART,
  UPDATE_REPAIRINVOICE_ORDER,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_REPAIRINVOICES:
      return {
        ...state,
        repairInvoices: action.payload,
        repairInvoiceLoading: false,
      };
    case GET_ADJUSTED_REPAIRINVOICES:
      return {
        ...state,
        adjustedRepairInvoices: action.payload,
        repairInvoiceLoading: false,
      };
    case UPDATE_ADJUSTED_REPAIRINVOICE:
      return {
        ...state,
        adjustedRepairInvoices: state.adjustedRepairInvoices.map((repairInvoice) =>
          repairInvoice._id === action.payload._id ? action.payload : repairInvoice
        ),
        repairInvoiceLoading: false,
      };
    case UPDATE_REPAIRINVOICE_ORDER:
      return {
        ...state,
        currentRepairInvoice: {
          ...state.currentRepairInvoice,
          order: action.payload,
        },
      };
    case ADD_REPAIRINVOICE:
      return {
        ...state,
        repairInvoices: [...state.repairInvoices, action.payload], // Adds new invoice to the array
        repairInvoiceLoading: false,
      };

    case UPDATE_REPAIRINVOICE:
      return {
        ...state,
        repairInvoices: state.repairInvoices
          ? state.repairInvoices.map((repairInvoice) =>
              repairInvoice._id === action.payload._id ? action.payload : repairInvoice
            )
          : [],
        filteredRepairInvoices: state.filteredRepairInvoices
          ? state.filteredRepairInvoices.map((repairInvoice) =>
              repairInvoice._id === action.payload._id ? action.payload : repairInvoice
            )
          : [],
        repairInvoiceLoading: false,
      };
    case DELETE_REPAIRINVOICE:
      return {
        ...state,
        repairInvoices: state.repairInvoices.filter((repairInvoice) => repairInvoice._id !== action.payload),
        repairInvoiceLoading: false,
      };
    case CLEAR_REPAIRINVOICES:
      return {
        ...state,
        repairInvoices: null,
        filteredRepairInvoices: null,
        error: null,
      };
    case SET_CURRENT_REPAIRINVOICE:
      return {
        ...state,
        currentRepairInvoice: action.payload,
      };
    case CLEAR_CURRENT_REPAIRINVOICE:
      return {
        ...state,
        currentRepairInvoice: null,
      };
    case SET_FILTERED_REPAIRINVOICES:
      return {
        ...state,
        filteredRepairInvoices: action.payload,
      };
    // case GET_REPAIRINVOICE_BY_SHOP_STATUS:
    //   return {
    //     ...state,
    //     filteredRepairInvoicesByShop: action.payload,
    //     repairInvoiceLoading: false,
    //     // filteredRepairInvoicesByShop: state.repairInvoices.filter((repairInvoice) => {
    //     //   let repairInvoiceId = repairInvoice.madeBy._id;
    //     //   return repairInvoiceId.match(action.payload.value);
    //     // }),
    //     // shop: action.payload,
    //   };
    // case SET_SHOP_FOR_FILTER:
    //   return {
    //     ...state,
    //     shop: action.payload,
    //   };
    case CLEAR_FILTERED_REPAIRINVOICES:
      return {
        ...state,
        filterededRepairInvoices: null,
      };
    // case CLEAR_FILTER_REPAIRINVOICES_BY_SHOP:
    //   return {
    //     ...state,
    //     filteredRepairInvoicesByShop: null,
    //   };
    case REPAIRINVOICE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case STATUS_CHANGE_REPAIRINVOICE:
      return {
        ...state,
        repairInvoices:
          state.repairInvoices && state.repairInvoices.filter((repairInvoice) => repairInvoice._id !== action.payload._id),
        adjustedRepairInvoices:
          state.adjustedRepairInvoices &&
          state.adjustedRepairInvoices.filter((repairInvoice) => repairInvoice._id !== action.payload._id),
        filteredRepairInvoicesByShop:
          state.filteredRepairInvoicesByShop &&
          state.filteredRepairInvoicesByShop.filter((repairInvoice) => repairInvoice._id !== action.payload._id),
        filteredRepairInvoice:
          state.filteredRepairInvoice &&
          state.filteredRepairInvoice.filter((repairInvoice) => repairInvoice._id !== action.payload._id),
        loading: false,
      };
    case SET_SEARCHED_REPAIRINVOICE:
      return {
        ...state,
        searchedRepairInvoice: action.payload,
        repairInvoiceLoading: false,
      };
    case CLEAR_SEARCHED_REPAIRINVOICE:
      return {
        ...state,
        searchedRepairInvoice: null,
      };

    // case SWITCH_LOADINGREPAIRINVOICE:
    //   return {
    //     ...state,
    //     repairInvoiceLoading: true,
    //   };
    case SET_REPAIRINVOICE_LOADING:
      return {
        ...state,
        repairInvoiceLoading: action.payload,
      };

    case SET_REPAIRINVOICE_NUMBER_SEARCH:
      return {
        ...state,
        repairInvoiceNumber: action.payload,
      };
    case SET_CUT_OFF_DATE:
      return {
        ...state,
        cutOffDate: action.payload,
      };
    case GET_REPAIRINVOICES_CHART:
      return {
        ...state,
        chartRepairInvoices: action.payload,
      };
    // case SET_REPAIRINVOICE_PAGINATION:
    //   return {
    //     ...state,
    //     pagination: action.payload,
    //   };
    // case GET_REPAIRINVOICE_COUNT:
    //   return {
    //     ...state,
    //     repairInvoiceCount: action.payload,
    //   };
    // case CLEAR_REPAIRINVOICE_COUNT:
    //   return {
    //     ...state,
    //     repairInvoiceCount: 0,
    //   };
    // case SET_REPAIRINVOICE_IS_CANCELED:
    //   return {
    //     ...state,
    //     isCancelled: action.payload,
    //   };
    default:
      return state;
  }
};
