import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import OrderContext from "../../../../context/order/orderContext";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import { useForm } from "react-hook-form";
import { Input, DatePicker, Tag, List, Table, Button, Typography, Space, Card } from "antd";
import moment from "moment";
import PopConfirm from "./PopConfirm";

const { Title } = Typography;

const defaultValues = {
  invoiceNumber: "",
  invoicedDate: "",
  billTo: "",
};

const ViewInvoiceForm = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues,
  });
  const [messages, setMessages] = useState();
  const orderContext = useContext(OrderContext);
  const { setCurrentOrder, currentOrder } = orderContext;
  const invoiceContext = useContext(InvoiceContext);
  const { currentInvoice, updateInvoiceStatus, updateInvoice, updateInvoiceOrder } = invoiceContext;
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    if (currentOrder) {
      setValue("_id", currentInvoice._id);
      setValue("invoicedDate", moment(currentInvoice.invoicedDate));
      setValue("invoiceNumber", currentInvoice.invoiceNumber);
      setValue(`order`, currentOrder);
      updateInvoiceOrder(currentOrder);
    }
  }, [currentOrder]);

  const onSubmit = (data) => {
    // console.log("this is the form data", data);
    updateInvoice(data);
  };

  const setExit = () => {
    navigate(-1);
  };

  const handleViewOrder = () => {
    if (currentInvoice && currentInvoice.order) {
      setCurrentOrder(currentInvoice.order);
      navigate("/shop/order/form");
    }
  };

  const dateFormat = "MM/DD/YYYY";
  const [invoiceLoad, setInvoiceLoad] = useState(false);

  const handleStatusChange = async () => {
    setInvoiceLoad(true);
    let theDate = new Date();
    const newMsg = {
      madeBy: user.name,
      date: theDate,
      msg: `This Invoice has been Adjusted.`,
    };
    // let theDate = new Date();
    let newRecord = {
      ...currentInvoice,
      status: "Adjusted",
      newMessage: newMsg,
      // messages: { ...currentInvoice.messages, msg: `This invoice has been Adjusted. - ${user.userName}` },
      //   processingDate: theDate,
    };
    await updateInvoice(newRecord);
    setInvoiceLoad(false);
    // statusChange(currentInvoice);
    navigate(-1);
  };

  const columns = [
    {
      title: "ITEM",
      key: "item",
      render: (text, record) => <span>{record.order.serialNumber}</span>,
    },
    {
      title: "QTY",
      key: "qty",
      render: () => <span>1</span>,
    },
    {
      title: "RATE",
      key: "rate",
      render: (text, record) => (
        <span>
          {record.order.grandTotal.shop.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
    {
      title: "AMOUNT",
      key: "amount",
      render: (text, record) => (
        <span>
          {record.order.grandTotal.shop.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
  ];

  return (
    <Card
      className="mt-4"
      style={{
        borderRadius: "12px",
        boxShadow: "0 6px 16px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.1)",
        border: "1px solid #e8e8e8",
      }}
    >
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Space direction="horizontal" style={{ width: "100%", justifyContent: "space-between" }}>
          <Title level={2}>Invoice</Title>
          <Space>
            <Button
              type="primary"
              onClick={handleViewOrder}
              style={{
                backgroundColor: "#1890ff",
                borderColor: "#1890ff",
                color: "white",
              }}
            >
              View Order
            </Button>
            <Button
              type="primary"
              onClick={() => setExit()}
              style={{
                backgroundColor: "#ff4d4f",
                borderColor: "#ff4d4f",
                color: "white",
              }}
            >
              Back
            </Button>
          </Space>
        </Space>

        <Space direction="horizontal" size="large" style={{ width: "100%", justifyContent: "space-between" }}>
          <Space direction="vertical">
            <Input addonBefore="INVOICE #" style={{ width: "300px" }} readOnly value={currentInvoice?.invoiceNumber} />
            <DatePicker format="MM/DD/YYYY" style={{ width: "300px" }} disabled value={moment(currentInvoice?.invoicedDate)} />
            <Input addonBefore="BILL TO:" style={{ width: "300px" }} readOnly value="StorMor" />
          </Space>
          <Tag color="green">{currentInvoice?.status}</Tag>
        </Space>

        <Table columns={columns} dataSource={currentInvoice ? [currentInvoice] : []} pagination={false} loading={invoiceLoad} />

        {currentInvoice && currentInvoice.status === "Denied" && (
          <Space style={{ justifyContent: "flex-end" }}>
            <PopConfirm
              msg={"Change Status to Adjusted?"}
              btnText={"Adjusted"}
              theStatus={"Adjusted"}
              handleStatusChange={handleStatusChange}
            />
          </Space>
        )}

        <List
          header={<div>Messages</div>}
          bordered
          dataSource={messages}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={item.madeBy} description={new Date(item.date).toLocaleString()} />
              {item.msg}
            </List.Item>
          )}
        />
      </Space>
    </Card>
  );
};

export default ViewInvoiceForm;
