import React, { useEffect, useContext } from "react";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import AddonContext from "../../../../../../context/addon/addOnContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Select from "react-select";
import { Form, Input, Button, Row, Col } from "antd";

const ShedModelForm = () => {
  const navigate = useNavigate();
  const shedModelContext = useContext(ShedModelContext);
  const { addShedModel, updateShedModel, currentShedModel, clearCurrentShedModel } = shedModelContext;

  const addonContext = useContext(AddonContext);
  const { getAllAddonsByParentCat, allAddOns } = addonContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      name: currentShedModel?.name || "",
      code: currentShedModel?.code || "",
      standardAddOns: currentShedModel?.standardAddOns || [], // Ensure standardAddOns is included
    },
  });

  useEffect(() => {
    if (currentShedModel) {
      reset({
        _id: currentShedModel._id,
        name: currentShedModel.name || "",
        code: currentShedModel.code || "",
        standardAddOns: currentShedModel.standardAddOns || [], // Reset with standardAddOns
      });
    }

    if (currentShedCategory) {
      setValue("shedCategory", currentShedCategory._id);
      getAllAddonsByParentCat(currentShedCategory.addonParentCategories[1]);
    }
  }, [currentShedModel, currentShedCategory]);

  const onSubmit = (data) => {
    if (!currentShedModel) {
      addShedModel(data);
      // navigate(-1);
    } else {
      updateShedModel(data);
      // navigate(-1);
      clearCurrentShedModel();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentShedModel();
  };

  const addOnOptions = allAddOns?.map((addon) => ({
    value: addon._id,
    label: addon.name,
  }));

  const { fields, append, remove } = useFieldArray({
    control,
    name: "standardAddOns",
  });

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">Shed Model Form</h4>

          {/* <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Back
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <Form
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            className="container-fluid bidformbackground border"
            style={{ borderRadius: "5px", padding: "20px" }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name" name="name">
                  <Controller name="name" control={control} render={({ field }) => <Input {...field} />} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Code" name="code">
                  {/*  rules={[{ required: true, message: "Please enter the code" }]} */}
                  <Controller name="code" control={control} render={({ field }) => <Input {...field} />} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Standard Add-Ons">
              {fields.map((item, index) => {
                // Find the currently selected addon to extract the ssId
                const selectedAddon = addOnOptions?.find((option) => option.value === item.addon);
                const ssId = selectedAddon ? allAddOns.find((addon) => addon._id === selectedAddon.value)?.ssId : "";

                return (
                  <Row gutter={16} key={item.id} align="middle" style={{ marginBottom: "10px" }}>
                    <Col span={10}>
                      <Controller
                        name={`standardAddOns.${index}.addon`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={addOnOptions}
                            placeholder="Select Add-Ons"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                            }}
                            value={addOnOptions?.find((option) => option.value === field.value)}
                          />
                        )}
                      />
                    </Col>
                    <Col span={6}>
                      {/* Display the ssId here */}
                      <Input value={ssId} placeholder="Addon SS ID" disabled />
                    </Col>
                    <Col span={4}>
                      <Controller
                        name={`standardAddOns.${index}.quantity`}
                        control={control}
                        render={({ field }) => <Input {...field} type="number" placeholder="Quantity" />}
                      />
                    </Col>
                    <Col span={4}>
                      <Button type="danger" onClick={() => remove(index)} style={{ width: "100%" }}>
                        Remove
                      </Button>
                    </Col>
                  </Row>
                );
              })}

              <Row justify="start">
                <Col span={4}>
                  <Button
                    type="dashed"
                    onClick={() => append({ addon: "", quantity: 1 })}
                    style={{
                      width: "100%",
                      backgroundColor: "#FF5733", // Custom background color
                      borderColor: "#FF5733", // Custom border color to match
                      color: "#fff", // Text color
                    }}
                  >
                    Add Standard Add-On
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            <Row justify="end">
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="btn btn-outline-primary">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ShedModelForm;
