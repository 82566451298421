import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";

import AuthState from "./context/auth/AuthState";
import AlertState from "./context/alert/AlertState";
import AnnouncementState from "./context/announcements/AnnouncementsState";
import SettingsState from "./context/settings/SettingsState";
import ItemState from "./context/item/ItemState";

import ShedLaborState from "./context/shedLabor/ShedLaborState";
import AddOnLaborState from "./context/addOnLabor/AddOnLaborState";

import AddOnState from "./context/addon/AddOnState";
import AddonCategoryState from "./context/addonCategory/AddonCategoryState";
import ComponentCategoryState from "./context/componentCategory/ComponentCategoryState";
import ComponentState from "./context/component/ComponentState";
import ShedCategoryState from "./context/shedCategory/ShedCategoryState";
import ShedState from "./context/shed/ShedState";
import ItemCategoryState from "./context/itemCategory/ItemCategoryState";
import ShedModelState from "./context/shedModel/ShedModelState";
import DealerState from "./context/dealer/DealerState";
import VendorState from "./context/vendor/VendorState";
import HaulBaseState from "./context/haulBase/HaulBaseState";
import OrderState from "./context/order/OrderState";
import ShopState from "./context/shop/ShopState";
import BaseStyleState from "./context/baseStyle/BaseStyleState";
import CompanyState from "./context/company/CompanyState";
import PaymentState from "./context/payment/PaymentState";
import InvoiceState from "./context/invoice/InvoiceState";

import MetalOrderState from "./context/metalOrder/MetalOrderState";
import MaterialOrderState from "./context/materialOrder/MaterialOrderState";
import CommentState from "./context/comment/CommentState";
import LogState from "./context/log/LogState";
import RepairInvoiceState from "./context/repairInvoice/RepairInvoiceState";

import AddonParentCategoryState from "./context/addonParentCategory/AddonParentCategoryState";

import { Routes, Route, BrowserRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CompanyState>
        <AuthState>
          <AlertState>
            <SettingsState>
              <ItemState>
                <ShedState>
                  <ShedCategoryState>
                    <ComponentCategoryState>
                      <ComponentState>
                        <AddOnState>
                          <AddonCategoryState>
                            <ShedLaborState>
                              <AddOnLaborState>
                                <ItemCategoryState>
                                  <ShedModelState>
                                    <VendorState>
                                      <DealerState>
                                        <HaulBaseState>
                                          <OrderState>
                                            <ShopState>
                                              <BaseStyleState>
                                                <InvoiceState>
                                                  <PaymentState>
                                                    <MetalOrderState>
                                                      <MaterialOrderState>
                                                        <CommentState>
                                                          <AnnouncementState>
                                                            <LogState>
                                                              <RepairInvoiceState>
                                                                <AddonParentCategoryState>
                                                                  <Routes>
                                                                    <Route path="/*" element={<App />} />
                                                                  </Routes>
                                                                </AddonParentCategoryState>
                                                              </RepairInvoiceState>
                                                            </LogState>
                                                          </AnnouncementState>
                                                        </CommentState>
                                                      </MaterialOrderState>
                                                    </MetalOrderState>
                                                  </PaymentState>
                                                </InvoiceState>
                                              </BaseStyleState>
                                            </ShopState>
                                          </OrderState>
                                        </HaulBaseState>
                                      </DealerState>
                                    </VendorState>
                                  </ShedModelState>
                                </ItemCategoryState>
                              </AddOnLaborState>
                            </ShedLaborState>
                          </AddonCategoryState>
                        </AddOnState>
                      </ComponentState>
                    </ComponentCategoryState>
                  </ShedCategoryState>
                </ShedState>
              </ItemState>
            </SettingsState>
          </AlertState>
        </AuthState>
      </CompanyState>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
