import React, { useEffect, useContext, useState, Fragment } from "react";
import ShedLaborContext from "../../../../../../context/shedLabor/shedLaborContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ReactSelect from "react-select";

const defaultValues = {};
const ShopShedLaborForm = () => {
  const navigate = useNavigate();

  const shedLaborContext = useContext(ShedLaborContext);
  const { addShedLabor, currentShedLabor, updateShopShedLabor, clearCurrentShopShedLabor } = shedLaborContext;
  const shedModelContext = useContext(ShedModelContext);
  const { getShedModels, shedModels } = shedModelContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const [isBaseStyleSelected, setIsBaseStyleSelected] = useState(false);
  const [baseStyle, setBaseStyle] = useState(null);
  const [buildBase, setBuildBase] = useState(0);
  const [roofBase, setRoofBase] = useState(0);
  const [trussCount, setTrussCount] = useState(0);
  const [paintHours, setPaintHours] = useState(0);

  const [buildCost, setBuildCost] = useState(0);
  const [roofCost, setRoofCost] = useState(0);
  const [trussCost, setTrussCost] = useState(0);
  const [paintCost, setPaintCost] = useState(0);
  const [doorCost, setDoorCost] = useState(0);
  const [totalLaborCost, setTotalLaborCost] = useState(0);

  const handleBaseStylesOptionChange = (e) => {
    setBaseStyle(e.value);
    setValue("baseStyle", e);
    if (e) {
      setIsBaseStyleSelected(true);
    } else {
      setIsBaseStyleSelected(false);
    }
  };

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    getShedModels(currentShop.defaultCategory._id);
    currentShedLabor && reset(currentShedLabor);
    currentShedLabor && setIsBaseStyleSelected(true);
    return () => {
      clearCurrentShopShedLabor();
    };
  }, []);

  const setCostFields = (shedLabor) => {
    if (shedLabor) {
      setBuildBase(shedLabor.shopValues.buildBase);
      setRoofBase(shedLabor.shopValues.roofBase);
      setTrussCount(shedLabor.shopValues.trussCount);
      setPaintHours(shedLabor.shopValues.paintHours);
    }
  };

  useEffect(() => {
    if (currentShedLabor) {
      setValue("baseStyle", {
        value: currentShedLabor.baseStyle._id,
        label: currentShedLabor.baseStyle.name,
      });
      setDoorCost(currentShedLabor.shopValues.doorCost);
      setBaseStyle(currentShedLabor.baseStyle);
      setIsBaseStyleSelected(true);
    }
    if (shedModels && currentShedLabor) {
      const selectedShedModel = shedModels.find((sc) => sc._id === currentShedLabor.model);
      if (selectedShedModel) {
        setValue("model", {
          value: selectedShedModel,
          label: selectedShedModel.name,
        });
      }
    }
    setCostFields(currentShedLabor);
  }, [shedModels]);

  const calculateCosts = () => {
    if (!isBaseStyleSelected || !baseStyle) return;
    const newBuildCost = buildBase * (baseStyle.shopValues.buildPercent.value / 100);
    const newRoofCost = roofBase * (baseStyle.shopValues.roofPercent.value / 100);
    const newTrussCost = trussCount * baseStyle.shopValues.trussCost.value;
    const newPaintCost = paintHours * baseStyle.shopValues.paintCost.value;
    setBuildCost(newBuildCost);
    setRoofCost(newRoofCost);
    setTrussCost(newTrussCost);
    setPaintCost(newPaintCost);
    setTotalLaborCost(newBuildCost + newRoofCost + newTrussCost + newPaintCost + doorCost);
  };

  useEffect(() => {
    calculateCosts();
  }, [buildBase, roofBase, trussCount, paintHours, baseStyle]);

  const onSubmit = (data) => {
    if (!currentShedLabor) {
      const updatedData = {
        ...data,
        totalLaborCost,
      };
      addShedLabor(updatedData);
    } else {
      const updatedData = {
        ...data,
        totalLaborCost,
      };
      updateShopShedLabor(updatedData);
    }
  };

  const shedLaborModelOptions =
    shedModels &&
    shedModels.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const handleModelChange = (e) => {
    setValue(`model`, e);
    // setValue("value.model.label", e.label);
  };

  // const handleSizeChange = (e) => {
  //   setValue(`value.size`, e);
  // };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "laborCategories",
  });

  // const onSubmit = (data) => {
  //   console.log(data);
  //   const updatedData = {
  //     ...data,
  //     totalLaborCost,
  //   };
  //   addShedLabor(updatedData);
  //   // Send data to your API or perform other actions
  // };
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <button className="mb-2 btn btn-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
        </div>
      </div>
      <form className="shedLaborForm" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="baseStyle">Base Style:</label>
        <Controller
          render={({ field }) => (
            <ReactSelect
              {...field}
              isClearable={true}
              isDisabled={true}
              // options={baseStylesOptions}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => {
                handleBaseStylesOptionChange(e);
              }}
            />
          )}
          name={`baseStyle`}
          control={control}
          className="shedlaborforminput"
        />
        <label htmlFor="model">Shed Model:</label>
        <Controller
          render={({ field }) => (
            <ReactSelect
              {...field}
              isClearable={true}
              isDisabled={true}
              disabled={!isBaseStyleSelected}
              options={shedLaborModelOptions}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => {
                handleModelChange(e);
              }}
            />
          )}
          name={`model`}
          control={control}
          className="shedlaborforminput"
        />
        {/* {errors.baseStyle && <p>This field is required</p>} */}
        <label htmlFor="width">Width:</label>
        <input
          className="shedlaborforminput"
          type="number"
          readOnly
          {...register("width", { required: true })}
          id="width"
          disabled={!isBaseStyleSelected}
        />
        {/* {errors.width && <p>This field is required</p>} */}
        <label htmlFor="length">Length:</label>
        <input
          className="shedlaborforminput"
          type="number"
          readOnly
          {...register("length", { required: true })}
          id="length"
          disabled={!isBaseStyleSelected}
        />
        {/* {errors.length && <p>This field is required</p>} */}

        <div className="input-row" style={{ display: "flex", justifyContent: "space-between" }}>
          <label htmlFor="shopValues.buildBase">Build Base:</label>
          <Controller
            render={({ field }) => (
              <input
                {...field}
                className="shedlaborforminput"
                type="number"
                id="shopValues.buildBase"
                disabled={!isBaseStyleSelected}
                onChange={(e) => {
                  field.onChange(e);
                  setBuildBase(e.target.value);
                }}
              />
            )}
            name="shopValues.buildBase"
            control={control}
            defaultValue={0}
            rules={{ required: true }}
          />
          <label htmlFor="buildCost">Cost:</label>
          <input className="shedlaborforminput" type="number" value={buildCost} disabled />
        </div>

        <div className="input-row" style={{ display: "flex", justifyContent: "space-between" }}>
          <label htmlFor="shopValues.roofBase">Roof Base:</label>
          <Controller
            render={({ field }) => (
              <input
                {...field}
                className="shedlaborforminput"
                type="number"
                id="shopValues.roofBase"
                disabled={!isBaseStyleSelected}
                onChange={(e) => {
                  field.onChange(e);
                  setRoofBase(e.target.value);
                }}
              />
            )}
            name="shopValues.roofBase"
            control={control}
            defaultValue={0}
            rules={{ required: true }}
          />
          <label htmlFor="roofCost">Cost:</label>
          <input className="shedlaborforminput" type="number" value={roofCost} disabled />
        </div>

        <div className="input-row" style={{ display: "flex", justifyContent: "space-between" }}>
          <label htmlFor="shopValues.trussCount">Truss Count:</label>
          <Controller
            render={({ field }) => (
              <input
                {...field}
                className="shedlaborforminput"
                type="number"
                id="shopValues.trussCount"
                disabled={!isBaseStyleSelected}
                onChange={(e) => {
                  field.onChange(e);
                  setTrussCount(e.target.value);
                }}
              />
            )}
            name="shopValues.trussCount"
            control={control}
            defaultValue={0}
            rules={{ required: true }}
          />
          <label htmlFor="trussCost">Cost:</label>
          <input className="shedlaborforminput" type="number" value={trussCost} disabled />
        </div>

        <div className="input-row" style={{ display: "flex", justifyContent: "space-between" }}>
          <label htmlFor="shopValues.paintHours">Paint Hours:</label>
          <Controller
            render={({ field }) => (
              <input
                {...field}
                className="shedlaborforminput"
                type="number"
                id="shopValues.paintHours"
                disabled={!isBaseStyleSelected}
                onChange={(e) => {
                  field.onChange(e);
                  setPaintHours(e.target.value);
                }}
              />
            )}
            name="shopValues.paintHours"
            control={control}
            defaultValue={0}
            rules={{ required: true }}
          />
          <label htmlFor="paintCost">Cost:</label>
          <input className="shedlaborforminput" type="number" value={paintCost} disabled />
        </div>
        <label htmlFor="paintCost">Door Cost:</label>
        <input className="shedlaborforminput" type="number" value={doorCost} disabled />
        <label htmlFor="shopValues.totalLaborCost">Total Labor Cost:</label>
        <input
          className="shedlaborforminput"
          type="number"
          value={totalLaborCost}
          {...register("shopValues.totalLaborCost")}
          id="shopValues.totalLaborCost"
          disabled
        />
        {/* {errors.paintHours && <p>This field is required</p>} */}
        <button className="shedlaborformbtn" type="submit" disabled={!isBaseStyleSelected}>
          Submit
        </button>
      </form>
    </Fragment>
  );
};

export default ShopShedLaborForm;
