import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentContext from "../../../../../context/payment/paymentContext";
import AuthContext from "../../../../../context/auth/authContext";
import ShopContext from "../../../../../context/shop/shopContext";
import PaymentInvoiceArray from "./PaymentInvoiceArray";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Input, DatePicker, Table } from "antd";
import moment from "moment";
import ShopExpensesGpTable from "./ShopExpensesGpTable";
import ReactSelect from "react-select";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./pdf/Invoice";

const { TextArea } = Input;
const defaultValues = {};

const ShopViewPaymentForm = () => {
  const navigate = useNavigate();

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });
  const formValues = watch();

  const paymentContext = useContext(PaymentContext);
  const { currentPayment, updatePayment } = paymentContext;

  const shopContext = useContext(ShopContext);
  const { shops, getShops, currentShop } = shopContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const [shopTableVisible, setShopTableVisible] = useState(false);

  useEffect(() => {
    if (currentPayment && currentPayment.invoices) {
      // Create a shallow copy of the invoices array
      const sortedInvoices = [...currentPayment.invoices];

      // Sorting the copied invoices array by invoiceNumber
      sortedInvoices.sort((a, b) => {
        const invoiceNumberA = a.invoice?.invoiceNumber || "";
        const invoiceNumberB = b.invoice?.invoiceNumber || "";

        if (invoiceNumberA > invoiceNumberB) {
          return 1;
        }
        if (invoiceNumberA < invoiceNumberB) {
          return -1;
        }
        return 0;
      });

      // Create a new object with the sorted invoices
      const updatedPayment = {
        ...currentPayment,
        invoices: sortedInvoices,
      };

      // Reset with the updated object instead of mutating currentPayment
      reset(updatedPayment);
    }

    console.log("currentPayment", currentPayment?.invoices);
  }, []);

  const onSubmit = (data) => {
    const shopsPaymentAdjustment = data.shopsPaymentAdjustment.value;
    // Create a new object with the extracted `shopsPaymentAdjustment`
    const updatedData = {
      ...data,
      shopsPaymentAdjustment,
    };
    updatePayment(updatedData);
  };

  const setExit = () => {
    navigate(-1);
  };

  // const handleViewOrder = () => {
  //   // alert('this is the order');
  //   setCurrentOrder(currentInvoice.order);
  //   navigate('/order/view');
  // };

  const dateFormat = "MM/DD/YYYY";

  const setMemo = (memo) => {
    setValue("memo", memo);
  };

  const shopOptions =
    shops &&
    shops.map((user) => {
      return { label: `${user.name}`, value: user };
    });

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (currentPayment) {
      setDataSource([
        {
          key: "1",
          type: { label: "Sub-Total", value: "paymentSubTotal" },
          value: currentPayment.paymentSubTotal.shop,
        },
        {
          key: "2",
          type: { label: "Adjustment", value: "shopsPaymentAdjustment" },
          value: currentPayment.shopsPaymentAdjustment,
        },
        {
          key: "3",
          type: { label: "Total", value: "paymentTotal" },
          value: currentPayment.paymentTotal.shop,
        },
      ]);
    }
  }, [currentPayment]);

  const columns = [
    {
      title: "",
      dataIndex: "empty",
      key: "empty",
      width: "50%",
    },
    {
      title: "",
      dataIndex: "type",
      key: "type",
      width: "25%",
      render: (text, record, index) => {
        const backgroundColor = "#55add8"; // Set the background color as needed

        return {
          props: {
            style: {
              background: backgroundColor,
            },
          },
          children: <div>{record.type.label}</div>,
        };
      },
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: "25%",
      render: (_, record, index) => {
        const fieldName = `${record.type.value}.value`;
        const readOnly = index !== 5; // Set the readOnly condition based on your requirements

        return {
          props: {
            style: {
              background: readOnly ? "#dbdbdb" : "",
            },
          },
          children: (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  className={`form-control custom-input ${readOnly ? "readonlyinput" : ""} `}
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={({ value }) => field.onChange(value)} // Update the value of the input
                  onBlur={field.onBlur}
                  value={field.value}
                  readOnly={readOnly}
                />
              )}
              control={control}
              name={fieldName}
              defaultValue={record.value}
            />
          ),
        };
      },
    },
  ];

  return (
    <div className="mt-2">
      <div className="row mb-4">
        <div className="col-md-12">
          <PDFDownloadLink
            document={<Invoice data={currentPayment && currentPayment} currentShop={currentShop} />}
            fileName={`${currentPayment && currentPayment.paymentNumber}_payment.pdf`}
            className="btn btn-outline-success "
            type="button"
            // style={{
            // textDecoration: "none",
            // padding: "10px",
            // color: "#ffffff",
            // backgroundColor: "#033699",
            // border: "1px solid #4a4a4a"
            // }}
          >
            {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download Payment & Invoices")}
          </PDFDownloadLink>
          <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>

      <div className="row">
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="  border  " style={{ borderRadius: "8px" }}>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 mt-4">
                      <div className="form-group"></div>
                    </div>

                    <div className="col-md-7"></div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-12">{/* <h3 className="text-center">Payment</h3> */}</div>
                  </div>

                  <div className="container">
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-2">
                              <label className="form-label">Payment To:</label>
                              <Controller
                                render={({ field }) => (
                                  <ReactSelect
                                    {...field}
                                    value={
                                      (shopOptions && shopOptions.find((option) => option.value._id === field.value)) || null
                                    }
                                    isDisabled={true}
                                    options={shopOptions}
                                    menuPortalTarget={document.body}
                                    menuPosition={"fixed"}
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                      setValue(`shop`, e.value);
                                    }}
                                    // isDisabled={true}
                                  />
                                )}
                                name={`shop`}
                                control={control}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-2">
                              <label className="form-label">Payment #:</label>
                              <input
                                className="form-control inputfield"
                                name="paymentNumber"
                                {...register("paymentNumber", { required: true })}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-2">
                              <label className="form-label">Date:</label>
                              <Controller
                                render={({ field, name }) => (
                                  <DatePicker
                                    format={dateFormat}
                                    className="form-control inputfield"
                                    name={name}
                                    value={field.value ? moment(field.value) : null}
                                    onChange={(date) => field.onChange(date ? date.toISOString() : "")}
                                  />
                                )}
                                control={control}
                                name={`paymentDate`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <PaymentInvoiceArray
                    {...{
                      control,
                      watch,
                      register,
                      defaultValues,
                      getValues,
                      setValue,
                      errors,
                      getValues,
                      // Add these new props
                      showShedProfitDollars: true,
                      showAddOnsProfitDollars: true,
                      showCustomAddOnsProfitDollars: true,
                      showProfitDollars: true,
                    }}
                  />
                  <Table dataSource={dataSource} columns={columns} pagination={false} />
                  <div className="row mt-4">
                    <div className="col-md-4"></div>
                    <div className="col-md-8">
                      <h4>Memo </h4>

                      <Controller
                        render={({ field, name }) => (
                          <TextArea
                            rows={4}
                            {...field}
                            style={{ backgroundColor: "white" }}
                            name={name}
                            onChange={(e) => {
                              const value = e.target.value;
                              // console.log("this is the value", value);
                              setMemo(value);
                            }}
                            placeholder="..."
                          />
                        )}
                        name={`memo`}
                        control={control}
                      />
                    </div>
                  </div>

                  {user && (user.role === "Shop Admin" || user.role === "Shop User") && (
                    <button
                      className="btn btn-warning mb-2 mt-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setShopTableVisible(!shopTableVisible);
                      }}
                    >
                      {shopTableVisible ? "Hide Shop Expenses & GP" : "Show Shop Expenses & GP"}
                    </button>
                  )}
                  {shopTableVisible && <ShopExpensesGpTable formValues={formValues} />}
                  <div className="row">
                    <div className="col-md-10"></div>
                    <div className="col-md-2">
                      <button className="btn btn-outline-primary float-end" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShopViewPaymentForm;
