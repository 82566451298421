import React, { Fragment, useEffect, useContext } from "react";
import SidingColorColumns from "./SidingColorColumns";

const AllSidingColors = ({ sidingColors, setSelectedSidingColor, selectedSidingColor }) => {
  const theTitle = "Siding Colors";

  return (
    <SidingColorColumns
      sidingColors={sidingColors}
      setSelectedSidingColor={setSelectedSidingColor}
      selectedSidingColor={selectedSidingColor}
      theTitle={theTitle}
    />
  );
};

export default AllSidingColors;
