import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Card, Statistic, Spin, Typography } from "antd";
import { DollarOutlined } from "@ant-design/icons";
import OrderContext from "../../../context/order/orderContext";
import InvoiceContext from "../../../context/invoice/invoiceContext";
import PaymentContext from "../../../context/payment/paymentContext"; // Add this line
import OrderChartOne from "./charts/OrderChartOne";
import InvoicesChartOne from "./charts/InvoicesChartOne";
import PaymentLineChart from "./charts/PaymentLineChart"; // Add this line

const { Title } = Typography;

const cardStyle = {
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  border: "1px solid #e8e8e8",
  borderRadius: "8px",
  transition: "all 0.3s",
};

const ShopHome = () => {
  const orderContext = useContext(OrderContext);
  const { chartOrders, getOrdersForChart } = orderContext;

  const invoiceContext = useContext(InvoiceContext);
  const { chartInvoices, getInvoiceForChart } = invoiceContext;

  const paymentContext = useContext(PaymentContext); // Add this line
  const { getPayments, payments } = paymentContext;

  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    getInvoiceForChart();
    getOrdersForChart();
    getPayments();
  }, []);

  useEffect(() => {
    if (payments) {
      console.log(payments);
      const processedData = processPaymentData(payments);
      setPaymentData(processedData);
    }
  }, [payments]);

  const getOrderCountByStatus = (status) => {
    return chartOrders?.filter((order) => order.status === status).length || 0;
  };

  const getInvoiceCountByStatus = (status) => {
    return chartInvoices?.filter((invoice) => invoice.status === status).length || 0;
  };

  const getTotalOrderValue = (orders, status = null) => {
    return (
      orders
        ?.filter((order) => (status ? order.status === status : true))
        .reduce((total, order) => total + (order.grandTotal?.shop || 0), 0) || 0
    );
  };

  const getTotalInvoiceValue = (invoices, status = null) => {
    return (
      invoices
        ?.filter((invoice) => (status ? invoice.status === status : true))
        .reduce((total, invoice) => total + (invoice.order?.grandTotal?.shop || 0), 0) || 0
    );
  };

  const processPaymentData = (payments) => {
    const now = new Date();
    const threeMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 2, 1);

    return payments
      .filter((payment) => new Date(payment.paymentDate) >= threeMonthsAgo)
      .map((payment) => ({
        date: new Date(payment.paymentDate).toISOString().split("T")[0],
        totalAmount: payment.paymentTotal.shop,
        grossProfitPercentage: payment.averageProfitPercent.shop * 100,
        invoiceCount: payment.invoices.length, // Add this line
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  return (
    <div style={{ padding: "24px" }}>
      <Title level={2}>Shop Dashboard</Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Card title="Total Order Value" style={cardStyle} hoverable>
            <Statistic
              title="Shop Total"
              value={getTotalOrderValue(chartOrders)}
              prefix={<DollarOutlined />}
              valueStyle={{ color: "#1890ff" }}
              precision={2}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card title="Total Invoice Value" style={cardStyle} hoverable>
            <Statistic
              title="Shop Total"
              value={getTotalInvoiceValue(chartInvoices)}
              prefix={<DollarOutlined />}
              valueStyle={{ color: "#1890ff" }}
              precision={2}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col xs={24}>
          <Card title="Order Status Totals" style={cardStyle} hoverable>
            <Row gutter={[16, 16]}>
              {["InQueue", "Building", "Finished", "On Hold"].map((status) => (
                <Col xs={24} sm={12} md={6} key={status}>
                  <Card title={status} size="small">
                    <Statistic title="Count" value={getOrderCountByStatus(status)} valueStyle={getStatusColor(status)} />
                    <Statistic
                      title="Total"
                      value={getTotalOrderValue(chartOrders, status)}
                      prefix={<DollarOutlined />}
                      valueStyle={{ color: "#1890ff" }}
                      precision={2}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col xs={24}>
          <Card title="Invoice Status Totals" style={cardStyle} hoverable>
            <Row gutter={[16, 16]}>
              {["New", "Denied", "Adjusted", "Approved"].map((status) => (
                <Col xs={24} sm={12} md={6} key={status}>
                  <Card title={status} size="small">
                    <Statistic title="Count" value={getInvoiceCountByStatus(status)} valueStyle={getInvoiceStatusColor(status)} />
                    <Statistic
                      title="Total"
                      value={getTotalInvoiceValue(chartInvoices, status)}
                      prefix={<DollarOutlined />}
                      valueStyle={{ color: "#1890ff" }}
                      precision={2}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col xs={24}>
          <Card title="Payment Trends" style={cardStyle} hoverable>
            {paymentData.length === 0 ? (
              <Spin size="large" />
            ) : (
              <PaymentLineChart
                paymentData={paymentData}
                colors={{
                  totalAmount: "rgb(0, 123, 255)", // Darker blue
                  grossProfitPercentage: "rgb(40, 167, 69)", // Green
                  invoiceCount: "rgb(255, 193, 7)", // Amber
                }}
              />
            )}
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col xs={24} md={12}>
          <Card title="Order Status Chart" style={cardStyle} hoverable>
            {!chartOrders ? <Spin size="large" /> : <OrderChartOne orders={chartOrders} />}
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Invoice Status Chart" style={cardStyle} hoverable>
            {!chartInvoices ? <Spin size="large" /> : <InvoicesChartOne invoices={chartInvoices} />}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case "InQueue":
      return { color: "#1890ff" };
    case "Building":
      return { color: "#faad14" };
    case "Finished":
      return { color: "#52c41a" };
    case "On Hold":
      return { color: "#ff4d4f" };
    default:
      return { color: "#1890ff" };
  }
};

const getInvoiceStatusColor = (status) => {
  switch (status) {
    case "New":
      return { color: "#1890ff" };
    case "Denied":
      return { color: "#ff4d4f" };
    case "Adjusted":
      return { color: "#faad14" };
    case "Approved":
      return { color: "#52c41a" };
    default:
      return { color: "#1890ff" };
  }
};

export default ShopHome;
