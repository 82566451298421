import {
  ADD_BASESTYLE,
  DELETE_BASESTYLE,
  CLEAR_BASESTYLES,
  SET_CURRENT_BASESTYLE,
  CLEAR_CURRENT_BASESTYLE,
  UPDATE_BASESTYLE,
  BASESTYLE_ERROR,
  GET_BASESTYLES,
  SET_BASESTYLES,
  FILTER_BASESTYLES,
  CLEAR_FILTER_BASESTYLE,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_BASESTYLES:
      return {
        ...state,
        baseStyles: action.payload,
        loading: false,
      };
    case ADD_BASESTYLE:
      return {
        ...state,
        baseStyle: action.payload,
        loading: false,
      };
    case UPDATE_BASESTYLE:
      return {
        ...state,
        // baseStyles: action.payload,
        loading: false,
      };
    case DELETE_BASESTYLE:
      return {
        ...state,
        baseStyles: state.baseStyles.filter((baseStyle) => baseStyle._id !== action.payload),
        loading: false,
      };
    case CLEAR_BASESTYLES:
      return {
        ...state,
        baseStyles: null,
        filteredBaseStyle: null,
        error: null,
        currentBaseStyle: null,
      };
    case SET_CURRENT_BASESTYLE:
      return {
        ...state,
        currentBaseStyle: action.payload,
      };

    case SET_BASESTYLES:
      return {
        ...state,
        baseStyles: action.payload,
      };
    case CLEAR_CURRENT_BASESTYLE:
      return {
        ...state,
        currentBaseStyle: null,
      };

    case BASESTYLE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FILTER_BASESTYLES:
      return {
        ...state,
        filteredBaseStyle: state.baseStyles.filter((baseStyle) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          console.log("this is the search baseStyle", action.payload, baseStyle);
          return baseStyle.name.value.match(regex);
        }),
      };
    case CLEAR_FILTER_BASESTYLE:
      return {
        ...state,
        filteredProduct: null,
      };
    default:
      return state;
  }
};
