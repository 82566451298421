import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllAddonLaborCategories = () => {
  const navigate = useNavigate();
  const addonCategoryContext = useContext(AddonCategoryContext);
  const { getAddonCategorys, addonCategorys, setCurrentAddonCategory } = addonCategoryContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  useEffect(() => {
    // getAddonCategorys();
    getAddonCategorys(currentShop.nbAddonCategory._id);
  }, []);

  useEffect(() => {}, [addonCategorys]);

  const columns = [
    {
      title: "Name",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddonLaborCategory(record)}>
            View
          </button>
          {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditAddonLaborCategory(record)}>
            Edit
          </button> */}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewAddonLaborCategory = (category) => {
    setCurrentAddonCategory(category);
    navigate("/shop/addonlabor/all");
  };

  return (
    <Table
      rowClassName={() => "hover-row"}
      // loading={!loadingProduct && productResults ? false : true}
      pagination={{ pageSize: 60 }}
      columns={columns}
      // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
      dataSource={addonCategorys}
      rowKey="_id"
      onChange={onChange}
      scroll={{ x: 1300, y: 2500 }}
    />
  );
};

export default ShopAllAddonLaborCategories;
