import React, { useEffect, useContext } from "react";
// import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import AddonParentCategoryContext from "../../../../../../context/addonParentCategory/addonParentCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";

const defaultValues = {};
const CopyAddonParentCategory = () => {
  const navigate = useNavigate();

  const addonParentCategoryContext = useContext(AddonParentCategoryContext);
  const { getAddonParentCategorys, addonParentCategorys, copyExistingCategoryData, currentAddonParentCategory } =
    addonParentCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    // currentShedCategory && reset(currentShedCategory);
    getAddonParentCategorys();
  }, []);

  console.log("This is currentAddonParentCategory", currentAddonParentCategory);

  const onSubmit = (data) => {
    // if (!currentShedCategory) {
    // addShedCategory(data);
    data.existingAddonParentCategory = currentAddonParentCategory._id;
    copyExistingCategoryData(data);
    navigate(-1);
    // } else {
    //   updateShedCategory(data);
    //   navigate(-1);
    //   clearCurrentShedCategory();
    // }
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentShedCategory();
  };

  const shedCategoryOptions = addonParentCategorys.map((shedCategory) => {
    return { value: shedCategory._id, label: shedCategory.name };
  });

  const handleCatOptionChange = (e) => {
    setValue("existingCategoryId", e);
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">Copy All Addons and Categories</h4>
          {/* {currentDoor && (
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form className="haulBaseForm" onSubmit={handleSubmit(onSubmit)}>
            {/* <label htmlFor="baseStyle">Existing Category to copy:</label>
            <Controller
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  isClearable={true}
                  options={shedCategoryOptions}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  onChange={(e) => {
                    handleCatOptionChange(e);
                  }}
                />
              )}
              name="existingCategoryId"
              control={control}
              className="shedlaborforminput"
            /> */}

            <label className="mt-3" htmlFor="name">
              New Parent Category Name
            </label>
            <input className="form-control" type="string" {...register("name", { required: true })} id="name" />
            {/* <label htmlFor="codePrefix">Prefix for categoryName Code:</label>
            <input className="form-control" type="string" {...register("codePrefix", { required: true })} id="codePrefix" /> */}

            <label htmlFor="categoryNameSuffix">Suffix for Category Name:</label>
            <input
              className="form-control"
              type="string"
              {...register("categoryNameSuffix", { required: true })}
              id="categoryNameSuffix"
            />

            <button
              // className={user.viewOnly ? "viewOnly" : "shedlaborformbtn"}
              // disabled={user.viewOnly || !isBaseStyleSelected}
              className="shedlaborformbtn mt-2"
              type="submit"
              // disabled={!isBaseStyleSelected}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CopyAddonParentCategory;
