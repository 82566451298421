import React, { useReducer, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import RepairInvoiceContext from "./repairInvoiceContext";
import repairInvoiceReducer from "./repairInvoiceReducer";
import AuthContext from "../auth/authContext";
import axios from "axios";
import { toast } from "react-toastify";

import {
  ADD_REPAIRINVOICE,
  DELETE_REPAIRINVOICE,
  CLEAR_REPAIRINVOICES,
  SET_CURRENT_REPAIRINVOICE,
  CLEAR_CURRENT_REPAIRINVOICE,
  UPDATE_REPAIRINVOICE,
  SET_FILTERED_REPAIRINVOICES,
  CLEAR_FILTERED_REPAIRINVOICES,
  REPAIRINVOICE_ERROR,
  GET_REPAIRINVOICES,
  STATUS_CHANGE_REPAIRINVOICE,
  GET_REPAIRINVOICES_CHART,
  SET_SEARCHED_REPAIRINVOICE,
  CLEAR_SEARCHED_REPAIRINVOICE,
  SWITCH_LOADINGREPAIRINVOICE,
  SET_REPAIRINVOICE_LOADING,
  CLEAR_FILTER_REPAIRINVOICES_BY_SHOP,
  SET_SHOP_FOR_FILTER,
  SET_REPAIRINVOICE_NUMBER_SEARCH,
  SET_CUT_OFF_DATE,
  SET_REPAIRINVOICE_PAGINATION,
  GET_REPAIRINVOICE_COUNT,
  CLEAR_REPAIRINVOICE_COUNT,
  SET_REPAIRINVOICE_IS_CANCELED,
  GET_ADJUSTED_REPAIRINVOICES,
  UPDATE_REPAIRINVOICE_ORDER,
} from "../types";

const RepairInvoiceState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    repairInvoices: null,
    adjustedRepairInvoices: null,
    repairInvoiceCount: null,
    currentRepairInvoice: null,
    searchedRepairInvoice: null,
    filteredRepairInvoice: null,
    chartRepairInvoices: null,
    filteredRepairInvoicesByShop: null,
    error: null,
    shop: null,
    repairInvoiceLoading: false,
    isCancelled: false,
    repairInvoiceNumber: null,
    cutOffDate: null,
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  const [state, dispatch] = useReducer(repairInvoiceReducer, initialState);
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const addRepairInvoice = async (repairInvoice) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/repairinvoice", { repairInvoice }, config);
      dispatch({ type: ADD_REPAIRINVOICE, payload: res.data });
      toast.success(`Invoice is created`);
    } catch (err) {
      dispatch({ type: REPAIRINVOICE_ERROR });
      toast.error(`${err.response.data.msg}`);
    }
  };

  const getRepairInvoicesByStatus = async (filter) => {
    try {
      const res = await axios.get(`/api/repairinvoice/status/${filter.status}/${filter.type}`);
      dispatch({ type: GET_REPAIRINVOICES, payload: res.data });
    } catch (err) {
      dispatch({ type: REPAIRINVOICE_ERROR });
    }
  };

  const getRepairInvoiceForChart = async () => {
    try {
      setRepairInvoiceLoading(true);
      const res = await axios.get(`/api/repairinvoice/chart/filter`);
      dispatch({ type: GET_REPAIRINVOICES_CHART, payload: res.data });
      setRepairInvoiceLoading(false);
    } catch (err) {
      dispatch({ type: REPAIRINVOICE_ERROR });
    }
  };

  const getRepairInvoiceById = async (id) => {
    try {
      setRepairInvoiceLoading(true);
      const res = await axios.get(`/api/repairinvoice/findbyid/${id}`);
      dispatch({ type: SET_CURRENT_REPAIRINVOICE, payload: res.data });
      if (user.role === "Office User" || user.role === "Office Admin") {
        if (res.data.type === "Repair") {
          navigate("/company/repair/invoice/form");
        } else {
          navigate("/company/driver/invoice/form");
        }
      } else {
        if (res.data.type === "Repair") {
          navigate("/shop/repair/invoice/form");
        } else {
          navigate("/shop/driver/invoice/form");
        }
      }
      setRepairInvoiceLoading(false);
    } catch (err) {
      dispatch({ type: REPAIRINVOICE_ERROR });
    }
  };

  const findRepairInvoiceByRepairInvoiceNumber = async (repairInvoiceNumber) => {
    try {
      dispatch({ type: SWITCH_LOADINGREPAIRINVOICE });
      const res = await axios.get(`/api/repairinvoice/search/${repairInvoiceNumber}`);
      dispatch({ type: SET_SEARCHED_REPAIRINVOICE, payload: res.data });
    } catch (err) {
      dispatch({ type: REPAIRINVOICE_ERROR });
    }
  };

  const deleteRepairInvoice = async (repairInvoice) => {
    try {
      const res = await axios.delete(`/api/repairinvoice/delete/${repairInvoice._id}`);
      // dispatch({ type: DELETE_REPAIRINVOICE, payload: repairInvoice._id });
      toast.success(`Invoice is deleted`);
      navigate(-1);
    } catch (err) {
      dispatch({ type: REPAIRINVOICE_ERROR });
      toast.error(`Invoice ${err}`);
    }
  };

  const updateRepairInvoice = async (repairInvoice) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/repairinvoice/update/${repairInvoice._id}`, repairInvoice, config);
      dispatch({ type: UPDATE_REPAIRINVOICE, payload: repairInvoice });
      toast.success(`Invoice is updated`);
      return res;
    } catch (err) {
      dispatch({ type: REPAIRINVOICE_ERROR });
      toast.error(`RepairInvoice ${err}`);
    }
  };

  const clearRepairInvoices = () => {
    dispatch({ type: CLEAR_REPAIRINVOICES });
  };

  // const updateRepairInvoiceOrder = (order) => {
  //   dispatch({ type: UPDATE_REPAIRINVOICE_ORDER, payload: order });
  // };

  const setCurrentRepairInvoice = (repairInvoice) => {
    dispatch({ type: SET_CURRENT_REPAIRINVOICE, payload: repairInvoice });
  };

  const clearCurrentRepairInvoice = () => {
    dispatch({ type: CLEAR_CURRENT_REPAIRINVOICE });
  };

  const setFilteredRepairInvoices = (text) => {
    dispatch({ type: SET_FILTERED_REPAIRINVOICES, payload: text });
  };
  const clearFilteredRepairInvoices = () => {
    dispatch({ type: CLEAR_FILTERED_REPAIRINVOICES });
  };

  const statusChange = (repairInvoice) => {
    dispatch({ type: STATUS_CHANGE_REPAIRINVOICE, payload: repairInvoice });
  };

  const setRepairInvoiceLoading = (bool) => {
    dispatch({ type: SET_REPAIRINVOICE_LOADING, payload: bool });
  };

  const setRepairInvoiceNumberSearch = (n) => {
    dispatch({ type: SET_REPAIRINVOICE_NUMBER_SEARCH, payload: n });
  };
  const clearSearchedRepairInvoice = () => {
    dispatch({ type: CLEAR_SEARCHED_REPAIRINVOICE });
  };
  const setCutOffDate = (date) => {
    dispatch({ type: SET_CUT_OFF_DATE, payload: date });
  };

  return (
    <RepairInvoiceContext.Provider
      value={{
        repairInvoices: state.repairInvoices,
        currentRepairInvoice: state.currentRepairInvoice,
        filteredRepairInvoices: state.filteredRepairInvoices,
        searchedRepairInvoice: state.searchedRepairInvoice,
        error: state.error,
        shop: state.shop,
        repairInvoiceLoading: state.repairInvoiceLoading,
        repairInvoiceNumber: state.repairInvoiceNumber,
        cutOffDate: state.cutOffDate,
        repairInvoiceCount: state.repairInvoiceCount,
        isCancelled: state.isCancelled,
        adjustedRepairInvoices: state.adjustedRepairInvoices,
        chartRepairInvoices: state.chartRepairInvoices,
        // getRepairInvoicesByStatus,
        addRepairInvoice,
        deleteRepairInvoice,
        setCurrentRepairInvoice,
        clearCurrentRepairInvoice,
        updateRepairInvoice,
        setFilteredRepairInvoices,
        clearFilteredRepairInvoices,
        clearRepairInvoices,
        statusChange,
        // getRepairInvoicesByShopAndStatus,
        getRepairInvoiceById,
        findRepairInvoiceByRepairInvoiceNumber,
        // getOpenRepairInvoices,
        clearSearchedRepairInvoice,
        setRepairInvoiceLoading,
        // updateBulkOrdersToRepairInvoiced,
        // updateRepairInvoiceStatus,
        // getRepairInvoicesForChartOne,
        // clearFilteredRepairInvoicesByShop,
        // setShopForFilter,
        setRepairInvoiceNumberSearch,
        setCutOffDate,
        // getPaidRepairInvoices,
        // setPagination,
        // getRepairInvoiceCount,
        // clearRepairInvoiceCount,
        // setIsCancelled,
        // getAdjustedRepairInvoices,
        // updateAdjustedRepairInvoice,
        getRepairInvoicesByStatus,
        getRepairInvoiceForChart,
        // updateRepairInvoiceOrder,
      }}
    >
      {props.children}
    </RepairInvoiceContext.Provider>
  );
};

export default RepairInvoiceState;
