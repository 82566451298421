import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import ShedLaborContext from "./shedLaborContext";
import shedLaborReducer from "./shedLaborReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_SHEDLABOR,
  DELETE_SHEDLABOR,
  CLEAR_SHEDLABORS,
  SET_CURRENT_SHEDLABOR,
  CLEAR_CURRENT_SHEDLABOR,
  UPDATE_SHEDLABOR,
  SHEDLABOR_ERROR,
  GET_SHEDLABORS,
  GET_ALL_SHEDLABORS,
} from "../types";

const ShedLaborState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    shedLabors: null,
    allShedLabors: null,
    currentShedLabor: null,
    error: null,
  };

  const [state, dispatch] = useReducer(shedLaborReducer, initialState);

  const getShedLaborsByModel = async (model) => {
    try {
      const res = await axios.get(`/api/shedlabor/model/${model._id}`, {
        // params: {
        //   id: model._id,
        // },
      });
      dispatch({ type: GET_SHEDLABORS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
      toast.error(`Shed Labor ${err}`);
    }
  };

  const getShopShedLaborsByModel = async (model) => {
    try {
      const res = await axios.get(`/api/shedlabor/model/shop/${model._id}`, {
        // params: {
        //   id: model._id,
        // },
      });
      dispatch({ type: GET_SHEDLABORS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
      toast.error(`Shed Labor ${err}`);
    }
  };

  const importShedLabor = async (labor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/shedlabor/import", { labor }, config);
      toast.success(`Shed Labor is imported`);
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
    }
  };

  const updateShopLabors = async (file) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put("/api/shedlabor/update/shop/all", { file }, config);
      toast.success(`Shed Labors is updated succesfully`);
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
    }
  };

  const getAllShedLabors = async (category) => {
    try {
      const res = await axios.get(`/api/shedlabor/${category}`);
      dispatch({ type: GET_ALL_SHEDLABORS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
      toast.error(`Shed ${err}`);
    }
  };

  // const getAllShedsLabor = async () => {
  //   try {
  //     const res = await axios.get(`/api/shedlabor`);
  //     dispatch({ type: GET_SHEDLABORS, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: SHEDLABOR_ERROR });
  //     toast.error(`Shed ${err}`);
  //   }
  // };

  // add
  const addShedLabor = async (shedLabor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/shedlabor", shedLabor, config);
      dispatch({ type: ADD_SHEDLABOR, payload: res.data });
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
    }
  };

  // delete
  const deleteShedLabor = async (id) => {
    try {
      const res = await axios.delete(`/api/shedlabor/delete/${id}`);
      dispatch({ type: DELETE_SHEDLABOR, payload: id });
      toast.success(`ShedLabor is deleted`);
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
    }
  };

  // update
  const updateShedLabor = async (labor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/shedlabor/update/${labor._id}`, labor, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_SHEDLABOR, payload: res.data });
      navigate(-1);
      toast.success(`Shed Labor is updated`);
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
      toast.error(`Shed Labor ${err}`);
    }
  };

  const calcLaborBase = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/shedlabor/buildbase/calc`, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_SHEDLABOR, payload: res.data });
      navigate(-1);
      toast.success(`Shed Labor is updated`);
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
      toast.error(`Shed Labor ${err}`);
    }
  };

  const updateShopShedLabor = async (labor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/shedlabor/shop/update/${labor._id}`, labor, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_SHEDLABOR, payload: res.data });
      navigate(-1);
      toast.success(`Shed Labor is updated`);
    } catch (err) {
      dispatch({ type: SHEDLABOR_ERROR });
      toast.error(`Shed Labor ${err}`);
    }
  };

  // clear
  const clearShedsLabor = () => {
    dispatch({ type: CLEAR_SHEDLABORS });
  };

  // set current
  const setCurrentShedLabor = (labor) => {
    dispatch({ type: SET_CURRENT_SHEDLABOR, payload: labor });
  };

  // clear current
  const clearCurrentShopShedLabor = () => {
    dispatch({ type: CLEAR_CURRENT_SHEDLABOR });
  };

  return (
    <ShedLaborContext.Provider
      value={{
        shedLabors: state.shedLabors,
        allShedLabors: state.allShedLabors,
        currentShedLabor: state.currentShedLabor,
        error: state.error,
        getShedLaborsByModel,
        getAllShedLabors,
        addShedLabor,
        deleteShedLabor,
        setCurrentShedLabor,
        clearCurrentShopShedLabor,
        updateShedLabor,
        clearShedsLabor,
        importShedLabor,
        getShopShedLaborsByModel,
        updateShopShedLabor,
        updateShopLabors,
        calcLaborBase,
        // getAllShedsLabor,
        // updateAllSheds,
      }}
    >
      {props.children}
    </ShedLaborContext.Provider>
  );
};

export default ShedLaborState;
