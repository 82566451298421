import React, { useState, useEffect, useContext, Fragment } from "react";
import OrderContext from "../../../../context/order/orderContext";
import ShopContext from "../../../../context/shop/shopContext";
// import OrderFilter from '../../../../context/order/OrderFilter';
import GlobalOrderSearch from "../../../../context/order/GlobalOrderSearch";
import { Checkbox, Table, Tag } from "antd";
import Select from "react-select";
import PopConfirm from "./comments_alerts/PopConfirm";
import DetailModal from "./DetailModal";

const OnHoldOrders = () => {
  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;

  const orderContext = useContext(OrderContext);
  const {
    getOrderById,
    // deleteOrder,
    getOrders,
    orders,
    updateOrder,
    // filteredOrder,
    clearOrders,
    setLoadingOrders,
    loadingOrders,
    getOrderCount,
    orderCount,
    clearOrderCount,
    pagination,
    setPagination,
    clearCurrentOrder,
    setCurrentOrder,
    searchedOrder,
  } = orderContext;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    clearCurrentOrder();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    clearCurrentOrder();
  };
  const handleShowModal = (record) => {
    setCurrentOrder(record);
    showModal(true);
  };

  const options =
    currentShop &&
    currentShop.shopUsers &&
    currentShop.shopUsers
      .filter((user) => user.role === "Builder")
      .map((option) => {
        return { label: `${option.name}`, value: option };
      });

  const setEditOrder = (order) => {
    // setCurrentOrder(order);
    getOrderById(order._id);
  };

  useEffect(() => {
    getShop();
    getOrders("On Hold");
    return () => {
      clearOrders();
    };
  }, []);

  const [filteredOrders, setFilteredOrders] = useState([]);
  useEffect(() => {
    const inQueueOrders = orders && orders.filter((order) => order.status === "On Hold");
    setFilteredOrders(inQueueOrders);
  }, [orders]);

  // const handleRemoveOrder = (order) => {
  //   Modal.confirm({
  //     title: 'Are you sure you want to delete this Order?',
  //     okText: 'Yes',
  //     okType: 'danger',
  //     onOk: () => {
  //       deleteOrder(order);
  //     },
  //   });
  // };

  const onMetalOrderChange = (e, o) => {
    let checked = e.target.checked;
    let newRecord = { ...o, metalOrdered: checked };
    updateOrder(newRecord);
  };

  const handleBuilderChange = (o, e) => {
    let newRecord = { ...o, builder: e };
    updateOrder(newRecord);
  };

  const columns = [
    {
      title: `Serial Number`,
      render: (text, record, rowIndex) => (
        <button className="btn btn-link" type="button" onClick={() => handleShowModal(record)}>
          {record.serialNumber}
        </button>
      ),
    },
    {
      title: "Ordered Date",
      render: (text, record) => <span>{new Date(record.dateOrdered).toLocaleDateString()}</span>,
    },
    {
      title: `Dealer`,
      render: (text, record, rowIndex) => <Fragment>{record.dealer && record.dealer.name}</Fragment>,
    },
    {
      title: `Customer`,
      render: (text, record) => <span>{record.customer}</span>,
    },

    {
      title: "Metal Ordered",
      width: "7%",
      align: "center",
      render: (text, record) => (
        <Fragment>
          <Checkbox checked={record.metalOrdered} onChange={(e) => onMetalOrderChange(e, record)} />
        </Fragment>
      ),
    },
    {
      title: "Builder",
      render: (text, record) => (
        <Fragment>
          <Select
            className="basic-single"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            value={(options && options.find((option) => option.value._id === record.builder?._id)) || null}
            name="builder"
            options={options}
            onChange={(e) => handleBuilderChange(record, e)}
          />
        </Fragment>
      ),
    },
    {
      title: "Status",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          <Tag className="" color="volcano">
            {record && record.status}
          </Tag>
        </Fragment>
      ),
    },
    {
      title: "Actions",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <Fragment>
          <button className="btn btn-xs btn-outline-secondary mb-2" type="button" onClick={(e) => setEditOrder(record, e)}>
            View
          </button>
          <br />
          <PopConfirm record={record} />
        </Fragment>
      ),
    },
  ];

  // const handleTableChange = (newPagination, filters, sorter) => {
  //   setLoadingOrders(true);
  //   getOrdersByCategory("On Hold", newPagination);
  //   setPagination(newPagination);
  // };

  // const handleRowClick = (record, rowIndex) => {
  //   setShowClickedRow(rowIndex);
  // };

  return (
    <div className="row">
      <div className="row mb-2">
        <div className="col-md-4">
          <h4>{filteredOrders && filteredOrders.length + " Orders On Hold"}</h4>
        </div>
        <div className="col-md-8">
          <GlobalOrderSearch />
        </div>
      </div>

      {/* <OrderFilter /> */}

      <DetailModal isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />

      <Table
        rowClassName={() => "hover-row"}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onMouseEnter: (event) => {
        //       handleRowClick(record, rowIndex);
        //     }, // click row
        //     onMouseLeave: (event) => {
        //       setShowClickedRow();
        //     },
        //   };
        // }}
        pagination={false}
        // loading={!loadingOrders && filteredOrders ? false : true}
        columns={columns}
        // dataSource={filteredOrder && filteredOrder !== null ? filteredOrder : filteredOrders && filteredOrders}
        loading={(!loadingOrders && filteredOrders) || (!loadingOrders && searchedOrder) ? false : true}
        dataSource={(searchedOrder && searchedOrder) || (filteredOrders && filteredOrders)}
        rowKey="_id"
        // onChange={handleTableChange}
        scroll={{ y: "60vh" }}
      />
    </div>
  );
};

export default OnHoldOrders;
