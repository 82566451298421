import React, { Fragment, useEffect, useState, useContext } from "react";
import ShedContext from "../../../../../../context/shed/shedContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import AuthContext from "../../../../../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";
import { BlobProvider } from "@react-pdf/renderer";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

import "antd/dist/antd.css";
import { Table, Switch } from "antd";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    padding: 10,
  },
  section: {
    marginTop: 2,
    padding: 2,
  },
  shedName: {
    fontSize: 14,
  },
  componentSection: {
    marginLeft: 15,
    // i want space on the bottom
    // marginBottom: 10,
    // fontSize: 14,
  },
  itemSection: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemName: {
    marginLeft: 5,
    fontSize: 10,
    width: "50%",
    borderBottomWidth: 1,
    borderBottomColor: "#D3D3D3",
  },
  componentName: {
    marginLeft: 5,
    marginBottom: 5,
    fontSize: 12,
    width: "50%",
    borderBottomWidth: 1,
    borderBottomColor: "#D3D3D3",
  },
  itemQuantity: {
    fontSize: 10,
    width: "50%",
    textAlign: "left",
    borderBottomWidth: 1,
    borderBottomColor: "#D3D3D3",
  },
  componentQuantity: {
    fontSize: 12,
    marginBottom: 5,
    width: "50%",
    textAlign: "left",
    borderBottomWidth: 1,
    borderBottomColor: "#D3D3D3",
  },
});

const ShedPDFDocument = ({ sheds, currentShedModel }) => (
  <Document>
    {sheds?.map((shed, shedIndex) => (
      <Page key={shedIndex} size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.shedName}>
            Shed: {currentShedModel.name} {shed.width}x{shed.length}
          </Text>
        </View>
        {shed.components?.map((component, index) => (
          <View key={index} style={[styles.section, styles.componentSection]}>
            <View style={styles.itemSection}>
              <Text style={styles.componentName}>
                Component {index + 1}: {component.name}
              </Text>
              <Text style={styles.componentQuantity}>Quantity: {component.quantity}</Text>
            </View>
            {/* {component.component.standardItems.map((item, itemIndex) => (
              <View key={itemIndex} style={styles.itemSection}>
                <Text style={styles.itemName}>- {item.item.name}</Text>
                <Text style={styles.itemQuantity}>{item.quantity}</Text>
              </View>
            ))} */}
          </View>
        ))}
        {shed.standardAddOns?.map((standardAddOn, index) => (
          <View key={index} style={[styles.section, styles.componentSection]}>
            <View style={styles.itemSection}>
              <Text style={styles.componentName}>
                Standard AddOn {index + 1}: {standardAddOn.name}
              </Text>
              <Text style={styles.componentQuantity}>Quantity: {standardAddOn.quantity}</Text>
            </View>
            {/* {standardAddOn.standardAddOn.standardItems.map((item, itemIndex) => (
              <View key={itemIndex} style={styles.itemSection}>
                <Text style={styles.itemName}>- {item.item.name}</Text>
                <Text style={styles.itemQuantity}>{item.quantity}</Text>
              </View>
            ))} */}
          </View>
        ))}
      </Page>
    ))}
  </Document>
);

const AllSheds = () => {
  const navigate = useNavigate();

  const shedContext = useContext(ShedContext);
  const { getSheds, sheds, setCurrentShed, importSheds, clearSheds, deleteShed, getShed } = shedContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const shedModelContext = useContext(ShedModelContext);
  const { currentShedModel } = shedModelContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentShedCategory) {
      navigate("company/shed/category/all");
      return;
    }

    const fetchSheds = async () => {
      setLoading(true);
      try {
        await getSheds(currentShedCategory._id, currentShop._id, currentShedModel._id);
      } catch (error) {
        console.error("Error fetching sheds:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSheds();

    return () => {
      clearSheds();
    };
  }, []);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  sheds &&
    sheds.map((shed) => {
      let newItem = {};
      // i need to include the category, model, baseStyle
      newItem.category = shed.category;
      newItem.model = shed.model;
      newItem.baseStyle = shed.baseStyle;
      newItem.shedLabor = shed.shedLabor?._id;
      newItem.haulBase = shed.haulBase?._id;

      newItem.Id = shed._id;
      newItem.Width = shed.width;
      newItem.Length = shed.length;
      newItem.Retail_Price = shed.retailPrice;

      // now i need to loop through the components and add them to the object along with the quantity, the labels will be component_[index] quantity_[index],

      shed.components.forEach((component, index) => {
        // console.log("This is component", component);
        newItem[`name_${index}`] = component.name;
        newItem[`component_${index}`] = component._id;
        newItem[`quantity_${index}`] = component.quantity;
      });

      // now i need to do the same thing for the standardAddOns

      shed.standardAddOns?.forEach((addOn, index) => {
        newItem[`addOn_${index}`] = addOn._id;
        newItem[`addOnQuantity_${index}`] = addOn.quantity;
      });

      // newItem.Item_Id = item.item._id;
      itemsForExport.push(newItem);
    });

  const exportFile = () => {
    const ws = utils.json_to_sheet(itemsForExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, "Sheds_BOM.xlsx");
  };

  const importFile = () => {
    importSheds(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const [showAllColumns, setShowAllColumns] = useState(false);

  const columns = [
    {
      title: "Width",
      render: (record) => <Fragment>{record.width}</Fragment>,
    },

    {
      title: "Length",
      render: (record) => <Fragment>{record.length}</Fragment>,
    },

    {
      title: "Retail Price",
      render: (record) => (
        <Fragment>
          {record &&
            record.retailPrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    // Conditionally visible columns
    showAllColumns && {
      title: "Shop Retail Percent",
      render: (record) => <Fragment>{record.shopsRetailPercent}</Fragment>,
    },
    showAllColumns && {
      title: "Shop Retail Price",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopsRetailPrice?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    showAllColumns && {
      title: "Overhead",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopOverhead?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    showAllColumns && {
      title: "Box Material Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.standardBoxMaterialCost?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    showAllColumns && {
      title: "Labor Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.standardLabor?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    showAllColumns && {
      title: "Box Total Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.standardBoxTotalCost?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Box GP",
      render: (record) => (
        <Fragment>
          {record &&
            record.standardBoxGrossProfit?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },

    {
      title: "Box GP %",
      render: (record) => <Fragment>{record && record.standardBoxGrossProfitPercent?.toLocaleString("en-US")}%</Fragment>,
    },

    {
      title: "Addon GP",
      render: (record) => (
        <Fragment>
          {record &&
            record.standardAddonsTotalGrossProfit?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },

    {
      title: "Addon GP %",
      render: (record) => <Fragment>{record && record.standardAddonsTotalGrossProfitPercent?.toLocaleString("en-US")}%</Fragment>,
    },

    {
      title: "Total GP",
      render: (record) => (
        <Fragment>
          {record &&
            record.totalStandardGrossProfit?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },

    {
      title: "Total GP %",
      render: (record) => <Fragment>{record && record.totalStandardGrossProfitPercent?.toLocaleString("en-US")}%</Fragment>,
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShed(record)}>
            View
          </button>
          {/* <button className="btn btn-xs btn-outline-danger" type="button" onClick={() => handledeleteShed(record)}>
            Delete
          </button> */}
        </span>
      ),
    },
  ].filter(Boolean); // Filter out the falsy values (hidden columns)

  const handledeleteShed = (record) => {
    deleteShed(record._id);
  };

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddShed = () => {
    navigate("/company/shed/form");
  };
  const handleViewShed = (shed) => {
    setLoading(true);
    getShed(shed._id)
      .then(() => {
        setLoading(false);
        navigate("/company/shed/form");
      })
      .catch((error) => {
        console.error("Error fetching shed:", error);
        setLoading(false);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-success float-start"}
                  disabled={user.viewOnly}
                  // className="mb-2 btn btn-outline-success float-start"
                  onClick={() => handleAddShed()}
                >
                  Add Shed
                </button>
                <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
                  Back
                </button>
                <h4 className="text-center">{currentShedModel && currentShedModel.name}</h4>

                {sheds && (
                  <button
                    className={user.viewOnly ? "viewOnly" : "mb-2 me-2 btn btn-outline-info float-end"}
                    disabled={user.viewOnly}
                    // className="mb-2 me-2 btn btn-outline-info float-end"
                    onClick={() => exportFile()}
                  >
                    Download BOM
                  </button>
                )}
                {/* {sheds && (
                  <BlobProvider document={<ShedPDFDocument sheds={sheds} currentShedModel={currentShedModel} />}>
                    {({ blob, url, loading, error }) => {
                      if (error) {
                        return <div>An error occurred while generating the PDF</div>;
                      }

                      return loading ? (
                        <button className="mb-2 me-2 btn btn-outline-info float-end" disabled>
                          Generating PDF...
                        </button>
                      ) : (
                        <a href={url} download={`${currentShedCategory.name}_${currentShedModel.name}`}>
                          <button
                            className={user.viewOnly ? "viewOnly" : "mb-2 me-2 btn btn-outline-info float-end"}
                            disabled={user.viewOnly}
                            // className="mb-2 me-2 btn btn-outline-info float-end"
                          >
                            Download PDFS
                          </button>
                        </a>
                      );
                    }}
                  </BlobProvider>
                )} */}
              </div>
            </div>

            <div>
              <label>
                Show All Columns
                <Switch checked={showAllColumns} onChange={() => setShowAllColumns(!showAllColumns)} style={{ marginLeft: 8 }} />
              </label>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              loading={loading}
              pagination={{ pageSize: 60 }}
              columns={columns}
              dataSource={sheds}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
          </div>
          <div className="col-md-4">
            <button
              className={user.viewOnly ? "viewOnly" : "mb-2 me-2 btn btn-outline-info"}
              disabled={user.viewOnly}
              // className="mb-2 me-2 btn btn-outline-info"
              onClick={() => importFile()}
            >
              Upload Sheds
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllSheds;
