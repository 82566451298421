import React, { Fragment, useEffect, useContext } from "react";
import MaterialOrderContext from "../../../../../context/materialOrder/materialOrderContext";
import ItemContext from "../../../../../context/item/itemContext";
import { Checkbox, Table } from "antd";

const RecieveItemIntoInventory = ({ theItem, currentShop }) => {
  const materialOrderContext = useContext(MaterialOrderContext);
  const itemContext = useContext(ItemContext);
  const { getRecievedMaterialOrders, materialOrders, updateMaterialOrder, clearMaterialOrders } = materialOrderContext;
  const { updateItem, setCurrentItem } = itemContext;

  useEffect(() => {
    getRecievedMaterialOrders(theItem);
  }, [theItem]);
  // console.log("This is theItem", theItem);
  // console.log("This is materialOrders", materialOrders);
  const filteredMaterialOrders =
    theItem &&
    materialOrders &&
    materialOrders
      .map((order) => {
        return {
          ...order,
          items: order?.items?.filter((item) => item.item._id === theItem?._id && !item.inInventory),
        };
      })
      // Filter out orders with no relevant items
      .filter((order) => order.items.length > 0);
  const onItemInventoryChange = (text, record, index) => {
    // Find the materialOrder that contains the item that has been updated
    const materialOrder = materialOrders.find((order) => order.items.some((item) => item._id === record._id));
    // If materialOrder is found
    if (materialOrder) {
      // Update the items in the materialOrder
      const updatedItems = materialOrder.items.map((item) =>
        item._id === record._id && !item.inInventory ? { ...item, inInventory: !item.inInventory } : item
      );
      // Update the materialOrder with the updated items
      const updatedMaterialOrder = { ...materialOrder, items: updatedItems };
      // Update this materialOrder in the context
      updateMaterialOrder(updatedMaterialOrder);
      // Update the theItem
      theItem = {
        ...theItem,
        shopValues: {
          ...theItem.shopValues,
          quantityInCurrent: +theItem.shopValues.quantityInCurrent + +record.quantity,
          quantityInRecieved: +theItem.shopValues.quantityInRecieved - +record.quantity,
          freight: record.newFreight,
          subTotal: record.newPrice,
          total: record.newPrice + record.newFreight,
        },
      };
      // If the item is not in the inventory, update and set the theItem
      if (record.inInventory === false) {
        updateItem(theItem, currentShop._id);
        setCurrentItem(theItem);
      }
    }
  };

  function NestedTable() {
    const expandedRowRender = (record, index, indent, expanded) => {
      const columns = [
        {
          width: "20%",
          render: (record) => <Fragment></Fragment>,
        },
        {
          title: "Item",
          render: (record) => <Fragment>{record.item.name}</Fragment>,
        },
        {
          title: "Quantity",
          width: "10%",
          render: (record) => <Fragment>{record.quantity}</Fragment>,
        },
        {
          title: "Freight",
          width: "10%",
          render: (record) => <Fragment>{record && record.newFreight?.toFixed(2)}</Fragment>,
        },
        {
          title: "Price",
          width: "10%",
          render: (record) => <Fragment>{record.newPrice}</Fragment>,
        },
        {
          title: "Push to Current Inventory",
          width: "10%",
          render: (text, record, index) => (
            <Fragment>
              {theItem._id === record.item._id && (
                <Checkbox checked={record.inInventory} onChange={(e) => onItemInventoryChange(text, record, index)} />
              )}
            </Fragment>
          ),
        },
      ];
      const data = record.items;
      return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const columns = [
      {
        title: "Purchase Order#",
        render: (record) => (
          <Fragment>
            <b>{record.purchaseOrderNumber}</b>
          </Fragment>
        ),
      },
    ];

    return (
      <Table
        className="components-table-demo-nested"
        columns={columns}
        expandable={{ expandedRowRender, columnWidth: "10%" }}
        dataSource={filteredMaterialOrders}
        defaultExpandAllRows={true}
      />
    );
  }

  return (
    <div className="container mt-40 mb-40 overflow-x-auto tableContainer">
      <h4 className="text-center">Received Items</h4>
      <NestedTable />
    </div>
  );
};

export default RecieveItemIntoInventory;
