import {
  ADD_ADDON,
  DELETE_ADDON,
  CLEAR_ADDONS,
  SET_CURRENT_ADDON,
  CLEAR_CURRENT_ADDON,
  UPDATE_ADDON,
  FILTER_ADDONS,
  CLEAR_FILTER_ADDON,
  ADDON_ERROR,
  GET_ADDONS,
  GET_ALL_ADDONS,
  // GET_ALL_ADDONS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ADDONS:
      return {
        ...state,
        addOns: action.payload,
        loadingAddOn: false,
      };
    case GET_ALL_ADDONS:
      return {
        ...state,
        allAddOns: action.payload,
        loadingAddOn: false,
      };
    // case GET_ALL_ADDONS:
    //   return {
    //     ...state,
    //     allAddOns: action.payload,
    //     loadingAddOn: false,
    //   };
    case ADD_ADDON:
      return {
        ...state,
        addOn: action.payload,
        loading: false,
      };
    case UPDATE_ADDON:
      const updatedAddOns = state.addOns?.map((addOn) => (addOn._id === action.payload._id ? action.payload : addOn));
      return {
        ...state,
        addOns: updatedAddOns,
        loading: false,
      };
    case DELETE_ADDON:
      return {
        ...state,
        addOns: state.addOns.filter((addon) => addon._id !== action.payload),
        loading: false,
      };
    case CLEAR_ADDONS:
      return {
        ...state,
        addOns: null,
        filteredAddOn: null,
        error: null,
        currentAddOn: null,
      };
    case SET_CURRENT_ADDON:
      return {
        ...state,
        currentAddOn: action.payload,
      };
    case CLEAR_CURRENT_ADDON:
      return {
        ...state,
        currentAddOn: null,
      };
    case FILTER_ADDONS:
      return {
        ...state,
        filteredAddOn: state.AddOns.filter((addOn) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return addOn.customer.value.name.match(regex);
        }),
      };
    case CLEAR_FILTER_ADDON:
      return {
        ...state,
        filteredAddOn: null,
      };
    case ADDON_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
