import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import InvoiceContext from "../../../../../context/invoice/invoiceContext";
import PaymentContext from "../../../../../context/payment/paymentContext";
import ShopContext from "../../../../../context/shop/shopContext";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Input, DatePicker } from "antd";
import moment from "moment";
import NewPaymentInvoiceArray from "./NewPaymentInvoiceArray";
import { toast } from "react-toastify";

const { TextArea } = Input;

const dateFormat = "MM/DD/YYYY";
const defaultValues = {
  paymentNumber: 0,
  shopsPaymentAdjustment: 0,
  paymentDate: moment(),
  paymentTo: {},
  invoices: [],
};

const NewPaymentForm = ({ setModalVisible, selectedInvoices, setSelectedInvoices }) => {
  const navigate = useNavigate();

  const shopContext = useContext(ShopContext);
  const { getShops, shops, currentShop, setCurrentShop } = shopContext;

  const invoiceContext = useContext(InvoiceContext);
  const { shop, statusChange } = invoiceContext;

  const paymentContext = useContext(PaymentContext);
  const { addPayment, setLoadingPayments } = paymentContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch } = useForm({
    defaultValues,
  });

  useEffect(() => {
    getShops();
    // console.log('runnin the set invoice form');
    // const serialArr = currentOrder && currentOrder.serialNumber.split('-');
    // const theInvoiceNum = serialArr && serialArr[serialArr.length - 1];
    // currentOrder && setValue('invoiceNumber', theInvoiceNum);
    // currentOrder && setValue('order', currentOrder);
    // currentInvoice && reset(currentInvoice);
    // console.log('this is the selected invoices in the new payment form', selectedInvoices);
    selectedInvoices && setValue("invoices", selectedInvoices);

    const invoiceTotal =
      selectedInvoices && selectedInvoices.reduce((acc, curr) => acc + parseFloat(curr.order.grandTotal.shop || 0), 0);

    // const selectedInvoiceLength = selectedInvoices && selectedInvoices.length;

    // const overheadTotal = selectedInvoices && selectedInvoices.reduce((acc, curr) => acc + parseFloat(curr.order.shopOverhead || 0), 0);
    // const laborTotal = selectedInvoices && selectedInvoices.reduce((acc, curr) => acc + parseFloat(curr.order.shopLabor || 0), 0);
    // const profitDollarsTotal = selectedInvoices && selectedInvoices.reduce((acc, curr) => acc + parseFloat(curr.order.shopProfitDollars || 0), 0);

    // const profitPercentSummed = selectedInvoices && selectedInvoices.reduce((acc, curr) => acc + parseFloat(curr.order.shopProfitPercent || 0), 0);
    // const averageProfitPercent = profitPercentSummed / selectedInvoiceLength;

    // const materialTotal = selectedInvoices && selectedInvoices.reduce((acc, curr) => acc + parseFloat(curr.order.shopTotalMaterialCost || 0), 0);
    // const addOnTotal = selectedInvoices && selectedInvoices.reduce((acc, curr) => acc + parseFloat(curr.order.addOnsCostTotalShop || 0), 0);
    // const customAddOnTotal =
    //   selectedInvoices && selectedInvoices.reduce((acc, curr) => acc + parseFloat(curr.order.customAddOnsCostTotalShop || 0), 0);

    // selectedInvoices && materialTotal && setValue('shopsPaymentMaterialTotal', materialTotal + addOnTotal + customAddOnTotal);
    // selectedInvoices && overheadTotal && setValue('shopsPaymentOverheadTotal', overheadTotal);
    // selectedInvoices && laborTotal && setValue('shopsPaymentLaborTotal', laborTotal);
    // selectedInvoices && profitDollarsTotal && setValue('shopsPaymentProfitDollarsTotal', profitDollarsTotal);
    // selectedInvoices && averageProfitPercent && setValue('shopsPaymentAverageProfitPercent', averageProfitPercent.toFixed(2));

    selectedInvoices && invoiceTotal && setValue("shopsPaymentSubTotal", invoiceTotal);
    selectedInvoices && invoiceTotal && setValue("shopsPaymentTotal", invoiceTotal);
    currentShop != null && setValue("paymentTo", { label: currentShop.label, value: currentShop });

    // shop && setValue('paymentTo', shop);
  }, [selectedInvoices]);

  const shopNames =
    shops &&
    shops.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const onSubmit = (data) => {
    setLoadingPayments(true);

    handleStatusChange(data);
    addPayment(data);
    setSelectedInvoices([]);
    setModalVisible(false);
  };

  const handleStatusChange = (data) => {
    data.invoices.forEach((invoice) => {
      statusChange(invoice);
    });
  };

  const handleViewOrder = () => {
    navigate("/order/view");
  };

  const setShopChange = (e) => {
    setValue("paymentTo", e);
    setCurrentShop(e.value);
  };

  const setAdjustment = (value) => {
    let invoices = getValues("invoices");
    let total = invoices.reduce((acc, curr) => acc + parseFloat(curr.order.grandTotal.shop || 0), 0);
    let newTotal = +total + +value;
    setValue("shopsPaymentAdjustment", value);
    setValue("shopsPaymentTotal", +newTotal);
  };

  const setMemo = (memo) => {
    setValue("memo", memo);
  };

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
          <div className="row mt-4 mb-4">
            <h2 className="text-center">New Payment Form</h2>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">PAYMENT #</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="paymentNumber" {...register("paymentNumber", { required: true })} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">DATE</label>
                      <div className="col-sm-9">
                        <Controller
                          render={({ field, name }) => (
                            <DatePicker format={dateFormat} className="form-control" name={name} {...field} />
                          )}
                          control={control}
                          name={`paymentDate`}
                          defaultValue={moment()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">PAYMENT TO:</label>
                      <div className="col-sm-9">
                        <Controller
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              options={shopNames}
                              value={(shopNames && shopNames.find((option) => option.value._id === currentShop?._id)) || null}
                              // menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              onChange={(e) => setShopChange(e)}
                            />
                          )}
                          rules={{ required: true }}
                          name={`paymentTo`}
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <NewPaymentInvoiceArray
                  {...{
                    control,
                    watch,
                    register,
                    defaultValues,
                    getValues,
                    setValue,
                    errors,
                    getValues,
                  }}
                />

                <div className="row mt-2">
                  <div className="col-md-6"></div>
                  <div className="col-sm-3 col-form-label">
                    <label className="float-end">Sub Total</label>
                  </div>
                  <div className="col-sm-3">
                    <Controller
                      render={({ field, name }) => (
                        <NumberFormat
                          readOnly
                          customInput={Input}
                          className="form-group form-control"
                          style={{ fontSize: 16, padding: 6 }}
                          {...field}
                          name={name}
                          thousandSeparator={true}
                          prefix={"$ "}
                          displayType="input"
                          decimalScale={2}
                          fixedDecimalScale="true"
                        />
                      )}
                      name={`shopsPaymentSubTotal`}
                      control={control}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-6"></div>
                  <div className="col-sm-3 col-form-label">
                    <label className="float-end">Adjustment</label>
                  </div>
                  <div className="col-sm-3">
                    <Controller
                      render={({ field, name }) => (
                        <input
                          className="form-control"
                          type="number"
                          step=".01"
                          name={name}
                          {...field}
                          onChange={(e) => {
                            const adjust = e.target.value;
                            setAdjustment(adjust);
                          }}
                        />
                      )}
                      control={control}
                      name={`shopsPaymentAdjustment`}
                    />
                  </div>
                </div>

                <div className="row mb-2 mt-2">
                  <div className="col-md-6"></div>
                  <div className="col-sm-3 col-form-label">
                    <label className="float-end">Total</label>
                  </div>
                  <div className="col-sm-3">
                    <Controller
                      render={({ field, name }) => (
                        <NumberFormat
                          readOnly
                          customInput={Input}
                          className="form-group form-control"
                          style={{ fontSize: 16, padding: 6 }}
                          {...field}
                          name={name}
                          thousandSeparator={true}
                          prefix={"$ "}
                          displayType="input"
                          decimalScale={2}
                          fixedDecimalScale="true"
                        />
                      )}
                      name={`shopsPaymentTotal`}
                      control={control}
                    />
                  </div>
                </div>

                <div className="row mb-4 mt-4">
                  <div className="col-md-8">
                    <h4>Memo </h4>
                    <Controller
                      render={({ field, name }) => (
                        <TextArea
                          rows={4}
                          {...field}
                          style={{ backgroundColor: "white" }}
                          name={name}
                          onChange={(e) => {
                            const value = e.target.value;
                            // console.log("this is the value", value);
                            setMemo(value);
                          }}
                          placeholder="..."
                        />
                        // <input
                        //   customInput={Textarea}
                        //   // className="form-group form-control"
                        //   // style={{ fontSize: 16, padding: 6 }}

                        //   // displayType="input"
                        // />
                      )}
                      name={`memo`}
                      control={control}
                    />
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-2">
                    <div className="buttons float-end">
                      <button className="btn btn-outline-primary " type="submit">
                        Create Payment
                        {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewPaymentForm;
