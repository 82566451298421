import React, { useEffect, useContext } from "react";
import SettingsForm from "./SettingsForm";
import ShopContext from "../../../../context/shop/shopContext";

const ShopSettings = () => {
  const shopContext = useContext(ShopContext);
  const { getShop } = shopContext;

  useEffect(() => {
    getShop();
  }, []);

  return <SettingsForm />;
};

export default ShopSettings;
