import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import CompanyContext from "./companyContext";
import companyReducer from "./companyReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_COMPANY,
  DELETE_COMPANY,
  CLEAR_COMPANYS,
  SET_CURRENT_COMPANY,
  CLEAR_CURRENT_COMPANY,
  UPDATE_COMPANY,
  SET_COMPANYS,
  COMPANY_ERROR,
  GET_COMPANYS,
  FILTER_COMPANYS,
  CLEAR_FILTER_COMPANY,
} from "../types";

const CompanyState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    company: null,
    currentCompany: null,
    error: null,
    filteredCompany: null,
  };

  const [state, dispatch] = useReducer(companyReducer, initialState);

  const getCompany = async () => {
    try {
      const res = await axios.get(`/api/company`);
      dispatch({ type: GET_COMPANYS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMPANY_ERROR });
      console.log("this is the err", err);
      toast.error(`Company ${err}`);
    }
  };

  // add
  const addCompany = async (company) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/company", { company }, config);
      dispatch({ type: ADD_COMPANY, payload: res.data });
      navigate(-1);
    } catch (err) {
      dispatch({ type: COMPANY_ERROR });
    }
  };

  // delete
  const deleteCompany = async (id) => {
    try {
      const res = await axios.delete(`/api/company/delete/${id}`);
      dispatch({ type: DELETE_COMPANY, payload: id });
      toast.success(`Company is deleted`);
    } catch (err) {
      dispatch({ type: COMPANY_ERROR });
    }
  };

  // update
  const updateCompany = async (id, company) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/company/update/${id}`, company, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_COMPANY, payload: res.data });
      toast.success(`Company is updated`);
    } catch (err) {
      dispatch({ type: COMPANY_ERROR });
      toast.error(`Company ${err}`);
    }
  };

  const updateCompanyMetal = async (id, company) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/company/metal/update/${id}`, company, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_COMPANY, payload: res.data });
      toast.success(`Metal Items are updated`);
    } catch (err) {
      dispatch({ type: COMPANY_ERROR });
      toast.error(`Company ${err}`);
    }
  };

  // clear
  const clearCompanys = () => {
    dispatch({ type: CLEAR_COMPANYS });
  };

  // set current
  const setCurrentCompany = (company) => {
    dispatch({ type: SET_CURRENT_COMPANY, payload: company });
  };

  const setCompanys = (labors) => {
    dispatch({ type: SET_COMPANYS, payload: labors });
  };

  // clear current
  const clearCurrentCompany = () => {
    dispatch({ type: CLEAR_CURRENT_COMPANY });
  };

  const filterCompanys = (text) => {
    dispatch({ type: FILTER_COMPANYS, payload: text });
  };

  const clearFilterCompany = () => {
    dispatch({ type: CLEAR_FILTER_COMPANY });
  };

  return (
    <CompanyContext.Provider
      value={{
        company: state.company,
        currentCompany: state.currentCompany,
        error: state.error,
        filteredCompany: state.filteredCompany,
        getCompany,
        setCompanys,
        addCompany,
        deleteCompany,
        setCurrentCompany,
        clearCurrentCompany,
        updateCompany,
        clearCompanys,
        filterCompanys,
        clearFilterCompany,
        updateCompanyMetal,
      }}
    >
      {props.children}
    </CompanyContext.Provider>
  );
};

export default CompanyState;
