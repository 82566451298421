import React, { useEffect, useContext, useState, useCallback } from "react";
import { Row, Col, Card, Statistic, Typography, Spin } from "antd";
import { DollarOutlined } from "@ant-design/icons";
import Select from "react-select";
import OrderContext from "../../../context/order/orderContext";
import InvoiceContext from "../../../context/invoice/invoiceContext";
import ShopContext from "../../../context/shop/shopContext";
import PaymentContext from "../../../context/payment/paymentContext";
import Alerts from "../../layout/Alerts";
import OrderChartOne from "./charts/OrderChartOne";
import InvoicesChartOne from "./charts/InvoicesChartOne";
import PaymentLineChart from "./charts/PaymentLineChart";

const { Title } = Typography;

const cardStyle = {
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  border: "1px solid #e8e8e8",
  borderRadius: "8px",
  transition: "all 0.3s",
};

const CompanyHome = () => {
  const orderContext = useContext(OrderContext);
  const { chartOrders, getOrdersForChart } = orderContext;

  const invoiceContext = useContext(InvoiceContext);
  const { chartInvoices, getInvoiceForChart } = invoiceContext;

  const shopContext = useContext(ShopContext);
  const { getShops, shops } = shopContext;

  const paymentContext = useContext(PaymentContext);
  const { getPayments, payments } = paymentContext;

  const [shopPaymentData, setShopPaymentData] = useState([]);
  const [companyPaymentData, setCompanyPaymentData] = useState([]);
  const [selectedShop, setSelectedShop] = useState({ value: "all", label: "All Shops" });
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [orderTotals, setOrderTotals] = useState({});
  const [invoiceTotals, setInvoiceTotals] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([getInvoiceForChart(), getOrdersForChart(), getShops(), getPayments()]);
        setIsDataReady(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const shopOptions = [
    { value: "all", label: "All Shops" },
    ...(shops?.map((shop) => ({
      value: shop._id,
      label: shop.name,
    })) || []),
  ];

  useEffect(() => {
    if (payments) {
      const processedShopData = processPaymentData(payments, "shop");
      const processedCompanyData = processPaymentData(payments, "company");
      setShopPaymentData(processedShopData);
      setCompanyPaymentData(processedCompanyData);
    }
  }, [payments, selectedShop]);

  const processPaymentData = (payments, type) => {
    const now = new Date();
    const threeMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 2, 1);

    return payments
      .filter((payment) => new Date(payment.paymentDate) >= threeMonthsAgo)
      .filter((payment) => selectedShop.value === "all" || payment.shop._id === selectedShop.value)
      .map((payment) => ({
        date: new Date(payment.paymentDate).toISOString().split("T")[0],
        totalAmount: payment.paymentTotal[type],
        grossProfitPercentage: payment.averageProfitPercent[type === "shop" ? "standard" : "company"] * 100,
        invoiceCount: payment.invoices.length,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const filterDataByShop = useCallback(
    (data) => {
      // console.log("Filtering data:", data);
      // console.log("Selected shop:", selectedShop.value);
      if (selectedShop.value === "all") return data;
      return data.filter((item) => {
        const shopId = item.shop?._id || item.shop || item.shopId;
        // console.log("Item:", item);
        // console.log("Item shop ID:", shopId);
        return shopId === selectedShop.value;
      });
    },
    [selectedShop.value]
  );

  useEffect(() => {
    if (isDataReady) {
      const filterData = async () => {
        setIsLoading(true);
        console.log("Filtering data...");
        const newFilteredOrders = filterDataByShop(chartOrders || []);
        const newFilteredInvoices = filterDataByShop(chartInvoices || []);
        setFilteredOrders(newFilteredOrders);
        setFilteredInvoices(newFilteredInvoices);
        // console.log("New filtered orders:", newFilteredOrders);
        // console.log("New filtered invoices:", newFilteredInvoices);
        // console.log("done");
        await new Promise((resolve) => setTimeout(resolve, 200)); // Small delay for smoother transition
        setIsLoading(false);
      };
      filterData();
    }
  }, [selectedShop.value, chartOrders, chartInvoices, filterDataByShop, isDataReady]);

  const calculateTotals = useCallback(() => {
    const orderTotal = {
      InQueue: { shop: 0, company: 0 },
      Building: { shop: 0, company: 0 },
      Finished: { shop: 0, company: 0 },
      "On Hold": { shop: 0, company: 0 },
    };

    filteredOrders.forEach((order) => {
      if (orderTotal[order.status]) {
        orderTotal[order.status].shop += order.grandTotal?.shop || 0;
        orderTotal[order.status].company += order.grandTotal?.company || 0;
      }
    });

    const invoiceTotal = {
      New: { shop: 0, company: 0 },
      Denied: { shop: 0, company: 0 },
      Adjusted: { shop: 0, company: 0 },
      Approved: { shop: 0, company: 0 },
    };

    filteredInvoices.forEach((invoice) => {
      if (invoiceTotal[invoice.status]) {
        invoiceTotal[invoice.status].shop += invoice.order?.grandTotal?.shop || 0;
        invoiceTotal[invoice.status].company += invoice.order?.grandTotal?.company || 0;
      }
    });

    // console.log("Calculated order totals:", orderTotal);
    // console.log("Calculated invoice totals:", invoiceTotal);
    setOrderTotals(orderTotal);
    setInvoiceTotals(invoiceTotal);
  }, [filteredOrders, filteredInvoices]);

  useEffect(() => {
    calculateTotals();
  }, [filteredOrders, filteredInvoices, calculateTotals]);

  // console.log("Filtered orders:", filteredOrders);
  // console.log("Filtered invoices:", filteredInvoices);

  const getOrderCountByStatus = (status) => {
    return filteredOrders.filter((order) => order.status === status).length || 0;
  };

  const getInvoiceCountByStatus = (status) => {
    return filteredInvoices.filter((invoice) => invoice.status === status).length || 0;
  };

  const handleShopChange = (selectedOption) => {
    console.log("Shop changed to:", selectedOption);
    setSelectedShop(selectedOption);
  };

  const renderStatistic = (title, value, prefix, color, precision = 2) => (
    <Spin spinning={isLoading} size="large">
      <Statistic title={title} value={isLoading ? "-" : value} prefix={prefix} valueStyle={{ color }} precision={precision} />
    </Spin>
  );

  // if (!isDataReady) {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
  //       <Spin size="large" />
  //     </div>
  //   );
  // }

  return (
    <div style={{ padding: "24px" }}>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2}>Company Dashboard</Title>
        </Col>
        <Col>
          <Select
            options={shopOptions}
            value={selectedShop}
            onChange={handleShopChange}
            placeholder="Select a shop"
            isClearable={false}
            isSearchable={true}
            isDisabled={isLoading}
            styles={{
              container: (provided) => ({
                ...provided,
                width: 400,
              }),
            }}
          />
        </Col>
      </Row>
      <Alerts />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Card title="Total Order Value" style={cardStyle} hoverable>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                {renderStatistic(
                  "Shop Total",
                  filteredOrders.reduce((sum, order) => sum + (order.grandTotal?.shop || 0), 0),
                  <DollarOutlined />,
                  "#1890ff"
                )}
              </Col>
              <Col span={12}>
                {renderStatistic(
                  "Company Total",
                  filteredOrders.reduce((sum, order) => sum + (order.grandTotal?.company || 0), 0),
                  <DollarOutlined />,
                  "#52c41a"
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card title="Total Invoice Value" style={cardStyle} hoverable>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                {renderStatistic(
                  "Shop Total",
                  filteredInvoices.reduce((sum, invoice) => sum + (invoice.order?.grandTotal?.shop || 0), 0),
                  <DollarOutlined />,
                  "#1890ff"
                )}
              </Col>
              <Col span={12}>
                {renderStatistic(
                  "Company Total",
                  filteredInvoices.reduce((sum, invoice) => sum + (invoice.order?.grandTotal?.company || 0), 0),
                  <DollarOutlined />,
                  "#52c41a"
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col xs={24}>
          <Card title="Order Status Totals" style={cardStyle} hoverable>
            <Row gutter={[16, 16]}>
              {["InQueue", "Building", "Finished", "On Hold"].map((status) => (
                <Col xs={24} sm={12} md={6} key={status}>
                  <Card title={status} size="small">
                    {renderStatistic("Count", getOrderCountByStatus(status), null, getStatusColor(status).color, 0)}
                    {renderStatistic("Shop Total", orderTotals[status]?.shop || 0, <DollarOutlined />, "#1890ff")}
                    {renderStatistic("Company Total", orderTotals[status]?.company || 0, <DollarOutlined />, "#52c41a")}
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col xs={24}>
          <Card title="Invoice Status Totals" style={cardStyle} hoverable>
            <Row gutter={[16, 16]}>
              {["New", "Denied", "Adjusted", "Approved"].map((status) => (
                <Col xs={24} sm={12} md={6} key={status}>
                  <Card title={status} size="small">
                    {renderStatistic("Count", getInvoiceCountByStatus(status), null, getInvoiceStatusColor(status).color, 0)}
                    {renderStatistic("Shop Total", invoiceTotals[status]?.shop || 0, <DollarOutlined />, "#1890ff")}
                    {renderStatistic("Company Total", invoiceTotals[status]?.company || 0, <DollarOutlined />, "#52c41a")}
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col xs={24}>
          <Card title="Shop Payment Trends (Standard GP)" style={cardStyle} hoverable>
            {shopPaymentData.length === 0 ? <Spin size="large" /> : <PaymentLineChart paymentData={shopPaymentData} />}
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col xs={24}>
          <Card title="Company Payment Trends (Company GP)" style={cardStyle} hoverable>
            {companyPaymentData.length === 0 ? <Spin size="large" /> : <PaymentLineChart paymentData={companyPaymentData} />}
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col xs={24} md={12}>
          <Card title="Order Status Chart" style={cardStyle} hoverable>
            {!chartOrders ? <Spin size="large" /> : <OrderChartOne orders={chartOrders} />}
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Invoice Status Chart" style={cardStyle} hoverable>
            {!chartInvoices ? <Spin size="large" /> : <InvoicesChartOne invoices={chartInvoices} />}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case "InQueue":
      return { color: "#1890ff" };
    case "Building":
      return { color: "#faad14" };
    case "Finished":
      return { color: "#52c41a" };
    case "On Hold":
      return { color: "#ff4d4f" };
    default:
      return { color: "#1890ff" };
  }
};

const getInvoiceStatusColor = (status) => {
  switch (status) {
    case "New":
      return { color: "#1890ff" };
    case "Denied":
      return { color: "#ff4d4f" };
    case "Adjusted":
      return { color: "#faad14" };
    case "Approved":
      return { color: "#52c41a" };
    default:
      return { color: "#1890ff" };
  }
};

export default CompanyHome;
