import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import OrderContext from "../../../../context/order/orderContext";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import { useForm, Controller } from "react-hook-form";
import { DatePicker, Input, Button, Typography, Space, Card, Table, Form } from "antd";
import moment from "moment";

const { Title } = Typography;

const defaultValues = {
  invoiceNumber: "",
  invoicedDate: moment(),
  billTo: "StorMor",
  order: {},
};

const NewInvoiceForm = ({ setModalVisible }) => {
  const navigate = useNavigate();

  const { control, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  const orderContext = useContext(OrderContext);
  const { currentOrder, updateOrder, clearCurrentOrder } = orderContext;
  const invoiceContext = useContext(InvoiceContext);
  const { addInvoice } = invoiceContext;
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    if (currentOrder) {
      console.log("Current Order:", currentOrder);
      const serialArr = currentOrder.serialNumber.split("-");
      const theInvoiceNum = serialArr[serialArr.length - 1];
      setValue("invoiceNumber", theInvoiceNum);
      setValue("order", currentOrder);
    }
  }, [currentOrder, setValue]);

  const onSubmit = (data) => {
    const msgs = [];
    let theDate = new Date();
    const newMsg = {
      madeBy: user.userName,
      date: theDate,
      msg: `Invoice created by ${user.name}.`,
    };
    msgs.push(newMsg);
    data.messages = msgs;
    setModalVisible(false);
    currentOrder.status = "Invoiced";
    currentOrder.invoicedDate = new Date();
    updateOrder(currentOrder);
    addInvoice(data);
    clearCurrentOrder();
  };

  const columns = [
    {
      title: "ITEM",
      key: "item",
      render: (text, record) => <span>{record.serialNumber}</span>,
    },
    {
      title: "QTY",
      key: "qty",
      render: () => <span>1</span>,
    },
    {
      title: "RATE",
      key: "rate",
      render: (text, record) => (
        <span>
          {record.grandTotal.shop.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
    {
      title: "AMOUNT",
      key: "amount",
      render: (text, record) => (
        <span>
          {record.grandTotal.shop.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },
  ];

  return (
    <Card
      className="mt-4"
      style={{
        borderRadius: "12px",
        boxShadow: "0 6px 16px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.1)",
        border: "1px solid #e8e8e8",
        width: "90%",
        height: "90%",
        margin: "0 auto",
        position: "relative",
        paddingBottom: "70px",
      }}
    >
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" style={{ height: "100%" }}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Title level={2}>Invoice</Title>
          <Space direction="horizontal" size="large" style={{ width: "100%", justifyContent: "space-between" }}>
            <Form.Item label="Invoice Number" required>
              <Controller
                name="invoiceNumber"
                control={control}
                rules={{ required: "Invoice number is required" }}
                render={({ field }) => <Input {...field} addonBefore="INVOICE #" style={{ width: "300px" }} />}
              />
            </Form.Item>
            <Form.Item label="Invoice Date" required>
              <Controller
                name="invoicedDate"
                control={control}
                rules={{ required: "Invoice date is required" }}
                render={({ field }) => <DatePicker {...field} format="MM/DD/YYYY" style={{ width: "300px" }} />}
              />
            </Form.Item>
            <Form.Item label="Bill To" required>
              <Controller
                name="billTo"
                control={control}
                rules={{ required: "Bill to is required" }}
                render={({ field }) => <Input {...field} addonBefore="BILL TO:" style={{ width: "300px" }} />}
              />
            </Form.Item>
          </Space>
          <Table columns={columns} dataSource={currentOrder ? [currentOrder] : []} pagination={false} />
        </Space>
      </Form>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          padding: "20px 24px",
          borderTop: "1px solid #f0f0f0",
          background: "white",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button type="primary" onClick={handleSubmit(onSubmit)} style={{ backgroundColor: "#52c41a", borderColor: "#52c41a" }}>
          Create Invoice
        </Button>
      </div>
    </Card>
  );
};

export default NewInvoiceForm;
