import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Modal } from "antd";
import AddTrimColor from "./AddTrimColor";

const TrimColorColumns = ({ trimColors, setSelectedTrimColor, selectedTrimColor }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRemove = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this User?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        // clearMaterialOrders();
        // getMaterialOrders();
      },
    });
  };

  const handleEdit = (color) => {
    setSelectedTrimColor(color);
    showModal();
  };

  const columns = [
    {
      title: "Color",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record.color}</span>;
      },
    },
    {
      title: "Id",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record._id}</span>;
      },
    },
    {
      title: "Actions",
      fixed: "right",
      width: "20%",
      render: (text, record) => (
        <Fragment>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleEdit(record)}>
            View
          </button>
          {/* <button className='btn btn-xs btn-outline-danger' type='button' onClick={() => handleRemove(record._id)}>
            Delete
          </button> */}
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <Modal
        // title="Add User"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} // Remove the default footer buttons
        bodyStyle={{
          maxHeight: "50vh", // Adjust the height based on your needs
          overflowY: "auto",
        }}
      >
        <AddTrimColor selectedTrimColor={selectedTrimColor} setSelectedTrimColor={setSelectedTrimColor} />
      </Modal>
      <Table
        pagination={{ pageSize: 200 }}
        loading={trimColors ? false : true}
        columns={columns}
        dataSource={trimColors && trimColors}
        rowKey="_id"
        bordered={true}
        onChange={onChange}
        scroll={{ y: "45vh" }}
      />
    </Fragment>
  );
};

export default TrimColorColumns;
