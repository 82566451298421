import {
  ADD_ORDER,
  DELETE_ORDER,
  CLEAR_ORDERS,
  SET_CURRENT_ORDER,
  CLEAR_CURRENT_ORDER,
  UPDATE_ORDER,
  FILTER_ORDERS,
  CLEAR_FILTER_ORDER,
  ORDER_ERROR,
  GET_ORDERS,
  STATUS_CHANGE_ORDER,
  SWITCH_LOADINGORDER,
  SET_CURRENT_SHOP_ORDERS,
  UPDATE_ORDERS,
  SET_LOADING_ORDERS,
  GET_ORDER_COUNT,
  CLEAR_ORDER_COUNT,
  SET_PAGINATION,
  CLEAR_SEARCHED_ORDER,
  SET_SEARCHED_ORDER,
  SET_SERIAL_NUMBER_SEARCH,
  GET_ORDERS_CHART,
  UPDATE_ORDER_STATUS,
  DELETE_DUP_ORDER,
  REVERT_UPDATE_ORDER,
  GET_TOP_ORDERS,
  GET_REPORT_ORDERS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loadingOrders: false,
      };
    case GET_TOP_ORDERS:
      return {
        ...state,
        topOrders: action.payload,
        loadingOrders: false,
      };
    case GET_REPORT_ORDERS:
      return {
        ...state,
        reportOrders: action.payload,
        loadingOrders: false,
      };
    case SET_CURRENT_SHOP_ORDERS:
      return {
        ...state,
        currentShopOrders: action.payload,
        loading: false,
      };
    case ADD_ORDER:
      return {
        ...state,
        orders: action.payload,
        loadingOrders: false,
      };
    case UPDATE_ORDER_STATUS:
      return {
        ...state,
        orders: state.orders && state.orders.map((order) => (order._id === action.payload._id ? action.payload : order)),
        loading: false,
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orders: state.orders && state.orders.map((order) => (order._id === action.payload._id ? action.payload : order)),
        loadingOrders: false,
      };
    case UPDATE_ORDERS:
      return {
        ...state,
        orders: null,
        loadingOrder: false,
      };
    case DELETE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => order._id !== action.payload),
        loading: false,
      };
    case DELETE_DUP_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => order.serialNumber !== action.payload),
        loading: false,
      };
    case CLEAR_ORDERS:
      return {
        ...state,
        orders: null,
        filteredOrder: null,
        error: null,
        currentOrder: null,
        reportOrders: [],
        topOrders: [],
      };
    case SET_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: action.payload,
      };
    case CLEAR_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: null,
      };
    case FILTER_ORDERS:
      return {
        ...state,
        filteredOrder: state.orders.filter((order) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return order.serialNumber.match(regex);
        }),
      };
    case CLEAR_FILTER_ORDER:
      return {
        ...state,
        filteredOrder: null,
      };
    case ORDER_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingOrder: false,
      };
    case STATUS_CHANGE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => order._id !== action.payload._id),
        loading: false,
      };
    case SWITCH_LOADINGORDER:
      return {
        ...state,
        loadingOrder: true,
      };
    case SET_LOADING_ORDERS:
      return {
        ...state,
        loadingOrders: action.payload,
      };
    case SET_SEARCHED_ORDER:
      return {
        ...state,
        searchedOrder: action.payload,
        loadingOrders: false,
      };
    case CLEAR_SEARCHED_ORDER:
      return {
        ...state,
        searchedOrder: null,
      };
    case GET_ORDER_COUNT:
      return {
        ...state,
        orderCount: action.payload,
      };
    case CLEAR_ORDER_COUNT:
      return {
        ...state,
        orderCount: 0,
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case SET_SERIAL_NUMBER_SEARCH:
      return {
        ...state,
        serialNumber: action.payload,
      };
    case GET_ORDERS_CHART:
      return {
        ...state,
        chartOrders: action.payload,
      };
    case REVERT_UPDATE_ORDER:
      return {
        ...state,
        orders: state.orders.map((oldOrder) => (oldOrder._id === action.payload._id ? action.payload : oldOrder)),
        loadingOrders: false,
      };
    default:
      return state;
  }
};
