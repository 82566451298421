import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DealerContext from "../../../../context/dealer/dealerContext";
import ShopContext from "../../../../context/shop/shopContext";
import AuthContext from "../../../../context/auth/authContext";
import { Form, Input, Button, Card, Typography, Row, Col } from "antd";

const { Title } = Typography;

const initialState = {
  name: "",
  shop: {
    label: "",
    value: {},
  },
  city: "",
  haulPercent: 0,
};

const AddDealer = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState(initialState);
  const { name, shop, city, haulPercent } = values;

  const dealerContext = useContext(DealerContext);
  const { addDealer, currentDealer, updateDealer } = dealerContext;

  const shopContext = useContext(ShopContext);
  const { getShops, shops } = shopContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    if (currentDealer) {
      setValues({
        name: currentDealer.name,
        shop: {
          label: currentDealer.shop.name,
          value: currentDealer.shop._id,
        },
        city: currentDealer.city,
        haulPercent: currentDealer.haulPercent,
      });
    }
    getShops();
  }, []);

  const shopOptions =
    shops &&
    shops.map((option) => {
      return { label: `${option.name}`, value: option._id };
    });

  const handleSubmit = () => {
    if (currentDealer) {
      updateDealer(currentDealer._id, values);
    } else {
      console.log("values", values);
      addDealer(values);
    }
    setValues(initialState);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const setShop = (event) => {
    setValues({ ...values, shop: event });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleNameChange = (e) => {
    let dealerName = e.target.value;
    setValues({ ...values, name: dealerName });
  };

  return (
    <div className="container-fluid">
      <Row justify="center">
        <Col span={24}>
          <Button type="danger" className="float-end" onClick={handleBack}>
            Back
          </Button>
        </Col>
      </Row>
      <Row justify="center" className="mt-4">
        <Col span={12}>
          <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", border: "1px solid #e8e8e8", borderRadius: "8px" }}>
            <Title level={4} className="text-center">
              {currentDealer ? "Edit Dealer" : "Add Dealer"}
            </Title>
            <Form layout="vertical" onFinish={handleSubmit}>
              <Form.Item label="Shop">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={shop}
                  placeholder={shop}
                  name={shop}
                  value={shop}
                  options={shopOptions}
                  onChange={setShop}
                />
              </Form.Item>
              <Form.Item label="Dealer Name">
                <Input type="text" name="name" value={name} onChange={handleNameChange} />
              </Form.Item>
              <Form.Item label="City">
                <Input type="text" name="city" value={city} onChange={handleChange} />
              </Form.Item>
              <Form.Item label="Haul Percent">
                <Input type="number" name="haulPercent" step="0.01" value={haulPercent} onChange={handleChange} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="float-end" disabled={user.viewOnly}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddDealer;
